import { PURGE } from 'redux-persist/lib/constants';
import {
  CLEAR_SELECTED_SUBJECT,
  FETCHED_SUBJECT_DETAILS,
  FETCH_SUBJECTS,
  SET_ERROR_SUBJECTS,
  SET_SELECTED_SUBJECT,
  STUDENT_SUBJECT_STATUS_FETCHED,
} from '../types';

const initialState = {
  subjectDetails: null,
  subjects: null,
  selectedSubject: null,
  error: null,
  status: null,
};

export const subjects = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBJECTS: {
      return {
        ...state,
        subjects: action.payload,
      };
    }
    case STUDENT_SUBJECT_STATUS_FETCHED: {
      return {
        ...state,
        selectedSubject: {
          ...state.selectedSubject,
          isFinished: action.payload,
        },
      };
    }
    case SET_SELECTED_SUBJECT: {
      return {
        ...state,
        selectedSubject: state.subjects
          ? [...state.subjects].find(subject => subject.id === action.payload)
          : null,
      };
    }
    case CLEAR_SELECTED_SUBJECT: {
      return {
        ...state,
        selectedSubject: null,
      };
    }
    case SET_ERROR_SUBJECTS: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case FETCHED_SUBJECT_DETAILS: {
      return {
        ...state,
        subjectDetails: action.payload,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
