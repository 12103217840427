import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import { COMMENT_CREATED, SET_ERROR_COMMENTS } from '../types';
import { fetchCurrentLogDetails } from './internshipActions';

export const addCommentToInternshipLog = comment => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${getState().internship.currentLog.id}/comments/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          comment,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(commentCreated(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMMENTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const commentCreated = comment => ({
  type: COMMENT_CREATED,
  payload: comment,
});

export const resolveCommentForInternshipLog = (logId, commentId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${logId}/comments/${commentId}/resolve`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMMENTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_COMMENTS,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchCurrentLogDetails(logId));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMMENTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const editCommentForInternshipLog = (logId, commentId, comment) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${logId}/comments/${commentId}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          comment,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMMENTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_COMMENTS,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchCurrentLogDetails(logId));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMMENTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};
