import { PURGE } from 'redux-persist/lib/constants';
import {
  DOCUMENT_VERIFIED,
  FETCHED_PROFESSORS_FOR_SUBJECT,
  FETCHED_PROFESSOR_DETAILS,
  PROFESSORS_FETCHED,
  SET_ERROR_PROFESSOR,
  SET_PAGE_PER_PAGE_PROFESSORS_LIST,
} from '../types';

export const InitState = {
  professorDetails: null,
  professorsList: null,
  professorsForSubjectList: null,
  error: null,
  status: null,
  totalPages: null,
  page: 1,
  perPage: 10,
  verifiedPDF: null,
};

export const professors = (state = InitState, action) => {
  switch (action.type) {
    case PROFESSORS_FETCHED: {
      return {
        ...state,
        professorsList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
      };
    }
    case FETCHED_PROFESSORS_FOR_SUBJECT: {
      return {
        ...state,
        professorsForSubjectList: action.payload,
      };
    }
    case SET_ERROR_PROFESSOR: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case DOCUMENT_VERIFIED: {
      return {
        ...state,
        verifiedPDF: action.payload,
      };
    }
    case SET_PAGE_PER_PAGE_PROFESSORS_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case FETCHED_PROFESSOR_DETAILS: {
      return {
        ...state,
        professorDetails: action.payload,
      };
    }
    case PURGE: {
      return InitState;
    }
    default:
      return state;
  }
};
