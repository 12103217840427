import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Container, Table } from 'reactstrap';
import {
  executeRequestsWithLoading,
  rejectInternshipRequest,
} from '../../../../store/actions';
import '../../../../styles/ListTable.scss';
import AlertModal from '../../../SharedComponents/InfoModal/AlertModal/AlertModal';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';
export const StudentInternshipRequestList = props => {
  const { t } = useTranslation();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [request, setRequest] = useState(null);
  const [headers, setHeaders] = useState(
    props.internshipRequestsListPage
      ? [
        {
          header: t(
            'list.internshipRequestsProfessorList.header.studentName'
          ),
          headerKey: 'studentName',
          isSorted: false,
          isSortedDesc: false,
        },
        {
          header: t('list.internshipRequestsProfessorList.header.email'),
          headerKey: 'email',
          isSorted: false,
          isSortedDesc: false,
        },
        {
          header: t(
            'list.internshipRequestsProfessorList.header.studentStudyType'
          ),
          headerKey: 'studentStudyType',
          isSorted: false,
          isSortedDesc: false,
        },
        {
          header: t(
            'list.internshipRequestsProfessorList.header.subjectName'
          ),
          headerKey: 'subjectName',
          isSorted: false,
          isSortedDesc: false,
        },
        {
          header: t(
            'list.internshipRequestsProfessorList.header.companyName'
          ),
          headerKey: 'companyName',
        },
        {
          header: t('list.internshipRequestsProfessorList.header.isPublic'),
          headerKey: 'isPublic',
        },
        {
          header: t('list.internshipRequestsProfessorList.header.actions'),
          headerKey: 'actions',
        },
      ]
      : [
        {
          header: t('list.internshipRequestsStudentList.header.name'),
          headerKey: 'name',
        },
        {
          header: t(
            'list.internshipRequestsStudentList.header.contactPersonEmail'
          ),
          headerKey: 'contactPersonEmail',
        },
        {
          header: t('list.internshipRequestsStudentList.header.city'),
          headerKey: 'city',
        },
        {
          header: t('list.internshipRequestsStudentList.header.url'),
          headerKey: 'url',
        },
        {
          header: t('list.internshipRequestsStudentList.header.startDate'),
          headerKey: 'startDate',
        },
        {
          header: t('list.internshipRequestsStudentList.header.actions'),
          headerKey: 'actions',
        },
      ]
  );
  const dispatch = useDispatch();
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const approveInternshipRequestHandler = async (approved, request) => {
    if (approved) {
      props.setCurrentInternship(request);
      if (props.setShowInternshipRequestInfoModal) {
        props.setShowInternshipRequestInfoModal(true);
      }
    }
    if (!props.internshipRequestsListPage && !approved) {
      await executeRequestsWithLoading(
        [dispatch(rejectInternshipRequest(request.id))],
        props.setLoading,
        mountedRef,
        true
      );
    }
    if (
      !approved &&
      props.internshipRequestsListPage &&
      props.fetchInternshipRequestsByPage
    ) {
      await executeRequestsWithLoading(
        [
          dispatch(
            rejectInternshipRequest(
              request.id,
              props.internshipRequestsListPage
            )
          ),
        ],
        props.setLoading,
        mountedRef,
        true
      );
    }
  };

  const checkPublic = status => {
    switch (status) {
      case true:
        return (
          <div className='active'>{t('list.companiesList.public.yes')}</div>
        );
      case false:
        return (
          <div className='inactive'>{t('list.companiesList.public.no')}</div>
        );
      default:
        break;
    }
  };

  return (
    <Container className='student-internship-requests-list' fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          data={props.internshipRequestList}
          sortDataByKey={props.setSorting}
        />
        <tbody className='list-table__item-row'>
          {props.internshipRequestList.map((request, index) => (
            <tr className='spacer  item-row' key={index}>
              <td>
                {props.internshipRequestsListPage
                  ? request.studentName
                  : request.companyName}
              </td>
              <td>
                <Table width='100%'>
                  <tbody>
                    <tr style={{ display: 'flex', flexDirection: 'column' }}>
                      <td
                        id='open-send-email-modal'
                        onClick={() => {
                          if (props.internshipRequestsListPage) {
                            props.setSelectedStudent({
                              id: request?.studentId,
                              name: request.studentName,
                            });
                            props.setShowSendEmailModal(true);
                          }
                        }}
                      >
                        {props.internshipRequestsListPage
                          ? request.studentEmail
                          : request.contactPersonEmail}
                      </td>
                      {/* <td>{request.contact.phone}</td> */}
                    </tr>
                  </tbody>
                </Table>
              </td>
              {!props.internshipRequestsListPage && <td>{request.city}</td>}
              <td>
                {props.internshipRequestsListPage ? (
                  request.studentStudyType
                ) : (
                  <a
                    className='url'
                    rel='noopener noreferrer'
                    target='_blank'
                    href={`//${request.url}`}
                  >
                    {request.url}
                  </a>
                )}
              </td>

              <td>
                {props.internshipRequestsListPage
                  ? request.subjectName
                  : moment(request.startDate).format('DD.MM.YYYY.')}
              </td>
              {props.internshipRequestsListPage && (
                <>
                  <td>{request.companyName}</td>
                  <td>{checkPublic(!request.isPrivate)}</td>
                </>
              )}
              <td>
                <Table width='100%'>
                  <tbody>
                    {props.role === 'professor' || props.role === 'admin' ? (
                      <tr className='action-row'>
                        <td>
                          <button
                            onClick={() => {
                              approveInternshipRequestHandler(true, request);
                            }}
                            className='action-button choose'
                          >
                            {t('buttons.selectInternshipRequest')}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              setRequest(request);
                              setShowAlertModal(true);
                            }}
                            className='action-button decline'
                          >
                            {t('buttons.removeInternshipRequest')}
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr className='action-row'>
                        <td>{t('internshipRequestSent')}</td>
                        <td>
                          <button
                            onClick={() => {
                              approveInternshipRequestHandler(false, request);
                            }}
                            className='action-button decline'
                          >
                            {t('buttons.removeInternshipRequest')}
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showAlertModal && (
        <AlertModal
          alertInfotext={t(
            'modal.alertModal.doYouWantToRemoveInternshipRequest'
          )}
          confirmOptions={() => {
            setShowAlertModal(false);
            approveInternshipRequestHandler(false, request);
          }}
          declineOptions={() => {
            setShowAlertModal(false);
            setRequest(null);
          }}
        />
      )}
    </Container>
  );
};

StudentInternshipRequestList.propTypes = {
  internshipRequestList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentInternship: PropTypes.func,
  setShowSendEmailModal: PropTypes.func,
  setSelectedStudent: PropTypes.func,
  role: PropTypes.string,
  internshipRequestsListPage: PropTypes.bool,
  fetchInternshipRequestsByPage: PropTypes.func,
  setSorting: PropTypes.func,
  setLoading: PropTypes.func,
  setShowInternshipRequestInfoModal: PropTypes.func,
};
