import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchStudiesProfessor,
} from '../../../store/actions';
import AddButton from '../../SharedComponents/AddButton/AddButton';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { AddOrUpdateStudyModal } from './AddOrUpdateStudyModal/AddOrUpdateStudyModal';
import StudiesList from './StudiesList/StudiesList';
import './StudiesListPage.scss';

const StudiesListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const studies = useSelector(state => state.studies.studies);

  const [loading, setLoading] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [showAddOrUpdateSubjectModal, setShowAddOrUpdateStudyModal] =
    useState(false);

  useEffect(() => {
    async function getStudiesAsProfessor() {
      await executeRequestsWithLoading(
        [dispatch(fetchStudiesProfessor())],
        setLoading,
        mountedRef
      );
    }
    getStudiesAsProfessor();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <div className='class-list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.studiesList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={20}
          data={
            <div className='class-list__actions'>
              <AddButton
                customClassName='add-subject-button'
                setShowModal={setShowAddOrUpdateStudyModal}
                text={t('buttons.addNewStudy')}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          dataClass='class-list__list'
          data={
            <div>
              {loading && <div className='loader-small' />}
              <div hidden={loading}>
                {studies && studies.length > 0 ? (
                  <StudiesList
                    setShowAddOrUpdateStudyModal={setShowAddOrUpdateStudyModal}
                    setSelectedStudy={setSelectedStudy}
                    studies={studies}
                  />
                ) : (
                  t('notFound.studiesList.noListFetched')
                )}
              </div>
            </div>
          }
        />
        {showAddOrUpdateSubjectModal && (
          <AddOrUpdateStudyModal
            setLoading={setLoading}
            setShowAddOrUpdateStudyModal={setShowAddOrUpdateStudyModal}
            setStudy={setSelectedStudy}
            study={selectedStudy}
          />
        )}
      </div>
    </div>
  );
};

export default StudiesListPage;
