import PropTypes from 'prop-types';
import React from 'react';
import { CalendarContainer } from 'react-datepicker';

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element) {
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

const DatepickerCustomContainer = (
  { className, children, mainHeaderText, smallHeaderText },
  resetDateInput
) => {
  return (
    <CalendarContainer className={className}>
      <div className='datepicker-header__header'>
        <div className='header-calendar-image'></div>
        <div className='datepicker-header__header__header-text'>
          <div className='header-main-text'>{mainHeaderText}</div>
          <div className='header-small-text'>{smallHeaderText}</div>
        </div>
        <div
          className='header-close-icon'
          onClick={() => {
            if (resetDateInput) {
              resetDateInput();
            }
            const element = document.getElementsByClassName(
              'react-datepicker__portal'
            );
            if (element.length) {
              simulateMouseClick(element[0]);
            }
          }}
        ></div>
      </div>
      <div style={{ position: 'relative' }}>{children}</div>
    </CalendarContainer>
  );
};

DatepickerCustomContainer.propTypes = {
  mainHeaderText: PropTypes.string,
  smallHeaderText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  setOpen: PropTypes.func,
  resetDateInput: PropTypes.func,
};

export default DatepickerCustomContainer;
