import React from 'react';
import { Container } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';

import { Routes } from './Routes';
import SuccessModal from './SharedComponents/InfoModal/SuccessModal';
import Navbar from './SharedComponents/Navbar/Navbar';

export const App = () => {
  return (
    <Router>
      <Container fluid>
        <Navbar />
        <Routes />
      </Container>
      <SuccessModal />
    </Router>
  );
};
