import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setPageAndPerPageCompaniesList } from '../../../store/actions';
import Pagination from '../../SharedComponents/Pagination/Pagination';

const CompaniesPagination = props => {
  const pages = useSelector(state => state.companies.totalPages);
  const dataPerPage = useSelector(state => state.companies.perPage);
  const page = useSelector(state => state.companies.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageCompaniesList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      page={page}
      dataPerPage={dataPerPage ? dataPerPage : 100}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.fetchCompaniesByPage}
    />
  );
};

CompaniesPagination.propTypes = {
  fetchCompaniesByPage: PropTypes.func,
};

export default CompaniesPagination;
