/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './components/App';
import { intercept } from './interceptor';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store/store';

import './styles/main.scss';
import './i18n';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

intercept();
reportWebVitals();
