/* istanbul ignore file */
import firebase from 'firebase/app';
import 'firebase/messaging';
import settings from '../settings';
import { notificationArrived } from './actions';
import { store } from './store';

const firebaseConfig = {
  apiKey: settings.apiKey,
  authDomain: settings.authDomain,
  projectId: settings.projectId,
  storageBucket: settings.storageBucket,
  messagingSenderId: settings.messagingSenderId,
  appId: settings.appId,
  measurementId: settings.measurementId,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const getToken = setToken => {
  return messaging
    .getToken({
      vapidKey: settings.vapidKey,
    })
    .then(currentToken => {
      if (currentToken) {
        setToken(currentToken);
      } else {
        setToken(null);
      }
    })
    .catch(err => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const removeToken = async () => {
  if (messaging) {
    await messaging
      .deleteToken()
      .then(() => {
        return;
      })
      .catch(err => {
        console.log('An error occurred while removing token. ', err);
      });
  }
};

export const onMessageListener = () => {
  messaging.onMessage(payload => {
    store.dispatch(
      notificationArrived({
        title: payload.notification.title,
        message: payload.notification.body,
      })
    );
  });
};
