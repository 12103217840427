import { PURGE } from 'redux-persist/lib/constants';
import { SET_SIGNATURE_TO_USER } from '../types';


const initialState = {
  error: null,
  status: null,
};

export const signature = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNATURE_TO_USER: {
      return {
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
