import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Table } from 'reactstrap';
import '../../../../styles/ListTable.scss';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';

const StudiesList = props => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([
    {
      header: t('list.studiesList.header.name'),
      headerKey: 'name',
    },
    {
      header: t('list.studiesList.header.department'),
      headerKey: 'department',
    },
    { header: t('list.studiesList.header.active'), headerKey: 'active' },
    {
      header: t('list.studiesList.header.actions'),
      headerKey: 'actions',
    },
  ]);

  const checkActive = status => {
    switch (status) {
      case true:
        return <div className='active'>{t('list.studiesList.active.yes')}</div>;
      case false:
        return (
          <div className='inactive'>{t('list.studiesList.active.no')}</div>
        );
      default:
        break;
    }
  };
  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader setHeaders={setHeaders} headers={headers} />
        <tbody className='list-table__item-row'>
          {props.studies.map(study => {
            return (
              <tr className='spacer item-row' key={study.id}>
                <td>{study.name}</td>
                <td>{study.department}</td>
                <td>{checkActive(study?.active)}</td>
                <td>
                  <button
                    id='link-to-study-edit-page'
                    onClick={() => {
                      props.setSelectedStudy(study);
                      props.setShowAddOrUpdateStudyModal(true);
                    }}
                  >
                    {t('buttons.edit')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

StudiesList.propTypes = {
  studies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedStudy: PropTypes.func.isRequired,
  setShowAddOrUpdateStudyModal: PropTypes.func.isRequired,
};

export default StudiesList;
