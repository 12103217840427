import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';

import {
  INTERNSHIP_REQUESTS_LIST_FETCHED,
  SET_ERROR_INTERNSHIP_REQUESTS_LIST,
  SET_PAGE_PER_PAGE_INTERNSHIP_REQUESTS_LIST,
} from '../types';

export const fetchInternshipRequestsList = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internship-requests/pagination?page=${
          getState().internshipRequests.page
        }&per_page=${getState().internshipRequests.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageInternshipRequestsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchInternshipRequestsList());
      } else {
        dispatch(internshipRequestsListFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchInternshipRequestsByQuery = (
  name,
  study,
  studentStudyType,
  selectedSubject,
  sortingOrder,
  headerKey
) => {
  return async (dispatch, getState) => {
    let query = '';
    if (name) query += `&search=${name}`;
    if (selectedSubject && selectedSubject.id)
      query += `&subjectId=${selectedSubject.id}`;
    if (study) query += `&study=${study}`;
    if (studentStudyType)
      query += `&study_type=${studentStudyType.value.toLowerCase()}`;
    if (headerKey) query += `&sort_by=${headerKey}`;

    query += `&sort_order=${
      sortingOrder !== null ? (sortingOrder ? 'asc' : 'desc') : 'desc'
    }`;
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internship-requests/pagination?page=${
          getState().internshipRequests.page
        }&per_page=${getState().internshipRequests.perPage}${query}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageInternshipRequestsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(
          fetchInternshipRequestsByQuery(
            name,
            study,
            studentStudyType,
            selectedSubject,
            sortingOrder,
            headerKey
          )
        );
      } else {
        dispatch(internshipRequestsListFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const internshipRequestsListFetched = data => ({
  type: INTERNSHIP_REQUESTS_LIST_FETCHED,
  payload: data,
});

export const setPageAndPerPageInternshipRequestsList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_INTERNSHIP_REQUESTS_LIST,
  payload: { page, perPage },
});
