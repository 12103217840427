import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Table } from 'reactstrap';
import '../../../../styles/ListTable.scss';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';

const MentorList = props => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([
    {
      header: t('list.mentorsList.header.name'),
      headerKey: 'name',
    },
    {
      header: t('list.mentorsList.header.companyName'),
      headerKey: 'companyName',
      role: false,
    },
    {
      header: t('list.mentorsList.header.contact'),
      headerKey: 'contact',
    },
    {
      header: t('list.mentorsList.header.actions'),
      headerKey: 'actions',
    },
  ]);

  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          role={
            props.studentInternshipMentorSelect || props.studentMentorSelect
          }
        />
        <tbody className='list-table__item-row'>
          {props.mentors.map((mentor, index) => {
            return (
              <tr className='spacer  item-row' key={index}>
                <td>
                  {mentor.firstName === '' && mentor.lastName === ''
                    ? 'Privremeni mentor'
                    : `${mentor.firstName} ${mentor.lastName}`}
                </td>
                {!props.studentInternshipMentorSelect &&
                  !props.studentMentorSelect && <td>{mentor.companyName}</td>}
                <td>
                  <Table width='100%'>
                    <tbody>
                      <tr style={{ display: 'flex', flexDirection: 'column' }}>
                        <td id='open-send-email-modal'>{mentor.email}</td>
                        {mentor.phone ? <td>{mentor.phone}</td> : null}
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td>
                  <button
                    id='link-to-mentor-page'
                    onClick={() => {
                      props.setSelectedMentor(mentor);
                      if (!props.hasMentor) {
                        if (props.studentMentorSelect) {
                          props.setShowSignatureModal(true);
                        } else {
                          if (!props.studentInternshipMentorSelect) {
                            props.setShowMentorDetailsModal(true);
                          }
                        }
                      }
                    }}
                  >
                    {t('buttons.select')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

MentorList.defaultProps = {
  studentInternshipMentorSelect: false,
  studentMentorSelect: false,
};

MentorList.propTypes = {
  mentors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setShowSignatureModal: PropTypes.func,
  studentMentorSelect: PropTypes.bool,
  studentInternshipMentorSelect: PropTypes.bool,
  hasMentor: PropTypes.bool,
  setShowMentorDetailsModal: PropTypes.func,
  setSelectedMentor: PropTypes.func,
};

export default MentorList;
