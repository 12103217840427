import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import './Breadcrumbs.scss';

const Breadcrumbs = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  const routes = [
    { path: '/', breadcrumb: t('breadCrumbs.dashboard') },
    { path: '/students-list', breadcrumb: t('breadCrumbs.studentList') },
    { path: '/summary-list', breadcrumb: t('breadCrumbs.summaryList') },
    { path: '/companies-list', breadcrumb: t('breadCrumbs.companiesList') },
    { path: '/companies', breadcrumb: t('breadCrumbs.companiesList') },
    { path: '/internship-requests', breadcrumb: t('breadCrumbs.internshipRequestsList') },
    { path: '/mentors-list', breadcrumb: t('breadCrumbs.mentorsList') },
    { path: '/subjects', breadcrumb: t('breadCrumbs.subjectsList') },
    { path: '/studies-list', breadcrumb: t('breadCrumbs.studiesList') },
    { path: '/professors-list', breadcrumb: t('breadCrumbs.professorsList') },
    { path: '/audit', breadcrumb: t('breadCrumbs.auditList') },
    { path: '/synchronization', breadcrumb: t('breadCrumbs.synchornizationPage') },
  ];
  const breadcrumbs = useReactRouterBreadcrumbs(routes);

  return (
    <div className='breadcrumbs'>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <span className='breadcrumbs__item' key={match.url}>
          <NavLink
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              return location.pathname === match.url;
            }}
            className='breadcrumbs__item__inactive'
            activeClassName='breadcrumbs__item__active'
            to={
              match.url === '/'
                ? user.role === 'professor' || user.role === 'admin'
                  ? '/professor-homepage'
                  : user.role === 'student'
                    ? '/student-homepage'
                    : '/mentor-homepage'
                : match.url
            }
          >
            <div className='breadcrumbs__item__name'>{breadcrumb}</div>
          </NavLink>
          {breadcrumbs.length - 1 !== index && <div className='divider'>/</div>}
        </span>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
