import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../SharedComponents/Pagination/Pagination';
import { setPageAndPerPageProfessorsList } from '../../../../store/actions';

const ProfessorPagination = props => {
  const pages = useSelector(state => state.professors.totalPages);
  const dataPerPage = useSelector(state => state.professors.perPage);
  const page = useSelector(state => state.professors.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageProfessorsList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      dataPerPage={dataPerPage}
      page={page}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.fetchProfessorsByPage}
    />
  );
};

ProfessorPagination.propTypes = {
  fetchProfessorsByPage: PropTypes.func,
};

export default ProfessorPagination;
