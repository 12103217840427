export const LOGIN_USER = 'LOGIN_USER';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const FETCH_STUDENT_INFO = 'FETCH_STUDENT_INFO';
export const RESET_STUDENT_DATA = 'RESET_STUDENT_DATA';

export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const ACCESS_TOKEN_REFRESHED = 'ACCESS_TOKEN_REFRESHED';
export const FETCH_ACCESS_TOKEN = 'FETCH_ACCESS_TOKEN';
export const  ACCESS_TOKEN_FETCHED = 'ACCESS_TOKEN_FETCHED';

export const SET_ERROR_USER = 'SET_ERROR_USER';

export const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
