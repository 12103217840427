import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Container, Table } from 'reactstrap';
import { sortComapniesListBy } from '../../../store/actions';
import '../../../styles/ListTable.scss';
import ListHeader from '../../SharedComponents/Listheader/ListHeader';

export const CompaniesList = props => {
  const { t } = useTranslation();
  const companies = useSelector(state => state.companies);
  const [headers, setHeaders] = useState([
    {
      header: t('list.companiesList.header.name'),
      headerKey: 'name',
    },
    {
      header: t('list.companiesList.header.contact'),
      headerKey: 'contact',
    },
    {
      header: t('list.companiesList.header.city'),
      headerKey: 'city',
    },
    {
      header: t('list.companiesList.header.website'),
      headerKey: 'url',
    },
    {
      header: t('list.companiesList.header.isPublic'),
      headerKey: 'isPublic',
      role: true,
    },
    {
      header: t('list.companiesList.header.active'),
      headerKey: 'active',
      role: true,
    },
    {
      header: t('list.companiesList.header.actions'),
      headerKey: 'actions',
    },
  ]);

  const checkActive = status => {
    switch (status) {
      case true:
        return (
          <div className='active'>{t('list.companiesList.active.yes')}</div>
        );
      case false:
        return (
          <div className='inactive'>{t('list.companiesList.active.no')}</div>
        );
      default:
        break;
    }
  };

  const checkPublic = status => {
    switch (status) {
      case true:
        return (
          <div className='active'>{t('list.companiesList.public.yes')}</div>
        );
      case false:
        return (
          <div className='inactive'>{t('list.companiesList.public.no')}</div>
        );
      default:
        break;
    }
  };

  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          data={companies && companies.data}
          sortDataByKey={sortComapniesListBy}
          role={
            !props.studentInternshipCompanySelect && props.role !== 'student'
          }
        />
        <tbody className='list-table__item-row'>
          {companies &&
            companies.data.map(company => {
              const sent = company.requestSent;
              return (
                <tr className='spacer item-row' key={company.id}>
                  <td>{company.name}</td>
                  <td>{company.contactPersonEmail}</td>
                  <td>{company.city}</td>
                  <td>
                    <a
                      className='company-url'
                      rel='noopener noreferrer'
                      target='_blank'
                      href={`http://${company.url}`}
                    >
                      {company.url}
                    </a>
                  </td>
                  {!props.studentInternshipCompanySelect &&
                    props.role !== 'student' && (
                    <>
                      <td>{checkPublic(company?.isPublic)}</td>
                      <td>{checkActive(company?.active)}</td>
                    </>
                  )}
                  <td>
                    <button
                      data-tip
                      data-for={
                        (sent ||
                          (props.role === 'student' && !company.hasOpenSlots) ||
                          (props.studentInternshipCompanySelect &&
                            (!company.enableInternshipRequests ||
                              !company.active ||
                              !company.isPublic))) &&
                        'send-company-request-button'
                      }
                      onClick={() => {
                        if (
                          !sent &&
                          company.hasOpenSlots &&
                          props.role === 'student'
                        ) {
                          props.setCompanyToDisplay(company);
                          if (props.setShowCompanymodal) {
                            props.setShowCompanymodal(true);
                          }
                        }
                        if (
                          props.role !== 'student' &&
                          ((company.isPublic &&
                            company.enableInternshipRequests &&
                            company.active &&
                            !sent) ||
                            !props.studentInternshipCompanySelect)
                        ) {
                          props.setCompanyToDisplay(company);
                          if (props.setShowCompanymodal) {
                            props.setShowCompanymodal(true);
                          }
                        }
                      }}
                      className={classNames({
                        'action-button': true,
                        disabled:
                          ((sent || !company.hasOpenSlots) &&
                            props.role === 'student') ||
                          (props.studentInternshipCompanySelect &&
                            (!company.enableInternshipRequests ||
                              !company.active ||
                              sent ||
                              !company.isPublic)),
                      })}
                    >
                      {sent
                        ? t('list.companiesList.button.selected')
                        : t('list.companiesList.button.notSelected')}
                    </button>
                    {(sent ||
                      company.hasOpenSlots === false ||
                      company.enableInternshipRequests === false ||
                      company.active === false ||
                      !company.isPublic) && (
                      <ReactTooltip
                        id='send-company-request-button'
                        place='top'
                        effect='solid'
                      >
                        <span>
                          {company.isPublic
                            ? company.enableInternshipRequests === false ||
                              company.active === false
                              ? t(
                                'list.companiesList.tooltip.internshipRequestsDisabled'
                              )
                              : company.hasOpenSlots === false
                                ? t('list.companiesList.tooltip.noFreeSlots')
                                : sent &&
                                t(
                                  'list.companiesList.tooltip.requestAlreadySent'
                                )
                            : t(
                              'list.companiesList.tooltip.internshipRequestsDisabled'
                            )}
                        </span>
                      </ReactTooltip>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Container>
  );
};

CompaniesList.propTypes = {
  setShowCompanymodal: PropTypes.func,
  setCompanyToDisplay: PropTypes.func,
  role: PropTypes.string,
  studentInternshipCompanySelect: PropTypes.bool,
};
