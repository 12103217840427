import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import isMobileView from '../../../checkForMobileView';
import {
  fetchMentorInfo,
  fetchProfessorInfo,
  fetchStudentInfo,
  logoutUser,
} from '../../../store/actions';
import { StudentIDIcon } from '../../../styles/assets/images/index.js';
import { ChangePasswordModal } from '../ChangePasswordModal/ChangePasswordModal';
import DataDisplay from '../DataDisplay/DataDisplay.jsx';
import MailToButton from '../MailToButton/MailToButton';
import './Navbar.scss';

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfoCard = useRef(null);
  const history = useHistory();
  const user = useSelector(state => state.user);
  const studentInfo = useSelector(state => state.student);
  const mentorInfo = useSelector(state => state.mentors.mentorInfo);
  const professorInfo = useSelector(state => state.professors.professorDetails);

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [displayUserInfo, setDisplayUserInfo] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const component = useRef(null);

  useEffect(() => {
    if (user.role) {
      if (user.role === 'student' && !studentInfo.id) {
        dispatch(fetchStudentInfo());
      }
      if (user.role === 'mentor') {
        dispatch(fetchMentorInfo());
      }
      if (user.role === 'professor') {
        dispatch(fetchProfessorInfo());
      }
    }
  }, [user.role]);

  useEffect(() => {
    if (isMobileView() && userInfoCard.current) {
      userInfoCard.current.scrollIntoView();
    }
  }, [displayUserInfo]);

  useEffect(() => {
    if (mentorInfo && user.role === 'mentor') {
      setUserInfo(mentorInfo);
    }
    if (professorInfo && user.role === 'professor') {
      setUserInfo(professorInfo);
    }
    if (studentInfo && studentInfo.id && user.role === 'student') {
      setUserInfo(studentInfo);
    }
  }, [mentorInfo, studentInfo, professorInfo]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (component.current && !component.current.contains(event.target)) {
        setDisplayUserInfo(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [component]);

  return (
    user.isAuthenticated &&
    user.role && (
      <div className='navbar'>
        <div
          className='role-logged-in'
          onClick={() => {
            switch (user.role) {
              case 'student': {
                history.replace('/student-homepage');
                break;
              }
              case 'admin':
              case 'professor': {
                history.replace('/professor-homepage');
                break;
              }
              case 'mentor': {
                history.replace('/mentor-homepage');
                break;
              }
            }
          }}
        >
          {user.role === 'student' && (
            <img
              className='navbar-role-icon'
              src={StudentIDIcon}
              alt='role-icon'
            />
          )}
          <div className='role-logged-in__text'>
            {user.role === 'student' && `VEVU ${t('userRole.student')}`}
            {user.role === 'professor' && `VEVU ${t('userRole.professor')}`}
            {user.role === 'admin' && `VEVU ${t('userRole.admin')}`}
            {user.role === 'mentor' && `VEVU ${t('userRole.mentor')}`}
          </div>
        </div>
        <div className='navbar__right-side'>
          <div
            ref={component}
            className='navbar__right-side__user-info'
            onClick={() => {
              if (!showChangePasswordModal) {
                if (displayUserInfo) {
                  setDisplayUserInfo(false);
                } else {
                  setDisplayUserInfo(true);
                }
              }
            }}
          >
            <div className='user-name'>
              {t('hello')},{' '}
              <div className='name'>
                {user.role === 'admin'
                  ? t('userRole.admin')
                  : `${userInfo?.firstName} ${userInfo?.lastName}`}
              </div>
            </div>
            <button
              className={classNames({
                'dropdown-button': true,
                'arrow-up': displayUserInfo,
                'arrow-down': !displayUserInfo,
              })}
            />
            {displayUserInfo && (
              <div
                ref={userInfoCard}
                className='user-info-modal'
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {user.role !== 'student' && (
                  <>
                    <DataDisplay
                      headerBolded
                      dataHeader={t('userInfoCard.firstNameAndLastName')}
                      dataClass='user-info-name'
                      data={
                        user.role === 'admin'
                          ? t('userRole.admin')
                          : `${userInfo.firstName} ${userInfo.lastName}`
                      }
                    />
                    {user.role !== 'admin' && (
                      <>
                        <DataDisplay
                          headerBolded
                          dataHeader={t('userInfoCard.phone')}
                          data={`${
                            userInfo.phone ? userInfo.phone : t('unknown')
                          }`}
                        />
                        {user.role === 'mentor' && (
                          <>
                            <DataDisplay
                              headerBolded
                              dataHeader={t('userInfoCard.companyName')}
                              data={userInfo.companyName}
                            />
                            <DataDisplay
                              headerBolded
                              dataHeader={t('userInfoCard.companyID')}
                              data={userInfo.companyOib}
                            />
                          </>
                        )}
                        <DataDisplay
                          headerBolded
                          dataHeader={t('userInfoCard.email')}
                          dataClass='user-info-email'
                          data={userInfo.email}
                        />
                      </>
                    )}
                    <div className='separator'></div>
                  </>
                )}
                <DataDisplay
                  TopSpacing={user.role !== 'student' ? 40 : 0}
                  data={
                    <div className='actions'>
                      {user.role !== 'student' && (
                        <button
                          className='change-password-button'
                          onClick={() => {
                            setShowChangePasswordModal(true);
                            setDisplayUserInfo(false);
                          }}
                        >
                          <div className='change-password-button__text'>
                            {t('userInfoCard.changePassword')}
                          </div>
                        </button>
                      )}
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'var(--hyperlink)',
                          fontSize: '15px',
                          letterSpacing: '0',
                          left: '23px',
                          position: 'relative',
                        }}
                        to='/tutorials'
                      >
                        {t('breadCrumbs.tutorials')}
                      </Link>
                      <div
                        style={{
                          left: '23px',
                          letterSpacing: '0',
                          position: 'relative',
                        }}
                      >
                        <MailToButton
                          label={t('buttons.userSupport')}
                          fontSize={'15px'}
                          color='var(--hyperlink)'
                          mailto='mailto:vlatko.goljevacki@codecons'
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            )}
            {showChangePasswordModal && (
              <ChangePasswordModal
                setShowChangePasswordModal={state => {
                  setShowChangePasswordModal(state);
                  setDisplayUserInfo(true);
                }}
              />
            )}
          </div>
          <button
            type='button'
            className='logout-button'
            onClick={e => {
              e.preventDefault();
              dispatch(logoutUser());
            }}
          >
            <div className='logout-image' />
            <div className='logout-button-text'>{t('buttons.logout')}</div>
          </button>
        </div>
      </div>
    )
  );
};

export default Navbar;
