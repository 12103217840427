import { PURGE } from 'redux-persist/lib/constants';
import {
  AUDIT_LOGS_FETCHED,
  SET_ERROR_AUDIT_LOGS,
  SET_PAGE_PER_PAGE_AUDIT_LOGS_LIST,
} from '../types';

export const InitState = {
  auditList: null,
  error: null,
  status: null,
  totalPages: null,
  page: 1,
  perPage: 10,
};

export const audit = (state = InitState, action) => {
  switch (action.type) {
    case AUDIT_LOGS_FETCHED: {
      return {
        ...state,
        auditList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
      };
    }
    case SET_ERROR_AUDIT_LOGS: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case SET_PAGE_PER_PAGE_AUDIT_LOGS_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case PURGE: {
      return InitState;
    }
    default:
      return state;
  }
};
