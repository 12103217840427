import axios from 'axios';
import settings from '../../settings';
import { logoutAndWipeStorage } from '../../interceptor';

import {
  STUDENT_INFO_FETCHED,
  LOGIN_STUDENT,
  SET_PAGE_PER_PAGE_STUDENT_LIST,
  SET_ERROR_STUDENT,
  FETCH_SUBJECTS,
  FETCH_STUDIES,
  SET_ERROR_STUDIES,
  SET_SELECTED_STUDY,
} from '../types';
import { stopLoading } from './loadingActions';
import Cookies from 'js-cookie';
import i18n from '../../i18n';

export const loginStudent = (accessToken, refreshToken) => ({
  type: LOGIN_STUDENT,
  payload: { accessToken, refreshToken },
});

export const fetchStudentInfo = id => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: id
          ? `${settings.REACT_APP_API_URL}/api/v1/students/${id}/info`
          : `${settings.REACT_APP_API_URL}/api/v1/students/info`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      const data = response.data.data;
      if (
        getState().user.role === 'student' &&
        (!data.firstName || !data.lastName || !data.id)
      ) {
        await logoutAndWipeStorage();
      }
      if (getState().user.role === 'student') {
        dispatch({
          type: FETCH_SUBJECTS,
          payload: data.subjects,
        });
        await dispatch(fetchStudiesStudent());
      } else if (getState().user.role !== 'mentor') {
        if (response.data.data) {
          dispatch({
            type: STUDENT_INFO_FETCHED,
            payload: {
              data: {
                studyType: response.data.data.studies.find(
                  study => study.id === getState().studies.selectedStudy.id
                )?.studyType,
              },
            },
          });
        }
      }
      setTimeout(() => {
        dispatch(stopLoading());
      }, 200);
      dispatch(studentInfoFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDENT,
          payload: {
            error: error.response.data?.data ? error.response.data.data : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchStudiesStudent = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/studies/for-student`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        JSON.stringify(response.data.data) ===
        JSON.stringify(getState().studies.studies)
      ) {
        return;
      } else {
        await dispatch(studiesFetched(response.data.data));
        if (!getState().studies.selectedStudy && response.data.data.length) {
          await dispatch(setSelectedStudy(response.data.data[0]));
        }
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDIES,
          payload: {
            error: error.response.data?.data ? error.response.data.data : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const studiesFetched = data => ({
  type: FETCH_STUDIES,
  payload: data,
});

export const setPageAndPerPageStudentList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_STUDENT_LIST,
  payload: { page, perPage },
});

export const studentInfoFetched = data => ({
  type: STUDENT_INFO_FETCHED,
  payload: { data },
});

export const updatePhoneNumber = phone => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/students/update-phone`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          phone,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return await dispatch(fetchStudentInfo());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDENT,
          payload: {
            error: error.response.data?.data ? error.response.data.data : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const checkStatus = action => {
  switch (action) {
    case 'NOT_SELECTED':
      return i18n.t('internshipStatusOnList.notSelected');
    case 'REQUEST_SENT':
      return i18n.t('internshipStatusOnList.internshipRequestSent');
    case 'CERTIFICATE_IN_REVIEW':
      return i18n.t('internshipStatusOnList.certificateOfEmploymentInReview');
    case 'CERTIFICATE_REJECTED':
      return i18n.t('internshipStatusOnList.certificateOfEmploymentRejected');
    case 'INTERNSHIP_PROCESSING':
      return i18n.t('internshipStatusOnList.internshipProcessing');
    case 'INTERNSHIP_APPROVED':
      return i18n.t('internshipStatusOnList.internshipApproved');
    case 'INTERNSHIP_IN_REVIEW_MENTOR':
      return i18n.t('internshipStatusOnList.mentorReviewingInternship');
    case 'INTERNSHIP_IN_REVIEW_PROFESSOR':
      return i18n.t('internshipStatusOnList.professorReviewingInternship');
    case 'INTERNSHIP_REVIEW_FAIL':
      return i18n.t('internshipStatusOnList.internshipReviewRejected');
    case 'COMPLETED':
      return i18n.t('internshipStatusOnList.internshipCompleted');
    default:
      break;
  }
};

export const checkStatusInInternshipInfo = action => {
  switch (action) {
    case 'PROCESSING':
      return i18n.t('internshipStatusOnStudentPage.processing');
    case 'APPROVED':
      return i18n.t('internshipStatusOnStudentPage.approved');
    case 'IN_REVIEW_MENTOR':
      return i18n.t('internshipStatusOnStudentPage.mentorReviewing');
    case 'IN_REVIEW_PROFESSOR':
      return i18n.t('internshipStatusOnStudentPage.professorReviewing');
    case 'REVIEW_FAIL':
      return i18n.t('internshipStatusOnStudentPage.reviewRejected');
    case 'COMPLETED':
      return i18n.t('internshipStatusOnStudentPage.completed');
    default:
      break;
  }
};

const fetchStudyObject = studyId => {
  return (_dispatch, getState) => {
    return getState().studies?.studies.find(
      savedStudy => savedStudy.id === studyId
    );
  };
};

export const checkStudy = studyId => {
  return dispatch => {
    const studyObject = dispatch(fetchStudyObject(studyId));
    return studyObject.name;
  };
};

export const setSelectedStudy = study => {
  return (dispatch, getState) => {
    dispatch({
      type: STUDENT_INFO_FETCHED,
      payload: { data: { studyType: study ? study.studyType : null } },
    });
    dispatch({
      type: SET_SELECTED_STUDY,
      payload: study
        ? getState().studies.studies.find(
          savedStudy => savedStudy.id === study.id
        )
        : study,
    });
  };
};
