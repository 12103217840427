export const INTERNSHIP_STUDENT_FETCHED = 'INTERNSHIP_STUDENT_FETCHED';
export const INTERNSHIP_REQUESTS_FETCHED = 'INTERNSHIP_REQUESTS_FETCHED';
export const INTERNSHIP_LOGS_FETCHED = 'INTERNSHIP_LOGS_FETCHED';
export const CURRENT_LOG_DETAILS_FETCHED = 'CURRENT_LOG_DETAILS_FETCHED';
export const SHOW_INTERNSHIP_DETAILS = 'SHOW_INTERNSHIP_DETAILS';
export const CURRENT_LOG_UPDATED = 'CURRENT_LOG_UPDATED';
export const SET_ERROR_INTERNSHIP = 'SET_ERROR_INTERNSHIP';
export const SET_INTERNSHIP_DOCUMENT_STATUSES =
  'SET_INTERNSHIP_DOCUMENT_STATUSES';
export const ADD_SUMMARY = 'ADD_SUMMARY';
export const INTERNSHIPS_FOR_MENTOR_FETCHED = 'INTERNSHIPS_FOR_MENTOR_FETCHED';
export const INTERNSHIP_LOG_IMAGES_FETCHED = 'INTERNSHIP_LOG_IMAGES_FETCHED';
export const CERTIFICATE_OF_EMPLOYMENT_FETCHED =
  'CERTIFICATE_OF_EMPLOYMENT_FETCHED';
export const RESET_INTERNSHIP_LOG_IMAGES_FETCHED =
  'RESET_INTERNSHIP_LOG_IMAGES_FETCHED';
export const SET_ERROR_INTERNSHIP_LIST = 'SET_ERROR_INTERNSHIP_LIST';
export const SET_PAGE_PER_PAGE_INTERNSHIPS_LIST =
  'SET_PAGE_PER_PAGE_INTERNSHIPS_LIST';
export const INTERNSHIP_RATINGS_FETCHED = 'INTERNSHIP_RATINGS_FETCHED';
export const SET_RETRY_GENERATION = 'SET_RETRY_GENERATION';
