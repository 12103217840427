import { PURGE } from 'redux-persist/lib/constants';
import {
  ACCESS_TOKEN_REFRESHED,
  STUDENT_LOGGED_IN,
  STUDENT_LOGGED_OUT,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  SET_ERROR_USER,
  ACCESS_TOKEN_FETCHED,
} from '../types';

const initialState = {
  role: null,
  isAuthenticated: false,
  error: null,
  status: null,
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
    case STUDENT_LOGGED_IN: {
      return {
        role: action.payload.role,
        isAuthenticated: true,
        error: null,
        status: null,
      };
    }
    case USER_LOGGED_OUT:
    case STUDENT_LOGGED_OUT: {
      return {
        role: null,
        isAuthenticated: false,
        error: null,
        status: null,
      };
    }
    case ACCESS_TOKEN_REFRESHED: {
      return {
        ...state,
      };
    }
    case ACCESS_TOKEN_FETCHED: {
      return {
        role: action.payload.role,
        isAuthenticated: true,
        error: null,
        status: null,
      };
    }
    case SET_ERROR_USER: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
