import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import reactDom from 'react-dom';
import isMobileView from '../../../checkForMobileView';
import './Modal.scss';

const Modal = props => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView();
    if (isMobileView()) {
      var body = document.getElementsByTagName('body');
      var pageToHide = document.querySelector('#root > .container-fluid');
      if (body && body[0]) {
        body[0].style.minWidth = null;
      }
      if (pageToHide) {
        pageToHide.style.display = 'none';
      }
    }
    return () => {
      if (isMobileView()) {
        if (body && body[0]) {
          body[0].style.minWidth = '1200px';
        }
        if (pageToHide) {
          pageToHide.style.display = null;
        }
      }
    };
  }, []);

  return reactDom.createPortal(
    <div className={props.className ? `modal ${props.className}` : 'modal'}>
      <div ref={ref} className='overlay' onMouseDown={props.closeModal}>
        <div
          className='overlay__children'
          onMouseDown={e => e.stopPropagation()}
        >
          {props.children}
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  noAccidentalClose: PropTypes.bool,
};

export default Modal;
