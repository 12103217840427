import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import {
  AUDIT_LOGS_FETCHED,
  SET_ERROR_AUDIT_LOGS,
  SET_PAGE_PER_PAGE_AUDIT_LOGS_LIST,
} from '../types';

export const fetchAuditLogs = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/audit-logs/pagination?page=${
          getState().audit.page
        }&per_page=${
          getState().audit.perPage
        }&sort_by=loggedAt&sort_order=desc`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageAuditList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchAuditLogs());
      } else {
        dispatch(auditLogsFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_AUDIT_LOGS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchAuditLogsByQuery = (
  name,
  action,
  startDate,
  endDate,
  headerKey,
  sortOrder
) => {
  return async (dispatch, getState) => {
    let query = '';
    if (name) query += `&search=${name}`;
    if (action && action !== 'Sve akcije') query += `&action=${action}`;
    if (startDate && endDate) query += `&date_range=${startDate},${endDate}`;

    query += `&sort_by=${headerKey ? headerKey : 'loggedAt'}&sort_order=${
      sortOrder !== null ? (sortOrder ? 'asc' : 'desc') : 'desc'
    }`;
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/audit-logs/pagination?page=${
          getState().audit.page
        }&per_page=${getState().audit.perPage}${query}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageAuditList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(
          fetchAuditLogsByQuery(
            name,
            action,
            startDate,
            endDate,
            headerKey,
            sortOrder
          )
        );
      } else {
        dispatch(auditLogsFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_AUDIT_LOGS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const auditLogsFetched = data => ({
  type: AUDIT_LOGS_FETCHED,
  payload: data,
});

export const setPageAndPerPageAuditList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_AUDIT_LOGS_LIST,
  payload: { page, perPage },
});
