import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchSubjects,
  fetchSubjectsForProfessor,
} from '../../../store/actions';
import AddButton from '../../SharedComponents/AddButton/AddButton';
import { AddOrUpdateSubjectModal } from '../../SharedComponents/AddOrUpdateSubjectModal/AddOrUpdateSubjectModal';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import SubjectList from './SubjectList/SubjectList';
import './SubjectListPage.scss';

const SubjectListPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const user = useSelector(state => state.user);
  const subjects = useSelector(state => state.subjects.subjects);

  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showSubjectDetailsModal, setShowSubjectDetailsModal] = useState(false);
  const [showAddOrUpdateSubjectModal, setShowAddOrUpdateSubjectModal] =
    useState(false);

  useEffect(() => {
    getSubjects();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getSubjects = useCallback(async () => {
    await executeRequestsWithLoading(
      user.role === 'professor'
        ? [dispatch(fetchSubjectsForProfessor())]
        : [dispatch(fetchSubjects())],
      setLoading,
      mountedRef
    );
  }, [user && user.role]);

  return (
    <div>
      <Breadcrumbs />
      <div className='class-list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.subjectsList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={20}
          data={
            user.role === 'admin' && (
              <div className='class-list__actions'>
                <AddButton
                  customClassName='add-subject-button'
                  setShowModal={setShowAddOrUpdateSubjectModal}
                  text={t('buttons.addNewSubject')}
                />
              </div>
            )
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          dataClass='class-list__list'
          data={
            <div>
              {loading && <div className='loader-small' />}
              <div hidden={loading}>
                {subjects && subjects.length > 0 ? (
                  <SubjectList
                    setShowAddOrUpdateSubjectModal={
                      setShowAddOrUpdateSubjectModal
                    }
                    setShowSubjectDetailsModal={setShowSubjectDetailsModal}
                    setSelectedSubject={setSelectedSubject}
                    selectedSubject={selectedSubject}
                    subjects={subjects}
                  />
                ) : (
                  t('notFound.subjectsList.noListFetched')
                )}
              </div>
            </div>
          }
        />
        {(showAddOrUpdateSubjectModal || showSubjectDetailsModal) && (
          <AddOrUpdateSubjectModal
            setShowAddOrUpdateSubjectModal={setShowAddOrUpdateSubjectModal}
            setShowSubjectDetailsModal={setShowSubjectDetailsModal}
            showSubjectDetailsModal={showSubjectDetailsModal}
            setSubject={setSelectedSubject}
            subject={selectedSubject}
          />
        )}
      </div>
    </div>
  );
};

export default SubjectListPage;
