import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import i18n from '../../i18n';
import settings from '../../settings';
import {
  COMPANIES_FETCHED,
  COMPANY_DETAILS_FETCHED,
  SET_COMPANY_SLOT_CONFIGURATION,
  SET_ERROR_COMPANIES,
  SET_ERROR_COMPANIES_SLOTS,
  SET_PAGE_PER_PAGE_COMPANIES_LIST,
} from '../types';

export const fetchCompanies = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/companies/for-student-subject/${
          getState().subjects.selectedSubject?.id
        }?page=${getState().companies.page}&per_page=${
          getState().companies.perPage
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageCompaniesList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchCompanies());
      } else {
        dispatch(companiesFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const downloadAllPublicCompanies = () => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/download`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES,
        payload: {
          error: null,
          status: response.status,
          message: i18n.t('companiesListSuccessfullyDownloaded'),
        },
      });
      dispatch({
        type: SET_ERROR_COMPANIES,
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
      const file = new Blob([response.data]);
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        `Popis poduzeća-${moment().format('DD.MM.YYYY. HH mm')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url_1);
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchCompaniesProfessor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/pagination?page=${
          getState().companies.page
        }&per_page=${getState().companies.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageCompaniesList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchCompaniesProfessor());
      } else {
        dispatch(companiesFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchCompanyById = companyId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/id/${companyId}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(companyDetailsFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchCompanyByOib = oib => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/oib/${oib}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(companyDetailsFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const sortComapniesListBy = (key, ascending) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/companies/for-student-subject/${
          getState().subjects.selectedSubject.id
        }?sort_by=${key}&sort_order=${ascending ? 'asc' : 'desc'}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(companiesFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const companiesFetched = data => ({
  type: COMPANIES_FETCHED,
  payload: { data },
});

export const companyDetailsFetched = data => ({
  type: COMPANY_DETAILS_FETCHED,
  payload: { data },
});

export const setPageAndPerPageCompaniesList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_COMPANIES_LIST,
  payload: { page, perPage },
});

export const fetchCompaniesByName = name => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/companies/for-student-subject/${
          getState().subjects.selectedSubject.id
        }?search=${name}&page=${getState().companies.page}&per_page=${
          getState().companies.perPage
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageCompaniesList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchCompaniesByName(name));
      } else {
        dispatch(companiesFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchCompaniesByNameProfessor = name => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/companies/pagination?search=${name}&page=${
          getState().companies.page
        }&per_page=${getState().companies.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageCompaniesList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchCompaniesByNameProfessor(name));
      } else {
        dispatch(companiesFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const createNewCompany = (
  company,
  companySlotsConfiguration,
  selectedSubject
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          name: company.name,
          oib: company.oib,
          address: company.address,
          city: company.city,
          postalCode: company.postalCode,
          country: company.country,
          url: company.url,
          contactPersonFullName: company.contactPersonFullName,
          contactPersonPhone: company.contactPersonPhone,
          contactPersonEmail: company.contactPersonEmail,
          economicBranch:
            i18n.t('dropdowns.studiesDropdown.allDepartments') ===
            company.economicBranch
              ? 'all'
              : company.economicBranch,
          active: company.active,
          enableInternshipRequests: company.enableInternshipRequests,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES,
        payload: {
          error: null,
          status: response.status,
          message: null,
        },
      });
      await dispatch(fetchCompaniesProfessor());
      if (companySlotsConfiguration.slots !== null) {
        await dispatch(
          createCompanySlotConfiguration(
            response.data.data.id,
            selectedSubject,
            companySlotsConfiguration.slots,
            companySlotsConfiguration.academicYear
          )
        );
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const updateCompany = company => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/${company.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          name: company.name,
          oib: company.oib,
          address: company.address,
          city: company.city,
          postalCode: company.postalCode,
          country: company.country,
          url: company.url,
          contactPersonFullName: company.contactPersonFullName,
          contactPersonPhone: company.contactPersonPhone,
          contactPersonEmail: company.contactPersonEmail,
          economicBranch:
            i18n.t('dropdowns.studiesDropdown.allDepartments') ===
            company.economicBranch
              ? 'all'
              : company.economicBranch,
          active: company.active,
          isPublic: company.isPublic,
          enableInternshipRequests: company.isPublic
            ? company.enableInternshipRequests
            : true,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES,
        payload: {
          error: null,
          status: response.status,
          message: null,
        },
      });
      await dispatch(fetchCompaniesProfessor());
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const fetchCompanySlotConfiguration = companyId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/${companyId}/internship-slot-configuration`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(SetCompanySlotConfiguration(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const createCompanySlotConfiguration = (
  companyId,
  selectedSubject,
  slots,
  academicYear
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/${companyId}/internship-slot-configuration/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          slots,
          companyId,
          subjectId: selectedSubject,
          academicYear,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES_SLOTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchCompanySlotConfiguration(companyId));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const updateCompanySlotConfiguration = (
  companyId,
  slotConfigId,
  slots
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/${companyId}/internship-slot-configuration/${slotConfigId}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          slots,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES_SLOTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchCompanySlotConfiguration(companyId));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const deleteCompanySlotConfiguration = (companyId, slotConfigId) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${settings.REACT_APP_API_URL}/api/v1/companies/${companyId}/internship-slot-configuration/${slotConfigId}/delete`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_COMPANIES_SLOTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchCompanySlotConfiguration(companyId));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_COMPANIES,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const SetCompanySlotConfiguration = data => ({
  type: SET_COMPANY_SLOT_CONFIGURATION,
  payload: { data },
});
