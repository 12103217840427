import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetBackgroundNotification } from '../../../store/actions';

import './SuccessModal.scss';
const SuccessModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loadingInfo = useSelector(state => state.loader.loadingInfo);

  const status = useSelector(state => state.errorMessage);
  const commentsStatus = useSelector(state => state.comments);
  const companiesStatus = useSelector(state => state.companies);
  const companySlotsStatus = useSelector(
    state => state.companies.companiesSlotsStatus
  );
  const internshipStatus = useSelector(state => state.internship);
  const professorsStatus = useSelector(state => state.professors);
  const mentorsStatus = useSelector(state => state.mentors);
  const studentStatus = useSelector(state => state.student);
  const subjectsStatus = useSelector(state => state.subjects);
  const studentListStatus = useSelector(state => state.studentList);
  const userStatus = useSelector(state => state.user);
  const studiesStatus = useSelector(state => state.studies);
  const internshipRequestsListStatus = useSelector(
    state => state.internshipRequests
  );
  const backgroundNotification = useSelector(
    state => state.backgroundNotification
  );

  const statusArray = [
    status,
    commentsStatus,
    companiesStatus,
    internshipStatus,
    professorsStatus,
    mentorsStatus,
    studentStatus,
    studentListStatus,
    subjectsStatus,
    userStatus,
    companySlotsStatus,
    internshipRequestsListStatus,
    studiesStatus,
  ];

  useEffect(() => {
    statusArray.map(statusInfo => {
      if (statusInfo && statusInfo.status) {
        if (statusInfo.status === 200 || statusInfo.status === 201) {
          toast.success(
            statusInfo.message
              ? statusInfo.message
              : t('modal.successModal.successMessage'),
            {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
        }
      }
    });
  }, [statusArray]);

  const id = useRef(null);
  const timeout = useRef(null);
  const arrayOfPathsToShowLoading = [
    '/studies-list',
    '/students-list',
    '/student-internships-list',
    '/summary-list',
    '/companies-list',
    '/companies',
    '/mentors-list',
    '/professors-list',
    '/subjects',
    '/audit',
    '/internship-requests',
    '/student-homepage',
    '/mentor-homepage',
    '/login',
  ];
  useEffect(() => {
    if (arrayOfPathsToShowLoading.includes(window.location.pathname)) {
      if (loadingInfo) {
        if (!timeout.current) {
          timeout.current = setTimeout(() => {
            id.current = toast.loading(t('modal.loadingModal.loadingMessage'), {
              theme: 'dark',
              position: 'bottom-right',
            });
          }, 2000);
        }
      } else {
        clearTimeout(timeout.current);
        toast.update(id.current, {
          render: t('modal.loadingModal.loadingFinishedMessage'),
          type: 'success',
          isLoading: false,
          theme: 'colored',
          closeOnClick: true,
          draggable: true,
          autoClose: 1000,
          position: 'bottom-right',
        });
        timeout.current = null;
      }
    } else {
      if (!loadingInfo) {
        if (timeout.current) {
          clearTimeout(timeout.current);
          toast.update(id.current, {
            render: t('modal.loadingModal.loadingFinishedMessage'),
            type: 'success',
            isLoading: false,
            theme: 'colored',
            closeOnClick: true,
            draggable: true,
            autoClose: 1000,
            position: 'bottom-right',
          });
          timeout.current = null;
        }
      }
    }
  }, [loadingInfo]);

  useEffect(() => {
    if (backgroundNotification.message) {
      dispatch(resetBackgroundNotification());
      toast.info(
        <>
          <div className='notification-title'>
            {backgroundNotification.title}
          </div>
          <div>{backgroundNotification.message}</div>
        </>,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );
    }
  }, [backgroundNotification]);

  return <ToastContainer />;
};

export default SuccessModal;
