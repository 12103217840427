export const FETCH_STUDENT_VIEW = 'FETCH_STUDENT_VIEW';
export const REQUEST_APPROVED = 'REQUEST_APPROVED';
export const SET_SHORT_DESCRIPTION_TO_INTERNSHIP =
  'SET_SHORT_DESCRIPTION_TO_INTERNSHIP';
export const SET_DESCRIPTION_TO_INTERNSHIP = 'SET_DESCRIPTION_TO_INTERNSHIP';
export const REQUEST_REMOVED = 'REQUEST_REMOVED';
export const SET_ERROR_PROFESSOR = 'SET_ERROR_PROFESSOR';
export const PROFESSORS_FETCHED = 'PROFESSORS_FETCHED';
export const SET_PAGE_PER_PAGE_PROFESSORS_LIST =
  'SET_PAGE_PER_PAGE_PROFESSORS_LIST';
export const FETCHED_PROFESSORS_FOR_SUBJECT = 'FETCHED_PROFESSORS_FOR_SUBJECT';
export const FETCHED_PROFESSOR_DETAILS = 'FETCHED_PROFESSOR_DETAILS';
export const DOCUMENT_VERIFIED = 'DOCUMENT_VERIFIED';
