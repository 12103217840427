import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export const ImageCropp = props => {
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    aspect: 16 / 9,
  });
  const [imageRef, setImageRef] = useState();

  useEffect(() => {
    if (imageRef) {
      cropImage(crop);
    }
  }, [imageRef]);

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(imageRef, crop);
      props.onImageCropped(croppedImage);
    }
  }

  async function getCroppedImage(sourceImage, cropConfig) {
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = cropConfig.width * pixelRatio;
    canvas.height = cropConfig.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
  }

  return (
    <ReactCrop
      src={props.imageToCrop}
      crop={crop}
      ruleOfThirds
      onImageLoaded={imageRef => {
        setImageRef(imageRef);
      }}
      onComplete={crop => cropImage(crop)}
      onChange={newCrop => setCrop(newCrop)}
      crossorigin='anonymous'
    />
  );
};

ImageCropp.propTypes = {
  imageToCrop: PropTypes.string,
  onImageCropped: PropTypes.func,
};
