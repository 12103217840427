import React from 'react';
import { useSelector } from 'react-redux';

import InternshipsListPage from '../InternshipsListPage/InternshipsListPage';

const MentorHomepage = () => {
  const user = useSelector(state => state.user);
  const isMentor = user.role.toLowerCase() === 'mentor';
  return (
    <div className='mentor-homepage'>{isMentor && <InternshipsListPage />}</div>
  );
};

export default MentorHomepage;
