import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './FileUploads.scss';

const DocumentUpload = props => {
  const { t } = useTranslation();
  return (
    <div className='document-input'>
      <label htmlFor='document-upload' className='file-btn'>
        <div className='file-btn__icon'></div>
        <div className='file-btn__text'>
          {props.documentFile?.document
            ? props.documentFile?.document?.name
            : t('buttons.chooseFile')}
        </div>
      </label>
      <input
        id='document-upload'
        name='document-upload'
        type='file'
        accept='.pdf'
        style={{ display: 'none' }}
        onChange={e => {
          props.setDocumentFile({
            document: e.target.files[0],
          });
        }}
      />
      {props.documentFile && props.documentFile.document && (
        <button
          className='remove-document'
          name='remove-document'
          onClick={() => {
            props.setDocumentFile(null);
          }}
        >
          {t('buttons.removeFile')}
        </button>
      )}
    </div>
  );
};

DocumentUpload.propTypes = {
  documentFile: PropTypes.shape(),
  setDocumentFile: PropTypes.func.isRequired,
};

export default DocumentUpload;
