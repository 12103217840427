import { NOTIFICATION_ARRIVED, RESET_NOTIFICATION } from '../types';

export const notificationArrived = notification => ({
  type: NOTIFICATION_ARRIVED,
  payload: notification,
});

export const resetBackgroundNotification = () => ({
  type: RESET_NOTIFICATION,
});
