import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../SharedComponents/Pagination/Pagination';
import { setPageAndPerPageAuditList } from '../../../../store/actions';

const AuditPagination = props => {
  const pages = useSelector(state => state.audit.totalPages);
  const dataPerPage = useSelector(state => state.audit.perPage);
  const page = useSelector(state => state.audit.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageAuditList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      dataPerPage={dataPerPage}
      page={page}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.fetchAuditLogsByPage}
    />
  );
};

AuditPagination.propTypes = {
  fetchAuditLogsByPage: PropTypes.func,
};

export default AuditPagination;
