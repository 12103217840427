import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProfessor,
  executeRequestsWithLoading,
  fetchProfessorDetails,
  fetchProfessors,
  fetchProfessorsByName,
  fetchSubjects,
  getProfessorsForSubject,
  regenerateProfessorPassword,
  setPageAndPerPageProfessorsList,
  updateProfessor,
} from '../../../store/actions';
import DataDisplay from '../DataDisplay/DataDisplay';
import { Dropdown } from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import './AddOrUpdateProfessorModal.scss';

export const AddOrUpdateProfessorModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const user = useSelector(state => state.user);
  const addProfessorError = useSelector(state => state.professors.error);
  const addProfessorStatus = useSelector(state => state.professors.status);
  const professors = useSelector(state => state.professors);
  const professorDetails = useSelector(
    state => state.professors.professorDetails
  );
  const [loading, setLoading] = useState(false);
  const [subjectsLoading, setSubjectsLoading] = useState(false);
  const [selectedExtistingProfessor, setSelectedExtistingProfessor] =
    useState(false);
  const subjects = useSelector(state => state.subjects.subjects);
  const [professor, setProfessor] = useState({
    firstName: '',
    lastName: '',
    code: crypto.getRandomValues(new Uint32Array(1))[0],
    email: '',
    phone: '',
    active: false,
    subjects: props.addProfesorToSubject ? [props.selectedSubject.id] : [],
  });

  const handleSend = () => {
    if (props.professor || selectedExtistingProfessor) {
      dispatch(updateProfessor(professor));
    } else {
      dispatch(
        createProfessor(
          professor,
          props.addProfesorToSubject,
          props.selectedSubject?.id
        )
      );
    }
  };

  useEffect(() => {
    if (addProfessorStatus === 201 || addProfessorStatus === 200) {
      props.setShowAddOrUpdateProfessorModal(false);
      if (props.addProfesorToSubject) {
        dispatch(getProfessorsForSubject(props.selectedSubject.id));
      }
    }
  }, [addProfessorStatus]);

  useEffect(() => {
    if (professorDetails) {
      setProfessor({
        ...professorDetails,
        subjects:
          professorDetails && professorDetails.subjects
            ? props.selectedSubject
              ? [
                ...professorDetails.subjects
                  .filter(subject => subject.active !== false)
                  .map(subject => subject.id),
                props.selectedSubject.id,
              ]
              : [
                ...professorDetails.subjects
                  .filter(subject => subject.active !== false)
                  .map(subject => subject.id),
              ]
            : [],
      });
    }
  }, [professorDetails]);

  useEffect(() => {
    if (!props.addProfesorToSubject) {
      getSubjects();
    }
    if (props.professor) {
      getProfessorDetails();
    }
    return () => {
      mountedRef.current = false;
      if (props.professor) {
        props.setProfessor(null);
      }
      dispatch({
        type: 'SET_ERROR_PROFESSOR',
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch({
        type: 'FETCHED_PROFESSOR_DETAILS',
        payload: null,
      });
    };
  }, []);

  const getSubjects = async () => {
    await executeRequestsWithLoading(
      [dispatch(fetchSubjects())],
      setSubjectsLoading,
      mountedRef
    );
  };

  const getProfessorDetails = async () => {
    await executeRequestsWithLoading(
      [dispatch(fetchProfessorDetails(props.professor.id))],
      setLoading,
      mountedRef
    );
  };

  const fetchProfessorsByPage = async searchQuery => {
    await executeRequestsWithLoading(
      searchQuery !== null && searchQuery !== ''
        ? [dispatch(fetchProfessorsByName(searchQuery))]
        : [dispatch(fetchProfessors())],
      null,
      mountedRef
    );
  };

  return (
    <Modal closeModal={() => props.setShowAddOrUpdateProfessorModal(false)}>
      <div className='modal-container-add-professor-modal'>
        <div className='modal-container-add-professor-modal__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-add-professor-modal__header__header-text'>
            <div className='header-main-text'>
              {props.professor
                ? t('modal.professorModal.mainHeader.editProfessor')
                : t('modal.professorModal.mainHeader.addProfessor')}
            </div>
            <div className='header-small-text'>
              {props.professor
                ? t('modal.professorModal.descriptionHeader.editProfessor')
                : t('modal.professorModal.descriptionHeader.addProfessor')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              props.setShowAddOrUpdateProfessorModal(false);
            }}
          ></div>
        </div>
        {props.addProfesorToSubject && (
          <div className='modal-container-add-professor-modal__body'>
            <DataDisplay
              dataClass='dropdown-for-professors'
              dataHeader={t(
                'dropdowns.professorsDropdown.listOfExistingProfessors'
              )}
              fullWidth
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <div>
                  <Dropdown
                    fullWidth
                    customclass='professors-dropdown'
                    inputSuggestions
                    suggestionPlaceholder={t(
                      'dropdowns.professorsDropdown.selectProfessorPlaceholder'
                    )}
                    fetchSuggestions={name => fetchProfessorsByPage(name)}
                    pages={{
                      page: professors.page,
                      totalPages: professors.totalPages,
                    }}
                    fetchPage={async (page, name, dropdownSetLoading) => {
                      dispatch(setPageAndPerPageProfessorsList(page, 10));
                      await executeRequestsWithLoading(
                        name !== null && name !== ''
                          ? [dispatch(fetchProfessorsByName(name))]
                          : [dispatch(fetchProfessors())],
                        dropdownSetLoading,
                        mountedRef
                      );
                    }}
                    handleSelect={item => {
                      if (item.id) {
                        dispatch(fetchProfessorDetails(item.id));
                        setSelectedExtistingProfessor(true);
                      } else {
                        setProfessor({
                          firstName: '',
                          lastName: '',
                          code: crypto.getRandomValues(new Uint32Array(1))[0],
                          email: '',
                          phone: '',
                          active: false,
                          subjects: props.addProfesorToSubject
                            ? [props.selectedSubject.id]
                            : [],
                        });
                        setSelectedExtistingProfessor(false);
                      }
                    }}
                    list={
                      professors.professorsList
                        ? [...professors.professorsList]
                          .filter(professor => {
                            return !props.professorsForSubject.find(
                              element => element.id === professor.id
                            );
                          })
                          .map(professor => {
                            return {
                              id: professor.id,
                              name: `${professor.firstName} ${professor.lastName}`,
                            };
                          })
                        : []
                    }
                    headerTitle={t(
                      'dropdowns.professorsDropdown.defaultOption'
                    )}
                    defaultHeaderOption={t(
                      'dropdowns.professorsDropdown.defaultOption'
                    )}
                  />
                </div>
              }
            />
          </div>
        )}
        <div className='modal-container-add-professor-modal__body'>
          {!selectedExtistingProfessor && (
            <>
              {loading && <div className='loader-small' />}
              <div className='professor-detail' hidden={loading}>
                {props.addProfesorToSubject && (
                  <DataDisplay
                    dataHeader={t('or')}
                    headerFontSize={23}
                    headerTextColor={'var(--hyperlink)'}
                    headerBolded
                    removeTopSeparator
                    setHeaderToCenter
                    alignHeader={'center'}
                  />
                )}
                <DataDisplay
                  dataHeader={t('inputs.firstName')}
                  dataClass='professor-first-name'
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input
                        value={professor.firstName}
                        onChange={e =>
                          setProfessor({
                            ...professor,
                            firstName: e.target.value,
                          })
                        }
                      />
                      {addProfessorError && addProfessorError['firstName'] && (
                        <div className='error'>
                          {addProfessorError['firstName'].notEmpty}
                        </div>
                      )}
                    </>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.lastName')}
                  dataClass='professor-last-name'
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input
                        value={professor.lastName}
                        onChange={e =>
                          setProfessor({
                            ...professor,
                            lastName: e.target.value,
                          })
                        }
                      />
                      {addProfessorError && addProfessorError['lastName'] && (
                        <div className='error'>
                          {addProfessorError['lastName'].notEmpty}
                        </div>
                      )}
                    </>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.email')}
                  dataClass='professor-email'
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input
                        type='email'
                        value={professor.email}
                        onChange={e =>
                          setProfessor({ ...professor, email: e.target.value })
                        }
                      />
                      {addProfessorError && addProfessorError['email'] && (
                        <div className='error'>
                          {addProfessorError['email'].notEmpty}
                          {addProfessorError['email'].unique}
                          {addProfessorError['email'].email}
                        </div>
                      )}
                    </>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.mobilePhone')}
                  dataClass='professor-phone'
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input
                        value={professor.phone}
                        onChange={e =>
                          setProfessor({ ...professor, phone: e.target.value })
                        }
                      />
                      {addProfessorError && addProfessorError['phone'] && (
                        <div className='error'>
                          {addProfessorError['phone'].exist}
                        </div>
                      )}
                    </>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.VEVUCode')}
                  dataClass='professor-vevu-code'
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input value={professor.code} disabled />
                      {addProfessorError && addProfessorError['vevuCode'] && (
                        <div className='error'>
                          {addProfessorError['vevuCode'].notEmpty}
                          {addProfessorError['vevuCode'].unique}
                        </div>
                      )}
                    </>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.professorActive')}
                  dataClass='professor-active'
                  headerBolded
                  dataSeparatorTopSpacing={4}
                  data={
                    <>
                      <input
                        type='checkbox'
                        id='professor-active'
                        name='professor-active'
                        checked={professor.active}
                        onChange={e =>
                          setProfessor({
                            ...professor,
                            active: e.target.checked,
                          })
                        }
                      />
                    </>
                  }
                />
                {addProfessorError && addProfessorError['active'] && (
                  <div className='error'>
                    {addProfessorError['active'].pattern}
                  </div>
                )}
              </div>
              {subjectsLoading && <div className='loader-small' />}
              <div className='professor-subject-list' hidden={subjectsLoading}>
                {!props.addProfesorToSubject && (
                  <DataDisplay
                    dataHeader={t('inputs.subjects')}
                    dataClass='professor-subjects'
                    headerBolded
                    dataSeparatorTopSpacing={4}
                    data={
                      <>
                        {subjects &&
                          subjects.map(subject => {
                            if (subject && subject.active) {
                              let subjectActive = false;
                              if (professor.subjects) {
                                subjectActive = professor.subjects.includes(
                                  subject.id
                                );
                              }
                              return (
                                <div
                                  className='professor-subject'
                                  key={subject.id}
                                >
                                  <div className='subject col-subject'>
                                    <div className='subject-name col-subject'>
                                      {subject.name}
                                    </div>
                                    <div className='subject-study col-subject'>
                                      {subject.study.name}
                                    </div>
                                  </div>
                                  <input
                                    type='checkbox'
                                    className='subject-checkbox col-subject'
                                    checked={subjectActive}
                                    onChange={() => {
                                      if (subjectActive) {
                                        setProfessor({
                                          ...professor,
                                          subjects: [
                                            ...professor.subjects.filter(
                                              professorSubject =>
                                                professorSubject !== subject.id
                                            ),
                                          ],
                                        });
                                      } else {
                                        setProfessor({
                                          ...professor,
                                          subjects: [
                                            ...professor.subjects,
                                            subject.id,
                                          ],
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                      </>
                    }
                  />
                )}
              </div>
            </>
          )}
          {!loading && (
            <div className='button-container'>
              {user.role === 'admin' &&
                professor.id &&
                !props.addProfesorToSubject && (
                <button
                  className='modal-container-add-mentor-modal__reset-password'
                  type='button'
                  onClick={e => {
                    e.preventDefault();
                    dispatch(regenerateProfessorPassword(professor.id));
                  }}
                >
                  {t('buttons.resetPassword')}
                </button>
              )}
              <button
                className='modal-container-add-professor-modal__send-button'
                id='send-email-btn'
                type='button'
                onClick={e => {
                  e.preventDefault();
                  handleSend();
                }}
              >
                {props.professor ? t('buttons.update') : t('buttons.save')}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

AddOrUpdateProfessorModal.propTypes = {
  setShowAddOrUpdateProfessorModal: PropTypes.func.isRequired,
  setProfessor: PropTypes.func,
  professor: PropTypes.object,
  addProfesorToSubject: PropTypes.bool,
  selectedSubject: PropTypes.object,
  professorsForSubject: PropTypes.array,
};
