import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import {
  LOGOUT_USER,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  ACCESS_TOKEN_REFRESHED,
  REFRESH_ACCESS_TOKEN,
  LOGIN_USER,
  ACCOUNT_CREATED,
  SET_ERROR_USER,
  FETCH_ACCESS_TOKEN,
  ACCESS_TOKEN_FETCHED,
} from '../types';

export const loginUser = (email, password) => ({
  type: LOGIN_USER,
  payload: {
    email,
    password,
  },
});

export const userLoggedIn = role => ({
  type: USER_LOGGED_IN,
  payload: {
    role,
  },
});

export const sendEmail = (studentId, subject, content) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/students/${studentId}/send-mail-for-subject/${
          getState().subjects.selectedSubject.id
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          subject,
          content,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_USER,
        payload: {
          error: null,
          status: response.status,
        },
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_USER,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const fetchAccessToken = refreshToken => ({
  type: FETCH_ACCESS_TOKEN,
  payload: { refreshToken },
});

export const accessTokenFetched = role => ({
  type: ACCESS_TOKEN_FETCHED,
  payload: {
    role,
  },
});

export const refreshAccessToken = refreshToken => ({
  type: REFRESH_ACCESS_TOKEN,
  payload: { refreshToken },
});

export const accessTokenRefreshed = () => ({
  type: ACCESS_TOKEN_REFRESHED,
});

export const setNewAddedAccount = data => ({
  type: ACCOUNT_CREATED,
  payload: data,
});

export const changePassword = (
  oldPassword,
  newPassword,
  newPasswordConfirmed
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/auth/update-password`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          oldPassword,
          newPassword,
          newPasswordConfirmed,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_USER,
        payload: {
          error: null,
          status: response.status,
        },
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_USER,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const sendPushNotificationToken = token => {
  return async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/auth/pn-token`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          tokenType: 'WEB',
          token,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return response;
    } catch (error) {
      return error;
    }
  };
};
