import i18n from '../../../i18n';
import publicRequestCreationAndDeletion from './StudentGifs/InternshipRequestCreationAndDeletion.gif';
import privateRequestCreationAndDeletion from './StudentGifs/PrivateInternshipRequestCreationAndDeletion.gif';
import sendCertificateOfEmployment from './StudentGifs/SendCertificateOfEmployment.gif';
import createDiaryLog from './StudentGifs/CreateDiaryLog.gif';
import editDiaryLog from './StudentGifs/EditDiaryLog.gif';
import sendInternshipToMentorReview from './StudentGifs/SendInternshipToReview.gif';
import viewComments from './StudentGifs/ViewComments.gif';

const defaultNameSpace = 'tutorials.studentTutorials.';

export const tutorialsForStudent = [
  {
    name: i18n.t(
      defaultNameSpace + 'internshipRequestCreationAndDeletionPublic.title'
    ),
    tutorial: {
      text: i18n.t(
        defaultNameSpace + 'internshipRequestCreationAndDeletionPublic.steps'
      ),
      source: publicRequestCreationAndDeletion,
    },
  },
  {
    name: i18n.t(
      defaultNameSpace + 'internshipRequestCreationAndDeletionPrivate.title'
    ),
    tutorial: {
      text: i18n.t(
        defaultNameSpace + 'internshipRequestCreationAndDeletionPrivate.steps'
      ),
      source: privateRequestCreationAndDeletion,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'sendCertificateOfEmployment.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'sendCertificateOfEmployment.steps'),
      source: sendCertificateOfEmployment,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'createDiaryLog.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'createDiaryLog.steps'),
      source: createDiaryLog,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'editDiaryLog.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'editDiaryLog.steps'),
      source: editDiaryLog,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'sendInternshipToMentorReview.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'sendInternshipToMentorReview.steps'),
      source: sendInternshipToMentorReview,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'viewComments.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'viewComments.steps'),
      source: viewComments,
    },
  },
];
