import axios from 'axios';
import Cookies from 'js-cookie';
import token from 'jsonwebtoken';
import moment from 'moment';
import settings from './settings';
import { accessTokenRefreshed, userLoggedOut } from './store/actions';
import { removeToken } from './store/firebase';

import { persistor, store } from './store/store';

let subscribers = [];
let isAlreadyFetchingAccessToken = false;

export const addSubscriber = callback => {
  subscribers.push(callback);
};

export const refreshAuthentication = () => {
  return axios({
    method: 'POST',
    url: `${settings.REACT_APP_AUTH_URL}/api/v1/token/refresh`,
    headers: { Authorization: `Bearer ${Cookies.get('Refreshtoken')}` },
  });
};

export const logoutAndWipeStorage = async () => {
  errorShown = false;
  await removeToken();
  if (store.getState().user.role === 'student') {
    window.location.replace(`${settings.REACT_APP_SAML_URL}/saml/slo`);
  }
  await persistor.purge();
  await persistor.flush();
  Cookies.remove('Refreshtoken');
  Cookies.remove('Accesstoken');
  await store.dispatch(userLoggedOut());
};

export const saveNewToken = accessToken => {
  Cookies.set('Accesstoken', accessToken, {
    expires: 365,
  });
  store.dispatch(accessTokenRefreshed());
};

export const onAccessTokenFetched = () => {
  subscribers.forEach(callback => callback());
  subscribers = [];
};

export const refreshAccessTokenAndReattemptRequest = async error => {
  try {
    const { response: errorResponse } = error;
    const refreshCookie = Cookies.get('Refreshtoken');
    if (!refreshCookie) {
      return Promise.reject(error);
    }
    const originalRequest = new Promise(resolve => {
      addSubscriber(() => {
        errorResponse.config.headers.Authorization = `Bearer ${Cookies.get(
          'Accesstoken'
        )}`;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await refreshAuthentication();

      if (response.status !== 200) logoutAndWipeStorage();
      if (!response.data) return Promise.reject(error);

      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;

      const newAccessTokenExpiration = token.decode(newAccessToken);
      const newRefreshTokenExpiration = token.decode(newRefreshToken);
      if (
        newAccessTokenExpiration.exp <= moment.utc().unix() ||
        newRefreshTokenExpiration.exp <= moment.utc().unix()
      ) {
        alert('Vaša prijava je istekla. Ponovno se prijavite!');
        logoutAndWipeStorage();
      }

      saveNewToken(newAccessToken);
      onAccessTokenFetched();
      isAlreadyFetchingAccessToken = false;
    }
    return originalRequest;
  } catch (err) {
    logoutAndWipeStorage();
    return Promise.reject(err);
  }
};

export const isTokenExpiredError = errorResponse => {
  if (errorResponse) return errorResponse.status === 401;
  return false;
};

var numberOfAxiosCallsPending = 0;
var errorShown = false;
const axiosCalltimeout = [];
export const intercept = () => {
  axios.interceptors.request.use(
    request => {
      if (!request.url.includes('/document-generation-statuses')) {
        numberOfAxiosCallsPending++;
        if (numberOfAxiosCallsPending === 1) {
          axiosCalltimeout.map(timeout => {
            clearTimeout(timeout);
          });
          axiosCalltimeout.map(() => axiosCalltimeout.pop());
          store.dispatch({ type: 'SET_LOADING_INFO', payload: true });
        }
      }
      const refreshTokenExpiration = token.decode(Cookies.get('Refreshtoken'));
      if (
        refreshTokenExpiration &&
        refreshTokenExpiration.exp <= moment.utc().unix()
      ) {
        alert('Vaša prijava je istekla. Ponovno se prijavite!');
        return logoutAndWipeStorage();
      }
      return request;
    },
    error => {
      const accessTokenExpiration = token.decode(Cookies.get('Accesstoken'));
      const refreshTokenExpiration = token.decode(Cookies.get('Refreshtoken'));
      if (
        accessTokenExpiration &&
        accessTokenExpiration.exp <= moment.utc().unix()
      ) {
        refreshAccessTokenAndReattemptRequest(error);
      }
      if (
        !refreshTokenExpiration ||
        (refreshTokenExpiration &&
          refreshTokenExpiration.exp <= moment.utc().unix())
      ) {
        alert('Vaša prijava je istekla. Ponovno se prijavite!');
        logoutAndWipeStorage();
      }
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      if (!response.config.url.includes('/document-generation-statuses')) {
        numberOfAxiosCallsPending--;
        if (numberOfAxiosCallsPending === 0) {
          axiosCalltimeout.push(
            setTimeout(() => {
              store.dispatch({ type: 'SET_LOADING_INFO', payload: false });
            }, 500)
          );
        }
      }
      return response;
    },
    error => {
      const errorResponse = error.response;
      if (!errorResponse.config.url.includes('/document-generation-statuses')) {
        numberOfAxiosCallsPending--;
        if (numberOfAxiosCallsPending === 0) {
          axiosCalltimeout.push(
            setTimeout(() => {
              store.dispatch({ type: 'SET_LOADING_INFO', payload: false });
            }, 500)
          );
        }
      }
      if (isTokenExpiredError(errorResponse)) {
        const state = store.getState();
        if (state.user.role === 'student' && !state.student.id) {
          alert('Korisnik nije student. Prijavite se s ispravnim podacima!');
          return logoutAndWipeStorage();
        } else {
          if (
            errorResponse.data.message === 'Korisnik nije prijavljen.' &&
            !errorShown
          ) {
            errorShown = true;
            alert('Vaša prijava je istekla. Ponovno se prijavite!');
            return logoutAndWipeStorage();
          }
          return refreshAccessTokenAndReattemptRequest(error);
        }
      }
      return Promise.reject(error);
    }
  );
};
