import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const MailToButton = ({ mailto, label, color, fontSize, bold }) => {
  return (
    <Link
      style={{
        display: 'block',
        padding: '0px',
        textDecoration: 'none',
        color: color,
        fontSize: fontSize,
        fontWeight: bold ? 'bold' : 'normal',
      }}
      to='#'
      onClick={e => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

MailToButton.defaultProps = {
  mailto: 'mailto:',
  label: '',
  color: '#000',
  fontSize: '1.2rem',
  bold: false,
};

MailToButton.propTypes = {
  mailto: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  bold: PropTypes.bool,
};

export default MailToButton;
