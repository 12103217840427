import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchProfessors,
  fetchProfessorsByName,
} from '../../../store/actions';
import AddButton from '../../SharedComponents/AddButton/AddButton';
import { AddOrUpdateProfessorModal } from '../../SharedComponents/AddOrUpdateProfessorModal/AddOrUpdateProfessorModal';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { PasswordDisplayModal } from '../../SharedComponents/PasswordDisplayModal/PasswordDisplayModal';
import Search from '../../SharedComponents/Search/Search';
import ProfessorPagination from './ProfessorPagination/ProfessorPagination';
import ProfessorsList from './ProfessorsList/ProfessorsList';
import './ProfessorsListPage.scss';

const ProfessorsListPage = () => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const professors = useSelector(state => state.professors.professorsList);
  const createdProfessor = useSelector(
    state => state.createdAccount.createdAccount
  );

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedProfessors, setSelectedProfessors] = useState(null);
  const [showAddOrUpdateProfessorModal, setShowAddOrUpdateProfessorModal] =
    useState(false);
  const [displayCreatedProfessorModal, setDisplayCreatedProfessorModal] =
    useState(false);

  useEffect(() => {
    fetchProfessorsByPage();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (createdProfessor) {
      setDisplayCreatedProfessorModal(true);
    }
  }, [createdProfessor]);

  const fetchProfessorsByPage = async () => {
    await executeRequestsWithLoading(
      searchQuery !== null
        ? [dispatch(fetchProfessorsByName(searchQuery))]
        : [dispatch(fetchProfessors())],
      setLoading,
      mountedRef
    );
  };

  return (
    <div>
      <Breadcrumbs />
      <div className='professors-list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.professorsList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={20}
          data={
            <div className='professors-list__actions'>
              <Search
                setLoading={setLoading}
                fetchData={fetchProfessors}
                fetchDataByName={fetchProfessorsByName}
                setSearchQuery={setSearchQuery}
                searchingByInfo={t('inputs.searchByInfo.professorsList')}
              />
              <AddButton
                customClassName='add-button'
                text={t('buttons.addNewProfessor')}
                setShowModal={setShowAddOrUpdateProfessorModal}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          dataClass='professors-list__list'
          data={
            <div>
              <div className='professors-list__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {professors && professors.length > 0 ? (
                    <ProfessorsList
                      professors={professors}
                      setSelectedProfessor={setSelectedProfessors}
                      setShowAddOrUpdateProfessorModal={
                        setShowAddOrUpdateProfessorModal
                      }
                    />
                  ) : searchQuery === null ? (
                    t('notFound.professorsList.noListFetched')
                  ) : (
                    t('notFound.professorsList.noSearchResult')
                  )}
                </div>
              </div>
              <div className='professors-list__list__pagination'>
                <ProfessorPagination
                  fetchProfessorsByPage={fetchProfessorsByPage}
                />
              </div>
            </div>
          }
        />
        {showAddOrUpdateProfessorModal && (
          <AddOrUpdateProfessorModal
            setProfessor={setSelectedProfessors}
            professor={selectedProfessors}
            setShowAddOrUpdateProfessorModal={setShowAddOrUpdateProfessorModal}
          />
        )}
        {displayCreatedProfessorModal && (
          <PasswordDisplayModal
            setShowPasswordDisplayModal={setDisplayCreatedProfessorModal}
          />
        )}
      </div>
    </div>
  );
};

export default ProfessorsListPage;
