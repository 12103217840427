import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMentorToProcessingInternshipAndActivate,
  changeMentorForInternship,
  executeRequestsWithLoading,
  fetchMentors,
  fetchMentorsByCompanyId,
  fetchMentorsByName,
} from '../../../store/actions';
import AddButton from '../../SharedComponents/AddButton/AddButton';
import { AddOrUpdateMentorModal } from '../../SharedComponents/AddOrUpdateMentorModal/AddOrUpdateMentorModal';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { PasswordDisplayModal } from '../../SharedComponents/PasswordDisplayModal/PasswordDisplayModal';
import Search from '../../SharedComponents/Search/Search';
import { Signing } from '../../SharedComponents/Signing/Signing';
import MentorList from './MentorList/MentorList';
import MentorPagination from './MentorPagination/MentorPagination';
import './MentorsPage.scss';

const MentorsPage = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const mentors = useSelector(state => state.mentors.mentors);
  const createdMentor = useSelector(
    state => state.createdAccount.createdAccount
  );
  const [loading, setLoading] = useState(false);
  const [displayCreatedMentorModal, setDisplayCreatedMentorModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [showMentorModal, setShowMentorModal] = useState(false);
  const [showSigningModal, setShowSigningModal] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchMentorsByPage();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (createdMentor) {
      setDisplayCreatedMentorModal(true);
    }
  }, [createdMentor]);

  const fetchMentorsByPage = async () => {
    await executeRequestsWithLoading(
      searchQuery !== null
        ? [dispatch(fetchMentorsByName(searchQuery))]
        : props.studentMentorSelect || props.studentInternshipMentorSelect
          ? [dispatch(fetchMentorsByCompanyId(props.companyId))]
          : [dispatch(fetchMentors())],
      setLoading,
      mountedRef
    );
  };

  const changeMentorOnInternship = async () => {
    await executeRequestsWithLoading(
      [dispatch(changeMentorForInternship(selectedMentor.id))],
      props.setLoading,
      mountedRef,
      true
    );
  };

  useEffect(() => {
    if (props.setSelectedMentor) {
      props.setSelectedMentor(selectedMentor);
    }
    if (props.studentMentorSelect && props.hasMentor && selectedMentor) {
      changeMentorOnInternship();
      props.setShowMentorListModal(false);
    }
  }, [selectedMentor]);

  const sendSignatureData = async signature => {
    await executeRequestsWithLoading(
      [
        dispatch(
          addMentorToProcessingInternshipAndActivate(
            selectedMentor.id,
            signature,
            props.allRequestsList,
            props.resetInterval
          )
        ),
      ],
      props.setLoading,
      mountedRef,
      true
    ).then(() => props.setShowAddMentorModal(false));
  };

  return (
    <div className='mentors-list'>
      {!props.studentInternshipMentorSelect && !props.studentMentorSelect && (
        <Breadcrumbs />
      )}
      <div className='mentors-list__list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.mentorsList')}
          dataHeaderSmalltext={
            props.selectedInternshipRequest?.isPrivate &&
            t('breadCrumbs.mentorsListSmallText')
          }
          headerBolded
          dynamicHeaderWidth
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={20}
          data={
            <div className='mentors-list__actions'>
              {!props.studentMentorSelect &&
                !props.studentInternshipMentorSelect &&
                !props.allRequestsList && (
                <Search
                  setLoading={setLoading}
                  searchingByInfo={t('inputs.searchByInfo.mentorsList')}
                  fetchData={fetchMentors}
                  fetchDataByName={fetchMentorsByName}
                  setSearchQuery={setSearchQuery}
                />
              )}
              <AddButton
                customClassName='add-button'
                text={t('buttons.addNewMentor')}
                setShowModal={() => {
                  if (
                    props.studentMentorSelect ||
                    props.studentInternshipMentorSelect
                  ) {
                    props.setShowAddMentorModal(true);
                  } else {
                    setShowMentorModal(true);
                  }
                }}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          data={
            <div>
              <div className='mentors-list__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {mentors && mentors.length > 0 ? (
                    <MentorList
                      studentInternshipMentorSelect={
                        props.studentInternshipMentorSelect
                      }
                      studentMentorSelect={props.studentMentorSelect}
                      mentors={mentors}
                      setShowSignatureModal={setShowSigningModal}
                      hasMentor={props.hasMentor}
                      setShowMentorDetailsModal={
                        props.studentMentorSelect
                          ? props.setShowAddMentorModal
                          : setShowMentorModal
                      }
                      setSelectedMentor={setSelectedMentor}
                    />
                  ) : searchQuery === null ? (
                    t('notFound.mentorsList.noListFetched')
                  ) : (
                    t('notFound.mentorsList.noSearchResult')
                  )}
                </div>
              </div>
              <div className='mentors-list__list__pagination'>
                <MentorPagination fetchMentorsByPage={fetchMentorsByPage} />
              </div>
            </div>
          }
        />
      </div>
      {showMentorModal &&
        !props.studentMentorSelect &&
        !props.studentInternshipMentorSelect && (
        <AddOrUpdateMentorModal
          companyId={props.companyId}
          setShowAddMentorModal={setShowMentorModal}
          mentor={selectedMentor}
          setMentor={setSelectedMentor}
          setLoading={setLoading}
        />
      )}
      {showSigningModal && (
        <Signing
          setShowMentorListModal={props.setShowMentorListModal}
          setShowSignatureModal={setShowSigningModal}
          sendSignatureData={data => sendSignatureData(data)}
        />
      )}
      {displayCreatedMentorModal && (
        <PasswordDisplayModal
          setShowPasswordDisplayModal={setDisplayCreatedMentorModal}
        />
      )}
    </div>
  );
};

MentorsPage.propTypes = {
  studentMentorSelect: PropTypes.bool,
  studentInternshipMentorSelect: PropTypes.bool,
  companyId: PropTypes.number,
  setCompanyOib: PropTypes.func,
  setShowMentorListModal: PropTypes.func,
  setShowAddMentorModal: PropTypes.func,
  setSelectedMentor: PropTypes.func,
  allRequestsList: PropTypes.bool,
  hasMentor: PropTypes.bool,
  setLoading: PropTypes.func,
  resetInterval: PropTypes.func,
  selectedInternshipRequest: PropTypes.object,
};

export default MentorsPage;
