import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Table } from 'reactstrap';
import {
  checkDocumentGenerationStatuses,
  internshipStudentFetched,
  startLoading,
  studentInfoFetched,
} from '../../../../store/actions';
import '../../../../styles/ListTable.scss';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';
const InternshipsListTable = props => {
  const student = useSelector(state => state.student);
  const [headers, setHeaders] = useState([
    {
      header: 'Kratak Opis',
      headerKey: 'shortDescription',
    },
    {
      header: 'Kontakt',
      headerKey: 'contact',
    },
    {
      header: 'Ime studenta',
      headerKey: 'studentName',
    },
    {
      header: 'Detalji',
      headerKey: 'actions',
    },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          data={props.internshipsList}
        />
        <tbody className='list-table__item-row'>
          {props.internshipsList.map(internship => {
            return (
              <tr className='spacer  item-row' key={internship.id}>
                <td
                  style={{
                    maxWidth: '40%',
                    width: '40%',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  {internship.shortDescription}
                </td>
                <td>
                  <Table width='100%'>
                    <tbody>
                      <tr style={{ display: 'flex', flexDirection: 'column' }}>
                        <td id='open-send-email-modal'>
                          {internship.studentEmail}
                        </td>
                        {internship.studentPhone ? (
                          <td>{internship.studentPhone}</td>
                        ) : null}
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td>{internship.studentName}</td>
                <td>
                  <button
                    id='link-to-internship-page'
                    onClick={() => {
                      dispatch(startLoading());
                      if (student?.id !== internship.studentId) {
                        dispatch(
                          studentInfoFetched({
                            email: null,
                            firstName: null,
                            id: internship.studentId,
                            jmbag: null,
                            lastName: null,
                            phone: null,
                            semester: null,
                            study: null,
                          })
                        );
                      }
                      dispatch(checkDocumentGenerationStatuses(internship.id));
                      dispatch(internshipStudentFetched({ id: internship.id }));
                      history?.push('/student-homepage');
                    }}
                  >
                    Odaberi
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

InternshipsListTable.propTypes = {
  internshipsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  approveInternshipRequestHandler: PropTypes.func,
  setSelectedStudent: PropTypes.func,
  setShowSendEmailModal: PropTypes.func,
};

export default InternshipsListTable;
