export const SET_ERROR_SYNC = 'SET_ERROR_SYNC';
export const SET_ERROR_SYNC_ALL = 'SET_ERROR_SYNC_ALL';
export const SET_ERROR_SYNC_ALL_EXISTING = 'SET_ERROR_SYNC_ALL_EXISTING';
export const SET_SYNC_ALL_DATA = 'SET_SYNC_ALL_DATA';
export const SET_SYNC_SINGLE_DATA = 'SET_SYNC_SINGLE_DATA';
export const SET_SYNC_ALL_EXISTING_DATA = 'SET_SYNC_ALL_EXISTING_DATA';
export const SET_STATUS_CHECK_ALL = 'SET_STATUS_CHECK_ALL';
export const SET_STATUS_CHECK_ALL_EXISTING = 'SET_STATUS_CHECK_ALL_EXISTING';
export const SET_STATUS_CHECK_SINGLE = 'SET_STATUS_CHECK_SINGLE';
export const REMOVE_DATA_ALL = 'REMOVE_DATA_ALL';
export const REMOVE_DATA_SINGLE = 'REMOVE_DATA_SINGLE';
export const REMOVE_DATA_ALL_EXISTING = 'REMOVE_DATA_ALL_EXISTING';
