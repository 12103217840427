import { PURGE } from 'redux-persist/lib/constants';
import {
  SET_ERROR_STUDENT,
  SET_STUDENT_SELECTED_SUBJECT,
  STUDENT_INFO_FETCHED,
} from '../types';

export const studentInitState = {
  email: null,
  firstName: null,
  id: null,
  jmbag: null,
  lastName: null,
  photo: null,
  semester: null,
  signature: null,
  studyType: null,
  subjects: null,
  year: null,
  selectedSubject: null,
  error: null,
  status: null,
};

export const student = (state = studentInitState, action) => {
  switch (action.type) {
    case STUDENT_INFO_FETCHED: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case SET_STUDENT_SELECTED_SUBJECT: {
      return {
        ...state,
        selectedSubject: [...state.subjects].find(
          subject => subject.id === action.payload
        ),
      };
    }
    case SET_ERROR_STUDENT: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return studentInitState;
    }
    default:
      return state;
  }
};
