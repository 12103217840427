import hr from 'date-fns/locale/hr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  approvePrivateInternshipRequest,
  approvePublicInternshipRequest,
  checkStatusInInternshipInfo,
  downloadInternshipConfirmation,
  downloadInternshipReferral,
  executeRequestsWithLoading,
  retryGeneratingDocument,
} from '../../../../store/actions';
import DataDisplay from '../../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import RatingModal from '../../../SharedComponents/RatingModal/RatingModal';
import { ReasonViewModal } from '../../../SharedComponents/ReasonViewModal/ReasonViewModal';
import SelectMentorForInternshipModal from '../../../SharedComponents/SelectMentorForInternshipModal/SelectMentorForInternshipModal';
import './InternshipInfo.scss';

registerLocale('hr', hr);

export const InternshipInfo = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const { internship, user, setLoading, resetInterval } = props;

  const internshipRequestsError = useSelector(
    state => state.internshipRequests.error
  );
  const internshipRequestsStatus = useSelector(
    state => state.internshipRequests.status
  );

  const [startDate, setStartDate] = useState(
    moment(props.internship?.startDate).toDate()
  );
  const [openDescription, setOpenDescription] = useState(false);
  const [openShortDescription, setOpenShortDescription] = useState(false);
  const [openOrganizationalUnit, setOpenOrganizationalUnit] = useState(false);
  const [showReasonViewModal, setShowReasonViewModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [shortDescription, setshortDescription] = useState('');
  const [companyOrganizationalUnit, setCompanyOrganizationalUnit] =
    useState('');
  const [description, setDescription] = useState('');
  const [
    showSelectMentorForInternshipModal,
    setShowSelectMentorForInternshipModal,
  ] = useState(false);
  const [disableApproveButton, setDisableApproveButton] = useState(false);
  const [tempInternshipRequest, setTempInternshipRequest] = useState(null);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (internship && internship.isPrivate) {
      setTempInternshipRequest(internship);
    }
  }, [internship]);

  useEffect(() => {
    if (internshipRequestsStatus === 201 || internshipRequestsStatus === 200) {
      setShowSelectMentorForInternshipModal(true);
    }
  }, [internshipRequestsStatus]);

  const checkForGeneratedDocument = documentType => {
    if (documentType === 'GENERATED') {
      return true;
    }
    return false;
  };

  const checkGenerationType = (type, confirmation) => {
    if (props.interval === 'stop') {
      if (confirmation) {
        return t(
          'documentGenerationStatuses.RETRY_GENERATION_INTERNSHIP_CONFIRMATION'
        );
      }
      return t('documentGenerationStatuses.RETRY_GENERATION_REFERRAL');
    }
    switch (type) {
      case 'GENERATING':
        return t('documentGenerationStatuses.GENERATING');
      case 'NOT_GENERATED':
        return t('documentGenerationStatuses.NOT_GENERATED');
      case 'GENERATED':
        if (confirmation) {
          return t(
            'documentGenerationStatuses.DOWNLOAD_INTERNSHIP_CONFIRMATION'
          );
        }
        return t('documentGenerationStatuses.DOWNLOAD_REFERRAL');
      case 'GENERATION_FAILED':
        if (confirmation) {
          return t(
            'documentGenerationStatuses.RETRY_GENERATION_INTERNSHIP_CONFIRMATION'
          );
        }
        return t('documentGenerationStatuses.RETRY_GENERATION_REFERRAL');
      default:
        break;
    }
  };

  return (
    <div className='internship-info'>
      {internship && (
        <>
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.companyName')}
            headerBolded
            headerWidth={230}
            data={internship.companyName}
          />
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.location')}
            headerBolded
            headerWidth={230}
            data={internship.location}
          />
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.mentorName')}
            headerBolded
            headerWidth={230}
            data={
              internship.mentorName && internship.mentorName.trim() === ''
                ? 'Privremeni mentor'
                : internship.mentorName
            }
          />
          <DataDisplay
            dataHeader={t(
              'studentHomepage.internshipInfo.mentorContact.mainHeader'
            )}
            headerBolded
            centerHeaderVertically
            headerWidth={230}
            data={
              <div className='mentor-contacts'>
                {internship.mentorEmail && (
                  <div>
                    {t('studentHomepage.internshipInfo.mentorContact.email')}{' '}
                    {internship.mentorEmail}
                  </div>
                )}
                {internship.mentorPhone && (
                  <div>
                    {t('studentHomepage.internshipInfo.mentorContact.phone')}{' '}
                    {internship.mentorPhone}
                  </div>
                )}
              </div>
            }
          />
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.description')}
            headerBolded
            headerWidth={230}
            dataFullWidth
            data={
              internship.description ? (
                <div style={{ wordBreak: 'break-word', width: '30%' }}>
                  {internship.description}
                </div>
              ) : user.role !== 'student' ? (
                <>
                  <div className='edit-inputs-container'>
                    <div>
                      {description !== '' && !openDescription && description}
                    </div>
                    <button
                      className='add-description-button'
                      hidden={openDescription}
                      onClick={() => setOpenDescription(true)}
                    >
                      {description !== ''
                        ? t('buttons.edit')
                        : t('buttons.add')}
                    </button>
                  </div>
                  <div>
                    <textarea
                      className='add-description-input'
                      hidden={!openDescription}
                      type='text'
                      onChange={e => setDescription(e.target.value)}
                      value={description}
                    />
                    <button
                      hidden={!openDescription || description === ''}
                      onClick={() => {
                        setOpenDescription(false);
                      }}
                      className='add-description-button-confirm'
                      name='add-description-button-confirm'
                    >
                      {t('buttons.save')}
                    </button>
                  </div>
                </>
              ) : null
            }
          />
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.shortDescription')}
            headerBolded
            headerWidth={230}
            dataFullWidth
            dataClass='short-description'
            data={
              internship.shortDescription ? (
                <div style={{ wordBreak: 'break-word', width: '30%' }}>
                  {internship.shortDescription}
                </div>
              ) : user.role !== 'student' ? (
                <>
                  <div className='edit-inputs-container'>
                    <div>
                      {shortDescription !== '' &&
                        !openShortDescription &&
                        shortDescription}
                    </div>
                    <button
                      className='add-short-description-button'
                      hidden={openShortDescription}
                      onClick={() => setOpenShortDescription(true)}
                    >
                      {shortDescription !== ''
                        ? t('buttons.edit')
                        : t('buttons.add')}
                    </button>
                  </div>
                  <input
                    className='add-short-description-input'
                    hidden={!openShortDescription}
                    type='text'
                    onChange={e => setshortDescription(e.target.value)}
                    value={shortDescription}
                  />
                  <button
                    hidden={!openShortDescription || shortDescription === ''}
                    onClick={() => {
                      setOpenShortDescription(false);
                    }}
                    className='add-short-description-button-confirm'
                    name='add-short-description-button-confirm'
                  >
                    {t('buttons.save')}
                  </button>
                </>
              ) : null
            }
          />
          {!internship.status && (
            <DataDisplay
              dataHeader={t(
                'studentHomepage.internshipInfo.organizationalUnit'
              )}
              headerBolded
              headerWidth={230}
              dataClass='organizational-unit'
              data={
                companyOrganizationalUnit && !openOrganizationalUnit ? (
                  <div className='edit-inputs-container'>
                    {companyOrganizationalUnit}
                    <button
                      className='add-organizational-unit-button'
                      hidden={openOrganizationalUnit || internship.status}
                      onClick={() => setOpenOrganizationalUnit(true)}
                    >
                      {companyOrganizationalUnit !== ''
                        ? t('buttons.edit')
                        : t('buttons.add')}
                    </button>
                  </div>
                ) : user.role !== 'student' ? (
                  <>
                    <button
                      className='add-organizational-unit-button'
                      hidden={openOrganizationalUnit}
                      onClick={() => setOpenOrganizationalUnit(true)}
                    >
                      {t('buttons.add')}
                    </button>
                    <input
                      className='add-organizational-unit-input'
                      hidden={!openOrganizationalUnit}
                      type='text'
                      onChange={e =>
                        setCompanyOrganizationalUnit(e.target.value)
                      }
                      value={companyOrganizationalUnit}
                    />
                    <button
                      hidden={
                        !openOrganizationalUnit ||
                        companyOrganizationalUnit === ''
                      }
                      onClick={() => {
                        setOpenOrganizationalUnit(false);
                      }}
                      className='add-organizational-unit-button-confirm'
                      name='add-organizational-unit-button-confirm'
                    >
                      {t('buttons.save')}
                    </button>
                  </>
                ) : null
              }
            />
          )}
          <DataDisplay
            dataHeader={t('studentHomepage.internshipInfo.status')}
            headerBolded
            headerWidth={230}
            data={
              internship.status === 'REVIEW_FAIL' ? (
                <div className='internship-status'>
                  <div className='internship-status__rejected'>
                    {checkStatusInInternshipInfo(internship.status)}
                  </div>
                  <button
                    className='check-reason-button'
                    onClick={() => setShowReasonViewModal(true)}
                  >
                    {t('buttons.viewInternshipRejectionReason')}
                  </button>
                </div>
              ) : (
                checkStatusInInternshipInfo(internship.status)
              )
            }
          />
          {!internship.status ? (
            <>
              <DataDisplay
                dataHeader={t('inputs.datepickerInternshipStartDate')}
                dataClass='start-date'
                headerBolded
                headerWidth={230}
                data={
                  <div className='start-date-datepicker'>
                    <DatePicker
                      disabledKeyboardNavigation
                      withPortal
                      locale='hr'
                      fixedHeight
                      calendarContainer={({
                        className,
                        children,
                        showPopperArrow,
                      }) =>
                        DatepickerCustomContainer({
                          className,
                          children,
                          mainHeaderText: t(
                            'modal.internshipStartdatePicker.mainHeader'
                          ),
                          smallHeaderText: t(
                            'modal.internshipStartdatePicker.descriptionHeaderText'
                          ),
                          showPopperArrow,
                        })
                      }
                      minDate={new Date().setDate(new Date().getDate() + 1)}
                      dateFormat='dd.MM.yyyy.'
                      onChange={date => {
                        setStartDate(date);
                      }}
                      selected={startDate}
                      value={startDate}
                    />
                    <div className='non-clickable-area' />
                  </div>
                }
              />
              {internshipRequestsError &&
                internshipRequestsError['startDate'] && (
                <div className='error'>
                  {internshipRequestsError['startDate'].dateAfter}
                </div>
              )}
            </>
          ) : (
            <DataDisplay
              dataHeader={t('studentHomepage.internshipInfo.startDate')}
              headerBolded
              headerWidth={230}
              data={moment(internship.startDate).format('DD.MM.YYYY.')}
            />
          )}
          <div className='row internship-info__actions'>
            {internship.status &&
              internship.status !== 'COMPLETED' &&
              internship.status !== 'PROCESSING' && (
              <DataDisplay
                removeTopSeparator
                data={
                  <button
                    type='button'
                    className='internship-document-button'
                    disabled={
                      (internship.referralGenerationStatus === 'GENERATING' &&
                          props.interval !== 'stop') ||
                        (props.user.role === 'mentor' &&
                          internship.referralGenerationStatus !== 'GENERATED')
                    }
                    onClick={() => {
                      if (
                        checkForGeneratedDocument(
                          internship.referralGenerationStatus
                        )
                      ) {
                        dispatch(downloadInternshipReferral());
                      }
                      if (
                        internship.referralGenerationStatus ===
                            'NOT_GENERATED' ||
                          internship.referralGenerationStatus ===
                            'GENERATION_FAILED' ||
                          props.interval === 'stop'
                      ) {
                        dispatch(
                          retryGeneratingDocument(
                            'referral',
                            props.resetInterval
                          )
                        );
                      }
                    }}
                  >
                    {internship &&
                        internship.referralGenerationStatus &&
                        checkGenerationType(
                          internship.referralGenerationStatus
                        )}
                  </button>
                }
              />
            )}
            {internship.status &&
              (internship.status === 'COMPLETED' ||
                internship.status === 'IN_REVIEW_PROFESSOR') && (
              <DataDisplay
                removeTopSeparator
                data={
                  <button
                    type='button'
                    className='internship-document-button'
                    disabled={
                      (internship.confirmationGenerationStatus ===
                          'GENERATING' &&
                          props.interval !== 'stop') ||
                        (props.user.role === 'mentor' &&
                          internship.confirmationGenerationStatus !==
                            'GENERATED')
                    }
                    onClick={() => {
                      if (
                        checkForGeneratedDocument(
                          internship.confirmationGenerationStatus
                        )
                      ) {
                        dispatch(downloadInternshipConfirmation());
                      }
                      if (
                        internship.confirmationGenerationStatus ===
                            'NOT_GENERATED' ||
                          internship.confirmationGenerationStatus ===
                            'GENERATION_FAILED' ||
                          props.interval === 'stop'
                      ) {
                        dispatch(
                          retryGeneratingDocument(
                            'confirmation',
                            props.resetInterval
                          )
                        );
                      }
                    }}
                  >
                    {internship &&
                        internship.confirmationGenerationStatus &&
                        checkGenerationType(
                          internship.confirmationGenerationStatus,
                          true
                        )}
                  </button>
                }
              />
            )}
            {(user.role === 'admin' || user.role === 'professor') &&
              (!internship.hasMentor ? (
                <div>
                  {internship.status === 'PROCESSING' && (
                    <DataDisplay
                      removeTopSeparator
                      data={
                        <button
                          onClick={() =>
                            setShowSelectMentorForInternshipModal(true)
                          }
                          className='internship-mentor-button'
                          name='internship-mentor-button'
                        >
                          {t('buttons.selectMentorForInternship')}
                        </button>
                      }
                    />
                  )}
                </div>
              ) : (
                <div>
                  {internship.status &&
                    internship.status === 'APPROVED' &&
                    internship.status !== 'IN_REVIEW_MENTOR' && (
                    <DataDisplay
                      removeTopSeparator
                      data={
                        <button
                          onClick={() => {
                            setShowSelectMentorForInternshipModal(true);
                          }}
                          className='internship-mentor-button'
                          name='internship-mentor-button'
                        >
                          {t('buttons.changeMentorForInternship')}
                        </button>
                      }
                    />
                  )}
                </div>
              ))}
            {internship.status && internship.status === 'COMPLETED' && (
              <DataDisplay
                removeTopSeparator
                data={
                  <button
                    type='button'
                    className='internship-document-button'
                    onClick={() => {
                      setShowRatingModal(true);
                    }}
                  >
                    {t('buttons.rateInternship')}
                  </button>
                }
              />
            )}
            {!internship.status && (
              <DataDisplay
                removeTopSeparator
                data={
                  <button
                    disabled={
                      (internship.isPrivate
                        ? companyOrganizationalUnit === '' ||
                          openOrganizationalUnit
                        : description === '' ||
                          shortDescription === '' ||
                          companyOrganizationalUnit === '' ||
                          openShortDescription ||
                          openDescription ||
                          openOrganizationalUnit) || disableApproveButton
                    }
                    onClick={async () => {
                      if (!disableApproveButton) {
                        setDisableApproveButton(true);
                        if (internship.isPrivate) {
                          await executeRequestsWithLoading(
                            [
                              dispatch(
                                approvePrivateInternshipRequest(
                                  internship.id,
                                  companyOrganizationalUnit,
                                  moment(startDate).format('YYYY-MM-DD'),
                                  () => setDisableApproveButton(false)
                                )
                              ),
                            ],
                            setLoading,
                            mountedRef,
                            true
                          );
                        } else {
                          await executeRequestsWithLoading(
                            [
                              dispatch(
                                approvePublicInternshipRequest(
                                  internship.id,
                                  description,
                                  shortDescription,
                                  companyOrganizationalUnit,
                                  moment(startDate).format('YYYY-MM-DD'),
                                  () => setDisableApproveButton(false)
                                )
                              ),
                            ],
                            setLoading,
                            mountedRef,
                            true
                          );
                        }
                      }
                    }}
                    className='internship-approve-button'
                    name='internship-approve-button'
                  >
                    {t('buttons.approveInternship')}
                  </button>
                }
              />
            )}
          </div>
          {showSelectMentorForInternshipModal && internship?.companyId && (
            <SelectMentorForInternshipModal
              setLoading={setLoading}
              resetInterval={resetInterval}
              selectedInternshipRequest={tempInternshipRequest}
              companyId={internship.companyId}
              hasMentor={internship.hasMentor}
              setShowMentorListModal={setShowSelectMentorForInternshipModal}
            />
          )}
          {showReasonViewModal && (
            <ReasonViewModal
              setShowReasonViewModal={setShowReasonViewModal}
              reason={internship.rejectionReason}
              rejectionDate={internship.rejectionDate}
              rejecterName={internship.rejecterName}
              rejecterType={internship.rejecterType}
            />
          )}
          {showRatingModal && (
            <RatingModal
              setShowRatingModal={setShowRatingModal}
              userRole={user.role}
            />
          )}
        </>
      )}
    </div>
  );
};

InternshipInfo.propTypes = {
  internship: PropTypes.shape({
    id: PropTypes.number,
    companyOrganizationalUnit: PropTypes.string,
    mentorEmail: PropTypes.string,
    mentorName: PropTypes.string,
    mentorPhone: PropTypes.string,
    companyId: PropTypes.number,
    isPrivate: PropTypes.bool,
    companyName: PropTypes.string,
    period: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    status: PropTypes.string,
    startDate: PropTypes.string,
    referral: PropTypes.bool,
    hasMentor: PropTypes.bool,
    mentorContact: PropTypes.string,
    rejectionDate: PropTypes.number,
    rejectionReason: PropTypes.string,
    rejecterName: PropTypes.string,
    rejecterType: PropTypes.string,
    referralGenerationStatus: PropTypes.string,
    confirmationGenerationStatus: PropTypes.string,
  }),
  setLoading: PropTypes.func,
  user: PropTypes.shape().isRequired,
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  resetInterval: PropTypes.func,
};
