import { PURGE } from 'redux-persist/lib/constants';
import { FETCH_STUDIES, SET_ERROR_STUDIES, SET_SELECTED_STUDY } from '../types';

const initialState = {
  studiesList: null,
  selectedStudy: null,
  error: null,
  status: null,
};

export const studies = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDIES: {
      return {
        ...state,
        studies: action.payload,
      };
    }
    case SET_SELECTED_STUDY: {
      return {
        ...state,
        selectedStudy: action.payload,
      };
    }
    case SET_ERROR_STUDIES: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
