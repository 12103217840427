import { PURGE } from 'redux-persist/lib/constants';
import {
  CERTIFICATE_OF_EMPLOYMENT_FETCHED,
  COMMENT_CREATED,
  CURRENT_LOG_DETAILS_FETCHED,
  CURRENT_LOG_UPDATED,
  INTERNSHIP_LOGS_FETCHED,
  INTERNSHIP_LOG_IMAGES_FETCHED,
  INTERNSHIP_RATINGS_FETCHED,
  INTERNSHIP_REQUESTS_FETCHED,
  INTERNSHIP_STUDENT_FETCHED,
  RESET_INTERNSHIP_LOG_IMAGES_FETCHED,
  SET_ERROR_INTERNSHIP,
  SET_INTERNSHIP_DOCUMENT_STATUSES,
  SHOW_INTERNSHIP_DETAILS,
  SET_RETRY_GENERATION,
} from '../types';

const initialState = {
  internship: null,
  requests: null,
  logs: null,
  currentLog: null,
  internshipDetails: 'info',
  currentLogImages: null,
  error: null,
  status: null,
  message: null,
  certificateOfEmployment: null,
  documentStatuses: null,
  ratings: null,
};

export const internship = (state = initialState, action) => {
  switch (action.type) {
    case INTERNSHIP_STUDENT_FETCHED: {
      return {
        ...state,
        internship: action.payload,
      };
    }
    case INTERNSHIP_REQUESTS_FETCHED: {
      return {
        ...state,
        requests: action.payload.data,
      };
    }
    case INTERNSHIP_RATINGS_FETCHED: {
      return {
        ...state,
        ratings: action.payload.data,
      };
    }
    case SET_INTERNSHIP_DOCUMENT_STATUSES: {
      return {
        ...state,
        documentStatuses: action.payload,
      };
    }
    case INTERNSHIP_LOGS_FETCHED: {
      return {
        ...state,
        logs: action.payload.data,
      };
    }
    case CURRENT_LOG_DETAILS_FETCHED:
    case CURRENT_LOG_UPDATED: {
      return {
        ...state,
        currentLog: action.payload,
      };
    }
    case INTERNSHIP_LOG_IMAGES_FETCHED: {
      return {
        ...state,
        currentLogImages: state.currentLogImages
          ? [action.payload, ...state.currentLogImages]
          : [action.payload],
      };
    }
    case CERTIFICATE_OF_EMPLOYMENT_FETCHED: {
      return {
        ...state,
        certificateOfEmployment: action.payload,
      };
    }
    case RESET_INTERNSHIP_LOG_IMAGES_FETCHED: {
      return {
        ...state,
        currentLogImages: null,
      };
    }
    case SHOW_INTERNSHIP_DETAILS: {
      return {
        ...state,
        internshipDetails: action.payload.detailsType,
      };
    }
    case SET_ERROR_INTERNSHIP: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case COMMENT_CREATED: {
      return {
        ...state,
        currentLog: {
          ...state.currentLog,
          comments: state.currentLog.comments
            ? [...state.currentLog.comments, action.payload]
            : [action.payload],
        },
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};

const newInitState = {
  retryGenerationFor: null,
  status: null,
};

export const retryGeneration = (state = newInitState, action) => {
  switch (action.type) {
    case SET_RETRY_GENERATION: {
      return {
        retryGenerationFor: action.payload.retryGenerationFor,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return newInitState;
    }
    default:
      return state;
  }
};
