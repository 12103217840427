import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import DataDisplay from '../DataDisplay/DataDisplay';
import Modal from '../Modal/Modal';
import { sendEmail } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import './SendEmailModal.scss';
import { useTranslation } from 'react-i18next';

export const SendEmailModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sendEmailStatus = useSelector(state => state.user.status);
  const [emailContent, setEmailContent] = useState({
    subject: '',
    content: '',
  });

  useEffect(() => {
    if (sendEmailStatus === 201 || sendEmailStatus === 200) {
      props.setshowSendEmailModal(false);
    }
  }, [sendEmailStatus]);

  const handleSend = () => {
    dispatch(
      sendEmail(props.student.id, emailContent.subject, emailContent.content)
    );
  };

  useEffect(() => {
    return () => {
      setEmailContent({
        subject: '',
        content: '',
      });
      dispatch({
        type: 'SET_ERROR_USER',
        payload: {
          error: null,
          status: null,
        },
      });
    };
  }, []);

  return (
    <Modal closeModal={() => props.setshowSendEmailModal(false)}>
      <div className='send-email-modal'>
        <div className='send-email-modal__header'>
          <div className='header-info-image'></div>
          <div className='send-email-modal__header__header-text'>
            <div className='header-main-text'>
              {t('modal.sendEmailModal.mainHeaderText')}
            </div>
            <div className='header-small-text'>
              {t('modal.sendEmailModal.descriptionHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setshowSendEmailModal(false)}
          ></div>
        </div>
        <div className='send-email-modal__body'>
          <DataDisplay
            dataHeader={t('inputs.recepient')}
            dataClass='recepient'
            headerBolded
            displayInColumn
            dataSeparatorTopSpacing={4}
            data={
              <div className='send-email-modal__body__recipient__value'>
                {props.student.name}
                {props.student.firstName} {props.student.lastName}
              </div>
            }
          />
          <DataDisplay
            dataHeader={t('inputs.emailSubjectTitle')}
            dataClass='subject'
            headerBolded
            displayInColumn
            dataSeparatorTopSpacing={4}
            data={
              <input
                className='subject-input'
                type='text'
                name='subject-input'
                value={emailContent.subject}
                onChange={e =>
                  setEmailContent({
                    ...emailContent,
                    subject: e.target.value,
                  })
                }
              />
            }
          />
          <DataDisplay
            dataHeader={t('inputs.emailDescription')}
            dataClass='description'
            headerBolded
            displayInColumn
            dataSeparatorTopSpacing={4}
            data={
              <textarea
                name='email-content'
                id='email-content'
                className='email-content'
                value={emailContent.content}
                onChange={e =>
                  setEmailContent({
                    ...emailContent,
                    content: e.target.value,
                  })
                }
              />
            }
          />
          <button
            className='send-email-modal__body__send-button'
            id='send-email-btn'
            onClick={() => handleSend()}
            disabled={!emailContent.subject || !emailContent.content}
          >
            {t('buttons.send')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

SendEmailModal.propTypes = {
  setshowSendEmailModal: PropTypes.func.isRequired,
  student: PropTypes.shape().isRequired,
};
