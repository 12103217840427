import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchInternshipRequestsByQuery,
  fetchInternshipRequestsList,
  fetchStudiesProfessor,
  fetchSubjects,
  fetchSubjectsForProfessor,
  internshipStudentFetched,
  setPageAndPerPageInternshipRequestsList,
} from '../../../store/actions';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import AlertModal from '../../SharedComponents/InfoModal/AlertModal/AlertModal';
import Search from '../../SharedComponents/Search/Search';
import SelectMentorForInternshipModal from '../../SharedComponents/SelectMentorForInternshipModal/SelectMentorForInternshipModal';
import { SendEmailModal } from '../../SharedComponents/SendEmailModal/SendEmailModal';
import { StudentInternshipRequestList } from '../../Student/StudentInternship/StudentInternshipRequestList/StudentInternshipRequestList';
import { InternshipRequestInfoModal } from './InternshipRequestInfoModal/InternshipRequestInfoModal';
import './InternshipRequestsListPage.scss';
import InternshipRequestsListPagination from './InternshipRequestsListPagination/InternshipRequestsListPagination';

const InternshipRequestsListPage = () => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const user = useSelector(state => state.user);
  const studies = useSelector(state => state.studies);
  const subjects = useSelector(state => state.subjects.subjects);
  const internshipStatus = useSelector(state => state.internship.status);
  const createdInternship = useSelector(state => state.internship.internship);
  const internshipRequestsList = useSelector(
    state => state.internshipRequests.internshipRequestsList
  );
  const internshipRequestsStatus = useSelector(
    state => state.internshipRequests.status
  );

  const [loading, setLoading] = useState(false);
  const [headerKey, setHeaderKey] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedStudyType, setSelectedStudyType] = useState(null);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [studyDropdownTitle, setStudyDropdownTitle] = useState('Svi studiji');
  const [subjectDropdownTitle, setSubjectDropdownTitle] = useState(
    t('dropdowns.subjectsDropdown.defaultOption')
  );
  const [selectedInternshipRequest, setSelectedInternshipRequest] =
    useState(null);
  const [
    showSelectedInternshipRequestInfoModal,
    setShowSelectedInternshipRequestInfoModal,
  ] = useState(false);
  const [
    showSelectMentorForInternshipModal,
    setShowSelectMentorForInternshipModal,
  ] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getSubjects();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    fetchInternshipRequestsByPage();
  }, [
    selectedStudy,
    selectedStudyType,
    selectedSubject,
    sortingOrder,
    headerKey,
  ]);

  useEffect(() => {
    if (internshipRequestsStatus === 201 || internshipRequestsStatus === 200) {
      fetchInternshipRequestsByPage();
    }
  }, [internshipRequestsStatus]);

  useEffect(() => {
    if (internshipStatus === 200 || internshipStatus === 201) {
      dispatch(internshipStudentFetched(null));
    }
  }, [internshipStatus]);

  const getSubjects = () => {
    if (user.role === 'professor') {
      dispatch(fetchSubjectsForProfessor());
    } else {
      dispatch(fetchSubjects());
    }
    dispatch(fetchStudiesProfessor());
  };

  const fetchInternshipRequestsByPage = async () => {
    if (selectedStudy || selectedStudyType || selectedSubject || headerKey) {
      await executeRequestsWithLoading(
        [
          dispatch(
            fetchInternshipRequestsByQuery(
              searchQuery,
              selectedStudy,
              selectedStudyType,
              selectedSubject,
              sortingOrder,
              headerKey
            )
          ),
        ],
        setLoading,
        mountedRef
      );
    }
    if (
      !selectedStudy &&
      !selectedStudyType &&
      !selectedSubject &&
      !headerKey
    ) {
      await executeRequestsWithLoading(
        [dispatch(fetchInternshipRequestsList())],
        setLoading,
        mountedRef
      );
    }
  };

  const setSorting = (key, order) => {
    setHeaderKey(key);
    setSortingOrder(order);
  };

  return (
    <div className='internship-requests-list-page'>
      <div className='internship-requests-list-page__top-bar'>
        {subjects && (
          <DataDisplay
            dataHeader={t('dropdowns.subjectsDropdown.title')}
            headerBolded
            headerFontSize={13}
            displayInColumn
            dataSeparatorTopSpacing={6}
            removeTopSeparator
            data={
              <Dropdown
                customclass='subjects-dropdown'
                handleSelect={item => {
                  if (
                    (selectedSubject && item.id !== selectedSubject.id) ||
                    !selectedSubject
                  ) {
                    setSubjectDropdownTitle(item.name ? item.name : item);
                    setSelectedSubject(item.name ? item : null);
                    dispatch(setPageAndPerPageInternshipRequestsList(1, 10));
                  }
                  setSearchQuery(null);
                }}
                list={[
                  ...subjects.map(subject => {
                    return {
                      id: subject.id,
                      semester: 7,
                      acceptRequestsFrom: subject.acceptRequestsFrom,
                      acceptRequestsTo: subject.acceptRequestsTo,
                      name: (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {subject.name}
                          <div className='selected-subject-study'>
                            {subject.study.name}
                          </div>
                        </div>
                      ),
                      key: subject.name,
                    };
                  }),
                ]}
                headerTitle={subjectDropdownTitle}
                defaultHeaderOption={t(
                  'dropdowns.subjectsDropdown.defaultOption'
                )}
              />
            }
          />
        )}
        <DataDisplay
          dataHeader={t('dropdowns.studentStudyTypeDropdown.title')}
          headerBolded
          headerFontSize={13}
          displayInColumn
          dataSeparatorTopSpacing={6}
          removeTopSeparator
          data={
            <Dropdown
              customclass='student-type-dropdown'
              handleSelect={item => {
                if (
                  item !== t('dropdowns.studentStudyTypeDropdown.defaultOption')
                ) {
                  setSelectedStudyType(item);
                } else {
                  setSelectedStudyType(null);
                }
              }}
              list={[
                {
                  id: 1,
                  name: t(
                    'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                    {
                      postProcess: 'interval',
                      count: 1,
                    }
                  ),
                  value: 'REDOVNI',
                },
                {
                  id: 2,
                  name: t(
                    'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                    {
                      postProcess: 'interval',
                      count: 2,
                    }
                  ),
                  value: 'IZVANREDNI',
                },
              ]}
              headerTitle={t(
                'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                {
                  postProcess: 'interval',
                  count: selectedStudyType ? selectedStudyType.id : 0,
                }
              )}
              defaultHeaderOption={t(
                'dropdowns.studentStudyTypeDropdown.defaultOption'
              )}
            />
          }
        />
        <DataDisplay
          dataHeader='Studij'
          headerBolded
          headerFontSize={13}
          displayInColumn
          dataSeparatorTopSpacing={6}
          removeTopSeparator
          data={
            <Dropdown
              customclass='study-dropdown'
              handleSelect={item => {
                if (item !== 'Svi studiji') {
                  setStudyDropdownTitle(item.name);
                  setSelectedStudy(item.id);
                } else {
                  setStudyDropdownTitle(item);
                  setSelectedStudy(null);
                }
              }}
              list={studies && studies.studies}
              headerTitle={studyDropdownTitle}
              defaultHeaderOption='Svi studiji'
            />
          }
        />
      </div>
      <Breadcrumbs />
      <div className='internship-requests-list-page__list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.internshipRequestsList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={30}
          data={
            <div className='internship-requests-list-page__list__search'>
              <Search
                setLoading={setLoading}
                searchingByInfo={t(
                  'inputs.searchByInfo.internshipRequestsList'
                )}
                fetchData={() => {
                  return fetchInternshipRequestsByQuery(
                    null,
                    selectedStudy,
                    selectedStudyType,
                    selectedSubject,
                    sortingOrder,
                    headerKey
                  );
                }}
                fetchDataByName={name => {
                  return fetchInternshipRequestsByQuery(
                    name,
                    selectedStudy,
                    selectedStudyType,
                    selectedSubject,
                    sortingOrder,
                    headerKey
                  );
                }}
                setSearchQuery={setSearchQuery}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          data={
            <div>
              <div className='internship-requests-list-page__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {internshipRequestsList &&
                  internshipRequestsList.length > 0 ? (
                      <StudentInternshipRequestList
                        setLoading={setLoading}
                        role={user.role}
                        fetchInternshipRequestsByPage={
                          fetchInternshipRequestsByPage
                        }
                        setShowInternshipRequestInfoModal={
                          setShowSelectedInternshipRequestInfoModal
                        }
                        internshipRequestsListPage
                        setCurrentInternship={setSelectedInternshipRequest}
                        internshipRequestList={internshipRequestsList}
                        setSorting={setSorting}
                        setShowSendEmailModal={setShowSendEmailModal}
                        setSelectedStudent={setSelectedStudent}
                      />
                    ) : searchQuery === null ? (
                      t('notFound.internshipRequestsList.noListFetched')
                    ) : (
                      t('notFound.internshipRequestsList.noSearchResult')
                    )}
                </div>
              </div>
              <div className='internship-requests-list-page__list__pagination'>
                <InternshipRequestsListPagination
                  fetchInternshipRequestsListByPage={
                    fetchInternshipRequestsByPage
                  }
                />
              </div>
            </div>
          }
        />
        {showSelectedInternshipRequestInfoModal && (
          <InternshipRequestInfoModal
            request={selectedInternshipRequest}
            setShowInternshipRequestInfoModal={
              setShowSelectedInternshipRequestInfoModal
            }
            fetchInternshipRequestsByPage={fetchInternshipRequestsByPage}
            setShowSelectMentorForInternshipModal={
              setShowSelectMentorForInternshipModal
            }
          />
        )}
        {showSelectMentorForInternshipModal && createdInternship && (
          <SelectMentorForInternshipModal
            selectedInternshipRequest={selectedInternshipRequest}
            companyId={createdInternship.companyId}
            setShowMentorListModal={setShowSelectMentorForInternshipModal}
            allRequestsList
            setLoading={setLoading}
            setShowAlertModal={setShowAlertModal}
          />
        )}
        {showSendEmailModal && (
          <SendEmailModal
            setshowSendEmailModal={setShowSendEmailModal}
            student={selectedStudent}
          />
        )}
        {showAlertModal && (
          <AlertModal
            alertInfotext={t(
              'modal.alertModal.doYouWantToLeaveInternshipRequest'
            )}
            confirmOptions={() => {
              setShowAlertModal(false);
              setShowSelectMentorForInternshipModal(false);
            }}
            declineOptions={() => {
              setShowAlertModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InternshipRequestsListPage;
