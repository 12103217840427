import hr from 'date-fns/locale/hr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkStatus,
  clearSelectedSubject,
  executeRequestsWithLoading,
  fetchStudiesProfessor,
  fetchSubjects,
  fetchSubjectsForProfessor,
  getStudentForSubjectbyQuery,
  getStudentsForSubject,
  setSelectedStudy,
  setSubject,
  setSubjectAcceptanceInterval,
  studentInfoFetched,
} from '../../../store/actions';
import { setFilteringOptions } from '../../../store/actions/filteringActions';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import DownloadPassedStudentsModal from '../../SharedComponents/DownloadPassedStudentsModal/DownloadPassedStudentsModal';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import Search from '../../SharedComponents/Search/Search';
import { SendEmailModal } from '../../SharedComponents/SendEmailModal/SendEmailModal';
import StudentList from '../../Student/StudentInternship/StudentList/StudentList';
import StudentPagination from '../../Student/StudentInternship/StudentList/StudentPagination/StudentPagination';
import './StudentListPage.scss';

registerLocale('hr', hr);

const StudentListPage = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const filteringOptions = useSelector(state => state.filteringOptions);
  const user = useSelector(state => state.user);
  const subjects = useSelector(state => state.subjects.subjects);
  const subjectSelected = useSelector(state => state.subjects.selectedSubject);
  const studentList = useSelector(state => state.studentList?.studentList);

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showDownloadPassedStudentsModal, setShowDownloadPassedStudentsModal] =
    useState(false);
  const [selectedSubject, setSelectedSubject] = useState(
    subjectSelected?.id
      ? subjectSelected.id
      : t('dropdowns.subjectsDropdown.defaultOption')
  );

  const [selectedStatuses, setSelectedStatuses] = useState(
    filteringOptions.studentListSelectedStatuses !== undefined
      ? filteringOptions.studentListSelectedStatuses
      : props.internshipsDisplay
        ? [
          'NOT_SELECTED',
          'REQUEST_SENT',
          'CERTIFICATE_IN_REVIEW',
          'CERTIFICATE_REJECTED',
          'INTERNSHIP_PROCESSING',
          'INTERNSHIP_APPROVED',
          'INTERNSHIP_IN_REVIEW_MENTOR',
          'INTERNSHIP_IN_REVIEW_PROFESSOR',
          'INTERNSHIP_REVIEW_FAIL',
        ]
        : null
  );
  const [selectedStudyType, setSelectedStudyType] = useState(
    filteringOptions.studentListSelectedStudyType !== undefined
      ? filteringOptions.studentListSelectedStudyType
      : null
  );
  const [headerKey, setHeaderKey] = useState(
    filteringOptions.studentListHeaderKey !== undefined
      ? filteringOptions.studentListHeaderKey
      : null
  );
  const [sortingOrder, setSortingOrder] = useState(
    filteringOptions.studentListSortingOrder !== undefined
      ? filteringOptions.studentListSortingOrder
      : null
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRangeForInternship, setDateRangeForInternship] = useState([
    null,
    null,
  ]);
  const [startDateForInternship, endDateForInternship] = dateRangeForInternship;

  const dispatch = useDispatch();

  useEffect(() => {
    async function retrieveSubjects() {
      await executeRequestsWithLoading([getSubjects()], setLoading, mountedRef);
    }
    retrieveSubjects();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      subjectSelected &&
      (selectedStatuses ||
        !selectedStatuses ||
        selectedStudyType ||
        endDate ||
        ((sortingOrder || !sortingOrder) && headerKey))
    ) {
      getStudentsForSubjectByPage();
      dispatch(
        setFilteringOptions({
          studentListSelectedStatuses: selectedStatuses,
          studentListSelectedStudyType: selectedStudyType,
          studentListHeaderKey: headerKey,
          studentListSortingOrder: sortingOrder,
        })
      );
    }
  }, [selectedStatuses, selectedStudyType, endDate, sortingOrder, headerKey]);

  const getSubjects = () => {
    dispatch(fetchStudiesProfessor());
    if (user.role === 'professor') {
      return dispatch(fetchSubjectsForProfessor());
    } else {
      return dispatch(fetchSubjects());
    }
  };

  const setSorting = (key, order) => {
    setHeaderKey(key);
    setSortingOrder(order);
  };

  const getStudentsForSubjectByPage = async () => {
    if (
      searchQuery ||
      selectedStatuses ||
      selectedStudyType ||
      endDate ||
      headerKey
    ) {
      await executeRequestsWithLoading(
        [
          dispatch(
            getStudentForSubjectbyQuery(
              searchQuery,
              selectedStatuses,
              selectedStudyType,
              startDate
                ? moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                : null,
              endDate
                ? moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                : endDate,
              headerKey,
              sortingOrder
            )
          ),
        ],
        setLoading,
        mountedRef
      );
    }
    if (
      !searchQuery &&
      !selectedStatuses &&
      !selectedStudyType &&
      !endDate &&
      !headerKey
    ) {
      await executeRequestsWithLoading(
        [dispatch(getStudentsForSubject())],
        setLoading,
        mountedRef
      );
    }
  };

  const statuses = [
    'NOT_SELECTED',
    'REQUEST_SENT',
    'CERTIFICATE_IN_REVIEW',
    'CERTIFICATE_REJECTED',
    'INTERNSHIP_PROCESSING',
    'INTERNSHIP_APPROVED',
    'INTERNSHIP_IN_REVIEW_MENTOR',
    'INTERNSHIP_IN_REVIEW_PROFESSOR',
    'INTERNSHIP_REVIEW_FAIL',
    'COMPLETED',
  ];

  return (
    <div className='student-list-page'>
      <div className='student-list-page__top-bar'>
        {subjects && (
          <DataDisplay
            dataHeader={t('dropdowns.subjectsDropdown.title')}
            headerBolded
            headerFontSize={13}
            displayInColumn
            dataSeparatorTopSpacing={6}
            removeTopSeparator
            customClassName='top-bar-subject-list'
            data={
              <Dropdown
                customclass='subjects-dropdown'
                handleSelect={item => {
                  if (item !== t('dropdowns.subjectsDropdown.defaultOption')) {
                    if (item.id !== selectedSubject) {
                      dispatch(setSelectedStudy(item.study));
                      dispatch(setSubject(Number(item.id)));
                      dispatch(studentInfoFetched({ id: null }));
                      setSelectedSubject(item.id);
                      getStudentsForSubjectByPage();
                    }
                  } else {
                    dispatch(clearSelectedSubject());
                    setSelectedSubject(item);
                    dispatch(setSelectedStudy(null));
                    setSearchQuery(null);
                    setDateRangeForInternship([null, null]);
                  }
                }}
                list={[
                  ...subjects.map(subject => {
                    return {
                      id: subject.id,
                      acceptRequestsFrom: subject.acceptRequestsFrom,
                      acceptRequestsTo: subject.acceptRequestsTo,
                      name: (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {subject?.name}
                          <div className='selected-subject-study'>
                            {subject?.study?.name}
                          </div>
                        </div>
                      ),
                      study: subject?.study,
                      key: subject?.name,
                    };
                  }),
                ]}
                headerTitle={
                  subjectSelected ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {subjectSelected.name}
                      <div className='selected-subject-study'>
                        {subjectSelected.study.name}
                      </div>
                    </div>
                  ) : (
                    t('dropdowns.subjectsDropdown.defaultOption')
                  )
                }
                defaultHeaderOption={t(
                  'dropdowns.subjectsDropdown.defaultOption'
                )}
              />
            }
          />
        )}
        {subjectSelected && (
          <div className='filtering-options'>
            <div className='student-list-page__top-bar__datepicker-wrapper'>
              <DataDisplay
                dataHeader={t('dropdowns.filteringByDateDropdown.title')}
                headerBolded
                headerFontSize={13}
                displayInColumn
                dataSeparatorTopSpacing={6}
                removeTopSeparator
                data={
                  <>
                    <div className='subject-internship-date-range'>
                      <div className='datepicker'>
                        <DatePicker
                          onClickOutside={() => {
                            setDateRange([null, null]);
                          }}
                          disabledKeyboardNavigation
                          locale='hr'
                          className='datepicker-range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={update => {
                            setDateRange(update);
                          }}
                          calendarContainer={({
                            className,
                            children,
                            showPopperArrow,
                          }) =>
                            DatepickerCustomContainer(
                              {
                                className,
                                children,
                                mainHeaderText: t(
                                  'dropdowns.filteringByDateDropdown.mainHeaderText'
                                ),
                                smallHeaderText: t(
                                  'dropdowns.filteringByDateDropdown.descriptionHeaderText'
                                ),
                                showPopperArrow,
                              },
                              () => {
                                setDateRange([null, null]);
                              }
                            )
                          }
                          shouldCloseOnSelect
                          withPortal
                          fixedHeight
                          monthsShown={2}
                          showPopperArrow={false}
                          selected={startDate}
                          value={
                            (startDate || endDate) &&
                            `${
                              startDate
                                ? moment(startDate).format('DD.MM.YYYY.')
                                : ''
                            } - ${
                              endDate
                                ? moment(endDate).format('DD.MM.YYYY.')
                                : ''
                            }`
                          }
                        />
                        <div className='non-clickable-area' />
                        {startDate && (
                          <div
                            className='clear-button'
                            onClick={() => {
                              setDateRange([null, null]);
                              if (
                                selectedSubject !==
                                  t(
                                    'dropdowns.subjectsDropdown.defaultOption'
                                  ) &&
                                subjectSelected
                              ) {
                                dispatch(
                                  getStudentForSubjectbyQuery(
                                    searchQuery,
                                    selectedStatuses,
                                    selectedStudyType,
                                    null,
                                    null,
                                    headerKey,
                                    sortingOrder
                                  )
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            <div className='student-list-page__top-bar__select'>
              <DataDisplay
                dataHeader={t('dropdowns.statusDropdown.title')}
                headerBolded
                headerFontSize={13}
                displayInColumn
                dataSeparatorTopSpacing={6}
                removeTopSeparator
                customClassName='top-bar-status-dropdown'
                data={
                  <Dropdown
                    customclass='status-dropdown'
                    handleSelect={item => {
                      if (
                        item !== t('dropdowns.statusDropdown.defaultOption')
                      ) {
                        if (selectedStatuses) {
                          if (
                            selectedStatuses.find(status => status === item.key)
                          ) {
                            if (selectedStatuses.length === 1) {
                              setSelectedStatuses(null);
                            } else
                              setSelectedStatuses(
                                selectedStatuses.filter(
                                  status => status !== item.key
                                )
                              );
                          } else {
                            setSelectedStatuses([
                              ...selectedStatuses,
                              item.key,
                            ]);
                          }
                        } else {
                          setSelectedStatuses([item.key]);
                        }
                      } else {
                        setSelectedStatuses(null);
                      }
                    }}
                    list={[
                      ...statuses.map((status, index) => {
                        return {
                          id: index,
                          name: checkStatus(status),
                          key: status,
                        };
                      }),
                    ]}
                    headerTitle={t(
                      'dropdowns.statusDropdown.selectedStatusDropdownTitle_interval',
                      {
                        postProcess: 'interval',
                        count: selectedStatuses ? selectedStatuses.length : 0,
                      }
                    )}
                    itemsSelected={
                      selectedStatuses
                        ? [
                          ...selectedStatuses.map((status, index) => {
                            return {
                              id: index,
                              name: checkStatus(status),
                              key: status,
                            };
                          }),
                        ]
                        : []
                    }
                    defaultHeaderOption={t(
                      'dropdowns.statusDropdown.defaultOption'
                    )}
                    multiselect
                  />
                }
              />
              <DataDisplay
                dataHeader={t('dropdowns.studentStudyTypeDropdown.title')}
                headerBolded
                headerFontSize={13}
                displayInColumn
                dataSeparatorTopSpacing={6}
                removeTopSeparator
                data={
                  <Dropdown
                    customclass='student-type-dropdown'
                    handleSelect={item => {
                      if (
                        item !==
                        t('dropdowns.studentStudyTypeDropdown.defaultOption')
                      ) {
                        setSelectedStudyType(item);
                      } else {
                        setSelectedStudyType(null);
                      }
                    }}
                    list={[
                      {
                        id: 1,
                        name: t(
                          'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                          {
                            postProcess: 'interval',
                            count: 1,
                          }
                        ),
                        value: 'REDOVNI',
                      },
                      {
                        id: 2,
                        name: t(
                          'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                          {
                            postProcess: 'interval',
                            count: 2,
                          }
                        ),
                        value: 'IZVANREDNI',
                      },
                    ]}
                    headerTitle={t(
                      'dropdowns.studentStudyTypeDropdown.selectedStudentStudyType_interval',
                      {
                        postProcess: 'interval',
                        count: selectedStudyType ? selectedStudyType.id : 0,
                      }
                    )}
                    defaultHeaderOption={t(
                      'dropdowns.studentStudyTypeDropdown.defaultOption'
                    )}
                  />
                }
              />
            </div>
            {!props.summaryList && (
              <div className='student-list-page__top-bar__datepicker-for-internship-range-wrapper'>
                <DataDisplay
                  dataHeader={t('dropdowns.internshipDateRange.title')}
                  headerBolded
                  headerFontSize={13}
                  displayInColumn
                  dataSeparatorTopSpacing={6}
                  removeTopSeparator
                  data={
                    <>
                      <div className='subject-internship-date-range'>
                        <div className='datepicker'>
                          <DatePicker
                            onClickOutside={() => {
                              setDateRangeForInternship([null, null]);
                            }}
                            disabledKeyboardNavigation
                            locale='hr'
                            className='datepicker-range'
                            calendarContainer={({
                              className,
                              children,
                              showPopperArrow,
                            }) =>
                              DatepickerCustomContainer(
                                {
                                  className,
                                  children,
                                  mainHeaderText: t(
                                    'dropdowns.internshipDateRange.mainHeaderText'
                                  ),
                                  smallHeaderText: t(
                                    'dropdowns.internshipDateRange.descriptionHeaderText'
                                  ),
                                  showPopperArrow,
                                },
                                () => {
                                  setDateRangeForInternship([null, null]);
                                }
                              )
                            }
                            selectsRange
                            startDate={startDateForInternship}
                            endDate={endDateForInternship}
                            enableTabLoop
                            dateFormatCalendar='MMMM'
                            onChange={update => {
                              if (!moment(update[0]).isSame(update[1])) {
                                setDateRangeForInternship(update);
                              } else {
                                setDateRangeForInternship([null, null]);
                              }
                            }}
                            shouldCloseOnSelect
                            withPortal
                            monthsShown={2}
                            showPopperArrow={false}
                            selected={startDateForInternship}
                            value={
                              startDateForInternship || endDateForInternship
                                ? `${
                                  startDateForInternship
                                    ? moment(startDateForInternship).format(
                                      'DD.MM.'
                                    )
                                    : ''
                                } - ${
                                  endDateForInternship
                                    ? moment(endDateForInternship).format(
                                      'DD.MM.'
                                    )
                                    : ''
                                }`
                                : `${moment(
                                  new Date(
                                    `${moment().get('year')}-${
                                      subjectSelected.acceptRequestsFrom
                                    }`
                                  )
                                ).format('DD.MM.')} - ${moment(
                                  new Date(
                                    `${moment().get('year')}-${
                                      subjectSelected.acceptRequestsTo
                                    }`
                                  )
                                ).format('DD.MM.')}`
                            }
                          />
                          <div className='non-clickable-area-for-internship-range' />
                          {startDateForInternship && (
                            <div
                              className='clear-button'
                              onClick={() => {
                                setDateRangeForInternship([null, null]);
                              }}
                            />
                          )}
                        </div>
                        <button
                          className='edit-date-range-for-subject-button'
                          disabled={
                            !startDateForInternship || !endDateForInternship
                          }
                          onClick={e => {
                            e.preventDefault();
                            dispatch(
                              setSubjectAcceptanceInterval(
                                moment(startDateForInternship)
                                  .startOf('day')
                                  .format('MM-DD'),
                                moment(endDateForInternship)
                                  .endOf('day')
                                  .format('MM-DD')
                              )
                            );
                            setDateRangeForInternship([null, null]);
                          }}
                        >
                          {t('buttons.edit')}
                        </button>
                      </div>
                    </>
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Breadcrumbs />
      <div className='student-list-page__list'>
        <DataDisplay
          dataHeader={
            props.summaryList
              ? t('breadCrumbs.summaryList')
              : t('breadCrumbs.studentList')
          }
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={30}
          data={
            <div className='student-list-page__list__search'>
              {selectedSubject !==
                t('dropdowns.subjectsDropdown.defaultOption') && (
                <>
                  {(user.role === 'admin' || user.role === 'professor') && (
                    <button
                      className='download-passed-students-button'
                      onClick={() => setShowDownloadPassedStudentsModal(true)}
                    >
                      {t('buttons.downloadPassedStudents')}
                    </button>
                  )}
                  <Search
                    searchingByInfo={t('inputs.searchByInfo.studentList')}
                    setLoading={setLoading}
                    fetchData={() => {
                      return getStudentForSubjectbyQuery(
                        null,
                        selectedStatuses,
                        selectedStudyType,
                        startDate
                          ? moment(startDate)
                            .startOf('day')
                            .format('YYYY-MM-DD HH:mm:ss')
                          : null,
                        endDate
                          ? moment(endDate)
                            .endOf('day')
                            .format('YYYY-MM-DD HH:mm:ss')
                          : endDate
                      );
                    }}
                    fetchDataByName={name => {
                      return getStudentForSubjectbyQuery(
                        name,
                        selectedStatuses,
                        selectedStudyType,
                        startDate
                          ? moment(startDate)
                            .startOf('day')
                            .format('YYYY-MM-DD HH:mm:ss')
                          : null,
                        endDate
                          ? moment(endDate)
                            .endOf('day')
                            .format('YYYY-MM-DD HH:mm:ss')
                          : endDate
                      );
                    }}
                    setSearchQuery={setSearchQuery}
                  />
                </>
              )}
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          data={
            selectedSubject !==
              t('dropdowns.subjectsDropdown.defaultOption') && (
              <div>
                <div className='student-list-page__list__display-list'>
                  {loading && <div className='loader-small' />}
                  <div hidden={loading}>
                    {studentList && studentList.length > 0 ? (
                      <StudentList
                        summaryList={props.summaryList}
                        setSorting={setSorting}
                        setSelectedStudent={setSelectedStudent}
                        setShowSendEmailModal={setShowSendEmailModal}
                        studentList={studentList}
                        selectedSubject={selectedSubject}
                      />
                    ) : searchQuery === null ? (
                      t('notFound.studentList.noListFetched')
                    ) : (
                      t('notFound.studentList.noSearchResult')
                    )}
                  </div>
                </div>
                <div className='student-list-page__list__pagination'>
                  <StudentPagination
                    getStudentsForSubjectByPage={getStudentsForSubjectByPage}
                  />
                </div>
              </div>
            )
          }
        />
      </div>
      {showSendEmailModal && (
        <SendEmailModal
          setshowSendEmailModal={setShowSendEmailModal}
          student={selectedStudent}
        />
      )}
      {showDownloadPassedStudentsModal && (
        <DownloadPassedStudentsModal
          setShowDownloadPassedStudentsModal={
            setShowDownloadPassedStudentsModal
          }
        />
      )}
    </div>
  );
};

StudentListPage.propTypes = {
  summaryList: PropTypes.bool,
  internshipsDisplay: PropTypes.bool,
  userSelectedFiltering: PropTypes.object,
};

export default StudentListPage;
