import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import {
  FETCHED_MENTORS,
  FETCHED_MENTORS_FOR_COMPANY,
  FETCHED_MENTOR_INFO,
  SET_ERROR_MENTOR,
  SET_PAGE_PER_PAGE_MENTORS_LIST,
} from '../types';
import { setNewAddedAccount } from './userActions';

export const fetchMentors = setLoading => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/pagination?page=${
          getState().mentors.page
        }&per_page=${getState().mentors.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageMentorList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchMentors(setLoading));
      } else {
        if (setLoading) {
          setLoading(false);
        }
        dispatch({
          type: FETCHED_MENTORS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchMentorDetails = mentorId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/${mentorId}/info`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(fetchedMentorInfo(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchMentorInfo = () => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/info`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(fetchedMentorInfo(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchedMentorInfo = mentor => ({
  type: FETCHED_MENTOR_INFO,
  payload: mentor,
});

export const fetchMentorsByCompanyId = companyId => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/companies/${companyId}/mentors?page=${
          getState().mentors.page
        }&per_page=${getState().mentors.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageMentorList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchMentorsByCompanyId(companyId));
      } else {
        dispatch({
          type: FETCHED_MENTORS_FOR_COMPANY,
          payload: response.data.data,
        });
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchMentorsByName = name => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/mentors/pagination?search=${name}&page=${
          getState().mentors.page
        }&per_page=${getState().mentors.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageMentorList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchMentorsByName(name));
      } else {
        dispatch({
          type: FETCHED_MENTORS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const sortMentorsBy = (key, ascending) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/mentors/pagination?sort_by=${key}&sort_order=${
          ascending ? 'asc' : 'desc'
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch({
        type: FETCHED_MENTORS,
        payload: response.data.data,
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const addMentor = (
  mentor,
  professorAddingMentorToCompany,
  companyId,
  setLoading
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          ...mentor,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_MENTOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch(setNewAddedAccount(response.data.data));
      if (setLoading) {
        setLoading(true);
      }
      if (professorAddingMentorToCompany) {
        await dispatch(fetchMentorsByCompanyId(companyId));
      } else {
        await dispatch(fetchMentors(setLoading));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const updateMentor = (mentor, setLoading) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/${mentor.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          firstName: mentor.firstName,
          lastName: mentor.lastName,
          email: mentor.email,
          phone: mentor.phone,
          companyOib: mentor.companyOib,
          active: mentor.active,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_MENTOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      if (setLoading) {
        setLoading(true);
      }
      await dispatch(fetchMentors(setLoading));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const regenerateMentorPassword = mentorId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/mentors/${mentorId}/regenerate-password`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_MENTOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch(setNewAddedAccount({ password: response.data.data }));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_MENTOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const setPageAndPerPageMentorList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_MENTORS_LIST,
  payload: { page, perPage },
});
