import { PURGE } from 'redux-persist/lib/constants';
import {
  FETCH_STUDENTS_FOR_CLASS,
  SET_ERROR_STUDENT_LIST,
  SET_PAGE_PER_PAGE_STUDENT_LIST,
} from '../types';

const initialState = {
  studentList: null,
  selectedSubject: null,
  page: 1,
  perPage: 10,
  totalPages: null,
  error: null,
  status: null,
};

export const studentList = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STUDENTS_FOR_CLASS: {
      return {
        studentList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
      };
    }
    case SET_PAGE_PER_PAGE_STUDENT_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case SET_ERROR_STUDENT_LIST: {
      return {
        ...state,
        message: action.payload?.message,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
