import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import i18n from '../../i18n';
import settings from '../../settings';
import { FETCH_STUDENTS_FOR_CLASS, SET_ERROR_STUDENT_LIST } from '../types';
import { setPageAndPerPageStudentList } from './studentActions';

export const getStudentsForSubject = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/students/${
          getState().subjects.selectedSubject.id
        }/pagination?page=${getState().studentList.page}&per_page=${
          getState().studentList.perPage
        }&sort_by=completed_at&sort_order=asc`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageStudentList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(getStudentsForSubject());
      } else {
        dispatch({
          type: FETCH_STUDENTS_FOR_CLASS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDENT_LIST,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const getStudentForSubjectbyQuery = (
  name,
  status,
  studyType,
  startDate,
  endDate,
  headerKey,
  sortOrder
) => {
  return async (dispatch, getState) => {
    let query = '';
    if (name) query += `&search=${name}`;
    if (status) query += `&status=${status}`;
    if (studyType) query += `&study_type=${studyType.value}`;
    if (startDate && endDate)
      query += `&completed_between=${startDate},${endDate}`;

    query += `&sort_by=${headerKey ? headerKey : 'completed_at'}&sort_order=${
      sortOrder !== null ? (sortOrder ? 'desc' : 'asc') : 'asc'
    }`;
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/students/${
          getState().subjects.selectedSubject.id
        }/pagination?page=${getState().studentList.page}&per_page=${
          getState().studentList.perPage
        }${query}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageStudentList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(
          getStudentForSubjectbyQuery(
            name,
            status,
            studyType,
            startDate,
            endDate,
            headerKey,
            sortOrder
          )
        );
      } else {
        dispatch({
          type: FETCH_STUDENTS_FOR_CLASS,
          payload: response.data.data,
        });
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDENT_LIST,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const downloadPassedStudents = year => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/${
          getState().subjects.selectedSubject.id
        }/download-passed-list`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        params: { year },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      const file = new Blob([response.data]);
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        `${i18n.t(
          'professorHomepage.downloadedPassedStudentListName'
        )}-${moment().format('DD.MM.YYYY. HH mm')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch({
        type: SET_ERROR_STUDENT_LIST,
        payload: {
          error: null,
          status: response.status,
          message: i18n.t('passedStudentsListSuccessfullyDownloaded'),
        },
      });
      dispatch({
        type: SET_ERROR_STUDENT_LIST,
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_STUDENT_LIST,
          payload: {
            error: i18n.t('errors.errorOccured'),
            status: error.response.status,
          },
        });
      }
    }
  };
};
