import axios from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  approveCertificateOfEmploymentProfessor,
  checkDocumentGenerationStatuses,
  checkStatusForScript,
  clearSelectedSubject,
  deleteCertificateOfEmployment,
  deleteInternship,
  downloadCertificateOfEmployment,
  executeRequestsWithLoading,
  fetchCertificateOfEmploymentProfessor,
  fetchCertificateOfEmploymentStudent,
  fetchInternshipInfo,
  fetchInternshipLogs,
  fetchInternshipRequests,
  fetchInternshipRequestsProfessor,
  fetchInternshipStudent,
  fetchInternshipStudentProfessor,
  fetchStudentInfo,
  loginStudent,
  rejectCertificateOfEmploymentProfessor,
  removeDataSingleStudent,
  scriptsSingleDataFetched,
  scriptStatusCheckSingleStudent,
  setInternshipDocumentStatuses,
  setSubject,
  showInternshipDetails,
  startLoading,
  syncStudent,
  uploadCertificateOfEmployment,
} from '../../../store/actions';
import AddInternshipForStudentModal from '../../Professor/AddInternshipForStudentModal/AddInternshipForStudentModal';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import DocumentUpload from '../../SharedComponents/FileUploads/DocumentUpload';
import AlertModal from '../../SharedComponents/InfoModal/AlertModal/AlertModal';
import ProgressBar from '../../SharedComponents/ProgressBar/ProgressBar';
import { ReasonModal } from '../../SharedComponents/ReasonModal/ReasonModal';
import { SendEmailModal } from '../../SharedComponents/SendEmailModal/SendEmailModal';
import { StudentInfo } from '../StudentInfo/StudentInfo';
import { InternshipInfo } from '../StudentInternship/InternshipInfo/InternshipInfo';
import { InternshipLog } from '../StudentInternship/internshipLog/InternshipLog';
import { StudentInternshipRequestList } from '../StudentInternship/StudentInternshipRequestList/StudentInternshipRequestList';
import './StudentHomepage.scss';

export const StudentHomepage = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const mountedRef = useRef(true);

  const retryGeneration = useSelector(state => state.retryGeneration);
  const selectedStudy = useSelector(state => state.studies?.selectedStudy);
  const studies = useSelector(state => state.studies?.studies);
  const student = useSelector(state => state.student);
  const user = useSelector(state => state.user);
  const internship = useSelector(state => state.internship);
  const syncScriptSingleStudent = useSelector(
    state => state.scriptsSingleStudent
  );
  const documentGenerationStatues = useSelector(
    state => state.internship.documentStatuses
  );

  const role = user.role;
  const studyType = student?.studyType?.toLowerCase();

  const currentSubject = useSelector(state => state.subjects.selectedSubject);
  const loader = useSelector(state => state.loader && state.loader.loading);

  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [currentInternship, setCurrentInternship] = useState(null);
  const [showCurrentSubject, setShowCurrentSubject] = useState(false);
  const [showCertificateUpload, setShowCertificateUpload] = useState(false);
  const [showCertificateUploadInput, setShowCertificateUploadInput] =
    useState(false);
  const [certificate, setCertificate] = useState(null);
  const [hasInternship, setHasInternship] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showAddInternshipModal, setShowAddInternshipModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState(
    t('dropdowns.studentSubjectDropdown.defaultOption')
  );
  const [intervalState, setIntervalState] = useState(null);
  const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);
  var interval = useRef(intervalState);
  let intervalSingleStudentOnHomepage = useRef(null);
  let timeoutSingleStudentSync = useRef(null);

  const resetInterval = () => {
    clearInterval(interval.current);
    interval.current = null;
    setIntervalState(null);
  };

  useEffect(() => {
    if (student && student.id) {
      if (role !== 'student') {
        dispatch(fetchStudentInfo(student.id));
      } else {
        dispatch(fetchStudentInfo());
      }
    }
    return () => {
      mountedRef.current = false;
      dispatch({
        type: 'SET_RETRY_GENERATION',
        payload: { retryGenerationFor: null, status: null },
      });
      dispatch(showInternshipDetails('info'));
      dispatch(
        scriptStatusCheckSingleStudent({
          status: null,
          data: null,
        })
      );
      resetInterval();
      if (intervalSingleStudentOnHomepage.current) {
        clearInterval(intervalSingleStudentOnHomepage.current);
      }
      if (timeoutSingleStudentSync.current) {
        clearInterval(timeoutSingleStudentSync.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (role !== 'student') {
        dispatch({
          type: 'INTERNSHIP_REQUESTS_FETCHED',
          payload: {
            data: null,
          },
        });
        dispatch({
          type: 'INTERNSHIP_LOGS_FETCHED',
          payload: {
            data: null,
          },
        });
      }
    };
  }, [role]);

  const getInternshipInfo = async () => {
    await executeRequestsWithLoading(
      [dispatch(fetchInternshipInfo(internship.internship?.id))],
      setLoading,
      mountedRef
    );
  };

  useEffect(() => {
    if (currentSubject && student.id) {
      getCurrentSubject();
    }
    if (role === 'mentor' && student.id) {
      setShowCurrentSubject(true);
      getInternshipInfo();
    }
  }, [student.id, currentSubject && currentSubject.id]);

  async function fetchCertificateOfEmployment() {
    if (studyType === 'izvanredni' && currentSubject) {
      await executeRequestsWithLoading(
        user.role === 'student'
          ? [dispatch(fetchCertificateOfEmploymentStudent())]
          : role === 'admin' || role === 'professor'
            ? [dispatch(fetchCertificateOfEmploymentProfessor(student.id))]
            : [],
        setLoading,
        mountedRef
      );
    }
  }
  useEffect(() => {
    fetchCertificateOfEmployment();
  }, [studyType, currentSubject && currentSubject.id]);

  useEffect(() => {
    if (
      studyType === 'izvanredni' ||
      role === 'professor' ||
      role === 'admin'
    ) {
      checkForCertificate();
    }
  }, [internship]);

  useEffect(() => {
    if (
      interval.current === null &&
      intervalState === null &&
      internship.internship &&
      internship.internship.status &&
      internship.internship.status !== 'PROCESSING' &&
      (internship.internship.referralGenerationStatus === 'GENERATING' ||
        internship.internship.confirmationGenerationStatus === 'GENERATING' ||
        internship.internship.diaryGenerationStatus === 'GENERATING')
    ) {
      var startTime = new Date().getTime();
      interval.current = setInterval(() => {
        if (new Date().getTime() - startTime > 120000 - 4000) {
          clearInterval(interval.current);
          interval.current = 'stop';
          setIntervalState('stop');
          return;
        }
        if (role !== 'student') {
          dispatch(fetchInternshipInfo(internship.internship.id));
        }
        if (role === 'student') {
          dispatch(fetchInternshipStudent());
        }
        dispatch(checkDocumentGenerationStatuses());
      }, 4000);
    }
  }, [internship, intervalState]);

  useEffect(() => {
    if (retryGeneration && retryGeneration.retryGenerationFor) {
      if (
        retryGeneration.retryGenerationFor === 'referral' &&
        (internship.internship.referralGenerationStatus === 'GENERATED' ||
          internship.internship.referralGenerationStatus ===
            'GENERATION_FAILED')
      ) {
        dispatch({
          type: 'SET_RETRY_GENERATION',
          payload: { retryGenerationFor: null, status: null },
        });
        clearInterval(interval.current);
      }
      if (
        retryGeneration.retryGenerationFor === 'confirmation' &&
        (internship.internship.confirmationGenerationStatus === 'GENERATED' ||
          internship.internship.confirmationGenerationStatus ===
            'GENERATION_FAILED')
      ) {
        dispatch({
          type: 'SET_RETRY_GENERATION',
          payload: { retryGenerationFor: null, status: null },
        });
        clearInterval(interval.current);
      }
      if (
        retryGeneration.retryGenerationFor === 'diary' &&
        (internship.internship.diaryGenerationStatus === 'GENERATED' ||
          internship.internship.diaryGenerationStatus === 'GENERATION_FAILED')
      ) {
        dispatch({
          type: 'SET_RETRY_GENERATION',
          payload: { retryGenerationFor: null, status: null },
        });
        clearInterval(interval.current);
      }
    } else {
      if (
        internship.internship &&
        internship.internship.status !== 'PROCESSING' &&
        internship.internship.status !== 'COMPLETED' &&
        internship.internship.status !== 'IN_REVIEW_PROFESSOR' &&
        internship.internship.status !== 'REVIEW_FAIL' &&
        (internship.internship.referralGenerationStatus === 'GENERATED' ||
          internship.internship.referralGenerationStatus ===
            'GENERATION_FAILED')
      ) {
        clearInterval(interval.current);
      }
      if (
        internship.internship &&
        (internship.internship.status === 'IN_REVIEW_PROFESSOR' ||
          internship.internship.status === 'REVIEW_FAIL') &&
        (internship.internship.confirmationGenerationStatus === 'GENERATED' ||
          internship.internship.confirmationGenerationStatus ===
            'GENERATION_FAILED')
      ) {
        clearInterval(interval.current);
      }
      if (
        internship.internship &&
        internship.internship.status === 'COMPLETED' &&
        (internship.internship.diaryGenerationStatus === 'GENERATED' ||
          internship.internship.diaryGenerationStatus === 'GENERATION_FAILED')
      ) {
        clearInterval(interval.current);
      }
    }
  }, [internship, documentGenerationStatues]);

  useEffect(() => {
    if (syncScriptSingleStudent.data && !syncScriptSingleStudent.message) {
      intervalSingleStudentOnHomepage.current = setInterval(() => {
        dispatch(checkStatusForScript(false, syncScriptSingleStudent.data));
      }, 1500);
    }
    if (
      (syncScriptSingleStudent.message &&
        syncScriptSingleStudent.message !== 'STARTED') ||
      syncScriptSingleStudent.status !== 200
    ) {
      clearInterval(intervalSingleStudentOnHomepage.current);
      if (syncScriptSingleStudent.status) {
        dispatch(removeDataSingleStudent());
        timeoutSingleStudentSync.current = setTimeout(() => {
          dispatch(
            scriptsSingleDataFetched({
              status: null,
              data: null,
            })
          );
        }, 5000);
      }
    }
  }, [syncScriptSingleStudent]);

  useEffect(
    prevProps => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');
      const refreshToken = urlParams.get('refresh_token');
      if (!user.isAuthenticated && accessToken && refreshToken) {
        dispatch(startLoading());
        dispatch(loginStudent(accessToken, refreshToken));
      } else {
        history.replace('/login');
      }
      if (prevProps !== props && user.isAuthenticated) {
        history.replace('/student-homepage');
      }
    },
    [user, currentSubject]
  );

  useEffect(() => {
    async function fetchLogs() {
      await Promise.allSettled([
        dispatch(fetchInternshipLogs(internship.internship.id)),
      ]);
    }
    if (!internship.internship && currentInternship) {
      setHasInternship(true);
    }
    if (internship && internship.internship && internship.internship.id) {
      setCurrentInternship(null);
      setHasInternship(true);
      fetchLogs();
    }
    if (!internship.internship && !currentInternship) {
      setHasInternship(false);
    }
  }, [currentInternship, internship.internship]);

  const checkForCertificate = () => {
    if (internship.certificateOfEmployment) {
      setShowCertificateUpload(true);
    } else if (
      !hasInternship &&
      internship.requests &&
      internship.requests.length === 0
    ) {
      if (!internship.certificateOfEmployment) {
        setShowCertificateUpload(true);
      } else {
        setShowCertificateUpload(false);
      }
    } else {
      setShowCertificateUpload(false);
    }
  };

  const getCurrentSubject = () => {
    setSendButtonIsDisabled(false);
    getInternship();
    setShowCurrentSubject(true);
    const startDate = new Date(
      new Date(`${moment().get('year')}-${currentSubject.acceptRequestsFrom}`)
    );
    const endDate = new Date(
      new Date(`${moment().get('year')}-${currentSubject.acceptRequestsTo}`)
    );
    if (moment(startDate).diff(endDate, 'days') >= 1) {
      if (moment().isBetween(endDate, startDate, 'days', '()')) {
        setSendButtonIsDisabled(true);
      }
    } else if (!moment().isBetween(startDate, endDate, 'days', '[]')) {
      setSendButtonIsDisabled(true);
    }
    if (role === 'student' && !currentSubject.active) {
      setSendButtonIsDisabled(true);
    }
  };

  const getInternship = async () => {
    await executeRequestsWithLoading(
      role !== 'student'
        ? role !== 'mentor'
          ? [
            dispatch(fetchInternshipRequestsProfessor()),
            dispatch(fetchInternshipStudentProfessor()),
          ]
          : []
        : [
          dispatch(fetchInternshipRequests()),
          dispatch(fetchInternshipStudent()),
        ],
      setLoading,
      mountedRef
    );
  };

  const handleDropdownSelect = item => {
    resetInterval();
    if (item !== t('dropdowns.studentSubjectDropdown.defaultOption')) {
      if (!currentSubject || currentSubject.id !== item.id) {
        dispatch(setSubject(Number(item.id)));
        dispatch(showInternshipDetails('info'));
        dispatch({
          type: 'CERTIFICATE_OF_EMPLOYMENT_FETCHED',
          payload: null,
        });
        setHasInternship(false);
        setCertificate(false);
        setShowCertificateUpload(false);
        setShowCertificateUploadInput(false);
        setDropdownTitle(item.name);
      }
    } else {
      dispatch(clearSelectedSubject());
      setHasInternship(false);
      setCertificate(false);
      setShowCertificateUpload(false);
      setShowCertificateUploadInput(false);
      setDropdownTitle(item);
    }
  };

  const checkSyncStatus = status => {
    switch (status) {
      case 'STARTED':
        break;
      case 'FAILED':
        return 'Sinkronizacija nije uspjela';
      case 'FINISHED_WITH_ERRORS':
        return 'Sinkronizacije je završila sa greškama';
      case 'FINISHED':
        return 'Sinkronizacija završena';
      default:
        return status;
    }
  };

  return !loader ? (
    <div className='student-homepage'>
      {/* student info card */}
      {user.isAuthenticated && student && (
        <>
          <StudentInfo
            currentSubject={currentSubject}
            selectedStudy={selectedStudy}
            studies={studies}
            studentInfo={student}
            user={user}
            setShowEmailModal={setShowEmailModal}
            resetSubjectDropdown={() => {
              dispatch(clearSelectedSubject());
              setHasInternship(false);
              setCertificate(false);
              setShowCertificateUpload(false);
              setShowCertificateUploadInput(false);
              setDropdownTitle(
                t('dropdowns.studentSubjectDropdown.defaultOption')
              );
            }}
          />
          {(role === 'professor' || role === 'admin') && (
            <div>
              <button
                disabled={
                  !syncScriptSingleStudent.message
                    ? syncScriptSingleStudent.data
                    : syncScriptSingleStudent.data &&
                      syncScriptSingleStudent.message === 'STARTED'
                }
                className='synchronize-student'
                onClick={() => {
                  dispatch(syncStudent(student.jmbag));
                }}
              >
                {t('buttons.syncStudent')}
              </button>
              <div>{checkSyncStatus(syncScriptSingleStudent.message)}</div>
            </div>
          )}
        </>
      )}
      {/* student subjects dropdown */}
      {role === 'student' && selectedStudy && (
        <div className='student-homepage__dropdown'>
          <div className='student-homepage__dropdown__label'>
            {t('dropdowns.studentSubjectDropdown.title')}
          </div>
          <Dropdown
            customclass='student-subjects-dropdown'
            list={
              student.subjects &&
              student.subjects
                .filter(subject => subject.studyId === selectedStudy.id)
                .map(subject => {
                  return {
                    id: subject.id,
                    acceptRequestsFrom: subject.acceptRequestsFrom,
                    acceptRequestsTo: subject.acceptRequestsTo,
                    name: (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {subject.name}
                        {!subject.active ? (
                          <div className='not-active'>{t('notActive')}</div>
                        ) : subject.passed ? (
                          <div className='selected-subject-passed'>
                            {t('subjectPassed')}
                          </div>
                        ) : (
                          <div className='selected-subject-study'>
                            {selectedStudy.name}
                          </div>
                        )}
                      </div>
                    ),
                    key: subject.name,
                  };
                })
            }
            handleSelect={handleDropdownSelect}
            headerTitle={
              currentSubject ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {currentSubject.name}
                  {!currentSubject.active ? (
                    <div className='not-active'>{t('notActive')}</div>
                  ) : currentSubject.passed ? (
                    <div className='selected-subject-passed'>
                      {t('subjectPassed')}
                    </div>
                  ) : (
                    <div className='selected-subject-study'>
                      {selectedStudy.name}
                    </div>
                  )}
                </div>
              ) : (
                dropdownTitle
              )
            }
            defaultHeaderOption={t(
              'dropdowns.studentSubjectDropdown.defaultOption'
            )}
          />
        </div>
      )}
      {(user.role === 'mentor' || currentSubject) && (
        <DataDisplay
          headerFontSize={24}
          headerBolded
          displayInColumn
          dataSeparatorBottomSpacing={25}
          dataSeparatorTopSpacing={15}
          data={
            <>
              <div className='student-homepage__class-options'>
                <button
                  type='button'
                  className={classNames({
                    'student-homepage__class-options__internship-info': true,
                    active: internship.internshipDetails === 'info',
                  })}
                  onClick={() => {
                    dispatch(showInternshipDetails('info'));
                  }}
                >
                  {t('studentHomepage.internshipInfoButton')}
                </button>
                <button
                  type='button'
                  className={classNames({
                    'student-homepage__class-options__internship-logs': true,
                    active: internship.internshipDetails === 'logs',
                  })}
                  disabled={!hasInternship}
                  onClick={() => {
                    if (hasInternship && internship.internship) {
                      dispatch(showInternshipDetails('logs'));
                    }
                  }}
                >
                  {t('studentHomepage.internshipLogsButton')}
                </button>
              </div>
              {uploadProgress ? (
                <ProgressBar completed={uploadProgress} />
              ) : (
                <>
                  {loading && (
                    <div className='loader-small dropdown__wrapper__menu__item' />
                  )}
                  <div hidden={loading}>
                    {internship &&
                      internship.internship &&
                      internship.internship.status &&
                      internship.internship.status !== 'COMPLETED' && (
                      <div>
                        {(role === 'professor' || role === 'admin') && (
                          <button
                            className='delete-internship'
                            onClick={() => {
                              setShowAlertModal(true);
                            }}
                          >
                            {t('buttons.deleteInternship')}
                          </button>
                        )}
                      </div>
                    )}
                    {((currentSubject && currentSubject.isFinished === true) ||
                      (internship &&
                        internship.internship &&
                        internship.internship.status &&
                        internship.internship.status === 'COMPLETED')) && (
                      <div className='internship-completed'>
                        {t('studentHomepage.internshipIsFinished')}
                      </div>
                    )}
                    {!hasInternship &&
                      !currentSubject?.isFinished &&
                      (internship.requests &&
                      internship.requests.length > 0 &&
                      !currentInternship ? (
                          <div className='student-homepage__internship-display'>
                            <div className='student-homepage__internship-display__header'>
                              {role === 'student' ? (
                                <>
                                  <DataDisplay
                                    dataClass='internship-requests-companies-header'
                                    dataHeader={t('studentHomepage.requestsList')}
                                    headerBolded
                                    headerFontSize={20}
                                    headerTextColor={'#005BA7'}
                                    centerHeaderVertically
                                    dataFullWidth
                                    floatDataRight
                                    TopSpacing={30}
                                    data={
                                      <div className='view-companies-list-button'>
                                        <button
                                          data-tip
                                          data-for={
                                            sendButtonIsDisabled &&
                                          'send-internship-request-button'
                                          }
                                          type='button'
                                          className={classNames({
                                            'student-homepage__no-internship__show-companies-btn': true,
                                            disabled: sendButtonIsDisabled,
                                          })}
                                          onClick={() => {
                                            if (!sendButtonIsDisabled) {
                                              history.push('/companies');
                                            }
                                          }}
                                        >
                                          <div className='student-homepage__no-internship__show-companies-btn__icon'></div>
                                          <div className='student-homepage__no-internship__show-companies-btn__text'>
                                            {t('buttons.viewCompaniesList')}
                                          </div>
                                        </button>
                                        {sendButtonIsDisabled && (
                                          <ReactTooltip
                                            id='send-internship-request-button'
                                            place='top'
                                            effect='solid'
                                          >
                                            {t(
                                              'studentHomepage.internshipRequestCannotBeSentAtThisTime'
                                            )}
                                          </ReactTooltip>
                                        )}
                                      </div>
                                    }
                                  />
                                </>
                              ) : (
                                role !== 'mentor' && (
                                  <DataDisplay
                                    dataClass='internship-requests-companies-header'
                                    dataHeader={t('studentHomepage.requestsList')}
                                    headerBolded
                                    headerFontSize={20}
                                    headerTextColor={'#005BA7'}
                                    centerHeaderVertically
                                    dataFullWidth
                                    floatDataRight
                                    TopSpacing={30}
                                    data={
                                      <div className='view-companies-list-button'>
                                        <button
                                          type='button'
                                          className='student-homepage__no-internship__show-companies-btn'
                                          onClick={() => {
                                            setShowAddInternshipModal(true);
                                          }}
                                        >
                                          <div className='student-homepage__no-internship__show-companies-btn__icon'></div>
                                          <div className='student-homepage__no-internship__show-companies-btn__text'>
                                            {t('buttons.addInternship')}
                                          </div>
                                        </button>
                                      </div>
                                    }
                                  />
                                )
                              )}
                            </div>
                            <StudentInternshipRequestList
                              role={role}
                              setLoading={setLoading}
                              setCurrentInternship={setCurrentInternship}
                              internshipRequestList={internship.requests}
                            />
                          </div>
                        ) : (
                          ((internship.certificateOfEmployment &&
                          internship.certificateOfEmployment.status !==
                            'APPROVED') ||
                          !internship.certificateOfEmployment) && (
                            <div className='student-homepage__no-internship'>
                              {role === 'student' ? (
                                <>
                                  {!internship.certificateOfEmployment && (
                                    <div className='student-homepage__no-internship__message'>
                                      <div className='student-homepage__no-internship__message__icon'></div>
                                      <div className='student-homepage__no-internship__message__text'>
                                        {t(
                                          'studentHomepage.youCurrentlyDoNotHaveInternshipRequest'
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <button
                                    data-tip
                                    data-for={
                                      (sendButtonIsDisabled ||
                                      (internship &&
                                        internship.certificateOfEmployment)) &&
                                    'send-internship-request-button'
                                    }
                                    type='button'
                                    className={classNames({
                                      'student-homepage__no-internship__show-companies-btn': true,
                                      disabled:
                                      sendButtonIsDisabled ||
                                      (internship &&
                                        internship.certificateOfEmployment),
                                    })}
                                    onClick={() => {
                                      if (
                                        !sendButtonIsDisabled &&
                                      internship &&
                                      !internship.certificateOfEmployment
                                      ) {
                                        history.push('/companies');
                                      }
                                    }}
                                  >
                                    <div className='student-homepage__no-internship__show-companies-btn__icon'></div>
                                    <div className='student-homepage__no-internship__show-companies-btn__text'>
                                      {t('buttons.viewCompaniesList')}
                                    </div>
                                  </button>
                                  {(sendButtonIsDisabled ||
                                  (internship &&
                                    internship.certificateOfEmployment)) && (
                                    <ReactTooltip
                                      id='send-internship-request-button'
                                      place='top'
                                      effect='solid'
                                    >
                                      {internship.certificateOfEmployment
                                        ? t(
                                          'studentHomepage.internshipCannotBeCreatedBecauseOfCertificate'
                                        )
                                        : t(
                                          'studentHomepage.internshipRequestCannotBeSentAtThisTime'
                                        )}
                                    </ReactTooltip>
                                  )}
                                </>
                              ) : (
                                role !== 'mentor' && (
                                  <>
                                    {internship &&
                                    !internship.certificateOfEmployment && (
                                      <div className='student-homepage__no-internship__message'>
                                        <div className='student-homepage__no-internship__message__icon'></div>
                                        <div className='student-homepage__no-internship__message__text'>
                                          {t(
                                            'studentHomepage.thisStudentDoesNotHaveInternshipRequest'
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <button
                                      data-tip
                                      data-for={
                                        internship &&
                                      internship.certificateOfEmployment &&
                                      'send-internship-request-button'
                                      }
                                      type='button'
                                      className={classNames({
                                        'student-homepage__no-internship__show-companies-btn': true,
                                        disabled:
                                        internship &&
                                        internship.certificateOfEmployment,
                                      })}
                                      onClick={() => {
                                        if (
                                          internship &&
                                        !internship.certificateOfEmployment
                                        ) {
                                          setShowAddInternshipModal(true);
                                        }
                                      }}
                                    >
                                      <div className='student-homepage__no-internship__show-companies-btn__icon'></div>
                                      <div className='student-homepage__no-internship__show-companies-btn__text'>
                                        {t('buttons.addInternship')}
                                      </div>
                                    </button>
                                    {internship &&
                                    internship.certificateOfEmployment && (
                                      <ReactTooltip
                                        id='send-internship-request-button'
                                        place='top'
                                        effect='solid'
                                      >
                                        {t(
                                          'studentHomepage.internshipCannotBeCreatedBecauseOfCertificate'
                                        )}
                                      </ReactTooltip>
                                    )}
                                  </>
                                )
                              )}
                            </div>
                          )
                        ))}
                    {showCertificateUpload &&
                      (internship.certificateOfEmployment ? (
                        <>
                          <DataDisplay
                            displayInColumn
                            data={
                              <>
                                {internship.certificateOfEmployment.status ===
                                  'IN_REVIEW' && (
                                  <DataDisplay
                                    dataHeader={t(
                                      'studentHomepage.internshipInfo.status'
                                    )}
                                    headerBolded
                                    data={t('certificateStatuses.inReview')}
                                  />
                                )}
                                {internship.certificateOfEmployment.status ===
                                  'APPROVED' &&
                                  (role === 'professor' || role === 'admin' ? (
                                    <DataDisplay
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.status'
                                      )}
                                      headerBolded
                                      data={
                                        <div className='certificate-status-container'>
                                          <div className='certificate-status-container__icon__approve'></div>
                                          <div className='certificate-status-container__text'>
                                            {t(
                                              'certificateStatuses.approvedForStudent'
                                            )}
                                          </div>
                                        </div>
                                      }
                                    />
                                  ) : (
                                    <DataDisplay
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.status'
                                      )}
                                      headerBolded
                                      data={
                                        <div className='certificate-status-container'>
                                          <div className='certificate-status-container__icon__approve'></div>
                                          <div className='certificate-status-container__text'>
                                            {t('certificateStatuses.approved')}
                                          </div>
                                        </div>
                                      }
                                    />
                                  ))}
                                {internship.certificateOfEmployment.status ===
                                  'REJECTED' && (
                                  <>
                                    <DataDisplay
                                      headerBolded
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.status'
                                      )}
                                      data={t('certificateStatuses.rejected')}
                                    />
                                    <DataDisplay
                                      headerBolded
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.certificate.rejectee'
                                      )}
                                      data={
                                        internship.certificateOfEmployment
                                          .rejecterType === 'ADMIN'
                                          ? t('userRole.admin')
                                          : `${t('userRole.professor')} ${
                                            internship.certificateOfEmployment
                                              .rejecterName
                                          }`
                                      }
                                    />
                                    <DataDisplay
                                      headerBolded
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.certificate.date'
                                      )}
                                      data={moment
                                        .utc(
                                          internship.certificateOfEmployment
                                            .rejectionDate
                                        )
                                        .format('DD.MM.YYYY.')}
                                    />
                                    <DataDisplay
                                      headerBolded
                                      dataHeader={t(
                                        'studentHomepage.internshipInfo.certificate.reason'
                                      )}
                                      data={
                                        internship.certificateOfEmployment
                                          .rejectionReason
                                      }
                                    />
                                  </>
                                )}
                                <DataDisplay
                                  dataHeader={t(
                                    'studentHomepage.internshipInfo.certificate.document'
                                  )}
                                  headerBolded
                                  centerHeaderVertically
                                  data={
                                    <button
                                      className='document-container'
                                      onClick={() => {
                                        dispatch(
                                          downloadCertificateOfEmployment()
                                        );
                                      }}
                                    >
                                      <div className='document-container__icon'></div>
                                      <div className='document-container__text'>
                                        {
                                          internship.certificateOfEmployment
                                            .name
                                        }
                                      </div>
                                    </button>
                                  }
                                />
                                <div className='student-homepage__certificate-of-employment-actions'>
                                  {role === 'professor' || role === 'admin'
                                    ? internship.certificateOfEmployment
                                      .status === 'IN_REVIEW' && (
                                      <>
                                        <button
                                          className='student-homepage__certificate-of-employment-actions__confirm'
                                          onClick={() => {
                                            executeRequestsWithLoading(
                                              [
                                                dispatch(
                                                  approveCertificateOfEmploymentProfessor()
                                                ),
                                              ],
                                              setLoading,
                                              mountedRef
                                            );
                                          }}
                                        >
                                          {t('buttons.approveCertificate')}
                                        </button>
                                        <button
                                          className='student-homepage__certificate-of-employment-actions__decline'
                                          onClick={() => {
                                            setShowReasonModal(true);
                                          }}
                                        >
                                          {t('buttons.rejectCertificate')}
                                        </button>
                                      </>
                                    )
                                    : role === 'student' &&
                                      internship.certificateOfEmployment
                                        .status !== 'APPROVED' && (
                                      <button
                                        className='student-homepage__certificate-of-employment-actions__delete'
                                        onClick={() => {
                                          executeRequestsWithLoading(
                                            [
                                              dispatch(
                                                deleteCertificateOfEmployment()
                                              ),
                                            ],
                                            setLoading,
                                            mountedRef
                                          );
                                        }}
                                      >
                                        {t('buttons.removeDocument')}
                                      </button>
                                    )}
                                </div>
                              </>
                            }
                          />
                        </>
                      ) : (
                        studyType === 'izvanredni' &&
                        role === 'student' && (
                          <>
                            <button
                              className='student-homepage__certificate-of-employment-actions__submit-certificate'
                              onClick={() => {
                                axios
                                  .get('/assets/POTVRDA O ZAPOSLENJU.pdf', {
                                    responseType: 'blob',
                                  })
                                  .then(response => {
                                    let url = window.URL.createObjectURL(
                                      response.data
                                    );
                                    let a = document.createElement('a');
                                    a.href = url;
                                    a.download = t(
                                      'professorHomepage.downloadedCertificateName'
                                    );
                                    a.click();
                                    window.URL.revokeObjectURL(url);
                                  });
                              }}
                            >
                              <div className='student-homepage__certificate-of-employment-actions__submit-certificate__icon'></div>
                              <div className='student-homepage__certificate-of-employment-actions__submit-certificate__text'>
                                {t('buttons.downloadCertificateOfEmployment')}
                              </div>
                            </button>
                            {!showCertificateUploadInput && (
                              <>
                                <button
                                  data-tip
                                  data-for={
                                    sendButtonIsDisabled &&
                                  'upload-certificate-button'
                                  }
                                  type='button'
                                  className={classNames({
                                    'student-homepage__certificate-of-employment-actions__submit-certificate': true,
                                    disabled: sendButtonIsDisabled,
                                  })}
                                  onClick={() => {
                                    if (!sendButtonIsDisabled) {
                                      setShowCertificateUploadInput(true);
                                    }
                                  }}
                                >
                                  <div className='student-homepage__certificate-of-employment-actions__submit-certificate__icon'></div>
                                  <div className='student-homepage__certificate-of-employment-actions__submit-certificate__text'>
                                    {t('buttons.uploadCertificateOfEmployment')}
                                  </div>
                                </button>
                                {sendButtonIsDisabled && (
                                  <ReactTooltip
                                    id='upload-certificate-button'
                                    place='top'
                                    effect='solid'
                                  >
                                    {t(
                                      'studentHomepage.certificateCannotBeUploadedAtThisTime'
                                    )}
                                  </ReactTooltip>
                                )}
                              </>
                            )}
                            {showCertificateUploadInput && (
                              <>
                                <div className='student-homepage__certificate-of-employment-certificate'>
                                  <DocumentUpload
                                    documentFile={
                                      certificate ? certificate : {}
                                    }
                                    setDocumentFile={setCertificate}
                                  />
                                  <button
                                    className='student-homepage__certificate-of-employment-certificate__action'
                                    hidden={!certificate}
                                    onClick={() => {
                                      if (certificate !== null) {
                                        const documentFile = new FormData();
                                        documentFile.append(
                                          'file',
                                          certificate.document
                                        );
                                        dispatch(
                                          uploadCertificateOfEmployment(
                                            documentFile,
                                            setUploadProgress
                                          )
                                        );
                                        setShowCertificateUploadInput(false);
                                      }
                                    }}
                                  >
                                    {t('buttons.send')}
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        )
                      ))}
                    {!showCertificateUpload &&
                      (user.role === 'mentor' ||
                        (showCurrentSubject && currentSubject !== '0')) &&
                      hasInternship &&
                      internship.internshipDetails === 'info' && (
                      <InternshipInfo
                        setLoading={setLoading}
                        resetInterval={resetInterval}
                        interval={intervalState}
                        user={user}
                        internship={
                          currentInternship
                            ? currentInternship
                            : internship.internship
                        }
                      />
                    )}
                    {hasInternship &&
                      internship.internshipDetails === 'logs' &&
                      (user.role === 'mentor' || showCurrentSubject) && (
                      <InternshipLog
                        setLoading={setLoading}
                        resetInterval={resetInterval}
                        interval={intervalState}
                        user={user}
                        internship={internship.internship}
                        logs={internship.logs}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          }
        />
      )}
      {showEmailModal && (
        <SendEmailModal
          setshowSendEmailModal={setShowEmailModal}
          student={student}
        />
      )}
      {showAddInternshipModal && (
        <AddInternshipForStudentModal
          setLoading={setLoading}
          setShowAddInternshipModal={setShowAddInternshipModal}
          student={student}
        />
      )}
      {showReasonModal && (
        <ReasonModal
          setShowReasonModal={setShowReasonModal}
          sendReasonData={data => {
            executeRequestsWithLoading(
              [dispatch(rejectCertificateOfEmploymentProfessor(data))],
              setLoading,
              mountedRef
            );
          }}
        />
      )}
      {showAlertModal && (
        <AlertModal
          alertInfotext={t('modal.alertModal.doYouWantToRemoveInternship')}
          confirmOptions={async () => {
            setLoading(true);
            setShowAlertModal(false);
            await executeRequestsWithLoading(
              [
                dispatch(showInternshipDetails('info')),
                dispatch(deleteInternship(resetInterval)),
                dispatch(setInternshipDocumentStatuses(null)),
              ],
              setLoading,
              mountedRef
            );
          }}
          declineOptions={() => {
            setShowAlertModal(false);
          }}
        />
      )}
    </div>
  ) : (
    <div className='loader' />
  );
};
