import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSelectedStudy } from '../../../store/actions';
import * as Images from '../../../styles/assets/images';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import AlertModal from '../../SharedComponents/InfoModal/AlertModal/AlertModal';
import './StudentInfo.scss';

export const StudentInfo = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentSubject,
    studentInfo,
    user,
    studies,
    selectedStudy,
    resetSubjectDropdown,
  } = props;
  const [showAlertModal, setShowAlertModal] = useState(false);

  return (
    <div className='student-info'>
      <div className='student-info__card'>
        <div className='student-info__card__left'>
          <img
            className='student-info__card__left__image'
            src={studentInfo.photo ? studentInfo.photo : Images.profilePicture}
          />
        </div>
        <div className='student-info__card__right'>
          <DataDisplay
            dataHeader={t('inputs.studentInfoName')}
            data={`${studentInfo.firstName} ${studentInfo.lastName}`}
            headerBolded
            BottomSpacing={5}
            TopSpacing={10}
          />
          <div className='student-info__card__right__phone'>
            <DataDisplay
              dataHeader={t('inputs.studentInfoPhone')}
              headerBolded
              dataClass={
                studentInfo.phone ? 'student-phone' : 'student-phone__empty'
              }
              data={studentInfo.phone ? studentInfo.phone : t('unknown')}
              BottomSpacing={5}
              TopSpacing={10}
            />
          </div>
          {user.role === 'student' && (
            <>
              <Dropdown
                customclass='study-type-student-info-dropdown'
                fullWidth
                list={studies}
                handleSelect={item => {
                  if (item.id) {
                    dispatch(setSelectedStudy(item));
                  } else {
                    dispatch(setSelectedStudy(null));
                  }
                  resetSubjectDropdown();
                }}
                headerTitle={
                  selectedStudy
                    ? selectedStudy.name
                    : t('dropdowns.studentInfoStudyDropdown.defaultOption')
                }
                defaultHeaderOption={t(
                  'dropdowns.studentInfoStudyDropdown.defaultOption'
                )}
              />
              {selectedStudy && (
                <>
                  <DataDisplay
                    dataHeader={t('inputs.studentInfoStudy')}
                    headerBolded
                    data={selectedStudy.name}
                    BottomSpacing={5}
                    TopSpacing={10}
                  />
                  <DataDisplay
                    dataHeader={t('inputs.studentInfoYear')}
                    headerBolded
                    data={t('studyYear', { year: selectedStudy.year })}
                    BottomSpacing={5}
                    TopSpacing={10}
                  />
                  <DataDisplay
                    dataHeader={t('inputs.studentInfoStatus')}
                    headerBolded
                    data={t(`${selectedStudy.studyType}`)}
                    BottomSpacing={5}
                    TopSpacing={10}
                  />
                </>
              )}
            </>
          )}
          {selectedStudy && studentInfo && user.role !== 'student' && (
            <>
              <DataDisplay
                dataHeader={t('inputs.studentInfoStudy')}
                headerBolded
                data={
                  studentInfo.studies.find(
                    study => selectedStudy.id === study.id
                  ).name
                }
                BottomSpacing={5}
                TopSpacing={10}
              />
              <DataDisplay
                dataHeader={t('inputs.studentInfoYear')}
                headerBolded
                data={t('studyYear', {
                  year: studentInfo.studies.find(
                    study => selectedStudy.id === study.id
                  ).year,
                })}
                BottomSpacing={5}
                TopSpacing={10}
              />
              <DataDisplay
                dataHeader={t('inputs.studentInfoStatus')}
                headerBolded
                data={t(
                  `${
                    studentInfo.studies.find(
                      study => selectedStudy.id === study.id
                    ).studyType
                  }`
                )}
                BottomSpacing={5}
                TopSpacing={10}
              />
            </>
          )}
        </div>
      </div>
      <div className='student-info__footer'>
        <div className='student-info__footer__jmbag'>
          <img
            className='student-info__footer__jmbag__icon'
            src={Images.StudentIDIconBlack}
          />
          <p className='student-info__footer__jmbag__value'>
            {studentInfo.jmbag ? studentInfo.jmbag : ''}
          </p>
        </div>
        {studentInfo.oib && (
          <div className='student-info__footer__oib'>
            <img
              className='student-info__footer__oib__icon'
              src={Images.StudentIDIconBlack}
            />
            <p className='student-info__footer__oib__value'>
              {studentInfo.oib ? studentInfo.oib : ''}
            </p>
          </div>
        )}
        {user.role !== 'student' && user.role !== 'mentor' && (
          <img
            className='student-info__footer__mail'
            src={Images.mailIcon}
            onClick={() => {
              if (studentInfo.email === '' || !studentInfo.email) {
                setShowAlertModal(true);
              } else {
                props.setShowEmailModal(true);
              }
            }}
          />
        )}
        {showAlertModal && (
          <AlertModal
            showOnlyConfirmOption
            confirmOptions={() => setShowAlertModal(false)}
            alertInfotext={t('modal.alertModal.thisStudentCannotBeContacted')}
          />
        )}
      </div>
      {(user.role === 'admin' || user.role === 'professor') && (
        <div className='student-info__footer'>
          <DataDisplay
            dataHeader={t('studentHomepage.selectedSubject')}
            headerBolded
            removeTopSeparator
            fullWidth
            data={currentSubject && currentSubject.name}
          />
        </div>
      )}
    </div>
  );
};

StudentInfo.propTypes = {
  currentSubject: PropTypes.object,
  studentInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  setShowEmailModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  studies: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  selectedStudy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  resetSubjectDropdown: PropTypes.func,
};
