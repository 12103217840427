import { PURGE } from 'redux-persist/lib/constants';
import { SET_ERROR_COMMENTS } from '../types';

const initialState = {
  comments: [],
  error: null,
  status: null,
};

export const comments = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_COMMENTS: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
