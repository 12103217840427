import hr from 'date-fns/locale/hr';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStudy,
  executeRequestsWithLoading,
  updateStudy,
} from '../../../../store/actions';
import DataDisplay from '../../../SharedComponents/DataDisplay/DataDisplay';
import Modal from '../../../SharedComponents/Modal/Modal';
import './AddOrUpdateStudyModal.scss';

registerLocale('hr', hr);

export const AddOrUpdateStudyModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const addStudyError = useSelector(state => state.studies.error);
  const studies = useSelector(state => state.studies);
  const [loading, setLoading] = useState(false);
  const [study, setStudy] = useState({
    name: '',
    codes: null,
    active: false,
    department: '',
  });
  const [codesInputvalue, setCodesInputvalue] = useState(null);
  const handleSend = async () => {
    if (props.study) {
      await executeRequestsWithLoading(
        [
          dispatch(
            updateStudy(
              {
                ...study,
                codes:
                  study.codes && study.codes.length > 0
                    ? study.codes.join()
                    : null,
              },
              props.setLoading
            )
          ),
        ],
        setLoading,
        isMounted
      );
    } else {
      await executeRequestsWithLoading(
        [
          dispatch(
            createStudy(
              {
                ...study,
                codes:
                  study.codes && study.codes.length > 0
                    ? study.codes.join()
                    : null,
              },
              props.setLoading
            )
          ),
        ],
        setLoading,
        isMounted
      );
    }
  };

  useEffect(() => {
    if (studies.status === 201 || studies.status === 200) {
      props.setShowAddOrUpdateStudyModal(false);
    }
  }, [studies]);

  useEffect(() => {
    if (props.study) {
      setStudy({
        id: props.study.id,
        name: props.study.name,
        codes: props.study.codes,
        active: props.study.active,
        department: props.study.department,
      });
    }
    return () => {
      isMounted.current = false;
      if (props.study) {
        props.setStudy(null);
      }
      setStudy({
        name: '',
        codes: null,
        active: false,
      });

      dispatch({
        type: 'SET_ERROR_STUDIES',
        payload: {
          error: null,
          status: null,
        },
      });
    };
  }, []);
  return (
    <Modal
      closeModal={() => {
        props.setShowAddOrUpdateStudyModal(false);
      }}
    >
      <div className='modal-container-add-study-modal'>
        <div className='modal-container-add-study-modal__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-add-study-modal__header__header-text'>
            <div className='header-main-text'>
              {props.study
                ? t('modal.studyModal.mainHeader.editStudy')
                : t('modal.studyModal.mainHeader.addStudy')}
            </div>
            <div className='header-small-text'>
              {props.study
                ? t('modal.studyModal.descriptionHeader.editStudy')
                : t('modal.studyModal.descriptionHeader.addStudy')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              props.setShowAddOrUpdateStudyModal(false);
            }}
          ></div>
        </div>
        <div className='modal-container-add-study-modal__body'>
          {loading && <div className='loader-small' />}
          <form
            hidden={loading}
            onSubmit={e => {
              e.preventDefault();
              handleSend();
            }}
          >
            <DataDisplay
              dataHeader={t('inputs.studyName')}
              dataClass='study-name'
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    value={study.name}
                    onChange={e => {
                      setStudy({ ...study, name: e.target.value });
                    }}
                  />
                  {addStudyError && addStudyError['name'] && (
                    <div className='error'>
                      {addStudyError['name'].notEmpty}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.studyDepartmentName')}
              dataClass='department'
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    value={study.department}
                    onChange={e =>
                      setStudy({ ...study, department: e.target.value })
                    }
                  />
                  {addStudyError && addStudyError['department'] && (
                    <div className='error'>
                      {addStudyError['department'].notEmpty}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.studyCodes')}
              dataClass='study-code'
              dataHeaderSmalltext={t('inputs.studyCodesEnterInstructions')}
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    defaultValue={
                      study.codes && study.codes.length
                        ? codesInputvalue
                          ? `${study.codes.join()},${codesInputvalue},`
                          : `${study.codes.join()},`
                        : codesInputvalue
                          ? codesInputvalue
                          : ''
                    }
                    style={{
                      borderColor:
                        study &&
                        study.codes &&
                        study.codes.includes(codesInputvalue) &&
                        'var(--alert-text)',
                    }}
                    placeholder={t('inputs.studyCodesPlaceholder')}
                    type='text'
                    onKeyDown={e => {
                      e.preventDefault();
                      if (e.key === 'Backspace') {
                        if (codesInputvalue) {
                          setCodesInputvalue(null);
                        } else {
                          if (study.codes && study.codes.length) {
                            let newCodes = [...study.codes];
                            newCodes.pop();
                            setStudy({
                              ...study,
                              codes: newCodes,
                            });
                          }
                        }
                      } else if (e.key === 'Enter') {
                        if (codesInputvalue) {
                          if (
                            study.codes &&
                            !study.codes.includes(codesInputvalue)
                          ) {
                            setStudy({
                              ...study,
                              codes: study.codes
                                ? [...study.codes, codesInputvalue]
                                : [codesInputvalue],
                            });
                          } else {
                            setStudy({
                              ...study,
                              codes: [codesInputvalue],
                            });
                          }
                          setCodesInputvalue(null);
                        }
                      } else if (/^[0-9]$/i.test(e.key)) {
                        if (!codesInputvalue) {
                          setCodesInputvalue(e.key);
                        } else {
                          setCodesInputvalue(codesInputvalue + e.key);
                        }
                      } else {
                        return;
                      }
                    }}
                  />
                  {addStudyError && addStudyError['codes'] && (
                    <div className='error'>
                      {addStudyError['codes'].notEmpty}
                      {addStudyError['codes'].unique}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.studyActive')}
              dataClass='study-active'
              headerBolded
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    type='checkbox'
                    id='study-active'
                    name='study-active'
                    checked={study.active}
                    onChange={e =>
                      setStudy({
                        ...study,
                        active: e.target.checked,
                      })
                    }
                  />
                </>
              }
            />
            {addStudyError && addStudyError['active'] && (
              <div className='error'>{addStudyError['active'].notEmpty}</div>
            )}

            <div className='button-container'>
              <button
                className='modal-container-add-study-modal__send-button'
                type='submit'
              >
                {props.study ? t('buttons.update') : t('buttons.save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

AddOrUpdateStudyModal.propTypes = {
  setShowAddOrUpdateStudyModal: PropTypes.func.isRequired,
  setStudy: PropTypes.func,
  setLoading: PropTypes.func,
  study: PropTypes.object,
};
