import hr from 'date-fns/locale/hr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSubject,
  executeRequestsWithLoading,
  fetchStudiesProfessor,
  fetchSubjectDetails,
  getProfessorsForSubject,
  updateSubject,
} from '../../../store/actions';
import ProfessorsList from '../../Professor/ProfessorsListPage.jsx/ProfessorsList/ProfessorsList';
import { AddOrUpdateProfessorModal } from '../AddOrUpdateProfessorModal/AddOrUpdateProfessorModal';
import DataDisplay from '../DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../DatepickerCustomContainer/DatepickerCustomContainer';
import { Dropdown } from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import { PasswordDisplayModal } from '../PasswordDisplayModal/PasswordDisplayModal';
import './AddOrUpdateSubjectModal.scss';

registerLocale('hr', hr);

export const AddOrUpdateSubjectModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const studies = useSelector(state => state.studies);
  const addSubjectError = useSelector(state => state.subjects.error);
  const addSubjectStatus = useSelector(state => state.subjects.status);
  const subjectDetails = useSelector(state => state.subjects.subjectDetails);
  const createdProfessor = useSelector(
    state => state.createdAccount.createdAccount
  );
  const professors = useSelector(
    state => state.professors.professorsForSubjectList
  );
  const [loadingProfessors, setLoadingProfessors] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState({
    name: '',
    studyId: null,
    semester: 0,
    subjectNumber: 0,
    expectedContent: '',
    expectedOutcomes: '',
    active: false,
    code: 0,
  });
  const [selectedTab, setSelectedTab] = useState('write');
  const [showAddOrUpdateProfessorModal, setShowAddOrUpdateProfessorModal] =
    useState(false);
  const [displayCreatedProfessorModal, setDisplayCreatedProfessorModal] =
    useState(false);
  const [dateRangeForInternship, setDateRangeForInternship] = useState([
    props.subject
      ? moment(
        new Date(
          `${moment().get('year')}-${props.subject.acceptRequestsFrom}`
        )
      ).toDate()
      : null,
    props.subject
      ? moment(
        new Date(`${moment().get('year')}-${props.subject.acceptRequestsTo}`)
      ).toDate()
      : null,
  ]);
  const [startDateForInternship, endDateForInternship] = dateRangeForInternship;
  const [studyDropdownTitle, setStudyDropdownTitle] = useState(
    t('dropdowns.studiesDropdown.defaultOption')
  );

  const handleSend = () => {
    if (props.subject) {
      dispatch(
        updateSubject(
          subject,
          startDateForInternship
            ? moment(startDateForInternship).startOf('day').format('MM-DD')
            : null,
          endDateForInternship
            ? moment(endDateForInternship).endOf('day').format('MM-DD')
            : null
        )
      );
    } else {
      dispatch(
        createSubject(
          subject,
          startDateForInternship
            ? moment(startDateForInternship).startOf('day').format('MM-DD')
            : null,
          endDateForInternship
            ? moment(endDateForInternship).endOf('day').format('MM-DD')
            : null
        )
      );
    }
  };

  useEffect(() => {
    if (addSubjectStatus === 201 || addSubjectStatus === 200) {
      props.setShowAddOrUpdateSubjectModal(false);
      props.setShowSubjectDetailsModal(false);
      setShowAddOrUpdateProfessorModal(false);
    }
  }, [addSubjectStatus]);

  useEffect(() => {
    if (createdProfessor) {
      setDisplayCreatedProfessorModal(true);
    }
  }, [createdProfessor]);

  useEffect(() => {
    if (subjectDetails) {
      setSubject({ ...subjectDetails, studyId: subjectDetails.study.id });
      setStudyDropdownTitle(subjectDetails.study.name);
    }
  }, [subjectDetails]);

  const getProfessorsForSelectedSubject = useCallback(async () => {
    if (props.subject) {
      await executeRequestsWithLoading(
        [dispatch(getProfessorsForSubject(props.subject.id))],
        setLoadingProfessors,
        mountedRef
      );
    }
  }, [props.subject && props.subject.id]);

  const fetchDetailsForSubject = useCallback(async () => {
    await executeRequestsWithLoading(
      [dispatch(fetchSubjectDetails(props.subject.id))],
      setLoading,
      mountedRef
    );
  }, [props.subject && props.subject.id]);

  useEffect(() => {
    if (props.subject) {
      getProfessorsForSelectedSubject();
      fetchDetailsForSubject();
    }
    return () => {
      mountedRef.current = false;
      if (props.subject) {
        props.setSubject(null);
      }
      setLoadingProfessors(true);
      setSubject({
        name: '',
        studyId: null,
        semester: 0,
        subjectNumber: 0,
        code: 0,
      });
      dispatch({
        type: 'FETCHED_SUBJECT_DETAILS',
        payload: null,
      });
      dispatch({
        type: 'SET_ERROR_SUBJECTS',
        payload: {
          error: null,
          status: null,
        },
      });
    };
  }, []);

  return (
    <Modal
      closeModal={() => {
        props.setShowAddOrUpdateSubjectModal(false);
        props.setShowSubjectDetailsModal(false);
        setShowAddOrUpdateProfessorModal(false);
      }}
    >
      <div className='modal-container-add-subject-modal'>
        <div className='modal-container-add-subject-modal__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-add-subject-modal__header__header-text'>
            <div className='header-main-text'>
              {props.subject
                ? t('modal.subjectModal.mainHeader.editSubject')
                : t('modal.subjectModal.mainHeader.addSubject')}
            </div>
            <div className='header-small-text'>
              {props.subject
                ? t('modal.subjectModal.descriptionHeader.editSubject')
                : t('modal.subjectModal.descriptionHeader.addSubject')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              props.setShowAddOrUpdateSubjectModal(false);
              props.setShowSubjectDetailsModal(false);
              setShowAddOrUpdateProfessorModal(false);
            }}
          ></div>
        </div>
        <div className='modal-container-add-subject-modal__body'>
          <div className='modal-container-add-subject-modal__body__form'>
            {loading && <div className='loader-small' />}
            <form
              hidden={loading}
              onSubmit={e => {
                e.preventDefault();
                handleSend();
              }}
            >
              <DataDisplay
                dataHeader={t('inputs.subjectName')}
                dataClass='subject-name'
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <input
                      value={subject.name}
                      onChange={e =>
                        setSubject({ ...subject, name: e.target.value })
                      }
                    />
                    {addSubjectError && addSubjectError['name'] && (
                      <div className='error'>
                        {addSubjectError['name'].notEmpty}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('dropdowns.studiesDropdown.title')}
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <Dropdown
                      fullWidth
                      disabledInputSuggestion={!!props.subject}
                      customclass='study-dropdown'
                      fetchSuggestions={() => dispatch(fetchStudiesProfessor())}
                      handleSelect={item => {
                        if (
                          item !== t('dropdowns.studiesDropdown.defaultOption')
                        ) {
                          setStudyDropdownTitle(item.name);
                          setSubject({ ...subject, studyId: item.id });
                        } else {
                          setStudyDropdownTitle(item);
                          setSubject({ ...subject, studyId: null });
                        }
                      }}
                      list={
                        studies &&
                        studies.studies &&
                        studies.studies.length &&
                        studies.studies.filter(study => study.active)
                      }
                      headerTitle={studyDropdownTitle}
                      defaultHeaderOption={t(
                        'dropdowns.studiesDropdown.defaultOption'
                      )}
                    />
                    {addSubjectError && addSubjectError['study'] && (
                      <div>
                        <div className='error'>
                          {addSubjectError['study'].enum}
                        </div>
                        <div className='error'>
                          {addSubjectError['study'].notEmpty}
                        </div>
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.semester')}
                dataClass='subject-semester'
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <input
                      type='number'
                      value={subject.semester}
                      onChange={e =>
                        setSubject({ ...subject, semester: e.target.value })
                      }
                    />
                    {addSubjectError && addSubjectError['semester'] && (
                      <div className='error'>
                        {addSubjectError['semester'].min}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.subjectNumber')}
                dataClass='subject-subject-number'
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <input
                      value={subject.subjectNumber}
                      type='number'
                      onChange={e =>
                        setSubject({
                          ...subject,
                          subjectNumber: e.target.value,
                        })
                      }
                    />
                    {addSubjectError && addSubjectError['subjectNumber'] && (
                      <div className='error'>
                        {addSubjectError['subjectNumber'].min}
                        {addSubjectError['subjectNumber'].unique}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.subjectCode')}
                dataClass='subject-subject-code'
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <input
                      value={subject.code}
                      type='number'
                      onChange={e =>
                        setSubject({ ...subject, code: e.target.value })
                      }
                    />
                    {addSubjectError && addSubjectError['code'] && (
                      <div className='error'>
                        {addSubjectError['code'].min}
                        {addSubjectError['code'].unique}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.subjectExpectedContent')}
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                dataClass='subject-subject-content'
                data={
                  <>
                    <ReactMde
                      minEditorHeight={82}
                      minPreviewHeight={82}
                      value={subject.expectedContent}
                      onChange={text =>
                        setSubject({ ...subject, expectedContent: text })
                      }
                      selectedTab={selectedTab}
                      onTabChange={setSelectedTab}
                      generateMarkdownPreview={markdown => {
                        return Promise.resolve(
                          <ReactMarkdown source={markdown} />
                        );
                      }}
                      commands={{
                        'header-1': {
                          name: 'header-1',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H1</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `# ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-2': {
                          name: 'header-2',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H2</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `## ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-3': {
                          name: 'header-3',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H3</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-4': {
                          name: 'header-4',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H4</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `#### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-5': {
                          name: 'header-5',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H5</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `##### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'custom-code-block': {
                          name: 'custom-code-block',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>
                                {'<'}/{'>'}
                              </span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              '```\n' +
                                opts.textApi.getState().selectedText +
                                '\n```'
                            );
                          },
                        },
                      }}
                      toolbarCommands={[
                        [
                          'header-1',
                          'header-2',
                          'header-3',
                          'header-4',
                          'header-5',
                        ],
                        ['bold'],
                        /* ['image'], */
                        ['unordered-list', 'ordered-list'],
                      ]}
                      childProps={{
                        writeButton: {
                          tabIndex: -1,
                        },
                      }}
                      l18n={{
                        write: t('inputs.internshipLogWriteOption'),
                        preview: t('inputs.internshipLogViewOption'),
                      }}
                    />
                    {addSubjectError && addSubjectError['expectedContent'] && (
                      <div className='error'>
                        {addSubjectError['expectedContent'].notEmpty}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.subjectExpectedOutcomes')}
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                dataClass='subject-subject-outcomes'
                data={
                  <>
                    <ReactMde
                      minEditorHeight={82}
                      minPreviewHeight={82}
                      value={subject.expectedOutcomes}
                      onChange={text =>
                        setSubject({ ...subject, expectedOutcomes: text })
                      }
                      selectedTab={selectedTab}
                      onTabChange={setSelectedTab}
                      generateMarkdownPreview={markdown => {
                        return Promise.resolve(
                          <ReactMarkdown source={markdown} />
                        );
                      }}
                      commands={{
                        'header-1': {
                          name: 'header-1',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H1</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `# ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-2': {
                          name: 'header-2',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H2</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `## ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-3': {
                          name: 'header-3',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H3</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-4': {
                          name: 'header-4',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H4</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `#### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'header-5': {
                          name: 'header-5',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>H5</span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              `##### ${opts.textApi.getState().selectedText}`
                            );
                          },
                        },
                        'custom-code-block': {
                          name: 'custom-code-block',
                          icon: function icon() {
                            return (
                              <span style={{ fontWeight: 'bold' }}>
                                {'<'}/{'>'}
                              </span>
                            );
                          },
                          execute: opts => {
                            opts.textApi.replaceSelection(
                              '```\n' +
                                opts.textApi.getState().selectedText +
                                '\n```'
                            );
                          },
                        },
                      }}
                      toolbarCommands={[
                        [
                          'header-1',
                          'header-2',
                          'header-3',
                          'header-4',
                          'header-5',
                        ],
                        ['bold'],
                        /* ['image'], */
                        ['unordered-list', 'ordered-list'],
                      ]}
                      childProps={{
                        writeButton: {
                          tabIndex: -1,
                        },
                      }}
                      l18n={{
                        write: t('inputs.internshipLogWriteOption'),
                        preview: t('inputs.internshipLogViewOption'),
                      }}
                    />
                    {addSubjectError && addSubjectError['expectedContent'] && (
                      <div className='error'>
                        {addSubjectError['expectedContent'].notEmpty}
                      </div>
                    )}
                  </>
                }
              />
              <DataDisplay
                dataHeader={t('inputs.subjectActive')}
                dataClass='subject-active'
                headerBolded
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    <input
                      type='checkbox'
                      id='subject-active'
                      name='subject-active'
                      checked={subject.active}
                      onChange={e =>
                        setSubject({
                          ...subject,
                          active: e.target.checked,
                        })
                      }
                    />
                  </>
                }
              />
              {addSubjectError && addSubjectError['active'] && (
                <div className='error'>
                  {addSubjectError['active'].notEmpty}
                </div>
              )}
              <DataDisplay
                dataHeader={t('dropdowns.subjectDateRange.title')}
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  <>
                    {loading ? (
                      <div className='loader-small' />
                    ) : (
                      <div className='datepicker-wrapper'>
                        <DatePicker
                          disabledKeyboardNavigation
                          locale='hr'
                          className='datepicker-range'
                          calendarContainer={({
                            className,
                            children,
                            showPopperArrow,
                          }) =>
                            DatepickerCustomContainer({
                              className,
                              children,
                              mainHeaderText: t(
                                'dropdowns.subjectDateRange.mainHeaderText'
                              ),
                              smallHeaderText: t(
                                'dropdowns.subjectDateRange.descriptionHeaderText'
                              ),
                              showPopperArrow,
                            })
                          }
                          selectsRange
                          startDate={startDateForInternship}
                          endDate={endDateForInternship}
                          enableTabLoop
                          dateFormatCalendar='MMMM'
                          onChange={update => {
                            setDateRangeForInternship(update);
                          }}
                          shouldCloseOnSelect
                          withPortal
                          fixedHeight
                          monthsShown={2}
                          showPopperArrow={false}
                          selected={startDateForInternship}
                          value={
                            startDateForInternship || endDateForInternship
                              ? `${
                                startDateForInternship
                                  ? moment(startDateForInternship).format(
                                    'DD.MM.'
                                  )
                                  : ''
                              } - ${
                                endDateForInternship
                                  ? moment(endDateForInternship).format(
                                    'DD.MM.'
                                  )
                                  : ''
                              }`
                              : props.subject &&
                                `${moment(
                                  props.subject.acceptRequestsFrom
                                ).format('DD.MM.')} - ${moment(
                                  props.subject.acceptRequestsTo
                                ).format('DD.MM.')}`
                          }
                        />
                        <div className='non-clickable-area' />
                      </div>
                    )}
                  </>
                }
              />
              {addSubjectError &&
                (addSubjectError['acceptRequestsFrom'] ||
                  addSubjectError['acceptRequestsTo']) && (
                <div>
                  <div className='error'>
                    {addSubjectError['acceptRequestsFrom']?.notEmpty ||
                        addSubjectError['acceptRequestsTo']?.notEmpty}
                    {addSubjectError['acceptRequestsFrom']?.dateAfter ||
                        addSubjectError['acceptRequestsTo']?.dateAfter}
                  </div>
                </div>
              )}
              <div className='button-container'>
                <button
                  className='modal-container-add-subject-modal__send-button'
                  id='send-email-btn'
                  type='submit'
                >
                  {props.subject ? t('buttons.update') : t('buttons.save')}
                </button>
              </div>
            </form>
          </div>
          {props.showSubjectDetailsModal && (
            <div>
              <DataDisplay
                dataHeader={t('inputs.subjectProfessorsList')}
                headerBolded
                headerFontSize={16}
                displayInColumn
                BottomSpacing={15}
              />
              <button
                disabled={!props.subject?.active}
                className='add-professor-list-for-subject-button'
                type='button'
                onClick={() => {
                  setShowAddOrUpdateProfessorModal(true);
                }}
              >
                {t('buttons.addNewProfessor')}
              </button>
              {loadingProfessors && <div className='loader-small' />}
              <div hidden={loadingProfessors || !props.subject?.active}>
                {professors && professors.length > 0 ? (
                  <>
                    <div className='professors-for-subject-list__list__display-list'>
                      <ProfessorsList
                        professors={professors}
                        addProfesorToSubject
                      />
                    </div>
                  </>
                ) : (
                  t('notFound.professorsList.noListFetched')
                )}
              </div>
              {showAddOrUpdateProfessorModal && (
                <AddOrUpdateProfessorModal
                  selectedSubject={subject}
                  addProfesorToSubject
                  setShowAddOrUpdateProfessorModal={
                    setShowAddOrUpdateProfessorModal
                  }
                  professorsForSubject={professors}
                />
              )}
              {displayCreatedProfessorModal && (
                <PasswordDisplayModal
                  setShowPasswordDisplayModal={setDisplayCreatedProfessorModal}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

AddOrUpdateSubjectModal.propTypes = {
  setShowAddOrUpdateSubjectModal: PropTypes.func.isRequired,
  setShowSubjectDetailsModal: PropTypes.func,
  setSubject: PropTypes.func,
  subject: PropTypes.object,
  showSubjectDetailsModal: PropTypes.bool,
  professors: PropTypes.array,
};
