import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Modal from '../Modal/Modal';
import './ConclusionModal.scss';

const ConclusionModal = props => {
  const { t } = useTranslation();
  return (
    <Modal closeModal={() => props.setShowConclusionModal(false)}>
      <div className='conclusion-modal'>
        <div className='conclusion-modal__header'>
          <div className='header-info-image'></div>
          <div className='conclusion-modal__header__header-text'>
            <div className='header-main-text'>
              {t('modal.conclusionModal.mainHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowConclusionModal(false)}
          ></div>
        </div>
        <div className='conclusion-modal__body'>
          <ReactMarkdown
            className='conclusion-text'
            source={props.conclusion}
          />
        </div>
      </div>
    </Modal>
  );
};

ConclusionModal.propTypes = {
  setShowConclusionModal: PropTypes.func,
  conclusion: PropTypes.string,
};

export default ConclusionModal;
