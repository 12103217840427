import i18n from '../../../i18n';
import approveAndRejectInternship from './MentorGifs/ApproveAndRejectInternship.gif';

const defaultNameSpace = 'tutorials.mentorTutorials.';

export const tutorialsForMentor = [
  {
    name: i18n.t(defaultNameSpace + 'approveAndRejectInternship.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'approveAndRejectInternship.steps'),
      source: approveAndRejectInternship,
    },
  },
];
