/* istanbul ignore file */
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import { reducers } from './reducers';
import authMiddleware from './middlewares/AuthMiddleware';
import { KEY_PREFIX, REHYDRATE } from 'redux-persist/lib/constants';
import { userLoggedOut } from './actions';

const middleware = [];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['internship'],
  timeout: null,
};

const crosstabSync = function (store, persistConfig, crosstabConfig) {
  const blacklist = crosstabConfig.blacklist ? crosstabConfig.blacklist : null;
  const whitelist = crosstabConfig.whitelist ? crosstabConfig.whitelist : null;
  const keyPrefix = crosstabConfig.keyPrefix
    ? crosstabConfig.keyPrefix
    : KEY_PREFIX;

  const { key } = persistConfig;

  window.addEventListener('storage', handleStorageEvent, false);

  function handleStorageEvent(e) {
    if (e.key && e.key.indexOf(keyPrefix) === 0) {
      if (e.oldValue === e.newValue) {
        return;
      }
      const oldStatePartial = JSON.parse(e.oldValue);
      const statePartial = JSON.parse(e.newValue);
      if (!oldStatePartial || !statePartial) {
        store.dispatch(userLoggedOut());
      }
      let state, oldValue;
      if (statePartial) {
        state = Object.keys(statePartial).reduce((state, reducerKey) => {
          if (whitelist && whitelist.indexOf(reducerKey) === -1) {
            return state;
          }
          if (blacklist && blacklist.indexOf(reducerKey) !== -1) {
            return state;
          }

          state[reducerKey] = JSON.parse(statePartial[reducerKey]);

          return state;
        }, {});
      }
      if (oldStatePartial) {
        oldValue = Object.keys(oldStatePartial).reduce((state, reducerKey) => {
          if (whitelist && whitelist.indexOf(reducerKey) === -1) {
            return state;
          }
          if (blacklist && blacklist.indexOf(reducerKey) !== -1) {
            return state;
          }

          state[reducerKey] = JSON.parse(oldStatePartial[reducerKey]);

          return state;
        }, {});
      }

      if (!oldStatePartial || !statePartial) {
        store.dispatch({
          key,
          payload: state,
          type: REHYDRATE,
        });
      }

      if (JSON.stringify(state) === JSON.stringify(oldValue)) {
        return;
      } else {
        store.dispatch({
          key,
          payload: state,
          type: REHYDRATE,
        });
      }
    }
  }
};

/* const rootReducer = (state, action) => {
  if (
    action.type === 'USER_LOGGED_OUT' ||
    (window.location && window.location.pathname === 'login')
  ) {
    state = undefined;
  }
  return reducers(state, action);
}; */

const persistedReducer = persistReducer(persistConfig, reducers);

middleware.push(thunk);
if (process.env.NODE_ENV === 'development') middleware.push(logger);

const store = createStore(
  persistedReducer,
  applyMiddleware(authMiddleware, ...middleware)
);

const persistor = persistStore(store);
crosstabSync(store, persistConfig, {
  blacklist: [
    'subjects',
    'loader',
    'internship',
    'student',
    'createdAccount',
    'studentList',
    'companies',
    'professors',
    'mentors'
  ],
});

export { persistor, store };
