import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import isMobileView from '../checkForMobileView';
import { sendPushNotificationToken } from '../store/actions';
import { getToken, onMessageListener } from '../store/firebase';
import AuditListPage from './Admin/AuditLogsPage/AuditListPage';
import StudiesListPage from './Admin/StudiesListPage/StudiesListPage';
import SynchronizationPage from './Admin/SynchronizationPage/SynchronizationPage';
import { Companies } from './Companies/Companies';
import { Login } from './Login/Login';
import InternshipsListPage from './Mentor/InternshipsListPage/InternshipsListPage';
import MentorHomepage from './Mentor/MentorHomepage/MentorHomepage';
import InternshipRequestsListPage from './Professor/InternshipRequestsListPage/InternshipRequestsListPage';
import MentorsPage from './Professor/MentorListPage/MentorsPage';
import ProfessorHomepage from './Professor/ProfessorHomepage/ProfessorHomepage';
import ProfessorsListPage from './Professor/ProfessorsListPage.jsx/ProfessorsListPage';
import StudentListPage from './Professor/StudentListPage/StudentListPage';
import SubjectListPage from './Professor/SubjectListPage/SubjectListPage';
import { sharedTutorials } from './SharedComponents/Tutorials/SharedTutorials';
import Tutorials from './SharedComponents/Tutorials/Tutorials';
import { tutorialsForAdmin } from './SharedComponents/Tutorials/TutorialsForAdmin';
import { tutorialsForMentor } from './SharedComponents/Tutorials/TutorialsForMentor';
import { tutorialsForProfessor } from './SharedComponents/Tutorials/TutorialsForProfessor';
import { tutorialsForStudent } from './SharedComponents/Tutorials/TutorialsForStudent';
import { StudentHomepage } from './Student/StudentHomepage/StudentHomepage';

export const Routes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (isMobileView()) {
      locationCb();
    }
  }, []);

  useEffect(() => {
    if (history && isMobileView()) {
      locationCb();
    }
  }, [history]);

  const locationCb = () => {
    return history.listen(location => {
      var element = document.getElementsByTagName('body');
      if (element && element[0]) {
        if (
          location.pathname === '/login' ||
          location.pathname === '/professor-homepage' ||
          location.pathname === '/synchronization'
        ) {
          element[0].style.minWidth = null;
        } else {
          element[0].style.minWidth = '1200px';
        }
      }
    });
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (
      firebase.messaging.isSupported() &&
      user.isAuthenticated &&
      (user.role === 'student' || user.role === 'professor') &&
      !token
    ) {
      getToken(setToken);
    }
  }, [user]);

  /* istanbul ignore next */
  useEffect(() => {
    if (firebase.messaging.isSupported() && token && user.isAuthenticated) {
      dispatch(sendPushNotificationToken(token));
      onMessageListener();
    }
  }, [token]);

  return (
    <Switch>
      <Route exact path='/' render={() => <Redirect to='/login' />} />
      {!user.isAuthenticated && <Route path='/login' component={Login} />}
      <Route path='/student-homepage' component={StudentHomepage} />
      <Switch>
        {user.isAuthenticated ? (
          <>
            <Route
              path='/login'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <Redirect to='/student-homepage' />;
                  case 'admin':
                    return <Redirect to='/professor-homepage' />;
                  case 'professor':
                    return <Redirect to='/professor-homepage' />;
                  case 'mentor':
                    return <Redirect to='/mentor-homepage' />;
                }
              }}
            />
            <Route
              path='/tutorials'
              render={() => (
                <Tutorials
                  tutorialsList={[...sharedTutorials].concat(
                    user.role === 'student'
                      ? tutorialsForStudent
                      : user.role === 'professor'
                        ? [
                          ...tutorialsForProfessor,
                          ...tutorialsForStudent,
                          ...tutorialsForMentor,
                        ]
                        : user.role === 'admin'
                          ? [
                            ...tutorialsForAdmin,
                            ...tutorialsForStudent,
                            ...tutorialsForProfessor,
                            ...tutorialsForMentor,
                          ]
                          : tutorialsForMentor
                  )}
                />
              )}
            />
            <Route
              path='/audit'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                    return <AuditListPage />;
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/summary-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                    return <StudentListPage summaryList />;
                  case 'professor':
                    return <StudentListPage summaryList />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/studies-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                    return <StudiesListPage />;
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/subjects'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                    return <SubjectListPage />;
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/professors-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <ProfessorsListPage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/professor-homepage'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/mentor-homepage'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/internships-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <InternshipsListPage />;
                }
              }}
            />
            <Route
              path='/internship-requests'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <InternshipRequestsListPage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/students-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <StudentListPage internshipsDisplay />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/mentors-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <MentorsPage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/companies'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <Companies />;
                  case 'admin':
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/companies-list'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'admin':
                  case 'professor':
                    return <Companies professorView />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
            <Route
              path='/synchronization'
              render={() => {
                switch (user.role) {
                  case 'student':
                    return <StudentHomepage />;
                  case 'professor':
                    return <ProfessorHomepage />;
                  case 'admin':
                    return <SynchronizationPage />;
                  case 'mentor':
                    return <MentorHomepage />;
                }
              }}
            />
          </>
        ) : (
          <Redirect to='/login' />
        )}
      </Switch>
    </Switch>
  );
};
