export const hr = {
  translation: {
    hello: 'Pozdrav',
    or: 'ILI',
    resolved: 'Riješeno',
    unknown: 'Nepoznato',
    studyYear: '{{year}}. godina',
    internshipRequestSent: 'Zahtjev poslan',
    IZVANREDNI: 'IZVANREDNI',
    REDOVNI: 'REDOVNI',
    subjectPassed: 'POLOŽEN',
    notActive: 'NEAKTIVAN',
    internshipLogImageAttachmentsSuccessfullyUploaded:
      'Prilozi uspješno preneseni',
    companiesListSuccessfullyDownloaded: 'Popis poduzeća uspješno preuzet',
    passedStudentsListSuccessfullyDownloaded:
      'Popis položenih studenata uspješno preuzet',
    studentHomepage: {
      selectedSubject: 'Odabrani predmet:',
      internshipCannotBeCreatedBecauseOfCertificate:
        'Ne može se kreirati praksa za ovaj predmet jer postoji potvrda o zaposlenju u obradi',
      internshipRequestCannotBeSentAtThisTime:
        'U ovom trenutku se ne može poslati zahtjev za praksu za ovaj predmet',
      certificateCannotBeUploadedAtThisTime:
        'Nije moguće dodati potvrdu o zaposlenju (Semestar nije započeo)',
      requestsList: 'Lista zahtjeva za praksu',
      internshipIsFinished: 'Praksa je položena',
      internshipInfoButton: 'Informacije o praksi',
      internshipLogsButton: 'Dnevnik prakse',
      youCurrentlyDoNotHaveInternshipRequest:
        'Trenutno nemate rezerviranu praksu',
      thisStudentDoesNotHaveInternshipRequest:
        'Ovaj korisnik nema zahtjeva za stručnu praksu',
      internshipInfo: {
        companyName: 'Naziv poduzeća',
        location: 'Lokacija',
        mentorName: 'Mentor',
        mentorContact: {
          mainHeader: 'Kontakt mentora',
          email: 'Email:',
          phone: 'Mob.:',
        },
        description: 'Opis',
        shortDescription: 'Kratak opis',
        status: 'Status prakse',
        startDate: 'Datum početka prakse',
        organizationalUnit: 'Organizacijska jedinica',
        certificate: {
          status: 'Status',
          rejectee: 'Odbio',
          date: 'Datum',
          reason: 'Razlog',
          document: 'Dokument',
        },
      },
      internshipLogs: {
        internshipLogHasUnresolvedComment:
          'Dnevnik prakse ima jedan ili više nerazriješen komentar.',
        confirmationDocumentGenerationText:
          'Potvrda o završetku prakse će se generirati kada mentor potvrdi praksu',
        logDay: '{{day}}. dan',
        dayDropdown: {
          defaultOption: 'Odaberi dan',
        },
        statuses: {
          IN_REVIEW_MENTOR: 'Predano mentoru na pregled',
          IN_REVIEW_PROFESSOR: 'Predano voditelju prakse na pregled',
        },
        hours: 'Sati',
        minutes: 'Minute',
        comments: {
          title: 'Komentari ({{numberOfComments}})',
          noComments: 'Trenutno nema komentara',
        },
      },
    },
    notFound: {
      studentList: {
        noSearchResult: 'Nije pronađen student za odabrani predmet',
        noListFetched: 'Trenutno nema studenata za odabrani predmet',
      },
      companiesList: {
        noSearchResult: 'Nema pronađenih poduzeća',
        noListFetched: 'Trenutno nema unesenih poduzeća',
      },
      mentorsList: {
        noSearchResult: 'Nema pronađenih mentora',
        noListFetched: 'Trenutno nema unesenih mentora',
      },
      professorsList: {
        noSearchResult: 'Nema pronađenih voditelja prakse',
        noListFetched: 'Trenutno nema unesenih voditelja prakse',
      },
      subjectsList: {
        noListFetched: 'Trenutno nema unesenih predmeta',
      },
      studiesList: {
        noListFetched: 'Trenutno nema unesenih studija',
      },
      auditList: {
        noSearchResult: 'Nema pronađenih događaja',
        noListFetched: 'Trenutno nema događaja',
      },
      internshipRequestsList: {
        noSearchResult: 'Nema pronađenih zahtjeva',
        noListFetched: 'Trenutno nema zahtjeva',
      },
      mentorInternshipsListPage: {
        noSearchResult: 'Nema pronađenih praksi',
        noListFetched: 'Trenutno nema unesenih praksi',
      },
    },
    userRole: {
      student: 'Student',
      professor: 'Voditelj Prakse',
      admin: 'Admin',
      mentor: 'Mentor',
    },
    userInfoCard: {
      firstNameAndLastName: 'Ime i prezime',
      phone: 'Telefon',
      mobilePhone: 'Mobitel',
      companyName: 'Naziv poduzeća',
      companyID: 'OIB poduzeća',
      email: 'E-pošta',
      changePassword: 'Promijeni lozinku',
    },
    login: {
      siteTitle: 'VEVU Praksa',
      siteSubtitle: 'Odaberite jednu od opcija za nastavak.',
      studentLogin: 'Student Prijava',
      regularLogin: 'Prijava',
      regularLoginSubTitle: 'Unesite svoje podatke kako biste se prijavili',
      forMoreInformationPleaseVisit: 'Za više informacija posjetite:',
      financedBy: 'Izradu web aplikacije sufinancirala je Europska unija iz Europskog socijalnog fonda',
    },
    inputs: {
      year: 'Godina',
      yearPlaceholder: 'Npr. 2021',
      internshipLogWriteOption: 'Pisanje',
      internshipLogViewOption: 'Pregled',
      date: 'Datum',
      timeWorked: 'Broj sati',
      datepickerInternshipStartDate: 'Početak prakse',
      passwordForCreatedUser: 'Lozinka za kreiranog korisnika:',
      search: 'Pretraživanje',
      searchByInfo: {
        studentList: '*Pretraživanje po Imenu, Emailu, JMBAG i Telefonu',
        companiesList:
          '*Pretraživanje po nazivu, kontakt email-u, mjestu i adresi',
        mentorsList:
          '*Pretraživanje po Imenu, Prezimenu, Nazivu poduzeća i email-u kontakta',
        professorsList: '*Pretraživanje po Imenu, Prezimenu i Email-u',
        auditList: '*Pretraživanje po Imenu, Prezimenu i Opisu',
        internshipRequestsList: '*Pretraživanje po Imenu i Emailu studenta',
      },
      datepickerPlannedInternshipStartDate: 'Planirani datum početka prakse',
      firstName: 'Ime',
      lastName: 'Prezime',
      email: 'Email Adresa',
      password: 'Lozinka',
      address: 'Adresa',
      mobilePhone: 'Mobitel',
      company: 'Poduzeće',
      subjects: 'Predmeti',
      addressPlaceholder: 'Adresa',
      companyAddress: 'Adresa poduzeća',
      companyAddressPlaceholder: 'Adresa',
      internshipDescription: 'Opis',
      internshipDescriptionPlaceholder: 'Opis',
      internshipShortDescription: 'Kratak opis',
      internshipShortDescriptionPlaceholder: 'Kratak opis',
      internshipOrganizationalUnit: 'Organizacijska jedinica',
      internshipOrganizationalUnitPlaceholder: 'Organizacijska jedinica',
      description: 'Opis',
      descriptionPlaceholder: 'Sadržaj',
      shortDescription: 'Kratak opis',
      shortDescriptionPlaceholder: 'Sadržaj',
      city: 'Grad',
      country: 'Država',
      VEVUCode: 'VEVU kod',
      studentPIN: 'Unesite JMBAG studenta',
      studentPINplaceholder: 'JMBAG',
      attachments: 'Prilozi',
      internshipLogAttachmentsDescription:
        'Samo slike formata .png, .jpg, .jpeg. Polje nije obavezno.',
      companyName: 'Naziv poduzeća',
      companyNamePlaceholder: 'Naziv poduzeća',
      companyPIN: 'OIB',
      companyPINplaceholder: 'OIB',
      companyHasInternshipRequestsEnabled: 'Moguće slanje zahtjeva za praksu',
      companyNumberOfFreeSlots: 'Broj mjesta za prijavu',
      companyAcademicYearPlaceholder: 'Akademska godina npr. `2020/2021`',
      companyInternshipSlots: {
        setCompanyIntenshipsLimit: 'Postavi ograničenje',
        companyInternshipLimitSet:
          'Postavljena ograničenja mjesta za predmete:',
        subjectName: 'Naziv predmeta',
        numberOfSlots: 'Mjesta',
        year: 'Godina',
        actions: {
          title: 'Akcije',
          edit: 'Uredi',
          delete: 'Obriši',
        },
      },
      postalCode: 'Poštanski broj',
      economicBranch: 'Odjel',
      website: 'Web stranica',
      fullNameOfContactPerson: 'Ime i prezime kontakt osobe',
      emailOfContactPerson: 'E-mail kontakt osobe',
      phoneNumberOfContactPerson: 'Broj telefona kontakt osobe',
      mentorFirstName: 'Ime mentora',
      mentorLastName: 'Prezime mentora',
      mentorEmail: 'E-mail mentora',
      mentorPhoneNumber: 'Broj telefona mentora',
      companyActive: 'Aktivno',
      companyPublic: 'Javno',
      professorActive: 'Aktivan',
      mentorActive: 'Aktivan',
      subjectActive: 'Aktivan',
      recepient: 'Prima',
      subjectName: 'Naziv',
      studyName: 'Naziv',
      studyDepartmentName: 'Odjel',
      studyCodes: 'Šifre studija',
      studyCodesPlaceholder: 'Upišite šifre studija',
      studyCodesEnterInstructions: '*Pritiskom na Enter dodaje se nova stavka',
      studyActive: 'Aktivan',
      semester: 'Semestar',
      subjectNumber: 'Broj predmeta',
      subjectCode: 'Šifra predmeta',
      subjectExpectedContent: 'Savladavanje sadržaja',
      subjectExpectedOutcomes: 'Ishodi kolegija',
      subjectProfessorsList: 'Popis profesora za predmet',
      emailSubjectTitle: 'Naslov',
      emailDescription: 'Sadržaj',
      currentPassword: 'Trenutna lozinka',
      currentPasswordPlaceholder: 'Unesite trenutnu lozinku',
      newPassword: 'Nova lozinka',
      newPasswordPlaceholder: 'Nova lozinka',
      newPasswordConfirmation: 'Potvrda nove lozinke',
      newPasswordConfirmationPlaceholder: 'Ponovite novu lozinku',
      studentInfoName: 'Ime i prezime:',
      studentInfoPhone: 'Telefon:',
      studentInfoStudy: 'Studij:',
      studentInfoYear: 'Godina:',
      studentInfoStatus: 'Status:',
    },
    dropdowns: {
      removeSelection: 'Obriši odabir',
      listIsEmpty: 'Lista je prazna',
      studentInfoStudyDropdown: {
        defaultOption: 'Odaberi studij',
      },
      studentSubjectDropdown: {
        title: 'Popis predmeta',
        defaultOption: 'Odaberi predmet',
      },
      subjectsDropdown: {
        title: 'Popis predmeta',
        defaultOption: 'Odaberi predmet',
      },
      fiterByInternshipStatusAsMentor: {
        title: 'Status prakse',
        defaultOption: 'Sve prakse',
      },
      filteringByAuditActions: {
        title: 'Popis akcija',
        defaultOption: 'Sve akcije',
      },
      filteringByDateAuditLogs: {
        title: 'Odabir datuma',
        mainHeaderText: 'Odabir datuma',
        descriptionHeaderText: 'Odaberite početni i krajnji datum',
      },
      filteringByDateDropdown: {
        title: 'Odabir datuma izvršenja prakse',
        mainHeaderText: 'Odabir raspona datuma za prikaz izvršenih praksi',
        descriptionHeaderText: 'Odaberite početni i kranji datum',
      },
      internshipStartDate: {
        mainHeaderText: 'Odabir datuma',
        descriptionHeaderText: 'Odaberite datum početka prakse',
      },
      internshipDateRange: {
        title: 'Raspon u kojem se studenti mogu prijaviti za praksu',
        mainHeaderText: 'Odabir raspona datuma za održavanje prakse',
        descriptionHeaderText:
          'Odaberite početni i kranji datum u kojem će se studenti moći prijaviti na praksu',
      },
      subjectDateRange: {
        title: 'Raspon u kojem se studenti mogu prijaviti za praksu',
        mainHeaderText: 'Odabir raspona datuma za održavanje prakse',
        descriptionHeaderText:
          'Odaberite raspon datuma u kojem se studenti mogu prijaviti na praksu',
      },
      statusDropdown: {
        title: 'Status prakse',
        defaultOption: 'Svi statusi',
        selectedStatusDropdownTitle_interval:
          '(0)[Svi statusi];(1)[Odabran jedan status];(2-inf)[Odabrano više statusa]',
      },
      studentStudyTypeDropdown: {
        title: 'Status studenta',
        defaultOption: 'Svi studenti',
        selectedStudentStudyType_interval:
          '(0)[Svi studenti];(1)[REDOVNI];(2-inf)[IZVANREDNI]',
      },
      studiesDropdown: {
        title: 'Studij',
        defaultOption: 'Odaberi studij',
        allDepartments: 'Svi odjeli',
      },
      companiesDropdown: {
        defaultOption: 'Odaberi poduzeće',
        singleCompany: 'Poduzeće',
        listOfCompanies: 'Popis poduzeća',
        inputCompanyNameOrChooseCompany: 'Upišite naziv ili odaberite poduzeće',
      },
      professorsDropdown: {
        defaultOption: 'Odaberite voditelja kojeg želite dodati',
        listOfExistingProfessors: 'Popis postojećih voditelja',
        selectProfessorPlaceholder: 'Odaberite voditelja kojeg želite dodati',
      },
    },
    buttons: {
      userSupport: 'Korisnička podrška',
      createInternshipForStudent: 'Napravi praksu',
      downloadPassedStudents: 'Preuzmi položene studente',
      saveRating: 'Spremi ocjenu',
      cancel: 'Odustani',
      markAsResolveComment: 'Označi kao riješeno',
      addNewComment: 'Dodaj novi komentar',
      editComment: 'Uredi komentar',
      saveComment: 'Spremi komentar',
      submitInternshipDiary: 'Predaj dnevnik rada',
      viewInternshipConclusion: 'Pregled osvrta na praksu',
      changeInternshipConclusion: 'Izmijeni osvrt na praksu',
      addInternshipConclusion: 'Dodaj osvrt na praksu',
      addNewInternshipLogs: 'Dodaj novi dan',
      approveCertificate: 'Odobri',
      rejectCertificate: 'Odbij',
      removeDocument: 'Obriši dokument',
      addInternship: 'Dodaj praksu',
      selectMentorForInternship: 'Dodaj mentora',
      changeMentorForInternship: 'Promijeni mentora',
      rateInternship: 'Ocijeni praksu',
      approveInternship: 'Potvrdi praksu',
      approveInternshipForMentor: 'Potvrdi praksu za mentora',
      rejectInternship: 'Odbij praksu',
      rejectInternshipForMentor: 'Odbij praksu za mentora',
      viewInternshipRejectionReason: 'Pogledaj razlog',
      removeFile: 'Obriši datoteku',
      chooseFile: 'Odaberite datoteku',
      uploadCertificateOfEmployment: 'Priloži potvrdu o zaposlenju',
      downloadCertificateOfEmployment:
        'Preuzmi predložak potvrde o zaposlenju',
      viewCompaniesList: 'Pogledaj popis poduzeća',
      deleteInternship: 'Obriši praksu',
      approveInternshipRequest: 'Odobri zahtjev',
      selectInternshipRequest: 'Odaberi zahtjev',
      removeInternshipRequest: 'Obriši zahtjev',
      addNewStudy: 'Dodaj studij',
      addNewSubject: 'Dodaj predmet',
      addNewProfessor: 'Dodaj Voditelja prakse',
      addNewMentor: 'Dodaj mentora',
      addNewCompany: 'Novo poduzeće',
      downloadCompanyList: 'Preuzmi popis poduzeća',
      edit: 'Uredi',
      login: 'Prijavi se',
      logout: 'Odjava',
      savePassword: 'Spremi lozinku',
      send: 'Pošalji',
      select: 'Odaberi',
      update: 'Ažuriraj',
      save: 'Spremi',
      add: 'Dodaj',
      sendInternshipRequestToCompany: 'Predaj zahtjev za praksu',
      sendPrivateInternshipRequest: 'Predaj zahtjev',
      pagination: {
        itemsPerPage: 'Broj zapisa:',
      },
      resetPassword: 'Ponovno postavi lozinku',
      editSignature: 'Uredi potpis',
      addSignature: 'Dodaj potpis',
      passwordSavedToClipboard: 'Lozinka je kopirana u međuspremnik',
      syncExistingStudentsHoverText:
        'Služi za izjednačavanje podataka studenata u bazi i podataka u ISVU sustavu',
      syncExistingStudents: 'Sinkroniziraj postojeće studente',
      syncSingleStudent: 'Sinkroniziraj jednog studenta',
      syncAllStudents: 'Sinkroniziraj sve studente',
      syncStudent: 'Sinkroniziraj studenta',
    },
    professorHomepage: {
      studentList: 'Studenti po predmetu',
      summary: 'Sažetak',
      companies: 'Poduzeća',
      mentors: 'Mentori',
      professors: 'Voditelji praksi',
      subjects: 'Predmeti',
      studies: 'Studiji',
      auditLogs: 'Popis događaja',
      studentSync: 'Sinkronizacija studenata',
      internshipRequests: 'Popis zahtjeva za praksu',
      documentCheck: {
        default: 'Provjerite valjanost dokumenta',
        uploaded: '% preneseno',
        documentIsValid: 'Dokument je važeći',
        documentIsNotValid: 'Dokument je nevažeći',
      },
      downloadCertificateOfEmployment: 'Preuzmite potvrdu o zaposlenju',
      downloadedCertificateName: 'Potvrda o zapolenju.pdf',
      downloadedPassedStudentListName: 'Položeni studenti',
      downloadedConfirmationFileName: 'Potvrda odrađene prakse',
      downloadedDiaryFileName: 'Dnevnik prakse',
      downloadedReferralFileName: 'Uputnica',
    },
    modal: {
      downloadPassedStudents: {
        mainHeaderText: 'Preuzmite popis studenata koji su položili predmet',
        descriptionHeaderText:
          'Unesite godinu za koju želite preuzeti popis studenata',
      },
      addInternshipForStudent: {
        mainHeaderText: 'Unos nove prakse',
        descriptionHeaderText: 'Unesite novu praksu za studenta',
      },
      ratingModal: {
        mainHeaderText: 'Ocijeni praksu',
        descriptionHeaderText: 'Unesite ocjene za praksu',
      },
      conclusionModal: {
        mainHeaderText: 'Pregled osvrta na praksu',
      },
      successModal: {
        successMessage: 'Zahtjev uspješno obrađen',
      },
      loadingModal: {
        loadingMessage: 'Učitavanje',
        loadingFinishedMessage: 'Završeno učitavanje',
      },
      selectMentorForInternship: {
        mainHeaderText: 'Popis mentora',
        defaultDescriptionHeaderText: 'Odabirom mentora potvrđujete praksu',
        selectedMentorDescriptionHeaderText:
          'Odabirom mentora mijenjate mentora za praksu',
      },
      alertModal: {
        doYouWantToLeaveInternshipRequest:
          'Želite li napustiti ovaj ekran? Morati ćete dodati ručno mentora praksi kako bi ju aktivirali.',
        doYouWantToRemoveInternshipRequest:
          'Želite li obrisati zahtjev za praksu?',
        doYouWantToRemoveInternship:
          'Jeste li sigurni da želite obrisati praksu?',
        thisStudentCannotBeContacted:
          'Ovaj student ne može biti kontaktiran na ovaj način jer nema unesenu email adresu.',
        alert: 'Oprez!',
        no: 'Ne',
        yes: 'Da',
        okay: 'U redu',
      },
      internshipRequestInfoModal: {
        mainHeaderText: 'Informacije o zahtjevu za praksu',
        descriptionHeaderText: 'Ažuriraj i potvrdi zahtjev za praksu',
      },
      internshipStartdatePicker: {
        mainHeader: 'Odabir datuma',
        descriptionHeaderText: 'Odaberite datum početka prakse',
      },
      sendEmailModal: {
        mainHeaderText: 'Kontakt obrazac',
        descriptionHeaderText: 'Unesite potrebne podatke',
      },
      modalInfoBar: {
        changePasswordMain: 'Promijeni lozinku',
        changePasswordDescriptionText: 'Ažuriraj svoju lozinku',
      },
      companyModal: {
        mainHeader: {
          editCompany: 'Uredi poduzeće',
          addNewCompany: 'Unos novog poduzeća',
          companySelect: 'Odabir poduzeća',
          addPrivateCompany: 'Unos privatnog poduzeća',
        },
        descriptionHeader: {
          editCompany: 'Uredite detalje poduzeća',
          addNewCompany: 'Unesite detalje novog poduzeća',
          companySelect: 'Odaberite poduzeće',
          addPrivateCompany: 'Unos detalje privatnog poduzeća',
        },
        ifMentorIsNotKnown:
          'Ako je mentor još nepoznat, podatke mentora ostaviti prazno.',
        ifPrivateMentorEmailExists:
          'Ukoliko je ovo točan email vašeg mentora, podatke mentora ostavite prazno i zamolite voditelja prakse da vam naknadno dodijeli točnog mentora.',
      },
      mentorModal: {
        mainHeader: {
          editMentor: 'Uređivanje mentora',
          addMentor: 'Unos novog mentora',
        },
        descriptionHeader: {
          editMentor: 'Uredite detalje mentora',
          addMentor: 'Unesite detalje mentora',
        },
      },
      professorModal: {
        mainHeader: {
          editProfessor: 'Uređivanje voditelja prakse',
          addProfessor: 'Unos novog voditelja prakse',
        },
        descriptionHeader: {
          editProfessor: 'Uredite detalje voditelja prakse',
          addProfessor: 'Unesite detalje voditelja prakse',
        },
      },
      subjectModal: {
        mainHeader: {
          editSubject: 'Uređivanje predmeta',
          addSubject: 'Unos novog predmeta',
        },
        descriptionHeader: {
          editSubject: 'Uredite detalje predmeta',
          addSubject: 'Unesite detalje predmeta',
        },
      },
      studyModal: {
        mainHeader: {
          editStudy: 'Uređivanje studija',
          addStudy: 'Unos novog studija',
        },
        descriptionHeader: {
          editStudy: 'Uredite detalje studija',
          addStudy: 'Unesite detalje studija',
        },
      },
      signing: {
        mainHeaderText: 'Unos novog potpisa',
        descriptionHeaderText: 'Unesite potpis na ispod predviđeno mjesto',
        enterSignatureHere: 'Potpišite se ovdje',
        useExistingSignature: 'Iskoristi postojeći potpis',
        removeSignature: 'Obriši',
        no: 'Ne',
        yes: 'Da',
        confirm: 'Potvrdi',
        uploadExistingSignature: 'Prenesi fotografiju postojećeg potpisa',
      },
      passwordDisplay: {
        mainHeaderText: 'Prikaz lozinke za kreiranog korisnika',
      },
      studentSync: {
        inputStudentPINmainText: 'Unos JMBAG-a studenta',
        inputStudentPINmainTextDescription:
          ' Unesite studentov JMBAG kako bi mogli započeti sinkronizaciju',
      },
    },
    list: {
      studentList: {
        header: {
          name: 'Ime i prezime',
          studentID: 'JMBAG',
          contact: 'Kontakt',
          study: 'Studij',
          internshipStatus: 'Status prakse',
          dateFinished: 'Završena',
          actions: 'Detalji',
        },
      },
      companiesList: {
        header: {
          name: 'Naziv',
          contact: 'Kontakt',
          city: 'Mjesto',
          website: 'Web stranica',
          isPublic: 'Javno',
          active: 'Aktivno',
          actions: 'Akcije',
        },
        active: {
          yes: 'Da',
          no: 'Ne',
        },
        public: {
          yes: 'Da',
          no: 'Ne',
        },
        button: {
          selected: 'Odabrano',
          notSelected: 'Odaberi',
        },
        tooltip: {
          internshipRequestsDisabled:
            'Onemogućeno slanje zahtjeva ovom poduzeću',
          noFreeSlots: 'Poduzeće nema slobodnih mjesta',
          requestAlreadySent: 'Već poslan zahtjev poduzeću',
        },
      },
      mentorsList: {
        header: {
          name: 'Ime i prezime',
          companyName: 'Naziv poduzeća',
          contact: 'Kontakt',
          actions: 'Akcije',
        },
      },
      professorsList: {
        header: {
          name: 'Ime i prezime',
          contact: 'Kontakt',
          active: 'Aktivan',
          actions: 'Akcije',
        },
        active: {
          yes: 'Da',
          no: 'Ne',
        },
      },
      subjectsList: {
        header: {
          name: 'Naziv',
          study: 'Studij',
          active: 'Aktivan',
          actions: 'Akcije',
        },
        active: {
          yes: 'Da',
          no: 'Ne',
        },
      },
      studiesList: {
        header: {
          name: 'Naziv',
          department: 'Odjel',
          active: 'Aktivan',
          actions: 'Akcije',
        },
        active: {
          yes: 'Da',
          no: 'Ne',
        },
      },
      auditList: {
        header: {
          user: 'Korisnik',
          email: 'Email',
          role: 'Rola',
          action: 'Akcija',
          description: 'Opis',
          date: 'Datum',
        },
      },
      internshipRequestsProfessorList: {
        header: {
          studentName: 'Ime i prezime',
          email: 'Email',
          studentStudyType: 'Status',
          subjectName: 'Predmet',
          companyName: 'Naziv poduzeća',
          isPublic: 'Javna praksa',
          actions: '',
        },
        isPublic: {
          yes: 'Da',
          no: 'Ne',
        },
      },
      internshipRequestsStudentList: {
        header: {
          name: 'Naziv',
          contactPersonEmail: 'Kontakt',
          city: 'Mjesto',
          url: 'Web stranica',
          startDate: 'Datum početka',
          actions: '',
        },
      },
    },
    internshipStatusOnList: {
      notSelected: 'Nije odabrana',
      internshipRequestSent: 'Zahtjev poslan',
      certificateOfEmploymentInReview: 'Potvrda o zaposlenju na pregledu',
      certificateOfEmploymentRejected: 'Potvrda o zaposlenju odbijen',
      internshipProcessing: 'Praksa u procesiranju',
      internshipApproved: 'Praksa odobrena',
      mentorReviewingInternship: 'Mentor pregledava praksu',
      professorReviewingInternship: 'Profesor pregledava praksu',
      internshipReviewRejected: 'Završen pregled prakse - Odbijeno',
      internshipCompleted: 'Završena praksa',
    },
    internshipStatusOnStudentPage: {
      processing: 'Praksa u procesiranju',
      approved: 'Praksa odobrena',
      mentorReviewing: 'Mentor pregledava praksu',
      professorReviewing: 'Profesor pregledava praksu',
      reviewRejected: 'Završen pregled prakse - Odbijeno',
      completed: 'Završena praksa',
    },
    auditListActions: {
      PROFESSOR_UPDATED: 'Profesor ažuriran',
      PROFESSOR_CREATED: 'Profesor kreiran',
      MENTOR_CREATED: 'Mentor kreiran',
      MENTOR_UPDATED: 'Mentor ažuriran',
      MENTOR_CHANGED: 'Mentor izmijenjen',
      SUBJECT_CREATED: 'Predmet kreiran',
      SUBJECT_UPDATED: 'Predmet ažuriran',
      STUDY_CREATED: 'Studij kreiran',
      STUDY_UPDATED: 'Studij ažuriran',
      COMPANY_INTERNSHIP_SLOT_CONFIG_DELETED:
        'Oganičenje mjesta za poduzeće obrisano',
      COMPANY_INTERNSHIP_SLOT_CONFIG_CREATED:
        'Oganičenje mjesta za poduzeće postavljeno',
      COMPANY_INTERNSHIP_SLOT_CONFIG_UPDATED:
        'Oganičenje mjesta za poduzeće ažurirano',
      PASSWORD_UPDATE: 'Lozinka ažurirana',
      PASSWORD_UPDATE_BY_ADMIN: 'Admin ažurirao lozinku',
      CERTIFICATE_OF_EMPLOYMENT_UPLOADED: 'Potvrda o zaposlenju prenesen',
      CERTIFICATE_OF_EMPLOYMENT_DELETED: 'Potvrda o zaposlenju obrisan',
      CERTIFICATE_OF_EMPLOYMENT_APPROVED_BY_PROFESSOR_OR_ADMIN:
        'Potvrda o zaposlenju odobren od profesora/admina',
      CERTIFICATE_OF_EMPLOYMENT_REJECTED_BY_PROFESSOR_OR_ADMIN:
        'Potvrda o zaposlenju odbijen od profesora/admina',
      ACCEPTED_INTERNSHIP_REQUEST: 'Zahtjev za praksom prihvaćen',
      INTERNSHIP_CREATED: 'Praksa kreirana',
      ACTIVATED_INTERNSHIP: 'Praksa aktivirana',
      INTERNSHIP_DELETED: 'Praksa obrisana',
      INTERNSHIP_SUBMITTED_TO_REVIEW: 'Praksa predana na pregled',
      INTERNSHIP_APPROVED_BY_MENTOR: 'Praksa odobrena od mentora',
      INTERNSHIP_REJECTED_BY_MENTOR: 'Praksa odbijena od mentora',
      INTERNSHIP_APPROVED_BY_PROFESSOR_OR_ADMIN:
        'Praksa potvrđena od profesora/admina',
      INTERNSHIP_REJECTED_BY_PROFESSOR_OR_ADMIN:
        'Praksa odbijena od profesora/admina',
      TRIGGERED_SYNC_ONE_STUDENT: 'Pokrenuta sinkronizacija jednog studenta',
      TRIGGERED_SYNC_ALL_STUDENTS: 'Pokrenuta sinkronizacija svih studenata',
      TRIGGERED_SYNC_EXISTING_STUDENTS:
        'Pokrenuta sinkronizacija postojećih studenata',
      FAILED_SYNC_ONE_STUDENT: 'Sinkronizacija jednog studenta neuspješna',
      FAILED_SYNC_ALL_STUDENTS: 'Sinkronizacija svih studenata neuspješna',
      FAILED_SYNC_EXISTING_STUDENTS:
        'Sinkronizacija postojećih studenata neuspješna',
      FINISHED_WITH_ERRORS_SYNC_ONE_STUDENT:
        'Sinkronizacija jednog studenta završena sa greškama',
      FINISHED_WITH_ERRORS_SYNC_ALL_STUDENTS:
        'Sinkronizacija svih studenata završena sa greškama',
      FINISHED_WITH_ERRORS_SYNC_EXISTING_STUDENTS:
        'Sinkronizacija postojećih studenata završena sa greškama',
      FINISHED_SYNC_ONE_STUDENT: 'Sinkronizacija jednost studenta završena',
      FINISHED_SYNC_ALL_STUDENTS: 'Sinkronizacija svih studenata završena',
      FINISHED_SYNC_EXISTING_STUDENTS:
        'Sinkronizacija postojećih studenata završena',
    },
    syncStatuses: {
      FAILED: 'Sinkronizacija nije uspjela',
      FINISHED_WITH_ERRORS: 'Sinkronizacija je završila sa greškama',
      FINISHED: 'Sinkronizacija završena',
    },
    documentGenerationStatuses: {
      GENERATING: 'Dokument se generira',
      NOT_GENERATED: 'Generirajte dokument',
      DOWNLOAD_REFERRAL: 'Preuzmi uputnicu',
      DOWNLOAD_INTERNSHIP_CONFIRMATION: 'Preuzmi potvrdu',
      DOWNLOAD_INTERNSHIP_DIARY: 'Preuzmi dnevnik rada',
      RETRY_GENERATION_REFERRAL: 'Ponovite generiranje uputnice',
      RETRY_GENERATION_INTERNSHIP_CONFIRMATION: 'Ponovite generiranje potvrde',
      RETRY_GENERATION_INTERNSHIP_DIARY: 'Ponovite generiranje dnevnika rada',
    },
    certificateStatuses: {
      approvedForStudent: 'Potvrda je odobren za studenta.',
      approved: 'Potvrda je odobren.',
      rejected: 'Potvrda je odbijen.',
      inReview: 'Potvrda predan na pregled',
    },
    mentorInternshipPageStatuses: {
      approved: 'Aktivne',
      'mentor-review': 'Na pregledu kod mentora',
      'professor-review': 'Na pregledu kod profesora',
      'failed-review': 'Pregled nuspješan',
      completed: 'Završene',
    },
    breadCrumbs: {
      dashboard: 'Početna stranica',
      studentList: 'Popis studenata po predmetu',
      summaryList: 'Sažetak',
      companiesList: 'Popis poduzeća',
      internshipRequestsList: 'Popis zahtjeva za praksu',
      mentorsList: 'Popis mentora',
      mentorsListSmallText:
        'Ako student za privatnu praksu još ne zna mentora, kreiran je privremeni mentor s današnjim datumom. Privremeni mentor se može dodjeliti praksi te mu se naknadno mogu promijeniti podatci.' +
        '\n' +
        'Kad se privremeni mentor dodjeli praksi kreira se bjanko uputnica (bez imena i prezimena mentora) koja se naknadno može ručno nadopuniti.',
      subjectsList: 'Popis predmeta',
      studiesList: 'Popis studija',
      professorsList: 'Popis voditelja praksi',
      auditList: 'Popis događaja',
      synchornizationPage: 'Sinkronizacija studenata',
      mentorInternshipsListPage: 'Popis praksi',
      tutorials: 'Vodič za aplikaciju',
    },
    tutorials: {
      shared: {
        signature: {
          steps: `Korištenje postojećeg:
          1. U slučaju da postoji spremljeni potpis u sustavu, pritiskom na tipku "Iskoristi postojeći potpis" prikazuje se postojeći potpis koji se može predati pritiskom na tipku "Da" ili maknuti iz trenutnog izbora pritiskom na tipku "Ne"

          Ručni unos novog potpisa:
          1. U prikazano polje za unos pritiskom i držanjem klika miša započinje se pisanje
          2. Nakon unesenog potisa, pritiskom na tipku "Potvrdi" uneseni potpis se šalje, dok se pritiskom na tipku "Obriši" unesni potpis briše iz prikaza

          Prenošenje fotografije potpisa:
          1. Pritisnuti na tipku "Prenesi fotografiju postojećeg potpisa"
          2. Odabrati fotografiju potpisa
          3. Označiti dio fotografije koji sadrži potpis
          4. Pritiskom na tipku "Da" označeni dio fotografije se šalje, dok pritiskom na tipku "Ne" odabrana fotografija se briše iz prikaza`,
          title: 'Unos potpisa',
        },
        rateInternship: {
          steps: `1. Odabrati završenu praksu za studenta
          2. Pritisnuti na tipku "Ocijeni praksu"
          3. Odabrati broj zvjezdica kao ocjenu
          4. Pritisnuti na tipku "Spremi ocjenu"

          Ponovnim pritiskom na "Ocijenu praksu" mogu se pregledati odabrane ocjene.
          *Prikaz za ocjenjivanje se može razlikovati između vrsta korisnika, ali ocjenjivanje se izvršava na isti način.`,
          title: 'Ocjenjivanje prakse (nije obavezno)',
        },
      },
      adminTutorials: {
        resetPasswordMentorAndProfessor: {
          steps: `1. Otići na listu mentora ili listu voditelja praksi
          2. Odabrati mentora/voditelja prakse kojem se želi ponovno postaviti lozinka pritiskom na tipku "Odaberi"
          3. Pritisnuti na tipku "Ponovno postavi lozinku"
          4. Nova generirana lozinka će biti prikazana na ekranu`,
          title: 'Ponovno postavljanje lozinke mentoru i voditelju prakse',
        },
        addAndEditProfessor: {
          steps: `1. Otići na listu voditelja praksi

          Dodavanje voditelja prakse:
          2. Pritisnuti na tipku "Dodaj voditelja prakse"
          3. Ispuniti sva potrebna polja
          4. Pritisnuti na tipku "Spremi" za kreiranje voditelja prakse

          Uređivanje voditelja prakse:
          2. Pritisnuti na tipku "Odaberi" pokraj voditelja kojeg se želi urediti
          3. Izmijeniti sva željena polja
          4. Pritisnuti na tipku "Ažuriraj" za spremanje izmjena`,
          title: 'Dodavanje i uređivanje voditelja prakse',
        },
        addAndEditStudy: {
          steps: `1. Otići na listu studija

          Dodavanje studija:
          2. Pritisnuti na tipku "Dodaj studij"
          3. Ispuniti sva potrebna polja
          4. Pritisnuti na tipku "Spremi" za kreiranje studija

          Uređivanje studija:
          2. Pritisnuti na tipku "Odaberi" pokraj studija kojeg se želi urediti
          3. Izmijeniti sva željena polja
          4. Pritisnuti na tipku "Ažuriraj" za spremanje izmjena`,
          title: 'Dodavanje i uređivanje studija',
        },
        addAndEditSubject: {
          steps: `1. Otići na listu predmeta

          Dodavanje predmeta:
          2. Pritisnuti na tipku "Dodaj predmet"
          3. Ispuniti sva potrebna polja
          4. Pritisnuti na tipku "Spremi" za kreiranje predmeta

          Uređivanje predmeta:
          2. Pritisnuti na tipku "Odaberi" pokraj predmeta kojeg se želi urediti
          3. Izmijeniti sva željena polja
          4. Pritisnuti na tipku "Ažuriraj" za spremanje izmjena`,
          title: 'Dodavanje i uređivanje predmeta',
        },
        syncStudents: {
          steps: `1. Otići na sinkronizaciju studenata
          2. Odabrati sinkroniziranje svih studenata ili jednog studenta
          3. Pričekati da se sinkronizacija završi

          Nakon završenja sinkronizacije poruka će biti prikazana`,
          title: 'Sinkronizacija studenata (Administrator)',
        },
      },
      studentTutorials: {
        internshipRequestCreationAndDeletionPublic: {
          steps: `0. Odabrati studij iz padajućeg izbornika (u slučaju da studij nije odabran)
          1. Odabrati predmet za koji se želi napraviti zahtjev za praksu
          2. Pritisnuti na Pogledaj popis poduzeća
          3. Pritisnuti na tipku "Odaberi" pokraj poduzeća za koje se želi poslati zahtjev za praksu
          4. Odabrati planirani datum početka prakse i pritisnuti na tipku "Predaj zahtjev za praksu"

          Ako se želi obrisati poslani zahtjev za praksu potrebno je vratiti se na početnu stranicu, odabrati isti predmet te pritisnuti na tipku "Obriši zahtjev"`,
          title: 'Kreiranje i brisanje zahtjeva za praksu (javna praksa)',
        },
        internshipRequestCreationAndDeletionPrivate: {
          steps: `0. Odabrati studij iz padajućeg izbornika (u slučaju da studij nije odabran)
          1. Odabrati predmet za koji se želi napraviti zahtjev za praksu
          2. Pritisnuti na Pogledaj popis poduzeća
          3. Pritisnuti na tipku "Novo poduzeće"
          4. Unijeti podatke o poduzeću kod kojega se želite prijaviti na praksu
          5. Unijeti željeni datum početka prakse i pritisnuti tipku "Predaj zahtjev za praksu"

          Ako se želi obrisati poslani zahtjev za praksu potrebno je vratiti se na početnu stranicu, odabrati isti predmet te pritisnuti na tipku "Obriši zahtjev"`,
          title: 'Kreiranje i brisanje zahtjeva za praksu (privatna praksa)',
        },
        sendCertificateOfEmployment: {
          steps: `1. Odabrati predmet za koji se želi priložiti potvrda o zaposlenju
          2. Pritisnuti na tipku "Preuzmi predložak certifikata o zaposlenju"
          3. Ispuniti predložak s potrebnim podatcima
          4. Pritisnuti na tipku "Priloži potvrdu o zaposlenju"
          5. Odabrati dokument i pritisnuti tipku "Pošalji"

          Nakon obavljene procedure, ceritifikat je predan profesoru na pregled. Ako je odobren, predmet je završen, u suprotnom se može ponoviti slanje ili obrisati dokument i napraviti zahtjev za praksu.`,
          title: 'Slanje potvrde o zaposljenju (IZVANREDNI STUDENT) ',
        },
        createDiaryLog: {
          steps: `1. Na odabranoj praksi pritisnuti na Dnevnik prakse
          2. Pritisnuti na tipku "Dodaj novi dan"
          3. Upisati broj sati, opis i priloge (ako imate)
          4. Priskom na tipku "Spremi" dodaje se novi unos u dnevnik rada koji se može pregledati odabirom sa padajućeg izbornika`,
          title: 'Ispunjavanje dnevnika rada',
        },
        editDiaryLog: {
          steps: `1. Odabrati unos sa padajućeg izbornika
          2. Pritisnuti na tipku "Uredi"
          3. Urediti željene podatke, obrisati ili dodati priloge
          4. Pritisnuti na tipku "Spremi" kako bi spremili izmijenjen

          U slučaju da ne želite izmijeniti unos, odabirom drugog unosa (ili "Obriši odabir") iz padajućeg izbornika izlazite iz načina uređivanja unosa.`,
          title: 'Uređivanje dnevnika rada',
        },
        sendInternshipToMentorReview: {
          steps: `1. Dodati osvrt na praksu
          2. Pritisnuti na tipku "Predaj dnevnik rada"
          3. Potpisati se unutar označenog mjesta ili prenijeti uslikani potpis
          4. Pritiskom na tipku "Da" predaje se dnevnik rada na pregled

          Mentor i voditelj prakse moraju odobriti dnevnik rada kako bi se praksa smatrala završenom.`,
          title: 'Slanje dnevnika prakse na pregled',
        },
        viewComments: {
          steps: `1. Odabrati dan za koji se želi pregledati komentari
          2. Pritisnuti na plavu traku s natpisom "Komentari"

          Voditelj prakse mora označiti komentar razriješenim kako bi se mogao predati dnevnik rada na pregled.
          Komentar je razriješen kada ima zelenu kvačicu pokraj komentara.`,
          title: 'Pregled komentara na unosu u dnevniku rada',
        },
      },
      professorTutorials: {
        approveInternshipRequestRequestList: {
          steps: `1. Odabrati praksu koju se želi odobriti pritiskom na tipku "Odaberi zahtjev"
          ---------------
          ZA JAVNE PRAKSE:
          2. Upisti Opis, Kratak opis i Organizacijsku jedinicu

          ZA PRIVATNE PRAKSE:
          2. Upisati Organizacijsku jedinicu

          *Datum početka prakse se također može mijenjati
          ---------------
          3. Pritisnuti na tipku "Odobri zahtjev"
          4. Odabrati mentora za praksu (odabirom mentora praksa postaje aktivna)
          5. Unijeti novi, iskoristiti postojeći ili prenijeti novi potpis
          6. Pritisnuti na tipku "Potvrdi"`,
          title: 'Odobravanje prakse (Popis zahtjeva za praksu)',
        },
        approveInternshipRequestStudentPage: {
          steps: `1. Odabrati studenta kojemu se želi potvrditi zahtjev za praksom pritiskom na tipku "Odaberi" pokraj studenta
          2. Pritisnuti tipku "Odaberi zahtjev" pokraj zahtjeva koji se želi odobriti

          ---------------
          ZA JAVNE PRAKSE:
          3. Upisti Opis, Kratak opis i Organizacijsku jedinicu pritiskom na tipku "Dodaj" za pojedini unos
          te pritiskom na zelenu kvačicu nakon unosa pokraj pojedinog polja za unos

          ZA PRIVATNE PRAKSE:
          3. Upisati Organizacijsku jedinicu pritiskom na tipku "Dodaj" te pritiskom na zelenu kvačicu
          nakon unosa pokraj polja za unos

          *Datum početka prakse se također može mijenjati
          ---------------
          4. Pritisnuti na tipku "Potvrdi praksu"
          5. Odabrati mentora za praksu (odabirom mentora praksa postaje aktivna) pritiskom na tipku "Dodaj mentora"
          6. Unijeti novi, iskoristiti postojeći ili prenijeti novi potpis`,
          title: 'Odobravanje prakse (Studenti po predmetu)',
        },
        removeInternship: {
          steps: `1. Odabrati studenta kojemu se želi obrisati praksa pritiskom na tipku "Odaberi" pokraj studenta
          2. Pritisnuti tipku "Obriši praksu"
          3. Odabrati "Da" na prikazu upozorenja

          Praksa se može obrisati u svakom trenutku dok nije status prakse "Završena"`,
          title: 'Brisanje prakse za studenta',
        },
        addInternshipForStudent: {
          steps: `1. Odabrati studenta kojemu se želi dodati praksa pritiskom na tipku "Odaberi" pokraj studenta
          2. Pritisnuti tipku "Dodaj praksu"
          3. Odabrati sa popisa poduzeće i mentora za praksu
          4. Upisti Opis, Kratak opis i Organizacijsku jedinicu
          5. Unijeti potpis i odabrati planirani datum početka prakse
          6. Pritisnuti na tipku "Napravi praksu"

          Pritiskom na tipku "Napravi praksu" kreirana praksa odmah postaje aktivna`,
          title: 'Dodavanje prakse za studenta',
        },
        sendEmailToStudent: {
          steps: `0. Odabrati Odabrati studenta kojemu se želi poslati email pritiskom na:
          1. Email studenta na listi
          2. Unesite Naslov i Sadržaj
          3. Pritisnite na tipku "Pošalji" kako bi poslali email studentu
          ILI
          1. Na tipku "Odaberi" pokraj studenta
          2. Pritisnite na tipku sa oznakom kuverte na kartici informacija o studentu
          3. Unesite Naslov i Sadržaj
          4. Pritisnite na tipku "Pošalji" kako bi poslali email studentu`,
          title: 'Slanje Email-a studentu',
        },
        addOrEditCompany: {
          steps: `1. Pritisnuti na tipku "Novo poduzeće" za dodavanje novog poduzeća
          ILI
          1. Pritisnuti na tipku "Odaberi" pokraj poduzeća koje se želi urediti

          2. Unesite nove ili uredite stare podatke poduzeća
          3. Pritisnite na tipku "Spremi" za kreiranje novog poduzeća ili na tipku "Ažuriraj"
          za uređivanje postojećeg

          Novo poduzeće se uvijek dodaje na zadnju stranicu, a može se i pretraživati prema mjestu i adresi, OIB-u, nazivu i kontakt email-u.`,
          title: 'Dodavanje i uređivanje poduzeća',
        },
        companySubjectLimit: {
          steps: `1. Pritisnuti na tipku "Odaberi" pokraj poduzeća koje se želi postaviti ograničenje
          2. Odabrati iz padajućeg izbornika predmet za koji se želi postaviti ograničenje
          3. Upisati broj mjesta i akademsku godinu za koju se odnosi ograničenje
          3. Pritisnite na tipku "Postavi ograničenje" za postavljanje ograničenja

          Ograničenja se na isti način mogu uređivati, a pritiskom na tipku "Obriši" ograničenje se briše`,
          title: 'Ograničavanje broja prijava poduzeću za određeni predmet',
        },
        addOrEditMentor: {
          steps: `1. Pritisnuti na tipku "Dodaj mentora" za dodavanje novog mentora
          ILI
          1. Pritisnuti na tipku "Odaberi" pokraj mentora kojeg se želi urediti

          2. Unesite nove ili uredite stare podatke mentora
          3. Pritisnite na tipku "Spremi" za kreiranje novog mentora ili na tipku "Ažuriraj"
          za uređivanje postojećeg

          Nakon kreiranja mentora, pojavljuje se prikaz sa lozinkom mentora. Pritiskom na lozinku ona se automatski kopira u međuspremnik i može se proslijediti mentoru ako je potrebno.`,
          title: 'Dodavanje i uređivanje mentora',
        },
        editSubjectDateRange: {
          steps: `1. Otići na "Popis studenata po predmetu"
          2. Pritisnuti na unos datuma pod "Raspon u kojem se studenti mogu prijaviti za praksu"
          3. Odabrati vremenski period u kojem se mogu studenti prijaviti na praksu
          4. Pritisnuti na tipku "Uredi" kako bi se izmjena spremila

          Studentima će biti prikazana poruka da se trenutno ne mogu prijaviti na praksu ako se današnji datum ne nalazi unutar odabranog intervala.`,
          title: 'Postavljanje raspona datuma prijave na praksu',
        },
        approveAndRejectInternship: {
          steps: `1. Odabrati studenta za kojega se želi pregledati praksa
          2. Odabrati "Dnevnik prakse"
          3. Pregledati unose u dnevnik prakse

          Odbijanje prakse:
          4. Pritisnite na tipku "Odbij praksu"
          5. Upišite razlog odbijanja
          6. Pritisnite tipku "Spremi"

          Odobravanje prakse:
          4. Pritisnite na tipku "Potvrdi praksu"
          5. Unesite potpis

          Nakon odobravanja prakse generirati će se dokument "Dnevnik prakse" sa svim dnevnim unosima i potpisima.`,
          title: 'Odobravanje i odbijanje prakse studenta (Voditelj prakse)',
        },
        addAndEditComment: {
          steps: `1. Odabrati studenta sa praksom
          2. Odabrati "Dnevnik prakse"
          3. Pritisnuti na plavu traku sa nazivom "Komentari"

          Uređivanje komentara:
          4. Pritisnuti na tipku "Uredi" pokraj komentara za uređivanje
          5. Promijeniti komentar i pritisnuti tipku "Uredi komentar" za spremanje izmjena

          Dodavanje novog komentara:
          4. Pritisnuti na tipku "Dodaj novi komentar"
          5. Upisati novi komentar
          6. Pritisnuti na tipku "Spremi komentar" za dodavanje novog komentara

          Razriješavanje komentara:
          4. Ako postoje ne riješeni komentari (označeni sa crvenom zastavicom) pritisnuti na tipku "Označi kao riješeno" kako bi se komentar razriješio

          Svi komentari moraju biti riješeni kako bi st udent mogao predati dnevnik prakse na pregled`,
          title: 'Dodavanje i uređivanje komentara na dnevniku prakse',
        },
        syncSingleStudentProfessor: {
          steps: `1. Odabrati studenta kojeg se želi sinkronizirati sa popisa studenata po predmetu
          2. Pritisnuti na tipku "Sinkroniziraj studenta" ispod kartice sa informacijama o studentu
          3. Pričekati da se izvrši sinkronizacija

          U slučaju uspješno završene sinkronizacije poruka "Sinkronizacija završena" će biti prikazana, a u suprotnom će se prikazati "Sinkronizacija nije uspjela"`,
          title: 'Sinkronizacija jednog studenta',
        },
        downloadPassedStudents: {
          steps: `1. Otići na "Studenti po predmetu" dio stranice
          2. Odabrati predmet iz padajućeg izbornika za koji se žele preuzeti položeni studenti
          3. Pritisnuti na tipku "Preuzmi položene studente"
          4. Upisati godinu za koju se želi preuzeti ploženi studenti i pritisnuti tipku "Pošalji"

          Godina se odnosi na godinu u kojoj su studenti završili odabrani predmet`,
          title: 'Preuzimanje ploženih studenata',
        },
      },
      mentorTutorials: {
        approveAndRejectInternship: {
          steps: `1. Odabrati studenta za kojega se želi pregledati praksa
          2. Odabrati "Dnevnik prakse"
          3. Pregledati unose u dnevnik prakse

          Odbijanje prakse:
          4. Pritisnite na tipku "Odbij praksu"
          5. Upišite razlog odbijanja
          6. Pritisnite tipku "Spremi"

          Odobravanje prakse:
          4. Pritisnite na tipku "Potvrdi praksu"
          5. Unesite potpis

          Nakon odobravanja prakse generirati će se potvrda o završetku prakse, a nakon što voditelj prakse odobri praksu, generirati će se dokument "Dnevnik prakse"`,
          title: 'Odobravanje i odbijanje prakse studenta (Mentor)',
        },
      },
    },
    errors: {
      errorOccured: 'Dogodila se greška, pokušajte ponovno',
      companyHasActiveInternships: 'Tvrtka ima aktivne prakse.',
      imageUploadErrorEncountered: 'Dogodila se greška. Pokušajte ponovno.',
      imageSizeTooBig: 'Slika je prevelika, molimo odaberite manju sliku',
      wrongImageType: 'Slika nije ispravnog formata',
    },
  },
};
