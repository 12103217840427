import { PURGE } from 'redux-persist/lib/constants';
import {
  INTERNSHIPS_FOR_MENTOR_FETCHED,
  SET_ERROR_INTERNSHIP_LIST,
  SET_PAGE_PER_PAGE_INTERNSHIPS_LIST,
} from '../types';

const initialLogState = {
  internshipsList: null,
  page: 1,
  perPage: 10,
  totalPages: null,
  error: null,
  status: null,
};

export const internshipsList = (state = initialLogState, action) => {
  switch (action.type) {
    case INTERNSHIPS_FOR_MENTOR_FETCHED: {
      return {
        ...state,
        internshipsList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
      };
    }
    case SET_ERROR_INTERNSHIP_LIST: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case SET_PAGE_PER_PAGE_INTERNSHIPS_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case PURGE: {
      return initialLogState;
    }
    default:
      return state;
  }
};
