import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../SharedComponents/Pagination/Pagination';
import { setPageAndPerPageMentorList } from '../../../../store/actions';

const MentorPagination = props => {
  const pages = useSelector(state => state.mentors.totalPages);
  const dataPerPage = useSelector(state => state.mentors.perPage);
  const page = useSelector(state => state.mentors.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageMentorList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      dataPerPage={dataPerPage}
      page={page}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.fetchMentorsByPage}
    />
  );
};

MentorPagination.propTypes = {
  fetchMentorsByPage: PropTypes.func,
};

export default MentorPagination;
