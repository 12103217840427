import axios from 'axios';
import Cookies from 'js-cookie';
import token from 'jsonwebtoken';

import { logoutAndWipeStorage } from '../../interceptor';
import settings from '../../settings';
import { accessTokenFetched, loginError, userLoggedIn } from '../actions';
import {
  FETCH_ACCESS_TOKEN,
  LOGIN_STUDENT,
  LOGIN_USER,
  LOGOUT_USER,
} from '../types';

let globalStore;

export const login = async (accessToken, refreshToken) => {
  Cookies.set('Accesstoken', accessToken, {
    expires: 365,
  });
  Cookies.set('Refreshtoken', refreshToken, {
    expires: 365,
  });
  await globalStore.dispatch(
    userLoggedIn(token.decode(accessToken).role.toLowerCase())
  );
  return 'Logged In';
};

export const userLogin = async (email, password) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${settings.REACT_APP_AUTH_URL}/api/v1/vevu/sign_in`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email,
        password,
      },
    });
    if (response.data.message) {
      globalStore.dispatch(loginError(response.data.message));
    } else {
      login(response.data.accessToken, response.data.refreshToken);
    }
  } catch (error) {
    globalStore.dispatch(loginError(error.message));
  }
};

export const getAccessToken = async () => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${settings.REACT_APP_AUTH_URL}/api/v1/token/refresh`,
      headers: { Authorization: `Bearer ${Cookies.get('Refreshtoken')}` },
    });
    return globalStore.dispatch(
      accessTokenFetched(
        token.decode(response.data.access_token).role.toLowerCase()
      )
    );
  } catch (error) {
    return globalStore.dispatch(loginError(error.message));
  }
};

const authMiddleware = store => next => action => {
  globalStore = store;

  switch (action.type) {
    case LOGIN_STUDENT: {
      return login(action.payload.accessToken, action.payload.refreshToken);
    }
    case LOGIN_USER: {
      return userLogin(action.payload.email, action.payload.password);
    }
    case LOGOUT_USER: {
      return logoutAndWipeStorage();
    }
    case FETCH_ACCESS_TOKEN: {
      return getAccessToken();
    }
    default:
      break;
  }
  return next(action);
};

export default authMiddleware;
