import i18n from '../../../i18n';
import signature from './SharedGifs/Signature.gif';
import rateInternship from './SharedGifs/RateInternship.gif';

const defaultNameSpace = 'tutorials.shared.';

export const sharedTutorials = [
  {
    name: i18n.t(defaultNameSpace + 'signature.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'signature.steps'),
      source: signature,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'rateInternship.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'rateInternship.steps'),
      source: rateInternship,
    },
  },
];
