/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchInternshipsForMentor,
  fetchInternshipsForMentorByQuery,
} from '../../../store/actions';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import Search from '../../SharedComponents/Search/Search';
import InternshipsListTable from './InternshipsList/InternshipsListTable';
import './InternshipsListPage.scss';
import InternshipsListPagination from './InternshipsListPagination/InternshipsListPagination';

const InternshipsListPage = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const internshipsList = useSelector(
    state => state.internshipsList.internshipsList
  );
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const dispatch = useDispatch();
  const [internshipStatusDropdownTitle, setInternshipStatusDropdownTitle] =
    useState('Sve prakse');

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    fetchInternshipsForMentorByPage();
  }, [selectedStatus]);

  const fetchInternshipsForMentorByPage = async () => {
    if (searchQuery || selectedStatus) {
      await executeRequestsWithLoading(
        [
          dispatch(
            fetchInternshipsForMentorByQuery(searchQuery, selectedStatus)
          ),
        ],
        setLoading,
        mountedRef
      );
    }
    if (!searchQuery && !selectedStatus) {
      await executeRequestsWithLoading(
        [dispatch(fetchInternshipsForMentor())],
        setLoading,
        mountedRef
      );
    }
  };

  return (
    <div className='internships-list'>
      <div className='internships-list__top-bar'>
        <DataDisplay
          dataHeader={t('dropdowns.fiterByInternshipStatusAsMentor.title')}
          headerBolded
          headerFontSize={13}
          displayInColumn
          dataSeparatorTopSpacing={6}
          removeTopSeparator
          data={
            <Dropdown
              customclass='internship-status-dropdown'
              handleSelect={item => {
                if (
                  item !==
                  t('dropdowns.fiterByInternshipStatusAsMentor.defaultOption')
                ) {
                  setInternshipStatusDropdownTitle(item.name);
                  setSelectedStatus(item.key);
                } else {
                  setInternshipStatusDropdownTitle(item);
                  setSelectedStatus(null);
                }
              }}
              list={[
                {
                  id: 0,
                  name: t('mentorInternshipPageStatuses.approved'),
                  key: 'approved',
                },
                {
                  id: 1,
                  name: t('mentorInternshipPageStatuses.mentor-review'),
                  key: 'mentor-review',
                },
                {
                  id: 2,
                  name: t('mentorInternshipPageStatuses.professor-review'),
                  key: 'professor-review',
                },
                {
                  id: 3,
                  name: t('mentorInternshipPageStatuses.failed-review'),
                  key: 'failed-review',
                },
                {
                  id: 4,
                  name: t('mentorInternshipPageStatuses.completed'),
                  key: 'completed',
                },
              ]}
              headerTitle={internshipStatusDropdownTitle}
              defaultHeaderOption={t(
                'dropdowns.fiterByInternshipStatusAsMentor.defaultOption'
              )}
            />
          }
        />
      </div>
      <div className='internships-list__list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.mentorInternshipsListPage')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={30}
          data={
            <div className='internships-list__list__search'>
              <Search
                setLoading={setLoading}
                fetchData={fetchInternshipsForMentor}
                fetchDataByName={name =>
                  fetchInternshipsForMentorByQuery(name, selectedStatus)
                }
                setSearchQuery={setSearchQuery}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          data={
            <div>
              <div className='internships-list__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {internshipsList && internshipsList.length > 0 ? (
                    <InternshipsListTable internshipsList={internshipsList} />
                  ) : searchQuery === null ? (
                    t('notFound.mentorInternshipsListPage.noListFetched')
                  ) : (
                    t('notFound.mentorInternshipsListPage.noSearchResult')
                  )}
                </div>
              </div>
              <div className='internships-list__list__pagination'>
                <InternshipsListPagination
                  fetchInternshipsListByPage={fetchInternshipsForMentorByPage}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

InternshipsListPage.propTypes = {
  companyId: PropTypes.number,
  setCompanyOib: PropTypes.func,
  setShowMentorListModal: PropTypes.func,
  setShowAddMentorModal: PropTypes.func,
  setSelectedMentor: PropTypes.func,
};

export default InternshipsListPage;
