import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkStatusForScript,
  removeDataAllExistingStudents,
  removeDataAllStudents,
  removeDataSingleStudent,
  scriptsAllDataFetched,
  scriptsExistingDataFetched,
  scriptsSingleDataFetched,
  scriptStatusCheckAllExistingStudents,
  scriptStatusCheckAllStudents,
  scriptStatusCheckSingleStudent,
  syncAllStudents,
  syncExistingStudents,
  syncStudent,
} from '../../../store/actions';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import Modal from '../../SharedComponents/Modal/Modal';
import './SynchronizationPage.scss';

const SynchronizationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const syncScriptAllStudents = useSelector(state => state.scriptsAllStudents);
  const syncScriptSingleStudent = useSelector(
    state => state.scriptsSingleStudent
  );
  const syncScriptExistingStudents = useSelector(
    state => state.scriptsExistingStudents
  );
  const [existingStudentsButtonHovered, setExistingStudentsButtonHovered] =
    useState(false);
  const [displayUserIdInput, setDisplayUserIdInput] = useState(false);
  const [studentJMBAG, setStudentJMBAG] = useState(null);
  let intervalAllStudents = useRef(null);
  let intervalExistingStudents = useRef(null);
  let intervalSingleStudent = useRef(null);

  useEffect(() => {
    if (syncScriptAllStudents.data && !syncScriptAllStudents.message) {
      intervalAllStudents.current = setInterval(() => {
        dispatch(checkStatusForScript(true, syncScriptAllStudents.data));
      }, 1500);
    }
    if (
      (syncScriptAllStudents.message &&
        syncScriptAllStudents.message !== 'STARTED') ||
      syncScriptAllStudents.status !== 200
    ) {
      clearInterval(intervalAllStudents.current);
      if (syncScriptAllStudents.status) {
        dispatch(removeDataAllStudents());
        setTimeout(() => {
          dispatch(
            scriptsAllDataFetched({
              status: null,
              data: null,
            })
          );
        }, 5000);
      }
    }
  }, [syncScriptAllStudents]);

  useEffect(() => {
    if (
      syncScriptExistingStudents.data &&
      !syncScriptExistingStudents.message
    ) {
      intervalExistingStudents.current = setInterval(() => {
        dispatch(
          checkStatusForScript(true, syncScriptExistingStudents.data, true)
        );
      }, 1500);
    }
    if (
      (syncScriptExistingStudents.message &&
        syncScriptExistingStudents.message !== 'STARTED') ||
      syncScriptExistingStudents.status !== 200
    ) {
      clearInterval(intervalExistingStudents.current);
      if (syncScriptExistingStudents.status) {
        dispatch(removeDataAllExistingStudents());
        setTimeout(() => {
          dispatch(
            scriptsExistingDataFetched({
              status: null,
              data: null,
            })
          );
        }, 5000);
      }
    }
  }, [syncScriptExistingStudents]);

  useEffect(() => {
    if (syncScriptSingleStudent.data && !syncScriptSingleStudent.message) {
      intervalSingleStudent.current = setInterval(() => {
        dispatch(checkStatusForScript(false, syncScriptSingleStudent.data));
      }, 1500);
    }
    if (
      (syncScriptSingleStudent.message &&
        syncScriptSingleStudent.message !== 'STARTED') ||
      syncScriptSingleStudent.status !== 200
    ) {
      clearInterval(intervalSingleStudent.current);
      if (syncScriptSingleStudent.status) {
        dispatch(removeDataSingleStudent());
        setTimeout(() => {
          dispatch(
            scriptsSingleDataFetched({
              status: null,
              data: null,
            })
          );
        }, 5000);
      }
    }
  }, [syncScriptSingleStudent]);

  useEffect(() => {
    if (syncScriptAllStudents.data) {
      intervalAllStudents.current = setInterval(() => {
        dispatch(checkStatusForScript(true, syncScriptAllStudents.data));
      }, 1500);
    }
    if (syncScriptExistingStudents.data) {
      intervalExistingStudents.current = setInterval(() => {
        dispatch(
          checkStatusForScript(true, syncScriptExistingStudents.data, true)
        );
      }, 1500);
    }
    if (syncScriptSingleStudent.data) {
      intervalSingleStudent.current = setInterval(() => {
        dispatch(checkStatusForScript(false, syncScriptSingleStudent.data));
      }, 1500);
    }
    return () => {
      dispatch(
        scriptStatusCheckAllStudents({
          status: null,
          data: null,
        })
      );
      dispatch(
        scriptStatusCheckAllExistingStudents({ status: null, data: null })
      );
      dispatch(scriptStatusCheckSingleStudent({ status: null, data: null }));
      if (intervalAllStudents.current) {
        clearInterval(intervalAllStudents.current);
      }
      if (intervalExistingStudents.current) {
        clearInterval(intervalExistingStudents.current);
      }
      if (intervalSingleStudent.current) {
        clearInterval(intervalSingleStudent.current);
      }
    };
  }, []);

  const checkSyncStatus = status => {
    if (status) {
      if (status === 'STARTED') {
        return;
      } else if (
        status !==
        'Skripta se ne može pokrenuti. Obratite se sistem administratoru za pomoć.'
      ) {
        return t(`syncStatuses.${status}`);
      } else {
        return status;
      }
    }
  };

  return (
    <div>
      <Breadcrumbs />
      <div className='synchronization-page'>
        <div className='synchronization-page__body'>
          <div className='synchronization-page__body__header'>
            {t('breadCrumbs.synchornizationPage')}
          </div>
          <button
            className='sync-all-sudents'
            disabled={
              !syncScriptAllStudents.message
                ? syncScriptAllStudents.data
                : syncScriptAllStudents.data &&
                  syncScriptAllStudents.message === 'STARTED'
            }
            onClick={() => {
              dispatch(syncAllStudents());
            }}
          >
            {(
              !syncScriptAllStudents.message
                ? syncScriptAllStudents.data
                : syncScriptAllStudents.data &&
                  syncScriptAllStudents.message === 'STARTED'
            ) ? (
                <div className='loader-small white' />
              ) : (
                t('buttons.syncAllStudents')
              )}
          </button>
          <div>{checkSyncStatus(syncScriptAllStudents.message)}</div>
          <button
            className='sync-single-student'
            disabled={
              !syncScriptSingleStudent.message
                ? syncScriptSingleStudent.data
                : syncScriptSingleStudent.data &&
                  syncScriptSingleStudent.message === 'STARTED'
            }
            onClick={() => {
              setDisplayUserIdInput(true);
            }}
          >
            {(
              !syncScriptSingleStudent.message
                ? syncScriptSingleStudent.data
                : syncScriptSingleStudent.data &&
                  syncScriptSingleStudent.message === 'STARTED'
            ) ? (
                <div className='loader-small white' />
              ) : (
                t('buttons.syncSingleStudent')
              )}
          </button>
          <div>{checkSyncStatus(syncScriptSingleStudent.message)}</div>
          <button
            className='sync-existing-students'
            disabled={
              !syncScriptExistingStudents.message
                ? syncScriptExistingStudents.data
                : syncScriptExistingStudents.data &&
                  syncScriptExistingStudents.message === 'STARTED'
            }
            onMouseEnter={() => setExistingStudentsButtonHovered(true)}
            onMouseLeave={() => setExistingStudentsButtonHovered(false)}
            onClick={() => {
              dispatch(syncExistingStudents());
            }}
          >
            {(
              !syncScriptExistingStudents.message
                ? syncScriptExistingStudents.data
                : syncScriptExistingStudents.data &&
                  syncScriptExistingStudents.message === 'STARTED'
            ) ? (
                <div className='loader-small white' />
              ) : existingStudentsButtonHovered ? (
                t('buttons.syncExistingStudentsHoverText')
              ) : (
                t('buttons.syncExistingStudents')
              )}
          </button>
          <div>{checkSyncStatus(syncScriptExistingStudents.message)}</div>
        </div>
        {displayUserIdInput && (
          <Modal closeModal={() => setDisplayUserIdInput(false)}>
            <div className='userid-input-modal'>
              <div className='userid-input-modal__header'>
                <div className='header-info-image'></div>
                <div className='userid-input-modal__header__header-text'>
                  <div className='header-main-text'>
                    {t('modal.studentSync.inputStudentPINmainText')}
                  </div>
                  <div className='header-small-text'>
                    {t('modal.studentSync.inputStudentPINmainTextDescription')}
                  </div>
                </div>
                <div
                  className='header-close-icon'
                  onClick={() => setDisplayUserIdInput(false)}
                ></div>
              </div>
              <div className='userid-input-modal__body'>
                <DataDisplay
                  dataHeader={t('inputs.studentPIN')}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <input
                      type='number'
                      placeholder={t('inputs.studentPINplaceholder')}
                      min={0}
                      onChange={e => setStudentJMBAG(e.target.value)}
                    />
                  }
                />
                <button
                  id='send-btn'
                  disabled={!studentJMBAG}
                  onClick={() => {
                    if (studentJMBAG) {
                      dispatch(syncStudent(studentJMBAG));
                      setDisplayUserIdInput(false);
                    }
                  }}
                >
                  {t('buttons.send')}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default SynchronizationPage;
