import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import './AlertModal.scss';

const AlertModal = props => {
  const { t } = useTranslation();
  return (
    <Modal className='modal-for-alert'>
      <div className='modal-container-alert'>
        <div className='alert-modal'>
          <div className='alert-modal__icon-alert'></div>
          <div className='alert-modal__header-text'>
            <div className='header-main-text'>
              {t('modal.alertModal.alert')}
            </div>
            <div className='header-small-text'>{props.alertInfotext}</div>
          </div>
          <div className='button-container'>
            {!props.showOnlyConfirmOption && (
              <button
                className='alert-decline-button'
                onClick={props.declineOptions}
              >
                {t('modal.alertModal.no')}
              </button>
            )}
            <button
              className='alert-confirm-button'
              onClick={props.confirmOptions}
            >
              {props.showOnlyConfirmOption
                ? t('modal.alertModal.okay')
                : t('modal.alertModal.yes')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AlertModal.propTypes = {
  alertInfotext: PropTypes.string,
  confirmOptions: PropTypes.func,
  declineOptions: PropTypes.func,
  showOnlyConfirmOption: PropTypes.bool,
};

export default AlertModal;
