import hr from 'date-fns/locale/hr';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  executeRequestsWithLoading,
  fetchAuditLogs,
  fetchAuditLogsByQuery,
} from '../../../store/actions';
import Breadcrumbs from '../../SharedComponents/Breadcrumbs/Breadcrumbs';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import Search from '../../SharedComponents/Search/Search';
import AuditList from './AuditList/AuditList';
import './AuditListPage.scss';
import AuditPagination from './AuditPagination/AuditPagination';

registerLocale('hr', hr);

const AuditListPage = () => {
  const { t } = useTranslation();
  const auditLogs = useSelector(state => state.audit.auditList);
  const mountedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [headerKey, setHeaderKey] = useState(null);
  const [sortingOrder, setSortingOrder] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [actionDropdownTitle, setActionDropdownTitle] = useState('Sve akcije');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAuditLogsByPage();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      selectedStatus ||
      endDate ||
      ((sortingOrder || !sortingOrder) && headerKey)
    ) {
      fetchAuditLogsByPage();
    }
  }, [selectedStatus, endDate, sortingOrder]);

  const setSorting = (key, order) => {
    setHeaderKey(key);
    setSortingOrder(order);
  };

  const fetchAuditLogsByPage = async () => {
    if (searchQuery || selectedStatus || endDate || headerKey) {
      await executeRequestsWithLoading(
        [
          dispatch(
            fetchAuditLogsByQuery(
              searchQuery,
              selectedStatus,
              startDate
                ? moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss')
                : null,
              endDate
                ? moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                : endDate,
              headerKey,
              sortingOrder
            )
          ),
        ],
        setLoading,
        mountedRef
      );
    }
    if (!searchQuery && !selectedStatus && !endDate && !headerKey) {
      await executeRequestsWithLoading(
        [dispatch(fetchAuditLogs())],
        setLoading,
        mountedRef
      );
    }
  };

  const actions = [
    'PROFESSOR_CREATED',
    'PROFESSOR_UPDATED',
    'MENTOR_CREATED',
    'MENTOR_UPDATED',
    'MENTOR_CHANGED',
    'SUBJECT_CREATED',
    'SUBJECT_UPDATED',
    'STUDY_CREATED',
    'STUDY_UPDATED',
    'COMPANY_INTERNSHIP_SLOT_CONFIG_DELETED',
    'COMPANY_INTERNSHIP_SLOT_CONFIG_CREATED',
    'COMPANY_INTERNSHIP_SLOT_CONFIG_UPDATED',
    'PASSWORD_UPDATE',
    'PASSWORD_UPDATE_BY_ADMIN',
    'CERTIFICATE_OF_EMPLOYMENT_UPLOADED',
    'CERTIFICATE_OF_EMPLOYMENT_DELETED',
    'CERTIFICATE_OF_EMPLOYMENT_APPROVED_BY_PROFESSOR_OR_ADMIN',
    'CERTIFICATE_OF_EMPLOYMENT_REJECTED_BY_PROFESSOR_OR_ADMIN',
    'ACCEPTED_INTERNSHIP_REQUEST',
    'INTERNSHIP_CREATED',
    'ACTIVATED_INTERNSHIP',
    'INTERNSHIP_DELETED',
    'INTERNSHIP_SUBMITTED_TO_REVIEW',
    'INTERNSHIP_APPROVED_BY_MENTOR',
    'INTERNSHIP_REJECTED_BY_MENTOR',
    'INTERNSHIP_APPROVED_BY_PROFESSOR_OR_ADMIN',
    'INTERNSHIP_REJECTED_BY_PROFESSOR_OR_ADMIN',
    'TRIGGERED_SYNC_ONE_STUDENT',
    'TRIGGERED_SYNC_ALL_STUDENTS',
    'TRIGGERED_SYNC_EXISTING_STUDENTS',
    'FAILED_SYNC_ONE_STUDENT',
    'FAILED_SYNC_ALL_STUDENTS',
    'FAILED_SYNC_EXISTING_STUDENTS',
    'FINISHED_WITH_ERRORS_SYNC_ONE_STUDENT',
    'FINISHED_WITH_ERRORS_SYNC_ALL_STUDENTS',
    'FINISHED_WITH_ERRORS_SYNC_EXISTING_STUDENTS',
    'FINISHED_SYNC_ONE_STUDENT',
    'FINISHED_SYNC_ALL_STUDENTS',
    'FINISHED_SYNC_EXISTING_STUDENTS',
  ];

  const checkAction = action => {
    return t(`auditListActions.${action}`);
  };

  return (
    <div className='audit-list'>
      <div className='audit-list__top-bar'>
        <DataDisplay
          dataHeader={t('dropdowns.filteringByAuditActions.title')}
          headerBolded
          headerFontSize={13}
          displayInColumn
          dataSeparatorTopSpacing={6}
          removeTopSeparator
          data={
            <Dropdown
              customclass='audit-action-type-dropdown'
              handleSelect={item => {
                if (
                  item !== t('dropdowns.filteringByAuditActions.defaultOption')
                ) {
                  setActionDropdownTitle(item.name);
                  setSelectedStatus(item.key);
                } else {
                  setActionDropdownTitle(item);
                  setSelectedStatus(item);
                }
              }}
              list={[
                ...actions.map((action, index) => {
                  return {
                    id: index,
                    name: checkAction(action),
                    key: action,
                  };
                }),
              ]}
              headerTitle={actionDropdownTitle}
              defaultHeaderOption={t(
                'dropdowns.filteringByAuditActions.defaultOption'
              )}
            />
          }
        />
        <div className='audit-list__top-bar__datepicker-wrapper'>
          <DataDisplay
            dataHeader={t('dropdowns.filteringByDateAuditLogs.title')}
            headerBolded
            headerFontSize={13}
            displayInColumn
            dataSeparatorTopSpacing={6}
            removeTopSeparator
            data={
              <>
                <div className='date-range'>
                  <div className='datepicker'>
                    <DatePicker
                      onClickOutside={() => {
                        setDateRange([null, null]);
                      }}
                      disabledKeyboardNavigation
                      locale='hr'
                      className='datepicker-range'
                      calendarContainer={({
                        className,
                        children,
                        showPopperArrow,
                      }) =>
                        DatepickerCustomContainer(
                          {
                            className,
                            children,
                            mainHeaderText: t(
                              'dropdowns.filteringByDateAuditLogs.mainHeaderText'
                            ),
                            smallHeaderText: t(
                              'dropdowns.filteringByDateAuditLogs.descriptionHeaderText'
                            ),
                            showPopperArrow,
                          },
                          () => {
                            setDateRange([null, null]);
                          }
                        )
                      }
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={update => {
                        setDateRange(update);
                      }}
                      shouldCloseOnSelect
                      withPortal
                      monthsShown={2}
                      showPopperArrow={false}
                      selected={startDate}
                      fixedHeight
                      value={
                        (startDate || endDate) &&
                        `${
                          startDate
                            ? moment(startDate).format('DD.MM.YYYY.')
                            : ''
                        } - ${
                          endDate ? moment(endDate).format('DD.MM.YYYY.') : ''
                        }`
                      }
                    />
                    <div className='non-clickable-area' />
                    {startDate && (
                      <div
                        className='clear-button'
                        onClick={() => {
                          setDateRange([null, null]);
                          dispatch(
                            fetchAuditLogsByQuery(
                              searchQuery,
                              selectedStatus,
                              null,
                              null,
                              headerKey,
                              sortingOrder
                            )
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
      <Breadcrumbs />
      <div className='audit-list__list'>
        <DataDisplay
          dataHeader={t('breadCrumbs.auditList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          TopSpacing={30}
          floatDataRight
          data={
            <div className='audit-list__list__search'>
              <Search
                searchingByInfo={t('inputs.searchByInfo.auditList')}
                setLoading={setLoading}
                fetchData={() => {
                  return fetchAuditLogsByQuery(
                    null,
                    selectedStatus,
                    startDate
                      ? moment(startDate)
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    endDate
                      ? moment(endDate)
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')
                      : endDate
                  );
                }}
                fetchDataByName={name => {
                  return fetchAuditLogsByQuery(
                    name,
                    selectedStatus,
                    startDate
                      ? moment(startDate)
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')
                      : null,
                    endDate
                      ? moment(endDate)
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')
                      : endDate
                  );
                }}
                setSearchQuery={setSearchQuery}
              />
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          data={
            <div>
              <div className='audit-list__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {auditLogs && auditLogs.length > 0 ? (
                    <AuditList
                      checkAction={checkAction}
                      auditLogs={auditLogs}
                      setSorting={setSorting}
                    />
                  ) : searchQuery === null ? (
                    t('notFound.auditList.noListFetched')
                  ) : (
                    t('notFound.auditList.noSearchResult')
                  )}
                </div>
              </div>
              <div className='audit-list__list__pagination'>
                <AuditPagination fetchAuditLogsByPage={fetchAuditLogsByPage} />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AuditListPage;
