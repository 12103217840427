import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './FileUploads.scss';
import { useDispatch } from 'react-redux';
import { deleteCurrentLogImage } from '../../../store/actions';

const ImageUpload = props => {
  const dispatch = useDispatch();
  const [imageSizeError, setImageSizeError] = useState(null);
  const [imageExtensionError, setImageExtensionError] = useState({
    image: null,
    error: false,
  });
  const inputs = ['firstImage', 'secondImage'];
  const firstImageRef = useRef(null);
  const secondImageRef = useRef(null);

  return (
    <div className='image-inputs'>
      {props.fetchedCurrentLogImages && (
        <div className='images-container'>
          {props.fetchedCurrentLogImages &&
            props.fetchedCurrentLogImages.length > 0 &&
            props.fetchedCurrentLogImages.map((image, index) => {
              return (
                <div key={index} className='current-log-image-view'>
                  <img
                    onClick={() => {
                      window.open(image.data, '_blank');
                    }}
                    src={image.data}
                    className='image-btn'
                    alt={image.imageId}
                  />
                  <button
                    className='current-log-delete-button'
                    onClick={() =>
                      dispatch(deleteCurrentLogImage(image.imageId))
                    }
                  >
                    Obriši
                  </button>
                </div>
              );
            })}
        </div>
      )}
      {props.multipleUploads ? (
        <>
          {inputs.map(
            (image, index) =>
              inputs.length !== props.fetchedCurrentLogImages?.length &&
              index !== props.fetchedCurrentLogImages?.length - 1 && (
                <div className={image} key={image}>
                  {image === 'firstImage' && props.imageFile.firstImage ? (
                    <img
                      className='image-btn'
                      src={URL.createObjectURL(props.imageFile.firstImage)}
                      alt='firstImage'
                    />
                  ) : image === 'secondImage' && props.imageFile.secondImage ? (
                    <img
                      className='image-btn'
                      src={URL.createObjectURL(props.imageFile.secondImage)}
                      alt='secondImage'
                    />
                  ) : (
                    <label htmlFor={image} className='image-btn'>
                      <div className='add-icon' />
                    </label>
                  )}
                  <input
                    type='file'
                    id={image}
                    className={image}
                    style={{ display: 'none' }}
                    name={image}
                    ref={
                      image === 'firstImage' ? firstImageRef : secondImageRef
                    }
                    onClick={() => {
                      setImageExtensionError({ image: null, error: false });
                      setImageSizeError(null);
                    }}
                    accept='image/png, image/jpg, image/jpeg'
                    onChange={e => {
                      if (e.target.files[0]) {
                        if (
                          (e.target.files[0].size / (1024 * 1024)).toFixed(2) <
                          3
                        ) {
                          if (
                            e.target.files[0].type !== 'image/png' &&
                            e.target.files[0].type !== 'image/jpg' &&
                            e.target.files[0].type !== 'image/jpeg'
                          ) {
                            setImageExtensionError({
                              image: image,
                              error: true,
                            });
                            return;
                          }
                          setImageSizeError(null);
                          if (image === 'firstImage') {
                            props.setImageFile({
                              firstImage: e.target.files[0],
                              secondImage: props.imageFile?.secondImage,
                            });
                          } else {
                            props.setImageFile({
                              secondImage: e.target.files[0],
                              firstImage: props.imageFile?.firstImage,
                            });
                          }
                        } else {
                          setImageSizeError(image);
                        }
                      }
                    }}
                  />
                  {imageSizeError === image && (
                    <div className='error-image'>
                      Slika je veća od 3MB, molimo odaberite manju sliku
                    </div>
                  )}
                  {imageExtensionError.image === image &&
                    imageExtensionError.error && (
                    <div className='error-image'>
                        Slika nije ispravnog formata
                    </div>
                  )}
                  {props.imageFile && props.imageFile[image] && (
                    <button
                      className={`remove-${image}`}
                      name={`remove-${image}`}
                      onClick={() => {
                        setImageExtensionError({ image: null, error: false });
                        setImageSizeError(null);
                        if (image === 'firstImage') {
                          firstImageRef.current.value = '';
                          props.setImageFile({
                            firstImage: null,
                            secondImage: props.imageFile?.secondImage,
                          });
                        } else {
                          secondImageRef.current.value = '';
                          props.setImageFile({
                            firstImage: props.imageFile?.firstImage,
                            secondImage: null,
                          });
                        }
                      }}
                    >
                      Obriši
                    </button>
                  )}
                </div>
              )
          )}
        </>
      ) : (
        <input
          type='file'
          name='imageUpload'
          accept='image/png, image/jpg, image/jpeg'
        />
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  fetchedCurrentLogImages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
  multipleUploads: PropTypes.bool,
  setImageFile: PropTypes.func.isRequired,
  imageFile: PropTypes.shape({
    firstImage: PropTypes.shape({
      name: PropTypes.string,
    }),
    secondImage: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

export default ImageUpload;
