import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../SharedComponents/Pagination/Pagination';
import { setPageAndPerPageInternshipRequestsList } from '../../../../store/actions';

const InternshipRequestsListPagination = props => {
  const pages = useSelector(state => state.internshipRequests.totalPages);
  const dataPerPage = useSelector(state => state.internshipRequests.perPage);
  const page = useSelector(state => state.internshipRequests.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageInternshipRequestsList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      dataPerPage={dataPerPage}
      page={page}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.fetchInternshipRequestsListByPage}
    />
  );
};

InternshipRequestsListPagination.propTypes = {
  fetchInternshipRequestsListByPage: PropTypes.func,
};

export default InternshipRequestsListPagination;
