import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Modal from '../Modal/Modal';
import './ReasonModal.scss';

export const ReasonModal = props => {
  const [reason, setReason] = useState('');

  const handleSend = () => {
    props.sendReasonData(reason);
    props.setShowReasonModal(false);
  };

  return (
    <Modal closeModal={() => props.setShowReasonModal(false)}>
      <div className='send-reason-modal'>
        <div className='send-reason-modal__header'>
          <div className='header-info-image'></div>
          <div className='send-reason-modal__header__header-text'>
            <div className='header-main-text'>Razlog odbijanja prakse</div>
            <div className='header-small-text'>
              Unesite razlog odbijanja prakse
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              props.setShowReasonModal(false);
            }}
          ></div>
        </div>

        <div className='send-reason-modal__body'>
          <textarea
            name='reason-content'
            id='reason-content'
            className='add-reason-input'
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
          <button
            className='send-reason-modal__body__send-button'
            id='send-button'
            onClick={() => handleSend()}
            disabled={reason === ''}
          >
            Spremi
          </button>
        </div>
      </div>
    </Modal>
  );
};

ReasonModal.propTypes = {
  setShowReasonModal: PropTypes.func.isRequired,
  sendReasonData: PropTypes.func.isRequired,
};
