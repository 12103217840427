import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMentor,
  companyDetailsFetched,
  executeRequestsWithLoading,
  fetchCompaniesByNameProfessor,
  fetchCompaniesProfessor,
  fetchCompanyById,
  fetchCompanyByOib,
  regenerateMentorPassword,
  setPageAndPerPageCompaniesList,
  updateMentor,
} from '../../../store/actions';
import DataDisplay from '../DataDisplay/DataDisplay';
import { Dropdown } from '../Dropdown/Dropdown';
import Modal from '../Modal/Modal';
import './AddOrUpdateMentorModal.scss';

export const AddOrUpdateMentorModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const user = useSelector(state => state.user);
  const companies = useSelector(state => state.companies.data);
  const companiesPage = useSelector(state => state.companies);
  const addMentorError = useSelector(state => state.mentors.error);
  const addMentorStatus = useSelector(state => state.mentors.status);
  const companyDetails = useSelector(state => state.companies.companyDetails);
  const [companyDropdownTitle, setCompanyDropdownTitle] = useState(
    t('dropdowns.companiesDropdown.defaultOption')
  );
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mentor, setMentor] = useState(
    props.mentor
      ? { ...props.mentor }
      : {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyOib: '',
        active: false,
      }
  );

  const handleSend = async () => {
    if (props.mentor && !props.studentMentorSelect) {
      await executeRequestsWithLoading(
        [dispatch(updateMentor(mentor, props.setLoading))],
        setModalLoading,
        mountedRef
      );
    } else {
      await executeRequestsWithLoading(
        [
          dispatch(
            addMentor(
              mentor,
              props.studentMentorSelect,
              props.companyId,
              props.setLoading
            )
          ),
        ],
        setModalLoading,
        mountedRef
      );
    }
  };

  useEffect(() => {
    if (addMentorStatus === 201 || addMentorStatus === 200) {
      props.setShowAddMentorModal(false);
    }
  }, [addMentorStatus]);

  useEffect(() => {
    if (companyDetails) {
      setMentor({ ...mentor, companyOib: companyDetails.oib });
    }
  }, [companyDetails]);

  const fetchCompany = async () => {
    if (props.selectedCompany) {
      await executeRequestsWithLoading(
        [dispatch(fetchCompanyById(props.selectedCompany))],
        setLoading,
        mountedRef
      );
    }
    if (props.companyId) {
      await executeRequestsWithLoading(
        [dispatch(fetchCompanyById(props.companyId))],
        setLoading,
        mountedRef
      );
    }
    if (props.mentor && props.mentor.companyOib) {
      await executeRequestsWithLoading(
        [dispatch(fetchCompanyByOib(props.mentor.companyOib))],
        setLoading,
        mountedRef
      );
    }
  };
  useEffect(() => {
    fetchCompany();
    return () => {
      mountedRef.current = false;
      if (props.mentor && !props.studentMentorSelect) {
        props.setMentor(null);
      }
      dispatch({
        type: 'SET_ERROR_MENTOR',
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch({
        type: 'FETCHED_MENTOR_INFO',
        payload: null,
      });
      dispatch(companyDetailsFetched(null));
    };
  }, []);

  const fetchComapniesByPage = async searchQuery => {
    await executeRequestsWithLoading(
      searchQuery !== null && searchQuery !== ''
        ? [dispatch(fetchCompaniesByNameProfessor(searchQuery))]
        : [dispatch(fetchCompaniesProfessor())],
      null,
      mountedRef
    );
  };

  return (
    <Modal
      className='add-mentor-modal'
      closeModal={() => props.setShowAddMentorModal(false)}
    >
      <div className='modal-container-add-mentor-modal'>
        <div className='modal-container-add-mentor-modal__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-add-mentor-modal__header__header-text'>
            <div className='header-main-text'>
              {props.mentor && !props.studentMentorSelect
                ? t('modal.mentorModal.mainHeader.editMentor')
                : t('modal.mentorModal.mainHeader.addMentor')}
            </div>
            <div className='header-small-text'>
              {props.mentor && !props.studentMentorSelect
                ? t('modal.mentorModal.descriptionHeader.editMentor')
                : t('modal.mentorModal.descriptionHeader.addMentor')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowAddMentorModal(false)}
          ></div>
        </div>
        <div className='modal-container-add-mentor-modal__body'>
          {modalLoading && (
            <div
              className='modal-container-add-mentor-modal__body__loading'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='loader-small' />
            </div>
          )}
          <div hidden={modalLoading}>
            <DataDisplay
              dataHeader={t('inputs.firstName')}
              dataClass='mentor-first-name'
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    value={mentor.firstName}
                    onChange={e =>
                      setMentor({ ...mentor, firstName: e.target.value })
                    }
                  />
                  {addMentorError && addMentorError['firstName'] && (
                    <div className='error'>
                      {addMentorError['firstName'].notEmpty}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.lastName')}
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              dataClass='mentor-last-name'
              data={
                <>
                  <input
                    value={mentor.lastName}
                    onChange={e =>
                      setMentor({ ...mentor, lastName: e.target.value })
                    }
                  />
                  {addMentorError && addMentorError['lastName'] && (
                    <div className='error'>
                      {addMentorError['lastName'].notEmpty}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.email')}
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              dataClass='mentor-email'
              data={
                <>
                  <input
                    type='email'
                    value={mentor.email}
                    onChange={e =>
                      setMentor({ ...mentor, email: e.target.value })
                    }
                  />
                  {addMentorError && addMentorError.email && (
                    <div className='error'>
                      {addMentorError.email.email}
                      {addMentorError.email.unique}
                      {addMentorError.email.notEmpty}
                    </div>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.mobilePhone')}
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              dataClass='mentor-phone'
              data={
                <>
                  <input
                    value={mentor.phone}
                    onChange={e =>
                      setMentor({ ...mentor, phone: e.target.value })
                    }
                  />
                  {addMentorError && addMentorError['phone'] && (
                    <div className='error'>{addMentorError['phone'].exist}</div>
                  )}
                </>
              }
            />
            {companies && (
              <DataDisplay
                dataClass='company-oib'
                dataHeader={
                  props.mentor
                    ? t('dropdowns.companiesDropdown.singleCompany')
                    : t('dropdowns.companiesDropdown.listOfCompanies')
                }
                headerBolded
                displayInColumn
                dataSeparatorTopSpacing={4}
                data={
                  !modalLoading && loading ? (
                    <div className='loader-small' />
                  ) : (
                    <div>
                      <Dropdown
                        customclass='companies-dropdown'
                        fullWidth
                        inputSuggestions
                        disabledInputSuggestion={
                          companyDetails && props.mentor
                            ? true
                            : props.studentMentorSelect
                              ? true
                              : false
                        }
                        suggestionPlaceholder={t(
                          'dropdowns.companiesDropdown.inputCompanyNameOrChooseCompany'
                        )}
                        fetchSuggestions={name => fetchComapniesByPage(name)}
                        pages={{
                          page: companiesPage.page,
                          totalPages: companiesPage.totalPages,
                        }}
                        fetchPage={async (page, name, dropdownSetLoading) => {
                          dispatch(setPageAndPerPageCompaniesList(page, 10));
                          await executeRequestsWithLoading(
                            name !== null && name !== ''
                              ? [dispatch(fetchCompaniesByNameProfessor(name))]
                              : [dispatch(fetchCompaniesProfessor())],
                            dropdownSetLoading,
                            mountedRef
                          );
                        }}
                        handleSelect={item => {
                          setMentor({
                            ...mentor,
                            companyOib: item.oib ? item.oib : null,
                          });
                          setCompanyDropdownTitle(item.name ? item.name : item);
                        }}
                        list={[
                          ...companies.map(company => {
                            return {
                              id: company.id,
                              name: company.name,
                              oib: company.oib,
                            };
                          }),
                        ]}
                        headerTitle={
                          companyDetails
                            ? companyDetails.name
                            : companyDropdownTitle
                        }
                        defaultHeaderOption={t(
                          'dropdowns.companiesDropdown.defaultOption'
                        )}
                      />
                      {addMentorError && addMentorError['companyOib'] && (
                        <div>
                          <div className='error'>
                            {addMentorError['companyOib'].exist}
                          </div>
                          <div className='error'>
                            {addMentorError['companyOib'].notEmpty}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                }
              />
            )}
            <DataDisplay
              dataHeader={t('inputs.mentorActive')}
              dataClass='mentor-active'
              headerBolded
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    type='checkbox'
                    id='mentor-active'
                    name='mentor-active'
                    checked={mentor.active}
                    onChange={e =>
                      setMentor({
                        ...mentor,
                        active: e.target.checked,
                      })
                    }
                  />
                </>
              }
            />
            {addMentorError && addMentorError['active'] && (
              <div className='error'>{addMentorError['active'].notNull}</div>
            )}
          </div>
          <div className='button-container'>
            {user.role === 'admin' && mentor.id && (
              <button
                className='modal-container-add-mentor-modal__reset-password'
                type='button'
                onClick={e => {
                  e.preventDefault();
                  dispatch(regenerateMentorPassword(mentor.id));
                }}
              >
                {t('buttons.resetPassword')}
              </button>
            )}
            <button
              className='modal-container-add-mentor-modal__send-button'
              type='button'
              onClick={e => {
                e.preventDefault();
                handleSend();
              }}
            >
              {props.mentor && !props.studentMentorSelect
                ? t('buttons.update')
                : t('buttons.save')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddOrUpdateMentorModal.propTypes = {
  studentMentorSelect: PropTypes.bool,
  setShowAddMentorModal: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  selectedCompany: PropTypes.number,
  setMentor: PropTypes.func,
  mentor: PropTypes.object,
  setLoading: PropTypes.func,
};
