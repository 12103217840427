import { PURGE } from 'redux-persist/lib/constants';
import {
  COMPANIES_FETCHED,
  SET_ERROR_COMPANIES,
  SET_PAGE_PER_PAGE_COMPANIES_LIST,
  SET_COMPANY_SLOT_CONFIGURATION,
  SET_ERROR_COMPANIES_SLOTS,
  COMPANY_DETAILS_FETCHED,
} from '../types';

const initialState = {
  totalPages: null,
  page: 1,
  perPage: 10,
  data: [],
  error: null,
  status: null,
  companiesSlotsStatus: null,
  companyDetails: null,
  message: null,
};

export const companies = (state = initialState, action) => {
  switch (action.type) {
    case COMPANIES_FETCHED: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case SET_PAGE_PER_PAGE_COMPANIES_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case SET_ERROR_COMPANIES: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case SET_ERROR_COMPANIES_SLOTS: {
      return {
        ...state,
        error: action.payload.error,
        companiesSlotsStatus: action.payload.status,
      };
    }
    case COMPANY_DETAILS_FETCHED: {
      return {
        ...state,
        companyDetails: action.payload.data,
      };
    }
    case SET_COMPANY_SLOT_CONFIGURATION: {
      return {
        ...state,
        companySlotConfiguration: action.payload.data,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
