const makeCancelable = async (
  promise,
  setLoading,
  isMounted,
  isExternalLoading
) => {
  let hasCanceled_ = false;

  const wrappedPromise = await new Promise((resolve, reject) => {
    promise.then(val => {
      if (hasCanceled_) {
        if (isExternalLoading) {
          if (setLoading) {
            setLoading(false);
          }
        }
        resolve(val);
      } else {
        if (isExternalLoading) {
          if (setLoading) {
            setLoading(false);
          }
        } else {
          if (isMounted.current && setLoading) {
            setLoading(false);
          }
        }
        resolve(val);
      }
    });
    promise.catch(error => {
      if (hasCanceled_) {
        if (isExternalLoading) {
          if (setLoading) {
            setLoading(false);
          }
        }
        reject(error);
      } else {
        if (isExternalLoading) {
          if (setLoading) {
            setLoading(false);
          }
        } else {
          if (isMounted.current && setLoading) {
            setLoading(false);
          }
        }
        reject(error);
      }
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const executeRequestsWithLoading = async (
  requestsList,
  setLoading,
  isMounted,
  isExternalLoading
) => {
  if (setLoading) {
    setLoading(true);
  }
  return await makeCancelable(
    Promise.allSettled(requestsList),
    setLoading,
    isMounted,
    isExternalLoading
  );
};
