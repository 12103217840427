import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addCommentToInternshipLog } from '../../../../store/actions';
import * as Images from '../../../../styles/assets/images';
import DataDisplay from '../../../SharedComponents/DataDisplay/DataDisplay';
import './InternshipComments.scss';

export const InternshipComments = props => {
  const { t } = useTranslation();
  const { comments, user, handleResolve, handleEdit, internshipStatus } = props;
  const canAddComment =
    user.role.toLowerCase() === 'admin' ||
    user.role.toLowerCase() === 'professor';

  const [addNewComment, setAddNewComment] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [newCommentContent, setNewCommentContent] = useState('');
  const [commentToUpdate, setCommentToUpdate] = useState({
    id: null,
    comment: '',
  });

  const dispatch = useDispatch();

  const handleSaveComment = () => {
    if (addNewComment) {
      dispatch(addCommentToInternshipLog(newCommentContent));
      setAddNewComment(false);
      setNewCommentContent('');
    }
    if (editComment) {
      handleEdit(commentToUpdate.id, commentToUpdate.comment);
      setEditComment(false);
    }
    setCommentToUpdate({
      id: null,
      comment: '',
    });
  };

  return (
    <div className='internship-comments'>
      <div
        className='internship-comments__header'
        onClick={() => setShowComments(!showComments)}
      >
        <img
          className='internship-comments__header__img'
          src={Images.CommentIcon}
        />
        <div className='internship-comments__header__label'>
          {t('studentHomepage.internshipLogs.comments.title', {
            numberOfComments: comments ? comments.length : 0,
          })}
        </div>
      </div>
      {showComments && (
        <div className='internship-comments__body'>
          {!addNewComment && canAddComment && internshipStatus !== 'COMPLETED' && (
            <button
              type='button'
              className='internship-comments__body__add-btn'
              onClick={() => setAddNewComment(true)}
            >
              {t('buttons.addNewComment')}
            </button>
          )}
          {(comments.length === 0 || !comments) && (
            <div className='internship-comments__body__no-comments'>
              {t('studentHomepage.internshipLogs.comments.noComments')}
            </div>
          )}
          {canAddComment && addNewComment && (
            <div className='internship-comments__body__new-comment'>
              <textarea
                className='internship-comments__body__new-comment__input'
                value={
                  editComment ? commentToUpdate.comment : newCommentContent
                }
                onChange={e => {
                  if (editComment) {
                    setCommentToUpdate({
                      ...commentToUpdate,
                      comment: e.target.value,
                    });
                  } else {
                    setNewCommentContent(e.target.value);
                  }
                }}
              />
              <div className='internship-comments__body__new-comment__btn-container'>
                <button
                  type='button'
                  className='internship-comments__body__new-comment__btn-container__btn'
                  disabled={
                    commentToUpdate.comment
                      ? commentToUpdate.comment === ''
                      : newCommentContent === ''
                  }
                  onClick={handleSaveComment}
                  id='save'
                >
                  {editComment
                    ? t('buttons.editComment')
                    : t('buttons.saveComment')}
                </button>
                <button
                  type='button'
                  className='internship-comments__body__new-comment__btn-container__btn'
                  onClick={() => {
                    setAddNewComment(false);
                    setEditComment(false);
                    setNewCommentContent(null);
                  }}
                  id='cancel'
                >
                  {t('buttons.cancel')}
                </button>
              </div>
            </div>
          )}
          {comments.map(comment => (
            <DataDisplay
              key={comment.id}
              dataHeader={
                <img
                  className='comment-flag'
                  src={
                    comment.resolved
                      ? Images.ResolvedWithFrame
                      : Images.FlaggedWithFrame
                  }
                />
              }
              centerHeaderVertically
              alignHeader='end'
              headerWidth={230}
              dataFullWidth
              data={
                <div
                  className={classNames({
                    'internship-comments__body__comment': true,
                    isResolved: comment.resolved,
                  })}
                  key={comment.id}
                >
                  <div className='internship-comments__body__comment__header'>
                    <div className='internship-comments__body__comment__header__author'>
                      {comment.name}
                    </div>
                    <div className='internship-comments__body__comment__header__date'>
                      {moment(comment.updatedAt).format('DD.MM.YYYY. u hh:mm')}
                    </div>
                  </div>
                  <div className='internship-comments__body__comment__text'>
                    {comment.id !== commentToUpdate.id && comment.comment}
                  </div>
                  {editComment &&
                    commentToUpdate &&
                    comment.id === commentToUpdate.id && (
                    <div className='internship-comments__body__new-comment'>
                      <textarea
                        className='internship-comments__body__new-comment__input'
                        value={
                          editComment
                            ? commentToUpdate.comment
                            : newCommentContent
                        }
                        onChange={e => {
                          if (editComment) {
                            setCommentToUpdate({
                              ...commentToUpdate,
                              comment: e.target.value,
                            });
                          } else {
                            setNewCommentContent(e.target.value);
                          }
                        }}
                      />
                      <div className='internship-comments__body__new-comment__btn-container'>
                        <button
                          type='button'
                          className='internship-comments__body__new-comment__btn-container__btn'
                          disabled={
                            commentToUpdate.comment
                              ? commentToUpdate.comment === ''
                              : newCommentContent === ''
                          }
                          onClick={handleSaveComment}
                          id='save'
                        >
                          {t('buttons.editComment')}
                        </button>
                        <button
                          type='button'
                          className='internship-comments__body__new-comment__btn-container__btn'
                          onClick={() => {
                            setEditComment(false);
                            setCommentToUpdate({ id: null, comment: '' });
                          }}
                          id='cancel'
                        >
                          {t('buttons.cancel')}
                        </button>
                      </div>
                    </div>
                  )}
                  {canAddComment && comment.id !== commentToUpdate.id && (
                    <div className='internship-comments__body__comment__actions'>
                      {internshipStatus !== 'COMPLETED' && (
                        <button
                          className='internship-comments__body__comment__actions__edit'
                          onClick={() => {
                            setCommentToUpdate(comment);
                            setEditComment(true);
                          }}
                        >
                          {t('buttons.edit')}
                        </button>
                      )}
                    </div>
                  )}
                  {canAddComment && (
                    <div
                      className={classNames({
                        resolvingCommentButton: true,
                        commentIsResolvedButton: comment.resolved,
                        commentIsNotResolvedButton: !comment.resolved,
                      })}
                      onClick={() => {
                        if (!comment.resolved) {
                          handleResolve(comment.id);
                        }
                      }}
                    >
                      {comment.resolved
                        ? t('resolved')
                        : internshipStatus !== 'COMPLETED' && t('buttons.markAsResolveComment')}
                    </div>
                  )}
                </div>
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

InternshipComments.propTypes = {
  comments: PropTypes.array,
  user: PropTypes.shape(),
  handleResolve: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  internshipStatus: PropTypes.string,
};
