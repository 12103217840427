import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import {
  checkIfStudentPassedTheSubjectAsProfessor,
  checkIfStudentPassedTheSubjectAsStudent,
  fetchCompanies,
} from '.';
import i18n from '../../i18n';
import settings from '../../settings';
import {
  CERTIFICATE_OF_EMPLOYMENT_FETCHED,
  CURRENT_LOG_DETAILS_FETCHED,
  CURRENT_LOG_UPDATED,
  INTERNSHIPS_FOR_MENTOR_FETCHED,
  INTERNSHIP_LOGS_FETCHED,
  INTERNSHIP_LOG_IMAGES_FETCHED,
  INTERNSHIP_RATINGS_FETCHED,
  INTERNSHIP_REQUESTS_FETCHED,
  INTERNSHIP_STUDENT_FETCHED,
  RESET_INTERNSHIP_LOG_IMAGES_FETCHED,
  SET_ERROR_INTERNSHIP,
  SET_INTERNSHIP_DOCUMENT_STATUSES,
  SET_PAGE_PER_PAGE_INTERNSHIPS_LIST,
  SET_RETRY_GENERATION,
  SHOW_INTERNSHIP_DETAILS,
} from '../types';

export const fetchInternshipStudent = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/for-student/${
          getState().subjects.selectedSubject.id
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(checkIfStudentPassedTheSubjectAsStudent());
      return dispatch(internshipStudentFetched(response.data.data));
    } catch (e) {
      await dispatch(checkIfStudentPassedTheSubjectAsStudent());
      return dispatch(internshipStudentFetched(null));
    }
  };
};

export const fetchInternshipStudentProfessor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/subjects/${
          getState().subjects.selectedSubject.id
        }/students/${getState().student.id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(checkIfStudentPassedTheSubjectAsProfessor());
      return dispatch(internshipStudentFetched(response.data.data));
    } catch (e) {
      await dispatch(checkIfStudentPassedTheSubjectAsProfessor());
      return dispatch(internshipStudentFetched(null));
    }
  };
};

export const fetchInternshipsForMentor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internships/for-mentor?page=${
          getState().internshipsList.page
        }&per_page=${getState().internshipsList.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageInternshipsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchInternshipsForMentor());
      } else {
        dispatch(internshipsForMentorFetched(response.data.data));
      }
    } catch (e) {
      return dispatch(internshipsForMentorFetched(null));
    }
  };
};

export const fetchInternshipsForMentorByQuery = (name, status) => {
  return async (dispatch, getState) => {
    let query = '';
    if (name) query += `&search=${name}`;
    if (status) query += `&status=${status}`;
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internships/for-mentor?page=${
          getState().internshipsList.page
        }&per_page=${getState().internshipsList.perPage}${query}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageInternshipsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchInternshipsForMentorByQuery(name, status));
      } else {
        dispatch(internshipsForMentorFetched(response.data.data));
      }
    } catch (e) {
      return dispatch(internshipsForMentorFetched(null));
    }
  };
};

export const fetchInternshipInfo = internshipId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${internshipId}/info`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return await dispatch(internshipStudentFetched(response.data.data));
    } catch (e) {
      return dispatch(internshipStudentFetched(null));
    }
  };
};

export const mentorApproveInternship = (signature, internshipId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/mentor-approve`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          signature,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipInfo(internshipId));
    } catch (e) {
      if (getState().user.role !== 'mentor') {
        if (e.response) {
          return dispatch({
            type: SET_ERROR_INTERNSHIP,
            payload: {
              error: e.response?.data?.message
                ? e.response?.data?.message
                : null,
              status: e.response.status,
            },
          });
        }
      }
      return await dispatch(fetchInternshipsForMentor());
    }
  };
};

export const mentorRejectInternship = (reason, internshipId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/mentor-reject`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          reason,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipInfo(internshipId));
    } catch (e) {
      if (getState().user.role !== 'mentor') {
        if (e.response) {
          return dispatch({
            type: SET_ERROR_INTERNSHIP,
            payload: {
              error: e.response?.data?.message
                ? e.response?.data?.message
                : null,
              status: e.response.status,
            },
          });
        }
      }
      return await dispatch(fetchInternshipsForMentor());
    }
  };
};

export const professorApproveInternship = (signature, internshipId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/professor-approve`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          signature,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipInfo(internshipId));
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const professorRejectInternship = (reason, internshipId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/professor-reject`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          reason,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipInfo(internshipId));
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const internshipsForMentorFetched = data => ({
  type: INTERNSHIPS_FOR_MENTOR_FETCHED,
  payload: data,
});

export const setPageAndPerPageInternshipsList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_INTERNSHIPS_LIST,
  payload: { page, perPage },
});

export const addMentorToProcessingInternshipAndActivate = (
  mentorId,
  signature,
  allRequestsList,
  resetInterval
) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/activate`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          mentorId,
          signature,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      if (!allRequestsList) {
        dispatch(internshipStudentFetched(response.data.data));
        if (resetInterval) {
          resetInterval();
        }
      }
    } catch (e) {
      return dispatch(internshipStudentFetched(null));
    }
  };
};

export const changeMentorForInternship = mentorId => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/change-mentor`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          mentorId,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch(internshipStudentFetched(response.data.data));
    } catch (e) {
      return dispatch(internshipStudentFetched(null));
    }
  };
};

export const internshipStudentFetched = data => ({
  type: INTERNSHIP_STUDENT_FETCHED,
  payload: data,
});

export const fetchInternshipRequests = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internship-requests/for-student-subject/${
          getState().subjects.selectedSubject.id
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(internshipRequestsFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        if (error.response) {
          return dispatch({
            type: SET_ERROR_INTERNSHIP,
            payload: {
              error:
                error.response &&
                error.response.data &&
                error.response?.data?.data
                  ? error.response?.data?.data
                  : null,
              status: error.response && error.response.status,
            },
          });
        }
      }
    }
  };
};

export const fetchInternshipRequestsProfessor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/internship-requests/subjects/${
          getState().subjects.selectedSubject.id
        }/students/${getState().student.id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(internshipRequestsFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const internshipRequestsFetched = data => ({
  type: INTERNSHIP_REQUESTS_FETCHED,
  payload: { data },
});

export const createInternshipForStudent = (
  description,
  shortDescription,
  startDate,
  companyId,
  mentorId,
  signature,
  organizationalUnit
) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          studentId: getState().student.id,
          subjectId: getState().subjects.selectedSubject.id,
          companyId,
          description,
          shortDescription,
          mentorId,
          signature,
          startDate: moment.utc(startDate).add(1, 'day').format('YYYY-MM-DD'),
          companyOrganizationalUnit: organizationalUnit,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch(internshipStudentFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const sendPublicInternshipRequest = (
  subjectId,
  companyId,
  startDate
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internship-requests/for-public`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          subjectId,
          companyId,
          startDate: moment.utc(startDate).add(1, 'day').format('YYYY-MM-DD'),
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchCompanies());
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const sendPrivateInternshipRequest = (subjectId, data) => {
  return async dispatch => {
    const emptyMentor =
      data.mentorEmail === '' &&
      data.mentorFirstName === '' &&
      data.mentorLastName === '' &&
      data.mentorPhone === '';
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internship-requests/for-private`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          subjectId,
          startDate: data.startDate
            ? moment.utc(data.startDate).add(1, 'day').format('YYYY-MM-DD')
            : '',
          name: data.name,
          oib: data.oib,
          address: data.address,
          city: data.city,
          postalCode: data.postalCode,
          country: data.country,
          url: data.url,
          contactPersonFullName: data.contactPersonFullName,
          contactPersonPhone: data.contactPersonPhone,
          contactPersonEmail: data.contactPersonEmail,
          shortDescription: data.shortDescription,
          description: data.description,
          economicBranch: data.economicBranch,
          mentorEmail: emptyMentor
            ? `privremeni-${moment().format('DD-MM-YYYY')}-${moment().format(
              'HH-mm-ss'
            )}@vevu.hr`
            : data.mentorEmail,
          mentorFirstName: data.mentorFirstName,
          mentorLastName: data.mentorLastName,
          mentorPhone: data.mentorPhone,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipRequests());
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const fetchCurrentLogDetails = (logId, setLoading) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${logId}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(resetLogImagesFetched());
      if (setLoading) {
        setLoading(false);
      }
      return dispatch(currentLogDetailsFetched(response.data.data));
    } catch (error) {
      return dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: error.response?.data?.data ? error.response?.data?.data : null,
          status: error.response && error.response.status,
        },
      });
    }
  };
};

export const currentLogDetailsFetched = data => ({
  type: CURRENT_LOG_DETAILS_FETCHED,
  payload: data,
});

export const updateLog = (duration, log, hasImages, files) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${getState().internship.currentLog.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          minutesWorked: duration,
          description: log,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (hasImages) {
        await dispatch(
          uploadDiaryLogImage(
            getState().internship.internship.id,
            response.data.data.id,
            files
          )
        );
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch(currentLogUpdated(response.data.data));
    } catch (error) {
      if (error.response) {
        return dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const currentLogUpdated = data => ({
  type: CURRENT_LOG_UPDATED,
  payload: data,
});

export const uploadDiaryLogImage = (internshipId, recordId, files) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${internshipId}/diary-records/${recordId}/images/upload`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: files,
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          message: i18n.t('internshipLogImageAttachmentsSuccessfullyUploaded'),
          status: response.status,
        },
      });
      await dispatch(fetchCurrentLogDetails(recordId));
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          message: null,
          status: null,
        },
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
          },
        });
      }
    }
  };
};

export const deleteCurrentLogImage = imageId => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/${
          getState().internship.currentLog.id
        }/images/${imageId}/delete`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(
        fetchCurrentLogDetails(getState().internship.currentLog.id)
      );
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
          },
        });
      }
    }
  };
};

export const fetchDiaryImages = (
  internshipId,
  recordId,
  imageId,
  cancelTokenSource
) => {
  return async dispatch => {
    try {
      const response = await axios({
        cancelToken: cancelTokenSource.token,
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${internshipId}/diary-records/${recordId}/images/${imageId}/data-url`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(internshipLogImagesFetched(response.data.data, imageId));
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
          },
        });
      }
    }
  };
};

export const addNewLog = (date, duration, log, hasImages, files) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary-records/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          minutesWorked: duration,
          description: log,
          dateWorked: date,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchInternshipLogs());
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      if (hasImages) {
        await dispatch(
          uploadDiaryLogImage(
            getState().internship.internship.id,
            response.data.data.id,
            files
          )
        );
      }
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
          },
        });
      }
    }
  };
};

export const addSummary = summary => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/conclusion`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          conclusion: summary,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      await dispatch(fetchInternshipStudent());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const submitInternship = signature => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/submit`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          signature,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return await dispatch(fetchInternshipStudent());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchInternshipLogs = id => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          id ? id : getState().internship.internship.id
        }/diary-records`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(internshipLogsFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchCertificateOfEmploymentStudent = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/certificate-of-employments/for-subject/${
          getState().subjects.selectedSubject.id
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(checkIfStudentPassedTheSubjectAsStudent());
      return dispatch(certificateOfEmploymentFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
        await dispatch(checkIfStudentPassedTheSubjectAsStudent());
        dispatch({
          type: CERTIFICATE_OF_EMPLOYMENT_FETCHED,
          payload: null,
        });
      }
    }
  };
};

export const fetchCertificateOfEmploymentProfessor = id => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/certificate-of-employments/for-subject/${
          getState().subjects.selectedSubject.id
        }/for-student/${id ? id : getState().student.id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(checkIfStudentPassedTheSubjectAsProfessor());
      return await dispatch(certificateOfEmploymentFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
        await dispatch(checkIfStudentPassedTheSubjectAsProfessor());
        dispatch({
          type: CERTIFICATE_OF_EMPLOYMENT_FETCHED,
          payload: null,
        });
      }
    }
  };
};

export const downloadCertificateOfEmployment = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/certificate-of-employments/${
          getState().internship.certificateOfEmployment.id
        }/download`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      const file = new Blob([response.data], {
        type: getState().internship.certificateOfEmployment.certificateMimeType,
      });
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        getState().internship.certificateOfEmployment.name
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        });
      }
    }
  };
};

export const deleteCertificateOfEmployment = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${settings.REACT_APP_API_URL}/api/v1/certificate-of-employments/${
          getState().internship.certificateOfEmployment.id
        }/delete`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch({
        type: CERTIFICATE_OF_EMPLOYMENT_FETCHED,
        payload: null,
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const setInternshipDocumentStatuses = data => ({
  type: SET_INTERNSHIP_DOCUMENT_STATUSES,
  payload: {
    ...data,
  },
});

export const checkDocumentGenerationStatuses = id => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          id ? id : getState().internship.internship.id
        }/document-generation-statuses`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(setInternshipDocumentStatuses(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const retryGeneratingDocument = (document, resetInterval) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url:
          document === 'referral'
            ? `${settings.REACT_APP_API_URL}/api/v1/internships/${
              getState().internship.internship.id
            }/documents/retry-generation/referral`
            : document === 'diary'
              ? `${settings.REACT_APP_API_URL}/api/v1/internships/${
                getState().internship.internship.id
              }/documents/retry-generation/diary`
              : `${settings.REACT_APP_API_URL}/api/v1/internships/${
                getState().internship.internship.id
              }/documents/retry-generation/confirmation`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch({
        type: SET_RETRY_GENERATION,
        payload: {
          retryGenerationFor: document,
          status: response.status,
        },
      });
      if (getState().user.role !== 'student') {
        await dispatch(
          fetchInternshipInfo(getState().internship.internship.id)
        );
        if (resetInterval) {
          resetInterval();
        }
      }
      if (getState().user.role === 'student') {
        await dispatch(fetchInternshipStudent());
        if (resetInterval) {
          resetInterval();
        }
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const downloadInternshipReferral = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/referral`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      const file = new Blob([response.data]);
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        `${i18n.t(
          'professorHomepage.downloadedReferralFileName'
        )}-${moment().format('DD.MM.YYYY. HH mm')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const downloadInternshipDiary = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/diary`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      const file = new Blob([response.data]);
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        `${i18n.t(
          'professorHomepage.downloadedDiaryFileName'
        )}-${moment().format('DD.MM.YYYY. HH mm')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const downloadInternshipConfirmation = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/confirmation`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        responseType: 'blob',
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      const file = new Blob([response.data]);
      const url_1 = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url_1;
      link.setAttribute(
        'download',
        `${i18n.t(
          'professorHomepage.downloadedConfirmationFileName'
        )}-${moment().format('DD.MM.YYYY. HH mm')}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const approveCertificateOfEmploymentProfessor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/certificate-of-employments/${
          getState().internship.certificateOfEmployment.id
        }/approve`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch(checkIfStudentPassedTheSubjectAsProfessor());
      await dispatch(fetchCertificateOfEmploymentProfessor());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const rejectCertificateOfEmploymentProfessor = reason => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/certificate-of-employments/${
          getState().internship.certificateOfEmployment.id
        }/reject`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          reason,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return await dispatch(fetchCertificateOfEmploymentProfessor());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const uploadCertificateOfEmployment = (file, setUploadProgress) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${
          settings.REACT_APP_API_URL
        }/api/v1/certificate-of-employments/for-subject/${
          getState().subjects.selectedSubject.id
        }/upload`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: data => {
          if (setUploadProgress) {
            setUploadProgress(Math.round((100 * data.loaded) / data.total));
          }
        },
        data: file,
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (setUploadProgress) {
        setUploadProgress(null);
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch(certificateOfEmploymentFetched(response.data.data));
    } catch (error) {
      if (setUploadProgress) {
        setUploadProgress(null);
      }
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const deleteInternship = resetInterval => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/delete`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch(internshipStudentFetched(null));
      dispatch(internshipLogsFetched(null));
      dispatch(currentLogDetailsFetched(null));
      dispatch(internshipRequestsFetched(null));
      resetInterval();
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const rateInternship = data => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/poll/submit`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: data,
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP,
        payload: {
          error: null,
          status: null,
        },
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
            message:
              error.response?.data?.message === 'Request validation error.'
                ? null
                : error.response?.data?.message
                  ? error.response?.data?.message
                  : null,
          },
        });
      }
    }
  };
};

export const getInternshipRating = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/internships/${
          getState().internship.internship.id
        }/poll`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(internshipRatingsFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_INTERNSHIP,
          payload: {
            error: error.response?.data?.data
              ? error.response?.data?.data
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const internshipLogsFetched = data => ({
  type: INTERNSHIP_LOGS_FETCHED,
  payload: { data },
});

export const internshipRatingsFetched = data => ({
  type: INTERNSHIP_RATINGS_FETCHED,
  payload: { data },
});

export const certificateOfEmploymentFetched = data => ({
  type: CERTIFICATE_OF_EMPLOYMENT_FETCHED,
  payload: data,
});

export const internshipLogImagesFetched = (data, imageId) => ({
  type: INTERNSHIP_LOG_IMAGES_FETCHED,
  payload: { data: data, imageId },
});

export const resetLogImagesFetched = () => ({
  type: RESET_INTERNSHIP_LOG_IMAGES_FETCHED,
  payload: null,
});

export const showInternshipDetails = detailsType => ({
  type: SHOW_INTERNSHIP_DETAILS,
  payload: { detailsType },
});
