import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import {
  CLEAR_SELECTED_SUBJECT,
  FETCHED_SUBJECT_DETAILS,
  FETCH_SUBJECTS,
  SET_ERROR_SUBJECTS,
  SET_SELECTED_SUBJECT,
  STUDENT_SUBJECT_STATUS_FETCHED,
} from '../types';

export const fetchSubjects = setCurrentSubjectAgain => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: FETCH_SUBJECTS,
        payload: response.data.data,
      });
      if (setCurrentSubjectAgain) {
        dispatch(setSubject(getState().subjects.selectedSubject.id));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const resetCurrentSubject = () => {
  return (dispatch, getState) => {
    dispatch(setSubject(getState().subjects.selectedSubject.id));
  };
};

export const fetchSubjectsForProfessor = setCurrentSubjectAgain => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/for-professor`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: FETCH_SUBJECTS,
        payload: response.data.data,
      });
      if (setCurrentSubjectAgain) {
        dispatch(setSubject(getState().subjects.selectedSubject.id));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchSubjectDetails = subjectId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/${subjectId}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch({
        type: FETCHED_SUBJECT_DETAILS,
        payload: response.data.data,
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const checkIfStudentPassedTheSubjectAsProfessor = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/students/${
          getState().student.id
        }/subject-completed/${getState().subjects.selectedSubject.id}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(subjectIsPassedStatusFetched(response.data.data));
    } catch (e) {
      return dispatch(subjectIsPassedStatusFetched(null));
    }
  };
};

export const checkIfStudentPassedTheSubjectAsStudent = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/students/subject-completed/${
          getState().subjects.selectedSubject.id
        }`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(subjectIsPassedStatusFetched(response.data.data));
    } catch (e) {
      return dispatch(subjectIsPassedStatusFetched(null));
    }
  };
};

export const subjectIsPassedStatusFetched = data => ({
  type: STUDENT_SUBJECT_STATUS_FETCHED,
  payload: data,
});

export const createSubject = (
  subject,
  acceptRequestsFrom,
  acceptRequestsTo
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          ...subject,
          acceptRequestsFrom,
          acceptRequestsTo,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_SUBJECTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchSubjects());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const updateSubject = (
  subject,
  acceptRequestsFrom,
  acceptRequestsTo
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/${subject.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          ...subject,
          acceptRequestsFrom,
          acceptRequestsTo,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_SUBJECTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchSubjects());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const setSubjectAcceptanceInterval = (startDate, endDate) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/${
          getState().subjects.selectedSubject.id
        }/update/internship-request-acceptance-interval`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          acceptRequestsFrom: startDate,
          acceptRequestsTo: endDate,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (getState().user.role === 'professor') {
        await dispatch(fetchSubjectsForProfessor(true));
      } else {
        await dispatch(fetchSubjects(true));
      }
      dispatch({
        type: SET_ERROR_SUBJECTS,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_SUBJECTS,
        payload: {
          error: null,
          status: null,
        },
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SUBJECTS,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const setSubject = subjectID => ({
  type: SET_SELECTED_SUBJECT,
  payload: subjectID,
});

export const clearSelectedSubject = () => ({
  type: CLEAR_SELECTED_SUBJECT,
});
