import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { executeRequestsWithLoading } from '../../../store/actions';
import ArrowDown from '../../../styles/assets/images/ArrowDown.svg';
import check from '../../../styles/assets/images/check.svg';
import './Dropdown.scss';

export const Dropdown = props => {
  const { t } = useTranslation();
  const component = useRef(null);
  const mountedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [filterInput, setFilterInput] = useState(null);
  const [selectedInput, setSelectedInput] = useState(null);
  const {
    list,
    handleSelect,
    headerTitle,
    multiselect,
    defaultHeaderOption,
    itemsSelected,
    customclass,
    fetchSuggestions,
    fetchPage,
    pages,
    suggestionPlaceholder,
    disabledInputSuggestion,
    fullWidth,
  } = props;

  const selectItem = item => {
    handleSelect(item);
  };

  useEffect(() => {
    if (props.inputSuggestions && headerTitle !== defaultHeaderOption) {
      setSelectedInput(headerTitle);
    }
  }, [headerTitle]);

  async function getSuggetions() {
    await executeRequestsWithLoading(
      [fetchSuggestions(filterInput)],
      setLoading,
      mountedRef
    );
  }

  async function fetchNewPage(page, name) {
    await fetchPage(page, name, setLoading);
  }

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (fetchSuggestions && dropdownOpened) {
      getSuggetions();
    }
  }, [dropdownOpened]);

  useEffect(() => {
    let timeout;
    let timeoutDelete;
    if (props.inputSuggestions) {
      if (filterInput !== null) {
        if (filterInput !== '') {
          timeout = setTimeout(() => {
            getSuggetions();
          }, 500);
        } else {
          timeoutDelete = setTimeout(() => {
            getSuggetions();
          }, 500);
        }
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (timeoutDelete) {
        clearTimeout(timeoutDelete);
      }
    };
  }, [filterInput]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (component.current && !component.current.contains(event.target)) {
        setDropdownOpened(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [component]);

  return (
    <div
      className='dropdown'
      ref={component}
      style={{ width: fullWidth && '100%' }}
    >
      <div className='dropdown__wrapper' style={{ width: fullWidth && '100%' }}>
        <div
          className={classNames({
            dropdown__wrapper__header: true,
            default: headerTitle === defaultHeaderOption,
          })}
        >
          {!props.inputSuggestions ? (
            <div
              className={classNames(customclass, {
                dropdown__wrapper__header__box: true,
                disabled: disabledInputSuggestion,
              })}
              style={{
                marginRight: fullWidth && '0',
                width: fullWidth && '100%',
              }}
              onClick={() =>
                !disabledInputSuggestion && setDropdownOpened(!dropdownOpened)
              }
            >
              <div className='dropdown__wrapper__header__box__title'>
                {headerTitle !== defaultHeaderOption
                  ? headerTitle
                  : defaultHeaderOption}
              </div>
              <img
                className={classNames({
                  dropdown__wrapper__header__box__caret: true,
                  opened: dropdownOpened,
                })}
                src={ArrowDown}
              />
            </div>
          ) : (
            <input
              className={classNames(customclass, {
                dropdown__wrapper__header__box: true,
                'suggestion-input': true,
              })}
              style={{ marginRight: fullWidth && '0' }}
              disabled={disabledInputSuggestion}
              placeholder={suggestionPlaceholder}
              onClick={() =>
                !disabledInputSuggestion && setDropdownOpened(!dropdownOpened)
              }
              value={
                selectedInput ? selectedInput : filterInput ? filterInput : ''
              }
              onChange={e => {
                if (selectedInput) setSelectedInput(null);
                setFilterInput(e.target.value);
                setDropdownOpened(true);
              }}
            />
          )}
        </div>
        {dropdownOpened &&
          (list && list.length > 0 ? (
            <div
              className='dropdown__wrapper__menu'
              style={{ width: fullWidth && '100%' }}
            >
              <div
                className={classNames(customclass, {
                  dropdown__wrapper__menu__item: true,
                })}
                onClick={e => {
                  e.preventDefault();
                  selectItem(defaultHeaderOption);
                  setDropdownOpened(false);
                  if (props.inputSuggestions) {
                    setSelectedInput(null);
                    setFilterInput(null);
                  }
                }}
              >
                <div className='dropdown__wrapper__menu__item__name-default'>
                  {t('dropdowns.removeSelection')}
                </div>
                {multiselect && itemsSelected && itemsSelected.length === 0 && (
                  <img
                    className='dropdown__wrapper__menu__item__selected'
                    src={check}
                  />
                )}
              </div>
              {loading && (
                <div className='loader-small dropdown__wrapper__menu__item' />
              )}
              {list.map((item, index) =>
                props.inputSuggestions
                  ? !loading && (
                    <div
                      className={classNames(
                        customclass,
                        {
                          dropdown__wrapper__menu__item: true,
                          selected: itemsSelected?.find(
                            selected => selected.key === item.key
                          ),
                        },
                        index
                      )}
                      key={item.id}
                      onClick={e => {
                        e.preventDefault();
                        if (props.inputSuggestions) {
                          setSelectedInput(item.name);
                        }
                        if (multiselect) {
                          selectItem(item);
                        } else {
                          handleSelect(item);
                          setDropdownOpened(false);
                        }
                      }}
                    >
                      <div className='dropdown__wrapper__menu__item__name'>
                        {item.name}
                      </div>
                      {multiselect &&
                          itemsSelected?.find(
                            selected => selected.key === item.key
                          ) && (
                        <img
                          className='dropdown__wrapper__menu__item__selected'
                          src={check}
                        />
                      )}
                    </div>
                  )
                  : !loading && (
                    <div
                      className={classNames(
                        customclass,
                        {
                          dropdown__wrapper__menu__item: true,
                          selected: itemsSelected?.find(
                            selected => selected.key === item.key
                          ),
                        },
                        index
                      )}
                      key={item.id}
                      onClick={e => {
                        e.preventDefault();
                        if (multiselect) {
                          selectItem(item);
                        } else {
                          handleSelect(item);
                          setDropdownOpened(false);
                        }
                      }}
                    >
                      <div className='dropdown__wrapper__menu__item__name'>
                        {item.name}
                      </div>
                      {multiselect &&
                          itemsSelected?.find(
                            selected => selected.key === item.key
                          ) && (
                        <img
                          className='dropdown__wrapper__menu__item__selected'
                          src={check}
                        />
                      )}
                    </div>
                  )
              )}
              {!loading && props.inputSuggestions && pages.totalPages > 1 && (
                <div className='dropdown__wrapper__menu__item'>
                  <button
                    disabled={pages.page === 1}
                    className='previous'
                    onClick={() => fetchNewPage(pages.page - 1, filterInput)}
                  >
                    ◄
                  </button>
                  <button
                    disabled={pages.page === pages.totalPages}
                    className='next'
                    onClick={() => fetchNewPage(pages.page + 1, filterInput)}
                  >
                    ►
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div
              className='dropdown__wrapper__menu'
              style={{ width: fullWidth && '100%' }}
            >
              <div className={classNames(customclass)}>
                <div className='dropdown__wrapper__menu__item__name-default'>
                  {loading ? (
                    <div className='loader-small' />
                  ) : (
                    t('dropdowns.listIsEmpty')
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
  handleSelect: PropTypes.func.isRequired,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  setHeaderTitle: PropTypes.func,
  multiselect: PropTypes.bool,
  defaultHeaderOption: PropTypes.string.isRequired,
  itemsSelected: PropTypes.arrayOf(PropTypes.shape()),
  customclass: PropTypes.string,
  inputSuggestions: PropTypes.bool,
  fetchSuggestions: PropTypes.func,
  fetchPage: PropTypes.func,
  pages: PropTypes.object,
  disabledInputSuggestion: PropTypes.bool,
  suggestionPlaceholder: PropTypes.string,
  fullWidth: PropTypes.bool,
};
