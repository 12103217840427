import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Table } from 'reactstrap';
import '../../../../styles/ListTable.scss';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';

const ProfessorsList = props => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([
    {
      header: t('list.professorsList.header.name'),
      headerKey: 'name',
    },
    {
      header: t('list.professorsList.header.contact'),
      headerKey: 'contact',
    },
    {
      header: t('list.professorsList.header.active'),
      headerKey: 'active',
      role: true,
    },
    {
      header: t('list.professorsList.header.actions'),
      headerKey: 'actions',
      role: true,
    },
  ]);

  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          role={!props.addProfesorToSubject}
        />
        <tbody className='list-table__item-row'>
          {props.professors.map((professor, index) => {
            return (
              <tr className='spacer item-row' key={index}>
                <td>
                  {professor.firstName} {professor.lastName}
                </td>
                <td>
                  <Table width='100%'>
                    <tbody>
                      <tr style={{ display: 'flex', flexDirection: 'column' }}>
                        <td id='open-send-email-modal'>{professor.email}</td>
                        {professor.phone ? <td>{professor.phone}</td> : null}
                      </tr>
                    </tbody>
                  </Table>
                </td>
                {!props.addProfesorToSubject && (
                  <td style={{ color: professor.active ? 'green' : 'red' }}>
                    {professor.active
                      ? t('list.professorsList.active.yes')
                      : t('list.professorsList.active.no')}
                  </td>
                )}
                {!props.addProfesorToSubject && (
                  <td>
                    <button
                      id='link-to-professor-page'
                      onClick={() => {
                        props.setSelectedProfessor(professor);
                        props.setShowAddOrUpdateProfessorModal(true);
                      }}
                    >
                      {t('buttons.select')}
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

ProfessorsList.propTypes = {
  professors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setShowAddOrUpdateProfessorModal: PropTypes.func,
  setSelectedProfessor: PropTypes.func,
  addProfesorToSubject: PropTypes.bool,
};

export default ProfessorsList;
