import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import DataDisplay from '../DataDisplay/DataDisplay';
import Modal from '../Modal/Modal';
import { changePassword } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import './ChangePasswordModal.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const ChangePasswordModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userError = useSelector(state => state.user.error);
  const userStatus = useSelector(state => state.user.status);

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmed: '',
  });
  const [displayPassword, setDisplayPassword] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordConfirmed: false,
  });

  const handleSend = () => {
    dispatch(
      changePassword(
        passwords.oldPassword,
        passwords.newPassword,
        passwords.newPasswordConfirmed
      )
    );
  };

  useEffect(() => {
    if (userStatus === 201 || userStatus === 200) {
      props.setShowChangePasswordModal(false);
    }
  }, [userStatus]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_ERROR_USER',
        payload: {
          error: null,
          status: null,
        },
      });
      setPasswords({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmed: '',
      });
    };
  }, []);

  return (
    <Modal
      className='change-password-modal'
      closeModal={() => props.setShowChangePasswordModal(false)}
    >
      <div className='modal-container-change-password'>
        <div className='modal-container-change-password__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-change-password__header__header-text'>
            <div className='header-main-text'>
              {t('modal.modalInfoBar.changePasswordMain')}
            </div>
            <div className='header-small-text'>
              {t('modal.modalInfoBar.changePasswordDescriptionText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowChangePasswordModal(false)}
          ></div>
        </div>
        <div className='modal-container-change-password__body'>
          <DataDisplay
            dataHeader={t('inputs.currentPassword')}
            dataClass='old-password'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <div className='password-input'>
                <input
                  className='password'
                  type={displayPassword.oldPassword ? 'text' : 'password'}
                  autoComplete='password'
                  onChange={e =>
                    setPasswords({ ...passwords, oldPassword: e.target.value })
                  }
                  value={passwords.oldPassword}
                  placeholder={t('inputs.currentPasswordPlaceholder')}
                  name='password'
                />
                <div
                  onClick={() =>
                    setDisplayPassword({
                      ...displayPassword,
                      oldPassword: !displayPassword.oldPassword,
                    })
                  }
                  className={classNames({
                    'password-icon': true,
                    'hide-password': !displayPassword.oldPassword,
                    'show-password': displayPassword.oldPassword,
                  })}
                />
              </div>
            }
          />
          {userError && userError['oldPassword'] && (
            <div className='error'>{userError['oldPassword'].notEmpty}</div>
          )}
          <DataDisplay
            dataHeader={t('inputs.newPassword')}
            dataClass='new-password'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <div className='password-input'>
                <input
                  className='password'
                  type={displayPassword.newPassword ? 'text' : 'password'}
                  autoComplete='password'
                  placeholder={t('inputs.newPasswordPlaceholder')}
                  id='new-password'
                  name='new-password'
                  value={passwords.newPassword}
                  onChange={e =>
                    setPasswords({
                      ...passwords,
                      newPassword: e.target.value,
                    })
                  }
                />
                <div
                  onClick={() =>
                    setDisplayPassword({
                      ...displayPassword,
                      newPassword: !displayPassword.newPassword,
                    })
                  }
                  className={classNames({
                    'password-icon': true,
                    'hide-password': !displayPassword.newPassword,
                    'show-password': displayPassword.newPassword,
                  })}
                />
              </div>
            }
          />
          {userError && userError['newPassword'] && (
            <div className='error'>{userError['newPassword'].notEmpty}</div>
          )}
          <DataDisplay
            dataHeader={t('inputs.newPasswordConfirmation')}
            dataClass='new-password-confirm'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <div className='password-input'>
                <input
                  className='password'
                  type={
                    displayPassword.newPasswordConfirmed ? 'text' : 'password'
                  }
                  autoComplete='password'
                  placeholder={t('inputs.newPasswordConfirmationPlaceholder')}
                  id='new-password-confirm'
                  name='new-password-confirm'
                  value={passwords.newPasswordConfirmed}
                  onChange={e =>
                    setPasswords({
                      ...passwords,
                      newPasswordConfirmed: e.target.value,
                    })
                  }
                />
                <div
                  onClick={() =>
                    setDisplayPassword({
                      ...displayPassword,
                      newPasswordConfirmed:
                        !displayPassword.newPasswordConfirmed,
                    })
                  }
                  className={classNames({
                    'password-icon': true,
                    'hide-password': !displayPassword.newPasswordConfirmed,
                    'show-password': displayPassword.newPasswordConfirmed,
                  })}
                />
              </div>
            }
          />
          {userError && userError['newPasswordConfirmed'] && (
            <>
              <div className='error'>
                {userError['newPasswordConfirmed'].confirms}
              </div>
              <div className='error'>
                {userError['newPasswordConfirmed'].notEmpty}
              </div>
            </>
          )}
          <div className='button-container'>
            <button
              className='modal-container-change-password__change-password-modal__send-button'
              id='send-btn'
              type='button'
              onClick={e => {
                e.preventDefault();
                handleSend();
              }}
            >
              {t('buttons.savePassword')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  setShowChangePasswordModal: PropTypes.func.isRequired,
};
