import { PURGE } from 'redux-persist/lib/constants';
import { ACCOUNT_CREATED } from '../types';

const initialState = {
  createdAccount: null,
  error: null,
  status: null,
};

export const createdAccount = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_CREATED: {
      return {
        ...state,
        createdAccount: action.payload,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
