import axios from 'axios';
import hr from 'date-fns/locale/hr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-editor.css';
import 'react-mde/lib/styles/css/react-mde-toolbar.css';
import 'react-mde/lib/styles/css/react-mde.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewLog,
  addSummary,
  downloadInternshipDiary,
  editCommentForInternshipLog,
  executeRequestsWithLoading,
  fetchCurrentLogDetails,
  fetchDiaryImages,
  mentorApproveInternship,
  mentorRejectInternship,
  professorApproveInternship,
  professorRejectInternship,
  resetLogImagesFetched,
  resolveCommentForInternshipLog,
  retryGeneratingDocument,
  showInternshipDetails,
  submitInternship,
  updateLog,
} from '../../../../store/actions';
import ConclusionModal from '../../../SharedComponents/ConclusionModal/ConclusionModal';
import DataDisplay from '../../../SharedComponents/DataDisplay/DataDisplay';
import { Dropdown } from '../../../SharedComponents/Dropdown/Dropdown';
import ImageUpload from '../../../SharedComponents/FileUploads/ImageUpload';
import { ReasonModal } from '../../../SharedComponents/ReasonModal/ReasonModal';
import { Signing } from '../../../SharedComponents/Signing/Signing';
import { InternshipComments } from '../InternshipComments/InternshipComments';
import './InternshipLog.scss';

registerLocale('hr', hr);

export const InternshipLog = props => {
  const { internship, logs, setLoading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useRef(true);
  const comments = useSelector(state => state.comments);
  const currentLog = useSelector(state => state.internship.currentLog);
  const currentLogImages = useSelector(
    state => state.internship.currentLogImages
  );
  const internshipErrorMessage = useSelector(state => state.internship.message);
  const internshipError = useSelector(state => state.internship.error);
  const internshipStatus = useSelector(state => state.internship.status);

  const [displayConculsionModal, setDisplayConculsionModal] = useState(false);
  const [hasLogs, setHasLogs] = useState();
  const [addNew, setAddNew] = useState(false);
  const [summary, setSummary] = useState(false);
  const [date, setDate] = useState();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [description, setDescription] = useState();
  const [selectedTab, setSelectedTab] = useState('write');
  const [currentComments, setCurrentComments] = useState([]);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);

  const [isDefaultOption, setIsDefaultOption] = useState(true);
  const [dropdownTitle, setDropdownTitle] = useState(
    t('studentHomepage.internshipLogs.dayDropdown.defaultOption')
  );

  const [editLog, setEditLog] = useState(false);
  const [imageFile, setImageFile] = useState({
    firstImage: null,
    secondImage: null,
  });
  useEffect(() => {
    if (internshipStatus === 201 || internshipStatus === 200) {
      setAddNew(false);
      setSummary(false);
      setEditLog(false);
      setImageFile({
        firstImage: null,
        secondImage: null,
      });
      dispatch({
        type: 'SET_ERROR_INTERNSHIP',
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
    }
  }, [internshipStatus]);

  useEffect(() => {
    if (logs) setHasLogs(true);
  }, [currentComments]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (currentLog) {
      setHours(Math.floor(currentLog.minutesWorked / 60));
      if (currentLog.minutesWorked % 60 < 10) {
        setMinutes('0' + (currentLog.minutesWorked % 60));
      } else {
        setMinutes(currentLog.minutesWorked % 60);
      }

      if (currentLog?.images?.length > 0) {
        if (currentLog?.images?.length === 1) {
          dispatch(
            fetchDiaryImages(
              internship.id,
              currentLog?.id,
              currentLog?.images[0].id,
              cancelTokenSource
            )
          );
        } else {
          dispatch(
            fetchDiaryImages(
              internship.id,
              currentLog?.id,
              currentLog?.images[0].id,
              cancelTokenSource
            )
          );
          dispatch(
            fetchDiaryImages(
              internship.id,
              currentLog?.id,
              currentLog?.images[1].id,
              cancelTokenSource
            )
          );
        }
      }
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [currentLog]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      dispatch(resetLogImagesFetched());
      dispatch({
        type: 'SET_ERROR_INTERNSHIP',
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
    };
  }, []);

  const getDay = async logId => {
    await executeRequestsWithLoading(
      [dispatch(fetchCurrentLogDetails(logId))],
      setLoading,
      isMounted,
      true
    );

    if (comments) {
      const currentDayComments = comments.comments?.find(comments =>
        moment.utc(comments.date).isSame(moment.utc(logId))
      );
      setCurrentComments(currentDayComments);
    }
  };

  const handleButtonClick = btn => {
    switch (btn) {
      case 'addNew': {
        dispatch(resetLogImagesFetched());
        setAddNew(!addNew);
        setEditLog(false);
        setSummary(false);
        setHours(0);
        setMinutes(0);
        setDescription('');
        setIsDefaultOption(true);
        dispatch({
          type: 'SET_ERROR_INTERNSHIP',
          payload: {
            error: null,
            status: null,
            message: null,
          },
        });
        break;
      }
      case 'summary': {
        dispatch(resetLogImagesFetched());
        setSummary(!summary);
        setAddNew(false);
        setDescription(internship.conclusion ? internship.conclusion : '');
        setIsDefaultOption(true);
        dispatch({
          type: 'SET_ERROR_INTERNSHIP',
          payload: {
            error: null,
            status: null,
            message: null,
          },
        });
        break;
      }
      case 'editCurrent': {
        setSummary(false);
        setAddNew(false);
        setDescription(currentLog.description);
        setEditLog(true);
        dispatch({
          type: 'SET_ERROR_INTERNSHIP',
          payload: {
            error: null,
            status: null,
            message: null,
          },
        });
      }
    }
  };

  const handleSave = type => {
    const imageFiles = new FormData();
    imageFiles.append('files', imageFile.firstImage);
    imageFiles.append('files', imageFile.secondImage);

    switch (type) {
      case 'new': {
        const duration = parseInt(hours) * 60 + parseInt(minutes);
        dispatch(
          addNewLog(
            moment(date).format('yyyy-MM-DD'),
            duration,
            description,
            imageFile.firstImage || imageFile.secondImage,
            imageFiles
          )
        );
        break;
      }
      case 'summary': {
        dispatch(addSummary(description));
        break;
      }
      case 'editCurrent': {
        const duration = parseInt(hours) * 60 + parseInt(minutes);
        dispatch(
          updateLog(
            duration,
            description,
            imageFile.firstImage || imageFile.secondImage,
            imageFiles
          )
        );
      }
    }
  };

  const handleResolve = id => {
    dispatch(resolveCommentForInternshipLog(currentLog.id, id));
  };

  const handleEdit = (id, comment) => {
    dispatch(editCommentForInternshipLog(currentLog.id, id, comment));
  };

  const checkForGeneratedDocument = documentType => {
    if (documentType === 'GENERATED') {
      return true;
    }
    return false;
  };

  const checkGeneretaionType = type => {
    if (props.interval === 'stop')
      return t('documentGenerationStatuses.RETRY_GENERATION_INTERNSHIP_DIARY');
    switch (type) {
      case 'GENERATING':
        return t('documentGenerationStatuses.GENERATING');
      case 'NOT_GENERATED':
        return t('documentGenerationStatuses.NOT_GENERATED');
      case 'GENERATED':
        return t('documentGenerationStatuses.DOWNLOAD_INTERNSHIP_DIARY');
      case 'GENERATION_FAILED':
        return t(
          'documentGenerationStatuses.RETRY_GENERATION_INTERNSHIP_DIARY'
        );
      default:
        break;
    }
  };

  return (
    <div className='internship-logs'>
      <div className='internship-logs__options-container'>
        {hasLogs && (
          <div>
            <Dropdown
              list={logs.map(log => ({
                id: log.id,
                name: t('studentHomepage.internshipLogs.logDay', {
                  day: log.dayNumber,
                }),
              }))}
              customclass='internship-log-day-dropdown'
              handleSelect={item => {
                if (item.id) {
                  getDay(item.id);
                  setAddNew(false);
                  setSummary(false);
                  setEditLog(false);
                  setIsDefaultOption(false);
                  setDropdownTitle(item.name);
                  dispatch(resetLogImagesFetched());
                } else {
                  setEditLog(false);
                  setAddNew(false);
                  setSummary(false);
                  setIsDefaultOption(true);
                  dispatch(resetLogImagesFetched());
                  setDropdownTitle(item);
                }
              }}
              headerTitle={dropdownTitle}
              defaultHeaderOption={t(
                'studentHomepage.internshipLogs.dayDropdown.defaultOption'
              )}
            />
          </div>
        )}
        <div className='internship-logs__options-container__actions'>
          {internship &&
            internship.status !== 'IN_REVIEW_MENTOR' &&
            internship.status !== 'IN_REVIEW_PROFESSOR' &&
            internship.status !== 'COMPLETED' &&
            props.user.role === 'student' && (
            <>
              <button
                type='button'
                className='internship-logs__options-container__add-btn'
                onClick={() => handleButtonClick('addNew')}
              >
                <div className='internship-logs__options-container__add-btn__icon'></div>
                <div className='internship-logs__options-container__add-btn__text'>
                  {t('buttons.addNewInternshipLogs')}
                </div>
              </button>
              {hasLogs && logs.length > 0 && (
                <button
                  type='button'
                  className='internship-logs__options-container__add-btn'
                  onClick={() => handleButtonClick('summary')}
                >
                  <div className='internship-logs__options-container__add-btn__icon'></div>
                  <div className='internship-logs__options-container__add-btn__text'>
                    {internship.conclusion
                      ? t('buttons.changeInternshipConclusion')
                      : t('buttons.addInternshipConclusion')}
                  </div>
                </button>
              )}
              {hasLogs &&
                  internship.status !== 'COMPLETED' &&
                  internship.conclusion && (
                <button
                  type='button'
                  className='internship-logs__options-container__submit-logs'
                  onClick={() => setShowSignatureModal(true)}
                >
                  <div className='internship-logs__options-container__submit-logs__icon'></div>
                  <div className='internship-logs__options-container__submit-logs__text'>
                    {t('buttons.submitInternshipDiary')}
                  </div>
                </button>
              )}
            </>
          )}
          {internship.conclusion && (
            <button
              type='button'
              className='conclusion-display-button'
              onClick={() => {
                setDisplayConculsionModal(true);
              }}
            >
              {t('buttons.viewInternshipConclusion')}
            </button>
          )}
          {internship.status === 'COMPLETED' && (
            <button
              type='button'
              className='internship-document-button'
              disabled={
                (internship.diaryGenerationStatus === 'GENERATING' &&
                  props.interval !== 'stop') ||
                (props.user.role === 'mentor' &&
                  internship.diaryGenerationStatus !== 'GENERATED')
              }
              onClick={() => {
                if (
                  checkForGeneratedDocument(internship.diaryGenerationStatus)
                ) {
                  dispatch(downloadInternshipDiary());
                }
                if (
                  internship.diaryGenerationStatus === 'NOT_GENERATED' ||
                  internship.diaryGenerationStatus === 'GENERATION_FAILED' ||
                  props.interval === 'stop'
                ) {
                  dispatch(
                    retryGeneratingDocument('diary', props.resetInterval)
                  );
                }
              }}
            >
              {internship &&
                internship.diaryGenerationStatus &&
                checkGeneretaionType(internship.diaryGenerationStatus)}
            </button>
          )}
        </div>
      </div>
      {props.user.role !== 'student' &&
        internship.status !== 'COMPLETED' &&
        internship.status !== 'APPROVED' &&
        (((props.user.role === 'mentor' ||
          props.user.role === 'professor' ||
          props.user.role === 'admin') &&
          internship.status === 'IN_REVIEW_MENTOR') ||
          ((props.user.role === 'professor' || props.user.role === 'admin') &&
            internship.status === 'IN_REVIEW_PROFESSOR')) && (
        <div className='internship-actions'>
          <div className='internship-actions__message'>
            {t(
              'studentHomepage.internshipLogs.confirmationDocumentGenerationText'
            )}
          </div>
          <button
            className='accept-button'
            onClick={() => setShowSignatureModal(true)}
          >
            {(props.user.role === 'admin' ||
                props.user.role === 'professor') &&
              internship.status === 'IN_REVIEW_MENTOR'
              ? t('buttons.approveInternshipForMentor')
              : t('buttons.approveInternship')}
          </button>
          <button
            className='reject-button'
            onClick={() => setShowReasonModal(true)}
          >
            {(props.user.role === 'admin' ||
                props.user.role === 'professor') &&
              internship.status === 'IN_REVIEW_MENTOR'
              ? t('buttons.rejectInternshipForMentor')
              : t('buttons.rejectInternship')}
          </button>
        </div>
      )}
      {internshipErrorMessage &&
        internshipStatus !== 200 &&
        internshipStatus !== 201 && (
        <div className='error'>{internshipErrorMessage}</div>
      )}
      {internship.status === 'IN_REVIEW_MENTOR'
        ? props.user.role !== 'mentor' && (
          <div className='internship-actions__message'>
            {t('studentHomepage.internshipLogs.statuses.IN_REVIEW_MENTOR')}
          </div>
        )
        : internship.status === 'IN_REVIEW_PROFESSOR' &&
          props.user.role !== 'professor' &&
          props.user.role !== 'admin' && (
          <div className='internship-actions__message'>
            {t('studentHomepage.internshipLogs.statuses.IN_REVIEW_PROFESSOR')}
          </div>
        )}
      {(addNew || summary || editLog) && (
        <div className='internship-logs__log-entry'>
          <div
            className='internship-logs__log-entry__date-time'
            style={{ display: addNew || editLog ? '' : 'none' }}
          >
            <DataDisplay
              dataHeader={t('inputs.date')}
              centerHeaderVertically
              headerBolded
              removeTopSeparator
              headerWidth={230}
              data={
                <>
                  <div className='datepicker-wrapper'>
                    <DatePicker
                      disabledKeyboardNavigation
                      locale='hr'
                      dateFormat='dd.MM.yyyy.'
                      selected={
                        editLog ? new Date(currentLog.dateWorked) : date
                      }
                      disabled={editLog}
                      onChange={date => setDate(date)}
                    />
                    <div className='non-clickable-area' />
                  </div>
                  {internshipError && internshipError.dateWorked && (
                    <>
                      <div className='error'>
                        {internshipError.dateWorked.dateAfter}
                      </div>
                      <div className='error'>
                        {internshipError.dateWorked.notEmpty}
                      </div>
                    </>
                  )}
                </>
              }
            />
            <DataDisplay
              dataHeader={t('inputs.timeWorked')}
              centerHeaderVertically
              headerBolded
              headerWidth={230}
              data={
                <div className='internship-logs__log-entry__duration'>
                  <input
                    className='internship-logs__log-entry__duration__number'
                    type='number'
                    min='0'
                    max='23'
                    value={hours}
                    id='hours'
                    onChange={e => setHours(e.target.value)}
                  />
                  <div className='duration-separator'>:</div>
                  <input
                    className='internship-logs__log-entry__duration__number'
                    type='number'
                    min='0'
                    max='59'
                    value={minutes}
                    id='minutes'
                    onChange={e => {
                      setMinutes(parseInt(e.target.value));
                    }}
                  />
                  <small className='small-text-duration'>(Sati : Minute)</small>
                  {internshipError && internshipError.minutesWorked && (
                    <>
                      <div className='error'>
                        {internshipError.minutesWorked.max}
                      </div>
                      <div className='error'>
                        {internshipError.minutesWorked.min}
                      </div>
                      <div className='error'>
                        {internshipError.minutesWorked.notNull}
                      </div>
                    </>
                  )}
                </div>
              }
            />
          </div>
          <DataDisplay
            dataHeader={t('inputs.description')}
            headerBolded
            dataFullWidth
            headerWidth={230}
            dataClass='internship-log-description-input'
            data={
              <>
                <ReactMde
                  value={description}
                  onChange={setDescription}
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={markdown => {
                    return Promise.resolve(<ReactMarkdown source={markdown} />);
                  }}
                  commands={{
                    'header-1': {
                      name: 'header-1',
                      icon: function icon() {
                        return <span style={{ fontWeight: 'bold' }}>H1</span>;
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          `# ${opts.textApi.getState().selectedText}`
                        );
                      },
                    },
                    'header-2': {
                      name: 'header-2',
                      icon: function icon() {
                        return <span style={{ fontWeight: 'bold' }}>H2</span>;
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          `## ${opts.textApi.getState().selectedText}`
                        );
                      },
                    },
                    'header-3': {
                      name: 'header-3',
                      icon: function icon() {
                        return <span style={{ fontWeight: 'bold' }}>H3</span>;
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          `### ${opts.textApi.getState().selectedText}`
                        );
                      },
                    },
                    'header-4': {
                      name: 'header-4',
                      icon: function icon() {
                        return <span style={{ fontWeight: 'bold' }}>H4</span>;
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          `#### ${opts.textApi.getState().selectedText}`
                        );
                      },
                    },
                    'header-5': {
                      name: 'header-5',
                      icon: function icon() {
                        return <span style={{ fontWeight: 'bold' }}>H5</span>;
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          `##### ${opts.textApi.getState().selectedText}`
                        );
                      },
                    },
                    'custom-code-block': {
                      name: 'custom-code-block',
                      icon: function icon() {
                        return (
                          <span style={{ fontWeight: 'bold' }}>
                            {'<'}/{'>'}
                          </span>
                        );
                      },
                      execute: opts => {
                        opts.textApi.replaceSelection(
                          '```\n' +
                            opts.textApi.getState().selectedText +
                            '\n```'
                        );
                      },
                    },
                  }}
                  toolbarCommands={[
                    [
                      'header-1',
                      'header-2',
                      'header-3',
                      'header-4',
                      'header-5',
                    ],
                    ['bold'],
                    /* ['image'], */
                    ['unordered-list', 'ordered-list'],
                  ]}
                  childProps={{
                    writeButton: {
                      tabIndex: -1,
                    },
                  }}
                  l18n={{
                    write: t('inputs.internshipLogWriteOption'),
                    preview: t('inputs.internshipLogViewOption'),
                  }}
                />
                {internshipError && internshipError.description && (
                  <div className='error'>
                    {internshipError.description.notEmpty}
                  </div>
                )}
                {internshipError && internshipError.conclusion && (
                  <div className='error'>
                    {internshipError.conclusion.notEmpty}
                  </div>
                )}
              </>
            }
          />
          {!summary && (
            <DataDisplay
              dataHeader={t('inputs.attachments')}
              dataHeaderSmalltext={t(
                'inputs.internshipLogAttachmentsDescription'
              )}
              headerBolded
              dataFullWidth
              headerWidth={230}
              data={
                <ImageUpload
                  multipleUploads
                  setImageFile={setImageFile}
                  imageFile={imageFile}
                  fetchedCurrentLogImages={currentLogImages}
                />
              }
            />
          )}
          <DataDisplay
            dataHeader={' '}
            dataFullWidth
            headerWidth={230}
            data={
              <button
                className='internship-logs__log-entry__save-btn'
                onClick={() => {
                  if (addNew) handleSave('new');
                  if (summary) handleSave('summary');
                  if (editLog) handleSave('editCurrent');
                }}
              >
                <div className='internship-logs__log-entry__save-btn__icon'></div>
                <div className='internship-logs__log-entry__save-btn__text'>
                  {t('buttons.save')}
                </div>
              </button>
            }
          />
        </div>
      )}
      {currentLog && !isDefaultOption && (
        <div className='internship-logs__current-log'>
          <div
            className='internship-logs__current-log__date'
            style={{ display: !editLog ? '' : 'none' }}
          >
            <DataDisplay
              dataHeader={t('inputs.date')}
              centerHeaderVertically
              headerBolded
              removeTopSeparator
              headerWidth={230}
              data={
                <div className='internship-logs__current-log__date__value'>
                  {moment(currentLog.dateWorked).format('DD.MM.YYYY.')}
                </div>
              }
            />
          </div>
          <div
            className='internship-logs__current-log__hours'
            style={{ display: !editLog ? '' : 'none' }}
          >
            <DataDisplay
              dataHeader={t('inputs.timeWorked')}
              centerHeaderVertically
              headerBolded
              headerWidth={230}
              data={
                <div className='internship-logs__log-entry__duration'>
                  <div className='internship-logs__current-log__hours__value'>
                    {hours}:{minutes}
                  </div>
                  <small className='small-text-duration'>
                    ({t('studentHomepage.internshipLogs.hours')} :{' '}
                    {t('studentHomepage.internshipLogs.minutes')})
                  </small>
                </div>
              }
            />
          </div>
          {!editLog && (
            <DataDisplay
              dataHeader={t('inputs.description')}
              headerBolded
              dataFullWidth
              headerWidth={230}
              dataClass='internship-log-description'
              data={
                <>
                  {props.user.role === 'student' &&
                    internship.status !== 'COMPLETED' &&
                    internship.status !== 'PROCESSING' &&
                    internship.status !== 'IN_REVIEW_MENTOR' &&
                    internship.status !== 'IN_REVIEW_PROFESSOR' && (
                    <button
                      className='internship-logs__current-log__description__edit-btn'
                      onClick={() => {
                        handleButtonClick('editCurrent');
                      }}
                    >
                      {t('buttons.edit')}
                    </button>
                  )}
                  <div className='internship-log-description__preview'>
                    <ReactMarkdown>{currentLog.description}</ReactMarkdown>
                  </div>
                </>
              }
            />
          )}
          {!editLog && (
            <DataDisplay
              dataHeader={t('inputs.attachments')}
              dataHeaderSmalltext={t(
                'inputs.internshipLogAttachmentsDescription'
              )}
              headerBolded
              dataFullWidth
              headerWidth={230}
              data={
                <div className='images-container'>
                  {currentLogImages &&
                    currentLogImages.length > 0 &&
                    currentLogImages.map((image, index) => {
                      return (
                        <div key={index} className='current-log-image-view'>
                          <img
                            onClick={() => {
                              var newTab = window.open();
                              newTab.document.body.innerHTML = `<img src='${image.data}'>`;
                            }}
                            src={image.data}
                            className='image-btn'
                            alt={image.imageId}
                          />
                        </div>
                      );
                    })}
                </div>
              }
            />
          )}
          {internshipErrorMessage &&
            internshipStatus !== 200 &&
            internshipStatus !== 201 &&
            internshipErrorMessage !==
              t(
                'studentHomepage.internshipLogs.internshipLogHasUnresolvedComment'
              ) && <div className='error'>{internshipErrorMessage}</div>}
          <InternshipComments
            user={props.user}
            comments={currentLog.comments}
            handleResolve={handleResolve}
            handleEdit={handleEdit}
            internshipStatus={internship.status}
          />
        </div>
      )}
      {showSignatureModal && (
        <Signing
          sendSignatureData={data => {
            if (
              (props.user.role === 'mentor' ||
                props.user.role === 'professor' ||
                props.user.role === 'admin') &&
              internship.status === 'IN_REVIEW_MENTOR'
            ) {
              executeRequestsWithLoading(
                [dispatch(mentorApproveInternship(data, internship.id))],
                setLoading,
                isMounted,
                true
              );
              props.resetInterval();
            } else if (
              props.user.role === 'professor' ||
              props.user.role === 'admin'
            ) {
              executeRequestsWithLoading(
                [
                  dispatch(professorApproveInternship(data, internship.id)),
                  dispatch(showInternshipDetails('info')),
                ],
                setLoading,
                isMounted,
                true
              );
              props.resetInterval();
            } else if (props.user.role === 'student') {
              executeRequestsWithLoading(
                [dispatch(submitInternship(data))],
                setLoading,
                isMounted,
                true
              );
            }
          }}
          setShowSignatureModal={setShowSignatureModal}
        />
      )}
      {showReasonModal && (
        <ReasonModal
          setShowReasonModal={setShowReasonModal}
          sendReasonData={data => {
            if (
              (props.user.role === 'mentor' ||
                props.user.role === 'professor' ||
                props.user.role === 'admin') &&
              internship.status === 'IN_REVIEW_MENTOR'
            ) {
              executeRequestsWithLoading(
                [
                  dispatch(mentorRejectInternship(data, internship.id)),
                  dispatch(showInternshipDetails('info')),
                ],
                setLoading,
                isMounted,
                true
              );
            } else if (
              props.user.role === 'professor' ||
              props.user.role === 'admin'
            ) {
              executeRequestsWithLoading(
                [
                  dispatch(professorRejectInternship(data, internship.id)),
                  dispatch(showInternshipDetails('info')),
                ],
                setLoading,
                isMounted,
                true
              );
            }
          }}
        />
      )}
      {displayConculsionModal && (
        <ConclusionModal
          setShowConclusionModal={setDisplayConculsionModal}
          conclusion={internship.conclusion}
        />
      )}
    </div>
  );
};

InternshipLog.propTypes = {
  interval: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  resetInterval: PropTypes.func,
  internship: PropTypes.shape(),
  comments: PropTypes.arrayOf(PropTypes.shape()),
  user: PropTypes.shape(),
  logs: PropTypes.arrayOf(PropTypes.shape()),
  setLoading: PropTypes.func,
};
