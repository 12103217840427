import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { documentVerified, verifyValidityOfPdf } from '../../../store/actions';
import './ProfessorHomepage.scss';

const ProfessorHomepage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const pdfUploadStatus = useSelector(state => state.professors.status);
  const pdfDocumentVerified = useSelector(
    state => state.professors.verifiedPDF
  );
  const user = useSelector(state => state.user);
  const isAdmin = user.role.toLowerCase() === 'admin';
  const timeout = useRef(null);

  const [uploadProgress, setUploadProgress] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (pdfUploadStatus === 200) {
      setUploadProgress(null);
      timeout.current = setTimeout(() => {
        dispatch(documentVerified(null));
      }, 5000);
      dispatch({
        type: 'SET_ERROR_PROFESSOR',
        payload: {
          error: null,
          status: null,
        },
      });
    }
  }, [pdfUploadStatus]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
      dispatch(documentVerified(null));
      setFile(null);
      clearTimeout(timeout.current);
    };
  }, []);

  const updateUploadProgress = data => {
    if (mountedRef.current) {
      setUploadProgress(Math.round((100 * data.loaded) / data.total));
    }
  };

  return (
    <div className='professor-homepage'>
      <div className='site-header'>
        <div className='site-logo' />
        <div className='site-title'>{t('login.siteTitle')}</div>
        <div className='site-text-under-title'>{t('login.siteSubtitle')}</div>
      </div>
      <div className='professor-homepage__button-container'>
        <>
          <NavLink
            className='professor-homepage__button-container__student-list'
            to='/students-list'
          >
            <div className='professor-homepage__button-container__student-list__text'>
              {t('professorHomepage.studentList')}
            </div>
          </NavLink>
          <NavLink
            className='professor-homepage__button-container__student-summary'
            to='/summary-list'
          >
            <div className='professor-homepage__button-container__student-summary__text'>
              {t('professorHomepage.summary')}
            </div>
          </NavLink>
          <NavLink
            className='professor-homepage__button-container__companies-list'
            to='/companies-list'
          >
            <div className='professor-homepage__button-container__companies-list__text'>
              {t('professorHomepage.companies')}
            </div>
          </NavLink>
          <NavLink
            className='professor-homepage__button-container__mentors-list'
            to='/mentors-list'
          >
            <div className='professor-homepage__button-container__mentors-list__text'>
              {t('professorHomepage.mentors')}
            </div>
          </NavLink>
        </>
        {isAdmin && (
          <>
            <NavLink
              className='professor-homepage__button-container__professors-list'
              to='/professors-list'
            >
              <div className='professor-homepage__button-container__professors-list__text'>
                {t('professorHomepage.professors')}
              </div>
            </NavLink>
            <NavLink
              className='professor-homepage__button-container__subjects-list'
              to='/subjects'
            >
              <div className='professor-homepage__button-container__subjects-list__text'>
                {t('professorHomepage.subjects')}
              </div>
            </NavLink>
            <NavLink
              className='professor-homepage__button-container__studies-list'
              to='/studies-list'
            >
              <div className='professor-homepage__button-container__studies-list__text'>
                {t('professorHomepage.studies')}
              </div>
            </NavLink>
            <NavLink
              className='professor-homepage__button-container__audit-list'
              to='/audit'
            >
              <div className='professor-homepage__button-container__audit-list__text'>
                {t('professorHomepage.auditLogs')}
              </div>
            </NavLink>
            <NavLink
              className='professor-homepage__button-container__synchronization'
              to='/synchronization'
            >
              <div className='professor-homepage__button-container__synchronization__text'>
                {t('professorHomepage.studentSync')}
              </div>
            </NavLink>
          </>
        )}
        <NavLink
          className='professor-homepage__button-container__internships-list'
          to='/internship-requests'
        >
          <div className='professor-homepage__button-container__internships-list__text'>
            {t('professorHomepage.internshipRequests')}
          </div>
        </NavLink>
        <div
          className={classNames({
            'professor-homepage__button-container__pdf-check': true,
            'pdf-is-valid': pdfDocumentVerified,
            'pdf-is-invalid':
              pdfDocumentVerified !== null && pdfDocumentVerified === false,
          })}
        >
          <input
            type='file'
            id='pdf-file'
            hidden
            accept='.pdf'
            value={file ? file : ''}
            onClick={() => {
              setFile('');
              dispatch(documentVerified(null));
              clearTimeout(timeout.current);
            }}
            onChange={e => {
              if (e.target.files[0]) {
                const documentFile = new FormData();
                documentFile.append('file', e.target.files[0]);
                dispatch(
                  verifyValidityOfPdf(documentFile, updateUploadProgress)
                );
              }
            }}
          />
          <label
            htmlFor='pdf-file'
            className='professor-homepage__button-container__pdf-check__label'
          >
            <div className='professor-homepage__button-container__pdf-check__label__text'>
              {pdfDocumentVerified !== null
                ? !pdfDocumentVerified
                  ? t('professorHomepage.documentCheck.documentIsNotValid')
                  : t('professorHomepage.documentCheck.documentIsValid')
                : uploadProgress
                  ? `${uploadProgress}${t(
                    'professorHomepage.documentCheck.uploaded'
                  )}`
                  : t('professorHomepage.documentCheck.default')}
            </div>
          </label>
        </div>
        <div
          className='professor-homepage__button-container__certificate-download'
          onClick={() => {
            axios
              .get('/assets/POTVRDA O ZAPOSLENJU.pdf', {
                responseType: 'blob',
              })
              .then(response => {
                let url = window.URL.createObjectURL(response.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = t('professorHomepage.downloadedCertificateName');
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              });
          }}
        >
          <div className='professor-homepage__button-container__certificate-download__text'>
            {t('professorHomepage.downloadCertificateOfEmployment')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessorHomepage;
