import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataDisplay from '../DataDisplay/DataDisplay';
import './Tutorials.scss';

const Tutorials = props => {
  const { t } = useTranslation();
  const [selectedTutorialNumber, setSelectedTutorialNumber] = useState(null);

  return (
    <div className='tutorials-page'>
      <DataDisplay
        dataHeader={t('breadCrumbs.tutorials')}
        headerBolded
        removeTopSeparator
        headerFontSize={23}
        headerTextColor={'#005BA7'}
      />
      <div className='tutorials-page__main'>
        <div className='tutorials-page__main__tutorials-component'>
          <div className='tutorials-page__main__tutorials-component__list'>
            {props.tutorialsList &&
              props.tutorialsList.map((tutorial, index) => (
                <button
                  className={classNames({
                    'tutorial-button': true,
                    selected: index === selectedTutorialNumber?.id,
                  })}
                  key={index}
                  onClick={() =>
                    setSelectedTutorialNumber({
                      id: index,
                      ...tutorial.tutorial,
                    })
                  }
                >
                  {tutorial.name}
                </button>
              ))}
          </div>
          {selectedTutorialNumber && (
            <div className='tutorials-page__main__tutorials-component__view'>
              <div className='steps'>{selectedTutorialNumber.text}</div>
              <img
                className='source'
                src={selectedTutorialNumber.source}
                alt='tutorial'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Tutorials.propTypes = {
  tutorialsList: PropTypes.array,
};

export default Tutorials;
