const settings = {
  apiKey: window.env.apiKey,
  authDomain: window.env.authDomain,
  projectId: window.env.projectId,
  storageBucket: window.env.storageBucket,
  messagingSenderId: window.env.messagingSenderId,
  appId: window.env.appId,
  measurementId: window.env.measurementId,
  vapidKey: window.env.vapidKey,
  REACT_APP_VEVU_FE_PORT: window.env.REACT_APP_VEVU_FE_PORT,
  REACT_APP_SAML_URL: window.env.REACT_APP_SAML_URL,
  REACT_APP_AUTH_URL: window.env.REACT_APP_AUTH_URL,
  REACT_APP_API_URL: window.env.REACT_APP_API_URL,
};

export default settings;
