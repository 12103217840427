import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  fetchCompanies,
  fetchCompaniesByName,
  fetchCompaniesProfessor,
  fetchCompaniesByNameProfessor,
  downloadAllPublicCompanies,
  executeRequestsWithLoading,
  fetchStudiesProfessor,
} from '../../store/actions';
import { CompaniesList } from './ComapniesList/CompaniesList';

import './Companies.scss';
import CompaniesPagination from './CompaniesPagination/CompaniesPagination';
import DataDisplay from '../SharedComponents/DataDisplay/DataDisplay';
import DisplayOrCreateCompanyModal from './CompaniesModal/DisplayOrCreateCompanyModal';
import Search from '../SharedComponents/Search/Search';
import AddButton from '../SharedComponents/AddButton/AddButton';
import Breadcrumbs from '../SharedComponents/Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';

export const Companies = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [showCompanyModal, setShowCompanymodal] = useState(false);
  const [companyToDisplay, setCompanyToDisplay] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const companies = useSelector(state => state.companies.data);
  const currentSubject = useSelector(state => state.subjects.selectedSubject);

  useEffect(() => {
    fetchCompaniesByPage();
    if (user.role === 'professor' || user.role === 'admin') {
      dispatch(fetchStudiesProfessor());
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchCompaniesByPage = async () => {
    await executeRequestsWithLoading(
      props.professorView
        ? searchQuery !== null && searchQuery !== ''
          ? [dispatch(fetchCompaniesByNameProfessor(searchQuery))]
          : [dispatch(fetchCompaniesProfessor())]
        : searchQuery !== null && searchQuery !== ''
          ? [dispatch(fetchCompaniesByName(searchQuery))]
          : [dispatch(fetchCompanies())],
      setLoading,
      mountedRef
    );
  };

  return (
    <div>
      {!props.studentInternshipCompanySelect && <Breadcrumbs />}
      <div className='companies'>
        <DataDisplay
          dataHeader={t('breadCrumbs.companiesList')}
          headerBolded
          headerFontSize={23}
          headerTextColor={'#005BA7'}
          dataFullWidth
          floatDataRight
          TopSpacing={20}
          data={
            <div className='companies__actions'>
              <Search
                setLoading={setLoading}
                setSearchQuery={setSearchQuery}
                fetchData={() => {
                  if (props.professorView) {
                    return fetchCompaniesProfessor();
                  } else {
                    return fetchCompanies();
                  }
                }}
                fetchDataByName={name => {
                  if (props.professorView) {
                    return fetchCompaniesByNameProfessor(name);
                  } else {
                    return fetchCompaniesByName(name);
                  }
                }}
                searchingByInfo={t('inputs.searchByInfo.companiesList')}
              />
              <AddButton
                customClassName='add-button'
                text={t('buttons.addNewCompany')}
                setShowModal={setShowCompanymodal}
              />
              {!props.studentInternshipCompanySelect && (
                <button
                  className='download-companies-pdf'
                  onClick={() => {
                    dispatch(downloadAllPublicCompanies());
                  }}
                >
                  {t('buttons.downloadCompanyList')}
                </button>
              )}
            </div>
          }
        />
        <DataDisplay
          TopSpacing={40}
          dataFullWidth
          dataClass='companies__list'
          data={
            <>
              <div className='companies__list__display-list'>
                {loading && <div className='loader-small' />}
                <div hidden={loading}>
                  {companies && companies.length > 0 ? (
                    <CompaniesList
                      role={user.role}
                      studentInternshipCompanySelect={
                        props.studentInternshipCompanySelect
                      }
                      setShowCompanymodal={
                        props.studentInternshipCompanySelect
                          ? null
                          : setShowCompanymodal
                      }
                      setCompanyToDisplay={
                        props.setSelectedCompany
                          ? props.setSelectedCompany
                          : setCompanyToDisplay
                      }
                    />
                  ) : searchQuery === null ? (
                    t('notFound.companiesList.noListFetched')
                  ) : (
                    t('notFound.companiesList.noSearchResult')
                  )}
                </div>
                <div className='companies__list__pagination'>
                  <CompaniesPagination
                    fetchCompaniesByPage={fetchCompaniesByPage}
                  />
                </div>
              </div>
            </>
          }
        />
      </div>
      {showCompanyModal && (
        <DisplayOrCreateCompanyModal
          studentInternshipCompanySelect={props.studentInternshipCompanySelect}
          setShowCompanymodal={setShowCompanymodal}
          role={user.role}
          professorView={props.professorView}
          setCompanyToDisplay={setCompanyToDisplay}
          companyToDisplay={companyToDisplay}
          currentSubject={currentSubject}
        />
      )}
    </div>
  );
};

Companies.propTypes = {
  professorView: PropTypes.bool,
  studentInternshipCompanySelect: PropTypes.bool,
  setSelectedCompany: PropTypes.func,
  setShowAddCompanyModal: PropTypes.func,
};
