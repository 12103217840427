import axios from 'axios';
import Cookies from 'js-cookie';
import { studiesFetched } from '.';
import settings from '../../settings';
import {
  DOCUMENT_VERIFIED,
  FETCHED_PROFESSORS_FOR_SUBJECT,
  FETCHED_PROFESSOR_DETAILS,
  PROFESSORS_FETCHED,
  SET_DESCRIPTION_TO_INTERNSHIP,
  SET_ERROR_INTERNSHIP_REQUESTS_LIST,
  SET_ERROR_PROFESSOR,
  SET_ERROR_STUDIES,
  SET_PAGE_PER_PAGE_PROFESSORS_LIST,
  SET_SHORT_DESCRIPTION_TO_INTERNSHIP,
} from '../types';
import {
  fetchInternshipRequests,
  fetchInternshipRequestsProfessor,
  internshipStudentFetched,
} from './internshipActions';
import { setNewAddedAccount } from './userActions';

export const fetchProfessorInfo = () => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/auth/info`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      await dispatch({
        type: FETCHED_PROFESSOR_DETAILS,
        payload: response.data.data,
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchStudiesProfessor = setLoading => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url:
          getState().user.role === 'professor'
            ? `${settings.REACT_APP_API_URL}/api/v1/studies/for-professor`
            : `${settings.REACT_APP_API_URL}/api/v1/studies`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(studiesFetched(response.data.data));
      if (setLoading) {
        setLoading(false);
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDIES,
          payload: {
            error:
              error.response && error.response.data && error.response.data.data,
            status: error.response && error.response.status,
          },
        })
      );
    }
  };
};

export const createProfessor = (
  professor,
  addProfesorToSubject,
  selectedSubjectId
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          firstName: professor.firstName,
          lastName: professor.lastName,
          vevuCode: professor.code,
          email: professor.email,
          phone: professor.phone,
          active: professor.active,
          subjects: professor.subjects
            ? [...professor.subjects]
            : [],
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_PROFESSOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch(setNewAddedAccount(response.data.data));
      if (addProfesorToSubject) {
        await dispatch(getProfessorsForSubject(selectedSubjectId));
      } else {
        await dispatch(fetchProfessors());
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const updateProfessor = professor => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/${professor.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          firstName: professor.firstName,
          lastName: professor.lastName,
          vevuCode: professor.code,
          email: professor.email,
          phone: professor.phone,
          active: professor.active,
          subjects: professor.subjects ? [...professor.subjects] : [],
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_PROFESSOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      await dispatch(fetchProfessors());
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const regenerateProfessorPassword = professorId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/${professorId}/regenerate-password`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_PROFESSOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_PROFESSOR,
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch(setNewAddedAccount({ password: response.data.data }));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchProfessorDetails = professorId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/${professorId}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch({
        type: FETCHED_PROFESSOR_DETAILS,
        payload: response.data.data,
      });
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const approvePublicInternshipRequest = (
  requestId,
  description,
  shortDescription,
  companyOrganizationalUnit,
  startDate,
  allRequestsList,
  disableApproveButton
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internship-requests/${requestId}/accept-public`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          startDate,
          shortDescription,
          description,
          companyOrganizationalUnit,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: null,
        },
      });
      if (disableApproveButton) {
        disableApproveButton(false);
      }
      dispatch(internshipStudentFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        if (allRequestsList) {
          dispatch({
            type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
            payload: {
              error: error.response.data.data,
              status: error.response.status,
            },
          });
        } else {
          dispatch({
            type: SET_ERROR_PROFESSOR,
            payload: {
              error: error.response.data.data,
              status: error.response.status,
            },
          });
        }
      }
    }
  };
};

export const approvePrivateInternshipRequest = (
  requestId,
  companyOrganizationalUnit,
  startDate,
  allRequestsList,
  disableApproveButton
) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internship-requests/${requestId}/accept-private`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          companyOrganizationalUnit,
          startDate,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: null,
        },
      });
      if (disableApproveButton) {
        disableApproveButton(false);
      }
      dispatch(internshipStudentFetched(response.data.data));
    } catch (error) {
      if (error.response) {
        if (allRequestsList) {
          dispatch({
            type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
            payload: {
              error: error.response.data.data,
              status: error.response.status,
            },
          });
        } else {
          dispatch({
            type: SET_ERROR_PROFESSOR,
            payload: {
              error: error.response.data.data,
              status: error.response.status,
            },
          });
        }
      }
    }
  };
};

export const rejectInternshipRequest = (requestId, internshipRequestList) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/internship-requests/${requestId}/reject`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_INTERNSHIP_REQUESTS_LIST,
        payload: {
          error: null,
          status: null,
        },
      });
      if (getState().user.role === 'student') {
        await dispatch(fetchInternshipRequests());
      } else if (!internshipRequestList) {
        await dispatch(fetchInternshipRequestsProfessor());
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const getProfessorsForSubject = subjectId => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/subjects/${subjectId}/professors`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      return dispatch(professorsForSubjectFetched(response.data.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchProfessors = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/pagination?page=${
          getState().professors.page
        }&per_page=${getState().professors.perPage}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageProfessorsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchProfessors());
      } else {
        dispatch(professorsFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const fetchProfessorsByName = name => {
  return async (dispatch, getState) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/professors/pagination?page=${
          getState().professors.page
        }&per_page=${getState().professors.perPage}&search=${name}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (
        response.data.data.page > response.data.data.totalPages &&
        response.data.data.totalPages !== 0
      ) {
        dispatch(
          setPageAndPerPageProfessorsList(
            response.data.data.totalPages,
            response.data.data.perPage
          )
        );
        await dispatch(fetchProfessorsByName(name));
      } else {
        dispatch(professorsFetched(response.data.data));
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const verifyValidityOfPdf = (file, setUploadProgress) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/pdf-signature-verifier/verify`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        onUploadProgress: data => {
          setUploadProgress(data);
        },
        data: file,
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_PROFESSOR,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch(documentVerified(response.data.data));
      if (setUploadProgress) {
        setUploadProgress(null);
      }
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_PROFESSOR,
          payload: {
            error: error.response.data.data,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const setPageAndPerPageProfessorsList = (page, perPage) => ({
  type: SET_PAGE_PER_PAGE_PROFESSORS_LIST,
  payload: { page, perPage },
});

export const documentVerified = data => ({
  type: DOCUMENT_VERIFIED,
  payload: data,
});

export const professorsFetched = data => ({
  type: PROFESSORS_FETCHED,
  payload: data,
});

export const professorsForSubjectFetched = data => ({
  type: FETCHED_PROFESSORS_FOR_SUBJECT,
  payload: data,
});

export const setShortDescriptionToInternship = shortDescription => ({
  type: SET_SHORT_DESCRIPTION_TO_INTERNSHIP,
  payload: shortDescription,
});

export const setDescriptionToInternship = description => ({
  type: SET_DESCRIPTION_TO_INTERNSHIP,
  payload: description,
});
