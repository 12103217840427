import hr from 'date-fns/locale/hr';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInternshipForStudent,
  executeRequestsWithLoading,
} from '../../../store/actions';
import { Companies } from '../../Companies/Companies';
import { AddOrUpdateMentorModal } from '../../SharedComponents/AddOrUpdateMentorModal/AddOrUpdateMentorModal';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import Modal from '../../SharedComponents/Modal/Modal';
import { Signing } from '../../SharedComponents/Signing/Signing';
import MentorsPage from '../MentorListPage/MentorsPage';
import './AddInternshipForStudentModal.scss';

registerLocale('hr', hr);

const AddInternshipForStudentModal = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const addInternshipError = useSelector(state => state.internship.error);
  const addInternshipStatus = useSelector(state => state.internship.status);
  const professor = useSelector(state => state.professors);
  const user = useSelector(state => state.user);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [description, setDescription] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [organizationalUnit, setOrganizationalUnit] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [signature, setSignature] = useState(
    user.role === 'professor' && professor.professorDetails?.signature
      ? professor.professorDetails.signature
      : null
  );
  const [showSigningModal, setShowSigningModal] = useState(false);
  const [showAddMentorModal, setShowAddMentorModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      mountedRef.current = false;
      setSelectedCompany(null);
      setSelectedMentor(null);
      setOrganizationalUnit('');
      setDescription('');
      setShortDescription('');
      setSignature(null);
      setStartDate(null);
    };
  }, []);

  useEffect(() => {
    if (addInternshipStatus === 201) {
      dispatch({
        type: 'SET_ERROR_INTERNSHIP',
        payload: {
          error: null,
          status: null,
        },
      });
      props.setShowAddInternshipModal(false);
    }
  }, [addInternshipStatus]);

  const handleSubmit = async () => {
    await executeRequestsWithLoading(
      [
        dispatch(
          createInternshipForStudent(
            description,
            shortDescription,
            startDate,
            selectedCompany.id,
            selectedMentor.id,
            signature,
            organizationalUnit
          )
        ),
      ],
      props.setLoading,
      mountedRef,
      true
    );
  };

  return (
    <Modal
      className='add-internship-for-student-modal'
      closeModal={() => {
        props.setShowAddInternshipModal(false);
      }}
    >
      <>
        <div className='modal-container-add-internship-for-student'>
          <div className='modal-container-add-internship-for-student__header'>
            <div className='header-info-image'></div>
            <div className='modal-container-add-internship-for-student__header__header-text'>
              <div className='header-main-text'>
                {t('modal.addInternshipForStudent.mainHeaderText')}
              </div>
              <div className='header-small-text'>
                {t('modal.addInternshipForStudent.descriptionHeaderText')}
              </div>
            </div>
            <div
              className='header-close-icon'
              onClick={() => props.setShowAddInternshipModal(false)}
            ></div>
          </div>
          <div className='modal-container-add-internship-for-student__body'>
            {selectedCompany && (
              <div className='edit-section'>
                <DataDisplay
                  dataHeader={selectedCompany.name}
                  BottomSpacing={20}
                  TopSpacing={20}
                  headerBolded
                  data={
                    <button
                      type='button'
                      className='edit-button'
                      onClick={() => {
                        setSelectedCompany(null);
                        setSelectedMentor(null);
                        dispatch({
                          type: 'SET_ERROR_INTERNSHIP',
                          payload: {
                            error: null,
                            status: null,
                          },
                        });
                      }}
                    >
                      {t('buttons.edit')}
                    </button>
                  }
                />
              </div>
            )}
            {selectedMentor && (
              <div className='edit-section'>
                <DataDisplay
                  dataHeader={
                    <>
                      {selectedMentor.firstName} {selectedMentor.lastName}
                    </>
                  }
                  BottomSpacing={20}
                  headerBolded
                  data={
                    <button
                      type='button'
                      className='edit-button'
                      onClick={() => {
                        setSelectedMentor(null);
                      }}
                    >
                      {t('buttons.edit')}
                    </button>
                  }
                />
              </div>
            )}
            {!selectedCompany && (
              <>
                <Companies
                  professorView
                  studentInternshipCompanySelect
                  setSelectedCompany={setSelectedCompany}
                />
              </>
            )}
            {selectedCompany && !selectedMentor && (
              <>
                <MentorsPage
                  setSelectedMentor={setSelectedMentor}
                  studentInternshipMentorSelect
                  setShowAddMentorModal={setShowAddMentorModal}
                  companyId={selectedCompany.id}
                />
              </>
            )}
            {selectedCompany && selectedMentor && (
              <div className='edit-section'>
                <DataDisplay
                  dataHeader={t('inputs.description')}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <div>
                      <textarea
                        className='add-description-input'
                        type='text'
                        onChange={e => setDescription(e.target.value)}
                        value={description}
                      />
                      {addInternshipError &&
                        addInternshipError['description'] && (
                        <div className='error'>
                          {addInternshipError['description'].notEmpty}
                        </div>
                      )}
                    </div>
                  }
                />
                <DataDisplay
                  dataHeader={t('inputs.shortDescription')}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  dataClass='organizational-unit'
                  data={
                    <div>
                      <input
                        className='add-short-description-input'
                        type='text'
                        onChange={e => setShortDescription(e.target.value)}
                        value={shortDescription}
                      />
                      {addInternshipError &&
                        addInternshipError['shortDescription'] && (
                        <div className='error'>
                          {addInternshipError['shortDescription'].notEmpty}
                        </div>
                      )}
                    </div>
                  }
                />
                <DataDisplay
                  dataHeader={t(
                    'studentHomepage.internshipInfo.organizationalUnit'
                  )}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  dataClass='organizational-unit'
                  data={
                    <div>
                      <input
                        className='add-organizational-unit-input'
                        type='text'
                        onChange={e => setOrganizationalUnit(e.target.value)}
                        value={organizationalUnit}
                      />
                      {addInternshipError &&
                        addInternshipError['companyOrganizationalUnit'] && (
                        <div className='error'>
                          {
                            addInternshipError['companyOrganizationalUnit']
                              .notEmpty
                          }
                        </div>
                      )}
                    </div>
                  }
                />
                <div className='signature-edit-section'>
                  {signature && (
                    <img className='trimmed-signed-image' src={signature} />
                  )}
                  <button
                    type='button'
                    className='add-signature-button'
                    onClick={() => setShowSigningModal(true)}
                  >
                    {signature
                      ? t('buttons.editSignature')
                      : t('buttons.addSignature')}
                  </button>
                </div>
                {addInternshipError && addInternshipError['signature'] && (
                  <div className='error'>
                    {addInternshipError['signature'].notEmptyForAuthTypes}
                  </div>
                )}
              </div>
            )}
            {selectedCompany && selectedMentor && (
              <>
                <div className='modal-container__new-internship-modal__form__date-input'>
                  <DataDisplay
                    dataHeader={t(
                      'inputs.datepickerPlannedInternshipStartDate'
                    )}
                    headerBolded
                    displayInColumn
                    dataSeparatorTopSpacing={4}
                    data={
                      <div className='datepicker-wrapper'>
                        <DatePicker
                          disabledKeyboardNavigation
                          locale='hr'
                          withPortal
                          fixedHeight
                          minDate={new Date().setDate(new Date().getDate() + 1)}
                          calendarContainer={({
                            className,
                            children,
                            showPopperArrow,
                          }) =>
                            DatepickerCustomContainer({
                              className,
                              children,
                              mainHeaderText: t(
                                'dropdowns.internshipStartDate.mainHeaderText'
                              ),
                              smallHeaderText: t(
                                'dropdowns.internshipStartDate.descriptionHeaderText'
                              ),
                              showPopperArrow,
                            })
                          }
                          className='new-company-datepicker'
                          dateFormat='dd.MM.yyyy.'
                          selected={startDate}
                          value={startDate}
                          onChange={date => setStartDate(date)}
                        />
                        <div className='non-clickable-area' />
                      </div>
                    }
                  />
                  {addInternshipError && addInternshipError.startDate && (
                    <div className='error'>
                      {addInternshipError.startDate.dateAfter}
                    </div>
                  )}
                </div>
                <button
                  disabled={!startDate}
                  className='modal-container__new-internship-modal__send-button'
                  type='button'
                  id='send-btn'
                  onClick={e => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  {t('buttons.createInternshipForStudent')}
                </button>
              </>
            )}
            {addInternshipError && addInternshipError.companyId && (
              <div className='error'>
                {addInternshipError.companyId.hasOpenInternshipSlots}
              </div>
            )}
            {addInternshipError && addInternshipError.studentId && (
              <div className='error'>{addInternshipError.studentId.exist}</div>
            )}
          </div>
        </div>
        {showAddMentorModal && (
          <AddOrUpdateMentorModal
            mentor={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              companyOib: selectedCompany.oib,
              active: true,
            }}
            companyId={selectedCompany.id}
            studentMentorSelect
            setShowAddMentorModal={setShowAddMentorModal}
          />
        )}
        {showSigningModal && (
          <Signing
            internshipInput
            signature={signature}
            setShowSignatureModal={setShowSigningModal}
            sendSignatureData={data => {
              setSignature(data);
            }}
          />
        )}
      </>
    </Modal>
  );
};

AddInternshipForStudentModal.propTypes = {
  setShowAddInternshipModal: PropTypes.func,
  setLoading: PropTypes.func,
};

export default AddInternshipForStudentModal;
