import i18n from '../../../i18n';
import approveInternshipRequestRequestList from './ProfessorGifs/ApproveInternshipRequestRequestList.gif';
import approveInternshipRequestStudentPage from './ProfessorGifs/ApproveInternshipRequestStudentPage.gif';
import removeInternship from './ProfessorGifs/RemoveInternship.gif';
import addInternshipForStudent from './ProfessorGifs/AddInternshipForStudent.gif';
import sendEmailToStudent from './ProfessorGifs/SendEmailToStudent.gif';
import addOrEditCompany from './ProfessorGifs/AddOrEditCompany.gif';
import companySubjectLimit from './ProfessorGifs/CompanySubjectLimit.gif';
import addOrEditMentor from './ProfessorGifs/AddOrEditMentor.gif';
import editSubjectDateRange from './ProfessorGifs/EditSubjectDateRange.gif';
import approveAndRejectInternship from './MentorGifs/ApproveAndRejectInternship.gif';
import addAndEditComment from './ProfessorGifs/AddAndEditComment.gif';
import syncSingleStudentProfessor from './ProfessorGifs/SyncSingleStudentProfessor.gif';
import downloadPassedStudents from './ProfessorGifs/DownloadPassedStudents.gif';

const defaultNameSpace = 'tutorials.professorTutorials.';

export const tutorialsForProfessor = [
  {
    name: i18n.t(
      defaultNameSpace + 'approveInternshipRequestRequestList.title'
    ),
    tutorial: {
      text: i18n.t(
        defaultNameSpace + 'approveInternshipRequestRequestList.steps'
      ),
      source: approveInternshipRequestRequestList,
    },
  },
  {
    name: i18n.t(
      defaultNameSpace + 'approveInternshipRequestStudentPage.title'
    ),
    tutorial: {
      text: i18n.t(
        defaultNameSpace + 'approveInternshipRequestStudentPage.steps'
      ),
      source: approveInternshipRequestStudentPage,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'removeInternship.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'removeInternship.steps'),
      source: removeInternship,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addInternshipForStudent.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addInternshipForStudent.steps'),
      source: addInternshipForStudent,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'sendEmailToStudent.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'sendEmailToStudent.steps'),
      source: sendEmailToStudent,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addOrEditCompany.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addOrEditCompany.steps'),
      source: addOrEditCompany,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'companySubjectLimit.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'companySubjectLimit.steps'),
      source: companySubjectLimit,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addOrEditMentor.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addOrEditMentor.steps'),
      source: addOrEditMentor,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'editSubjectDateRange.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'editSubjectDateRange.steps'),
      source: editSubjectDateRange,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'approveAndRejectInternship.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'approveAndRejectInternship.steps'),
      source: approveAndRejectInternship,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addAndEditComment.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addAndEditComment.steps'),
      source: addAndEditComment,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'syncSingleStudentProfessor.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'syncSingleStudentProfessor.steps'),
      source: syncSingleStudentProfessor,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'downloadPassedStudents.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'downloadPassedStudents.steps'),
      source: downloadPassedStudents,
    },
  },
];
