export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const ALL_COMPANIES_FETCHED = 'ALL_COMPANIES_FETCHED';
export const FETCH_COMPANIES_BY_NAME = 'FETCH_COMPANIES_BY_NAME';
export const COMPANIES_BY_NAME_FETCHED = 'COMPANIES_BY_NAME_FETCHED';
export const COMPANIES_FETCHED = 'COMPANIES_FETCHED';
export const SET_PAGE_PER_PAGE_COMPANIES_LIST = 'SET_PAGE_PER_PAGE_COMPANIES_LIST';
export const SET_ERROR_COMPANIES = 'SET_ERROR_COMPANIES';
export const SET_COMPANY_SLOT_CONFIGURATION = 'SET_COMPANY_SLOT_CONFIGURATION';
export const SET_ERROR_COMPANIES_SLOTS = 'SET_ERROR_COMPANIES_SLOTS';
export const COMPANY_DETAILS_FETCHED = 'COMPANY_DETAILS_FETCHED';
