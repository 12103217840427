import i18n from '../../../i18n';
import resetPasswordMentorAndProfessor from './AdminGifs/ResetPasswordMentorAndProfessor.gif';
import addAndEditProfessor from './AdminGifs/AddAndEditProfessor.gif';
import addAndEditStudy from './AdminGifs/AddAndEditStudy.gif';
import addAndEditSubject from './AdminGifs/AddAndEditSubject.gif';
import syncStudents from './AdminGifs/SyncStudents.gif';

const defaultNameSpace = 'tutorials.adminTutorials.';

export const tutorialsForAdmin = [
  {
    name: i18n.t(defaultNameSpace + 'resetPasswordMentorAndProfessor.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'resetPasswordMentorAndProfessor.steps'),
      source: resetPasswordMentorAndProfessor,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addAndEditProfessor.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addAndEditProfessor.steps'),
      source: addAndEditProfessor,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addAndEditStudy.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addAndEditStudy.steps'),
      source: addAndEditStudy,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'addAndEditSubject.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'addAndEditSubject.steps'),
      source: addAndEditSubject,
    },
  },
  {
    name: i18n.t(defaultNameSpace + 'syncStudents.title'),
    tutorial: {
      text: i18n.t(defaultNameSpace + 'syncStudents.steps'),
      source: syncStudents,
    },
  },
];
