import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import {
  REMOVE_DATA_ALL,
  REMOVE_DATA_ALL_EXISTING,
  REMOVE_DATA_SINGLE,
  SET_ERROR_SYNC,
  SET_ERROR_SYNC_ALL,
  SET_ERROR_SYNC_ALL_EXISTING,
  SET_STATUS_CHECK_ALL,
  SET_STATUS_CHECK_ALL_EXISTING,
  SET_STATUS_CHECK_SINGLE,
  SET_SYNC_ALL_DATA,
  SET_SYNC_ALL_EXISTING_DATA,
  SET_SYNC_SINGLE_DATA,
} from '../types';

export const syncAllStudents = () => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/scripts/sync/students`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(scriptsAllDataFetched(response.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SYNC_ALL,
          payload: {
            error: error.response.data.data,
            message: error.response.data.message
              ? error.response.data.message
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const syncExistingStudents = () => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/scripts/sync/existing_students`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(scriptsExistingDataFetched(response.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SYNC_ALL_EXISTING,
          payload: {
            error: error.response.data.data,
            message: error.response.data.message
              ? error.response.data.message
              : null,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const syncStudent = studentJmbag => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/scripts/sync/students/${studentJmbag}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch(scriptsSingleDataFetched(response.data));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_SYNC,
          payload: {
            error: error.response.data.data,
            message: error.response.data.message,
            status: error.response.status,
          },
        })
      );
    }
  };
};

export const checkStatusForScript = (all, uuid, allExistingStudent) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${settings.REACT_APP_API_URL}/api/v1/scripts/${uuid}/finished`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      if (all) {
        if (allExistingStudent) {
          dispatch(scriptStatusCheckAllExistingStudents(response.data));
        } else {
          dispatch(scriptStatusCheckAllStudents(response.data));
        }
      } else {
        dispatch(scriptStatusCheckSingleStudent(response.data));
      }
    } catch (error) {
      if (error.response) {
        if (all) {
          return dispatch({
            type: SET_ERROR_SYNC_ALL,
            payload: {
              error: error.response.data.data,
              message: error.response.data.message
                ? error.response.data.message
                : null,
              status: error.response.status,
            },
          });
        } else {
          return dispatch({
            type: SET_ERROR_SYNC,
            payload: {
              error: error.response.data.data,
              message: error.response.data.message
                ? error.response.data.message
                : null,
              status: error.response.status,
            },
          });
        }
      }
    }
  };
};

export const scriptsAllDataFetched = data => ({
  type: SET_SYNC_ALL_DATA,
  payload: data,
});

export const scriptsExistingDataFetched = data => ({
  type: SET_SYNC_ALL_EXISTING_DATA,
  payload: data,
});

export const scriptsSingleDataFetched = data => ({
  type: SET_SYNC_SINGLE_DATA,
  payload: data,
});

export const scriptStatusCheckAllStudents = data => ({
  type: SET_STATUS_CHECK_ALL,
  payload: data,
});

export const scriptStatusCheckAllExistingStudents = data => ({
  type: SET_STATUS_CHECK_ALL_EXISTING,
  payload: data,
});

export const scriptStatusCheckSingleStudent = data => ({
  type: SET_STATUS_CHECK_SINGLE,
  payload: data,
});

export const removeDataAllStudents = () => ({
  type: REMOVE_DATA_ALL,
});

export const removeDataAllExistingStudents = () => ({
  type: REMOVE_DATA_ALL_EXISTING,
});

export const removeDataSingleStudent = () => ({
  type: REMOVE_DATA_SINGLE,
});
