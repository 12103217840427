import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setPageAndPerPageStudentList } from '../../../../../store/actions';
import Pagination from '../../../../SharedComponents/Pagination/Pagination';

const StudentPagination = props => {
  const pages = useSelector(state => state.studentList.totalPages);
  const dataPerPage = useSelector(state => state.studentList.perPage);
  const page = useSelector(state => state.studentList.page);
  const dispatch = useDispatch();
  const setPagination = (page, perPage) => {
    dispatch(setPageAndPerPageStudentList(page, perPage));
  };
  return (
    <Pagination
      pages={pages}
      dataPerPage={dataPerPage}
      page={page}
      setPageAndPerPage={setPagination}
      fetchDataByPage={props.getStudentsForSubjectByPage}
    />
  );
};

StudentPagination.propTypes = {
  getStudentsForSubjectByPage: PropTypes.func,
};

export default StudentPagination;
