import { PURGE } from 'redux-persist/lib/constants';
import {
  FETCHED_MENTORS,
  FETCHED_MENTORS_FOR_COMPANY,
  SET_ERROR_MENTOR,
  SET_PAGE_PER_PAGE_MENTORS_LIST,
  FETCHED_MENTOR_INFO,
} from '../types';

const initialMentorState = {
  mentors: null,
  mentorInfo: null,
  error: null,
  status: null,
  page: 1,
  perPage: 10,
  totalPages: null,
};

export const mentors = (state = initialMentorState, action) => {
  switch (action.type) {
    case FETCHED_MENTORS: {
      return {
        mentors: action.payload.data,
        mentorInfo: null,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
        status: null,
        error: null,
      };
    }
    case FETCHED_MENTORS_FOR_COMPANY: {
      return {
        ...state,
        mentors: action.payload,
        totalPages: action.payload.totalPages ? action.payload.totalPages : 1,
        status: null,
        error: null,
      };
    }
    case FETCHED_MENTOR_INFO: {
      return {
        ...state,
        mentorInfo: action.payload,
      };
    }
    case SET_PAGE_PER_PAGE_MENTORS_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case SET_ERROR_MENTOR: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialMentorState;
    }
    default:
      return state;
  }
};
