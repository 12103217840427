import { PURGE } from 'redux-persist/lib/constants';
import { LOGIN_ERROR, SET_ERROR } from '../types';

const initialState = {
  message: null,
  error: null,
  status: null,
};

export const errorMessage = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_ERROR: {
      return {
        ...state,
        message: action.payload.message,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
