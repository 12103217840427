import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../../settings';
import { SET_SIGNATURE_TO_USER } from '../types';

export const setSignatureToUser = signature => {
  return async (dispatch, getState) => {
    if (getState().user.role !== 'admin') {
      try {
        const response = await axios({
          method: 'PATCH',
          url: `${settings.REACT_APP_API_URL}/api/v1/auth/signature`,
          headers: {
            Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
          },
          data: {
            signature,
          },
        });
        if (response.status !== 200 && response.status !== 201) {
          throw new Error();
        }
        return dispatch({
          type: SET_SIGNATURE_TO_USER,
          payload: { error: null, status: response.status },
        });
      } catch (error) {
        return (
          error.response &&
          dispatch({
            type: SET_SIGNATURE_TO_USER,
            payload: {
              error: error.response.data.data,
              status: error.response.status,
            },
          })
        );
      }
    }
  };
};
