import React, { useState } from 'react';
import { Container, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';
import '../../../../styles/ListTable.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const AuditList = props => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([
    {
      header: t('list.auditList.header.user'),
      headerKey: 'userName',
    },
    {
      header: t('list.auditList.header.email'),
      headerKey: 'userEmail',
    },
    {
      header: t('list.auditList.header.role'),
      headerKey: 'userAuthType',
    },
    {
      header: t('list.auditList.header.action'),
      headerKey: 'action',
    },
    {
      header: t('list.auditList.header.description'),
      headerKey: 'description',
    },
    {
      header: t('list.auditList.header.date'),
      headerKey: 'loggedAt',
      isSorted: true,
      isSortedDesc: false,
    },
  ]);

  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          sortDataByKey={props.setSorting}
        />
        <tbody className='list-table__item-row'>
          {props.auditLogs.map(audit => {
            return (
              <tr className='spacer  item-row' key={audit.id}>
                <td>{audit.userName}</td>
                <td>{audit.userEmail}</td>
                <td>{t(`userRole.${audit.userAuthType.toLowerCase()}`)}</td>
                <td>{props.checkAction(audit.action)}</td>
                <td>{audit.description}</td>
                <td>{moment(audit.loggedAt).format('DD.MM.YYYY. HH:mm:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

AuditList.propTypes = {
  auditLogs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkAction: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
};

export default AuditList;
