import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, Table } from 'reactstrap';
import {
  certificateOfEmploymentFetched,
  checkStatus,
  currentLogDetailsFetched,
  internshipStudentFetched,
  resetCurrentSubject,
  startLoading,
  studentInfoFetched,
} from '../../../../store/actions';
import '../../../../styles/ListTable.scss';
import ListHeader from '../../../SharedComponents/Listheader/ListHeader';
const StudentList = props => {
  const { t } = useTranslation();
  const studentSelected = useSelector(state => state.student);
  const [headers, setHeaders] = useState([
    {
      header: t('list.studentList.header.name'),
      headerKey: 'name',
      isSorted: false,
      isSortedDesc: false,
    },
    {
      header: t('list.studentList.header.studentID'),
      headerKey: 'jmbag',
      isSorted: false,
      isSortedDesc: false,
    },
    {
      header: t('list.studentList.header.contact'),
      headerKey: 'contact',
      role: false,
    },
    {
      header: t('list.studentList.header.study'),
      headerKey: 'study',
    },
    {
      header: t('list.studentList.header.internshipStatus'),
      headerKey: 'finished',
    },
    {
      header: t('list.studentList.header.dateFinished'),
      headerKey: 'completed_at',
      isSorted: true,
      isSortedDesc: false,
      role: true,
    },
    {
      header: t('list.studentList.header.actions'),
      headerKey: 'actions',
    },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Container fluid>
      <Table width='100%' className='list-table'>
        <ListHeader
          setHeaders={setHeaders}
          headers={headers}
          role={props.summaryList ? props.summaryList : false}
          sortDataByKey={props.setSorting}
        />
        <tbody className='list-table__item-row'>
          {props.studentList.map(student => {
            const internshipStatus = checkStatus(student.status);
            return (
              <tr className='spacer  item-row' key={student.id}>
                <td>{student.name}</td>
                <td>{student.jmbag}</td>
                {!props.summaryList && (
                  <td>
                    <Table width='100%'>
                      <tbody>
                        <tr
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 0,
                          }}
                        >
                          <td
                            id='open-send-email-modal'
                            onClick={() => {
                              props.setSelectedStudent(student);
                              props.setShowSendEmailModal(true);
                            }}
                          >
                            {student.email}
                          </td>
                          {student.phone && <td>{student.phone}</td>}
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                )}
                <td>{student.study}</td>
                <td
                  style={{
                    color:
                      internshipStatus ===
                      t('internshipStatusOnList.internshipCompleted')
                        ? '#468329'
                        : (internshipStatus ===
                            t(
                              'internshipStatusOnList.certificateOfEmploymentRejected'
                            ) ||
                            internshipStatus ===
                              t(
                                'internshipStatusOnList.internshipReviewRejected'
                              )) &&
                          'red',
                  }}
                >
                  {internshipStatus}
                </td>
                {props.summaryList && (
                  <td>
                    {student.completed_at &&
                      moment(student.completed_at).format('DD.MM.YYYY.')}
                  </td>
                )}
                <td>
                  <button
                    id='link-to-student-page'
                    onClick={() => {
                      dispatch(currentLogDetailsFetched(null));
                      if (student.id !== studentSelected?.id) {
                        dispatch(internshipStudentFetched(null));
                        dispatch(certificateOfEmploymentFetched(null));
                        dispatch(resetCurrentSubject());
                        dispatch(startLoading());
                        dispatch(
                          studentInfoFetched({
                            email: null,
                            firstName: null,
                            id: student.id,
                            jmbag: null,
                            lastName: null,
                            phone: null,
                            semester: null,
                            study: null,
                          })
                        );
                      }
                      history?.push('/student-homepage');
                    }}
                  >
                    {t('buttons.select')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

StudentList.propTypes = {
  studentList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  approveInternshipRequestHandler: PropTypes.func,
  setSorting: PropTypes.func.isRequired,
  summaryList: PropTypes.bool,
  setSelectedStudent: PropTypes.func.isRequired,
  setShowSendEmailModal: PropTypes.func.isRequired,
  selectedSubject: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default StudentList;
