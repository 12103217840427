import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { setNewAddedAccount } from '../../../store/actions';
import DataDisplay from '../DataDisplay/DataDisplay';
import Modal from '../Modal/Modal';
import './PasswordDisplayModal.scss';

export const PasswordDisplayModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createdAccount = useSelector(
    state => state.createdAccount.createdAccount
  );
  useEffect(() => {
    return () => {
      dispatch(setNewAddedAccount(null));
    };
  }, []);
  return (
    <Modal>
      <div className='send-reason-modal'>
        <div className='send-reason-modal__header'>
          <div className='header-info-image'></div>
          <div className='send-reason-modal__header__header-text'>
            <div className='header-main-text'>
              {t('modal.passwordDisplay.mainHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowPasswordDisplayModal(false)}
          ></div>
        </div>
        <div className='send-reason-modal__body'>
          <DataDisplay
            dataHeader={t('inputs.passwordForCreatedUser')}
            headerBolded
            removeTopSeparator
            dynamicHeaderWidth
            centerHeaderVertically
            headerFontSize={17}
            data={
              <>
                <a data-tip data-for='password-text' data-event='click'>
                  <div
                    className='copy-to-clipboard'
                    onMouseDown={() => {
                      navigator.clipboard.writeText(createdAccount?.password);
                    }}
                  >
                    {createdAccount?.password}
                  </div>
                </a>
                <ReactTooltip
                  id='password-text'
                  getContent={() => (
                    <div style={{ color: 'black' }}>
                      {t('buttons.passwordSavedToClipboard')}
                    </div>
                  )}
                  effect='solid'
                  delayHide={2000}
                  place={'right'}
                  border
                  type={'success'}
                  eventOff='click'
                />
              </>
            }
          />
        </div>
      </div>
    </Modal>
  );
};

PasswordDisplayModal.propTypes = {
  setShowPasswordDisplayModal: PropTypes.func.isRequired,
};
