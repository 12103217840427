import { PURGE } from 'redux-persist/lib/constants';
import { START_LOADING, STOP_LOADING } from '../types';

const initState = {
  loading: false,
  loadingInfo: false,
};

export const loader = (state = initState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case 'SET_LOADING_INFO':
      return { ...state, loadingInfo: action.payload };
    case PURGE: {
      return initState;
    }
    default:
      return state;
  }
};
