import hr from 'date-fns/locale/hr';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCompanySlotConfiguration,
  createNewCompany,
  deleteCompanySlotConfiguration,
  executeRequestsWithLoading,
  fetchCompanySlotConfiguration,
  fetchSubjects,
  fetchSubjectsForProfessor,
  sendPrivateInternshipRequest,
  sendPublicInternshipRequest,
  updateCompany,
  updateCompanySlotConfiguration,
} from '../../../store/actions';
import DataDisplay from '../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import { Dropdown } from '../../SharedComponents/Dropdown/Dropdown';
import Modal from '../../SharedComponents/Modal/Modal';
import '../Companies.scss';

registerLocale('hr', hr);

const DisplayOrCreateCompanyModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const subjects = useSelector(state => state.subjects?.subjects);
  const studies = useSelector(state => state.studies?.studies);
  const selectedStudy = useSelector(state => state.studies?.selectedStudy);
  const companySlotsList = useSelector(
    state => state.companies.companySlotConfiguration
  );
  const addInternshipError = useSelector(state => state.internship.error);
  const addInternshipStatus = useSelector(state => state.internship.status);
  const addCompanyMessage = useSelector(state => state.companies.message);
  const addCompanyError = useSelector(state => state.companies.error);
  const addCompanyStatus = useSelector(state => state.companies.status);
  const CompanySlotsStatus = useSelector(
    state => state.companies.companiesSlotsStatus
  );

  const [loading, setLoading] = useState(false);
  const [subjectDropdownTitle, setSubjectDropdownTitle] = useState(
    t('dropdowns.subjectsDropdown.defaultOption')
  );
  const [studyDropdownTitle, setStudyDropdownTitle] = useState(
    t('dropdowns.studiesDropdown.defaultOption')
  );
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [companySlotsConfiguration, setCompanySlotsConfiguration] = useState({
    slots: null,
    academicYear: '',
  });
  const [newCompanyState, setNewCompanyState] = useState(
    props.companyToDisplay
      ? { ...props.companyToDisplay }
      : {
        address: '',
        city: '',
        contactPersonEmail: '',
        contactPersonFullName: '',
        contactPersonPhone: '',
        mentorEmail: '',
        mentorFirstName: '',
        mentorLastName: '',
        mentorPhone: '',
        country: '',
        name: '',
        oib: '',
        postalCode: '',
        description: '',
        shortDescription: '',
        url: '',
        active: true,
        economicBranch: '',
        enableInternshipRequests: true,
      }
  );

  const handleSendRequest = type => {
    if (type === 'public') {
      dispatch(
        sendPublicInternshipRequest(
          props.currentSubject.id,
          newCompanyState.id,
          startDate
        )
      );
    } else if (type === 'create-new') {
      dispatch(
        createNewCompany(
          newCompanyState,
          companySlotsConfiguration,
          selectedSubject
        )
      );
    } else if (type === 'edit') {
      dispatch(updateCompany(newCompanyState));
    } else {
      dispatch(
        sendPrivateInternshipRequest(props.currentSubject.id, newCompanyState)
      );
    }
  };

  useEffect(() => {
    if (addInternshipStatus === 201) {
      if (props.setCompanyToDisplay) props.setCompanyToDisplay(null);
      props.setShowCompanymodal(false);
    }
  }, [addInternshipStatus]);

  useEffect(() => {
    if (addCompanyStatus === 201 || addCompanyStatus === 200) {
      if (props.setCompanyToDisplay) props.setCompanyToDisplay(null);
      props.setShowCompanymodal(false);
    }
  }, [addCompanyStatus]);

  useEffect(() => {
    if (CompanySlotsStatus === 201 || CompanySlotsStatus === 200) {
      dispatch({
        type: 'SET_ERROR_COMPANIES_SLOTS',
        payload: {
          error: null,
          status: null,
        },
      });
      setSelectedSubject(null);
      setSubjectDropdownTitle(t('dropdowns.subjectsDropdown.defaultOption'));
    }
  }, [CompanySlotsStatus]);

  const fetchCompanySlots = async () => {
    await executeRequestsWithLoading(
      [
        dispatch(fetchCompanySlotConfiguration(props.companyToDisplay.id)),
        props.role === 'professor'
          ? dispatch(fetchSubjectsForProfessor())
          : dispatch(fetchSubjects()),
      ],
      setLoading,
      mountedRef
    );
  };

  useEffect(() => {
    if (props.professorView) {
      if (!props.studentInternshipCompanySelect) {
        if (props.companyToDisplay && props.companyToDisplay.id) {
          setStudyDropdownTitle(
            props.companyToDisplay.economicBranch === 'all'
              ? t('dropdowns.studiesDropdown.allDepartments')
              : props.companyToDisplay.economicBranch
          );
          fetchCompanySlots();
        }
      } else {
        setStudyDropdownTitle(
          selectedStudy.department
            .toLowerCase()
            .replace(/\w/, firstLetter => firstLetter.toUpperCase())
        );
        setNewCompanyState({
          ...newCompanyState,
          economicBranch: selectedStudy.department
            .toLowerCase()
            .replace(/\w/, firstLetter => firstLetter.toUpperCase()),
        });
      }
    }
    if (props.role === 'student' && selectedStudy) {
      setStudyDropdownTitle(
        selectedStudy?.department
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase())
      );
      setNewCompanyState({
        ...newCompanyState,
        economicBranch: selectedStudy.department
          .toLowerCase()
          .replace(/\w/, firstLetter => firstLetter.toUpperCase()),
      });
    }
    return () => {
      mountedRef.current = false;
      dispatch({
        type: 'SET_ERROR_COMPANIES',
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch({
        type: 'SET_ERROR_INTERNSHIP',
        payload: {
          error: null,
          status: null,
        },
      });
      dispatch({
        type: 'SET_COMPANY_SLOT_CONFIGURATION',
        payload: { data: null },
      });
    };
  }, []);

  return (
    <Modal
      closeModal={() => {
        if (props.setCompanyToDisplay) props.setCompanyToDisplay(null);
        props.setShowCompanymodal(false);
      }}
    >
      <div className='new-company-modal'>
        <div className='new-company-modal__header'>
          <div className='header-info-image'></div>
          <div className='new-company-modal__header__header-text'>
            <div className='header-main-text'>
              {props.companyToDisplay
                ? props.professorView
                  ? t('modal.companyModal.mainHeader.editCompany')
                  : t('modal.companyModal.mainHeader.companySelect')
                : props.role === 'student'
                  ? t('modal.companyModal.mainHeader.addPrivateCompany')
                  : t('modal.companyModal.mainHeader.addNewCompany')}
            </div>
            <div className='header-small-text'>
              {props.companyToDisplay
                ? props.professorView
                  ? t('modal.companyModal.descriptionHeader.editCompany')
                  : t('modal.companyModal.descriptionHeader.companySelect')
                : props.role === 'student'
                  ? t('modal.companyModal.descriptionHeader.addPrivateCompany')
                  : t('modal.companyModal.descriptionHeader.addNewCompany')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              if (props.setCompanyToDisplay) props.setCompanyToDisplay(null);
              props.setShowCompanymodal(false);
            }}
          ></div>
        </div>
        <div className='new-company-modal__form'>
          <>
            <div className='column-container'>
              <div className='col'>
                <div className='new-company-modal__form__name'>
                  {props.professorView ||
                  (!props.companyToDisplay && !props.professorView) ? (
                      <>
                        <DataDisplay
                          dataHeader={t('inputs.companyName')}
                          headerBolded
                          displayInColumn
                          dataSeparatorTopSpacing={4}
                          removeTopSeparator
                          data={
                            <input
                              className='name-input'
                              type='text'
                              placeholder={t('inputs.companyNamePlaceholder')}
                              name='name-input'
                              value={newCompanyState.name}
                              onChange={e =>
                                setNewCompanyState({
                                  ...newCompanyState,
                                  name: e.target.value,
                                })
                              }
                            />
                          }
                        />
                        {addCompanyError && addCompanyError['name'] && (
                          <div className='error'>
                            {addCompanyError['name'].notEmpty}
                          </div>
                        )}
                        {addInternshipError && addInternshipError['name'] && (
                          <div className='error'>
                            {addInternshipError['name'].notEmpty}
                          </div>
                        )}
                      </>
                    ) : (
                      <DataDisplay
                        dataHeader={t('inputs.companyName')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        removeTopSeparator
                        data={newCompanyState.name}
                      />
                    )}
                </div>
                <div className='new-company-modal__form__oib'>
                  {props.professorView ||
                  (!props.companyToDisplay && !props.professorView) ? (
                      <>
                        <DataDisplay
                          dataHeader={t('inputs.companyPIN')}
                          headerBolded
                          displayInColumn
                          dataSeparatorTopSpacing={4}
                          data={
                            <input
                              name='internship-oib'
                              className='internship-oib'
                              placeholder={t('inputs.companyPINplaceholder')}
                              value={newCompanyState.oib}
                              onChange={e =>
                                setNewCompanyState({
                                  ...newCompanyState,
                                  oib: e.target.value,
                                })
                              }
                            />
                          }
                        />

                        {addCompanyError && addCompanyError['oib'] && (
                          <>
                            <div className='error'>
                              {addCompanyError['oib'].unique}
                            </div>
                            <div className='error'>
                              {addCompanyError['oib'].notEmpty}
                            </div>
                            <div className='error'>
                              {addCompanyError['oib'].oib}
                            </div>
                          </>
                        )}
                        {addInternshipError && addInternshipError['oib'] && (
                          <>
                            <div className='error'>
                              {addInternshipError['oib'].unique}
                            </div>
                            <div className='error'>
                              {addInternshipError['oib'].notEmpty}
                            </div>
                            <div className='error'>
                              {addInternshipError['oib'].oib}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      newCompanyState.oib && (
                        <DataDisplay
                          dataHeader={t('inputs.companyPIN')}
                          headerBolded
                          displayInColumn
                          dataSeparatorTopSpacing={4}
                          data={newCompanyState.oib}
                        />
                      )
                    )}
                </div>
                <div className='new-company-modal__form__internship-description'>
                  {!props.companyToDisplay && props.role === 'student' && (
                    <>
                      <DataDisplay
                        dataHeader={t('inputs.description')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <>
                            <textarea
                              name='internship-description'
                              className='internship-description'
                              placeholder={t('inputs.descriptionPlaceholder')}
                              value={newCompanyState.description}
                              onChange={e =>
                                setNewCompanyState({
                                  ...newCompanyState,
                                  description: e.target.value,
                                })
                              }
                            />
                            {addCompanyError && addCompanyError.description && (
                              <div className='error'>
                                {addCompanyError.description.notEmpty}
                              </div>
                            )}
                            {addInternshipError &&
                              addInternshipError.description && (
                              <div className='error'>
                                {addInternshipError.description.notEmpty}
                              </div>
                            )}
                          </>
                        }
                      />
                    </>
                  )}
                </div>
                <div className='new-company-modal__form__internship-short-description'>
                  {!props.companyToDisplay && props.role === 'student' && (
                    <div>
                      <DataDisplay
                        dataHeader={t('inputs.shortDescription')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <>
                            <input
                              name='internship-shortDescription'
                              className='internship-shortDescription'
                              placeholder={t(
                                'inputs.shortDescriptionPlaceholder'
                              )}
                              value={newCompanyState.shortDescription}
                              onChange={e =>
                                setNewCompanyState({
                                  ...newCompanyState,
                                  shortDescription: e.target.value,
                                })
                              }
                            />
                            {addCompanyError &&
                              addCompanyError.shortDescription && (
                              <div className='error'>
                                {addCompanyError.shortDescription.notEmpty}
                              </div>
                            )}
                            {addInternshipError &&
                              addInternshipError.shortDescription && (
                              <div className='error'>
                                {addInternshipError.shortDescription.notEmpty}
                              </div>
                            )}
                          </>
                        }
                      />
                    </div>
                  )}
                </div>
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        dataHeader={t('inputs.companyAddress')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='company-address'
                            className='address'
                            placeholder={t('inputs.companyAddressPlaceholder')}
                            value={newCompanyState.address}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                address: e.target.value,
                              })
                            }
                          />
                        }
                      />
                      {addCompanyError && addCompanyError['address'] && (
                        <div className='error'>
                          {addCompanyError['address'].notEmpty}
                        </div>
                      )}
                      {addInternshipError && addInternshipError['address'] && (
                        <div className='error'>
                          {addInternshipError['address'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.companyAddress')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.address}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        dataHeader={t('inputs.city')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='company-city'
                            className='city'
                            placeholder={t('inputs.city')}
                            value={newCompanyState.city}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                city: e.target.value,
                              })
                            }
                          />
                        }
                      />
                      {addCompanyError && addCompanyError['city'] && (
                        <div className='error'>
                          {addCompanyError['city'].notEmpty}
                        </div>
                      )}
                      {addInternshipError && addInternshipError['city'] && (
                        <div className='error'>
                          {addInternshipError['city'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.city')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.city}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        dataHeader={t('inputs.country')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='company-country'
                            className='country'
                            placeholder={t('inputs.country')}
                            value={newCompanyState.country}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                country: e.target.value,
                              })
                            }
                          />
                        }
                      />
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.country')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.country}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='company-postal-code'
                            className='postalCode'
                            placeholder={t('inputs.postalCode')}
                            value={newCompanyState.postalCode}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                postalCode: e.target.value,
                              })
                            }
                          />
                        }
                        dataHeader={t('inputs.postalCode')}
                      />
                      {addCompanyError && addCompanyError['postalCode'] && (
                        <div className='error'>
                          {addCompanyError['postalCode'].notEmpty}
                        </div>
                      )}
                      {addInternshipError && addInternshipError['postalCode'] && (
                        <div className='error'>
                          {addInternshipError['postalCode'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.postalCode')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.postalCode}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <>
                            <Dropdown
                              fullWidth
                              disabledInputSuggestion={
                                props.role === 'student' ||
                              props.studentInternshipCompanySelect
                              }
                              customclass='department-dropdown'
                              handleSelect={item => {
                                if (
                                  item !==
                                t('dropdowns.studiesDropdown.defaultOption')
                                ) {
                                  setStudyDropdownTitle(item.department);
                                  setNewCompanyState({
                                    ...newCompanyState,
                                    economicBranch: item.department,
                                  });
                                } else {
                                  setStudyDropdownTitle(item);
                                  setNewCompanyState({
                                    ...newCompanyState,
                                    economicBranch: '',
                                  });
                                }
                              }}
                              list={
                                studies && studies.length
                                  ? [
                                    {
                                      id: 'all',
                                      name: t(
                                        'dropdowns.studiesDropdown.defaultOption'
                                      ),
                                      department: t(
                                        'dropdowns.studiesDropdown.allDepartments'
                                      ),
                                    },
                                    ...studies,
                                  ].map(study => {
                                    return {
                                      ...study,
                                      name: study.department
                                        .toLowerCase()
                                        .replace(/\w/, firstLetter =>
                                          firstLetter.toUpperCase()
                                        ),
                                      department: study.department
                                        .toLowerCase()
                                        .replace(/\w/, firstLetter =>
                                          firstLetter.toUpperCase()
                                        ),
                                    };
                                  })
                                  : []
                              }
                              headerTitle={studyDropdownTitle}
                              defaultHeaderOption={t(
                                'dropdowns.studiesDropdown.defaultOption'
                              )}
                            />
                            {addCompanyError && addCompanyError['study'] && (
                              <div>
                                <div className='error'>
                                  {addCompanyError['economicBranch'].notEmpty}
                                </div>
                              </div>
                            )}
                          </>
                        }
                        dataHeader={t('inputs.economicBranch')}
                      />
                      {addCompanyError && addCompanyError.economicBranch && (
                        <div className='error'>
                          {addCompanyError.economicBranch.notEmpty}
                        </div>
                      )}
                      {addInternshipError &&
                      addInternshipError.economicBranch && (
                        <div className='error'>
                          {addInternshipError.economicBranch.notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    newCompanyState.economicBranch && (
                      <DataDisplay
                        dataHeader={t('inputs.economicBranch')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={newCompanyState.economicBranch}
                      />
                    )
                  )}
                <div className='new-company-modal__form__company-website'>
                  {props.professorView ||
                  (!props.companyToDisplay && !props.professorView) ? (
                      <>
                        <DataDisplay
                          dataHeader={t('inputs.website')}
                          headerBolded
                          displayInColumn
                          dataSeparatorTopSpacing={4}
                          data={
                            <input
                              name='company-website'
                              className='company-website'
                              placeholder={t('inputs.website')}
                              value={newCompanyState.url}
                              onChange={e =>
                                setNewCompanyState({
                                  ...newCompanyState,
                                  url: e.target.value,
                                })
                              }
                            />
                          }
                        />
                      </>
                    ) : (
                      <DataDisplay
                        dataHeader={t('inputs.website')}
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <a
                            className='company-url'
                            rel='noopener noreferrer'
                            target='_blank'
                            href={`//${newCompanyState.url}`}
                          >
                            {newCompanyState.url}
                          </a>
                        }
                      />
                    )}
                </div>
              </div>
              <div className='col'>
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        removeTopSeparator
                        data={
                          <input
                            name='contact-person-name'
                            className='contactPersonFullName'
                            placeholder={t('inputs.fullNameOfContactPerson')}
                            value={newCompanyState.contactPersonFullName}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                contactPersonFullName: e.target.value,
                              })
                            }
                          />
                        }
                        dataHeader={t('inputs.fullNameOfContactPerson')}
                      />

                      {addCompanyError &&
                      addCompanyError['contactPersonFullName'] && (
                        <div className='error'>
                          {addCompanyError['contactPersonFullName'].notEmpty}
                        </div>
                      )}
                      {addInternshipError &&
                      addInternshipError['contactPersonFullName'] && (
                        <div className='error'>
                          {addInternshipError['contactPersonFullName'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.fullNameOfContactPerson')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      removeTopSeparator
                      data={newCompanyState.contactPersonFullName}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='contact-person-email'
                            className='contactPersonEmail'
                            placeholder={t('inputs.emailOfContactPerson')}
                            value={newCompanyState.contactPersonEmail}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                contactPersonEmail: e.target.value,
                              })
                            }
                          />
                        }
                        dataHeader={t('inputs.emailOfContactPerson')}
                      />

                      {addCompanyError &&
                      addCompanyError['contactPersonEmail'] && (
                        <div className='error'>
                          {addCompanyError['contactPersonEmail'].notEmpty}
                        </div>
                      )}
                      {addInternshipError &&
                      addInternshipError['contactPersonEmail'] && (
                        <div className='error'>
                          {addInternshipError['contactPersonEmail'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.emailOfContactPerson')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.contactPersonEmail}
                    />
                  )}
                {props.professorView ||
                (!props.companyToDisplay && !props.professorView) ? (
                    <>
                      <DataDisplay
                        headerBolded
                        displayInColumn
                        dataSeparatorTopSpacing={4}
                        data={
                          <input
                            name='contact-person-phone'
                            className='contactPersonPhone'
                            placeholder={t('inputs.phoneNumberOfContactPerson')}
                            value={newCompanyState.contactPersonPhone}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                contactPersonPhone: e.target.value,
                              })
                            }
                          />
                        }
                        dataHeader={t('inputs.phoneNumberOfContactPerson')}
                      />

                      {addCompanyError &&
                      addCompanyError['contactPersonPhone'] && (
                        <div className='error'>
                          {addCompanyError['contactPersonPhone'].notEmpty}
                        </div>
                      )}
                      {addInternshipError &&
                      addInternshipError['contactPersonPhone'] && (
                        <div className='error'>
                          {addInternshipError['contactPersonPhone'].notEmpty}
                        </div>
                      )}
                    </>
                  ) : (
                    <DataDisplay
                      dataHeader={t('inputs.phoneNumberOfContactPerson')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={newCompanyState.contactPersonPhone}
                    />
                  )}
                {!props.companyToDisplay && props.role === 'student' && (
                  <>
                    <DataDisplay
                      displayInColumn
                      headerBolded
                      headerTextColor='rgb(108, 0, 0)'
                      dataHeader={t('modal.companyModal.ifMentorIsNotKnown')}
                    />
                    <DataDisplay
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <input
                          name='mentor-first-name'
                          className='mentorFirstName'
                          placeholder={t('inputs.mentorFirstName')}
                          value={newCompanyState.mentorFirstName}
                          onChange={e =>
                            setNewCompanyState({
                              ...newCompanyState,
                              mentorFirstName: e.target.value,
                            })
                          }
                        />
                      }
                      dataHeader={t('inputs.mentorFirstName')}
                    />
                    {newCompanyState.mentorFirstName === '' &&
                      (newCompanyState.mentorLastName !== '' ||
                        newCompanyState.mentorEmail !== '' ||
                        newCompanyState.mentorPhone !== '') && (
                      <div className='error'>
                          Vrijednost polja je obvezna.
                      </div>
                    )}
                    {addInternshipError &&
                      addInternshipError['mentorFirstName'] && (
                      <div className='error'>
                        {addInternshipError['mentorFirstName'].notEmpty}
                      </div>
                    )}
                    <DataDisplay
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <input
                          name='mentor-last-name'
                          className='mentorLastName'
                          placeholder={t('inputs.mentorLastName')}
                          value={newCompanyState.mentorLastName}
                          onChange={e =>
                            setNewCompanyState({
                              ...newCompanyState,
                              mentorLastName: e.target.value,
                            })
                          }
                        />
                      }
                      dataHeader={t('inputs.mentorLastName')}
                    />
                    {newCompanyState.mentorLastName === '' &&
                      (newCompanyState.mentorFirstName !== '' ||
                        newCompanyState.mentorEmail !== '' ||
                        newCompanyState.mentorPhone !== '') && (
                      <div className='error'>
                          Vrijednost polja je obvezna.
                      </div>
                    )}
                    {addInternshipError &&
                      addInternshipError['mentorLastName'] && (
                      <div className='error'>
                        {addInternshipError['mentorLastName'].notEmpty}
                      </div>
                    )}
                    <DataDisplay
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <input
                          name='mentor-email'
                          className='mentorEmail'
                          placeholder={t('inputs.mentorEmail')}
                          value={newCompanyState.mentorEmail}
                          onChange={e =>
                            setNewCompanyState({
                              ...newCompanyState,
                              mentorEmail: e.target.value,
                            })
                          }
                        />
                      }
                      dataHeader={t('inputs.mentorEmail')}
                    />
                    {newCompanyState.mentorEmail === '' &&
                      (newCompanyState.mentorFirstName !== '' ||
                        newCompanyState.mentorLastName !== '' ||
                        newCompanyState.mentorPhone !== '') && (
                      <div className='error'>
                          Vrijednost polja je obvezna.
                      </div>
                    )}
                    {addInternshipError && addInternshipError['mentorEmail'] && (
                      <div className='error'>
                        {addInternshipError['mentorEmail'].notEmpty}
                        {addInternshipError['mentorEmail'].email}
                        {addInternshipError['mentorEmail'].unique &&
                          addInternshipError['mentorEmail'].unique +
                            '\n' +
                            t('modal.companyModal.ifPrivateMentorEmailExists')}
                      </div>
                    )}
                    <DataDisplay
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <input
                          name='mentor-phone-number'
                          className='mentorPhone'
                          placeholder={t('inputs.mentorPhoneNumber')}
                          value={newCompanyState.mentorPhone}
                          onChange={e =>
                            setNewCompanyState({
                              ...newCompanyState,
                              mentorPhone: e.target.value,
                            })
                          }
                        />
                      }
                      dataHeader={t('inputs.mentorPhoneNumber')}
                    />
                  </>
                )}
                {props.professorView && (
                  <>
                    <DataDisplay
                      dataHeader={t('inputs.companyActive')}
                      dataClass='active-company'
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <>
                          <input
                            type='checkbox'
                            id='active'
                            name='active-company'
                            checked={newCompanyState.active}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                active: e.target.checked,
                              })
                            }
                          />
                        </>
                      }
                    />
                    {addCompanyError && addCompanyError.active && (
                      <div className='error'>
                        {addCompanyError.active.notEmpty}
                      </div>
                    )}
                    {addInternshipError && addInternshipError.active && (
                      <div className='error'>
                        {addInternshipError.active.notEmpty}
                      </div>
                    )}
                    {addCompanyMessage === 'tvrtka ima aktivne prakse.' && (
                      <div className='error'>
                        {t('errors.companyHasActiveInternships')}
                      </div>
                    )}
                  </>
                )}
                {props.professorView && props.companyToDisplay && (
                  <div>
                    <DataDisplay
                      dataHeader={t('inputs.companyPublic')}
                      dataClass='public-company'
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <>
                          <input
                            type='checkbox'
                            id='public'
                            name='public-company'
                            checked={newCompanyState.isPublic}
                            onChange={e =>
                              setNewCompanyState({
                                ...newCompanyState,
                                isPublic: e.target.checked,
                              })
                            }
                          />
                        </>
                      }
                    />
                    {addCompanyError && addCompanyError.public && (
                      <div className='error'>
                        {addCompanyError.public.notEmpty}
                      </div>
                    )}
                    {addInternshipError && addInternshipError.public && (
                      <div className='error'>
                        {addInternshipError.public.notEmpty}
                      </div>
                    )}
                    {props.companyToDisplay.isPublic && (
                      <>
                        <DataDisplay
                          dataHeader={t(
                            'inputs.companyHasInternshipRequestsEnabled'
                          )}
                          dataClass='enable-internship-requests'
                          headerBolded
                          displayInColumn
                          dataSeparatorTopSpacing={4}
                          data={
                            <>
                              <input
                                type='checkbox'
                                id='enable-internship-requests'
                                name='enable-internship-requests'
                                checked={
                                  newCompanyState.enableInternshipRequests
                                }
                                onChange={e =>
                                  setNewCompanyState({
                                    ...newCompanyState,
                                    enableInternshipRequests: e.target.checked,
                                  })
                                }
                              />
                            </>
                          }
                        />
                        {addCompanyError &&
                          addCompanyError.enableInternshipRequests && (
                          <div className='error'>
                            {
                              addCompanyError.enableInternshipRequests
                                .notEmpty
                            }
                          </div>
                        )}
                        {addInternshipError &&
                          addInternshipError.enableInternshipRequests && (
                          <div className='error'>
                            {
                              addInternshipError.enableInternshipRequests
                                .notEmpty
                            }
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            {props.professorView &&
              props.companyToDisplay &&
              props.companyToDisplay.isPublic &&
              (loading ? (
                <div className='loader-small' />
              ) : (
                <div className='company-slots-form'>
                  {subjects && (
                    <DataDisplay
                      dataHeader={t('dropdowns.subjectsDropdown.title')}
                      headerBolded
                      displayInColumn
                      dataSeparatorTopSpacing={4}
                      data={
                        <Dropdown
                          customclass='subjects-dropdown'
                          fullWidth
                          handleSelect={item => {
                            setSubjectDropdownTitle(
                              item.name ? item.name : item
                            );
                            setSelectedSubject(item.id ? item.id : item);
                            if (
                              item ===
                              t('dropdowns.subjectsDropdown.defaultOption')
                            ) {
                              setSelectedSubject(null);
                            } else {
                              const companySlot = companySlotsList.find(
                                slot => slot.subjectId === item.id
                              );
                              if (companySlot) {
                                setCompanySlotsConfiguration({
                                  slots: companySlot.slots,
                                  academicYear: companySlot.academicYear,
                                  alreadyExists: true,
                                  slotId: companySlot.id,
                                });
                              } else {
                                setCompanySlotsConfiguration({
                                  slots: null,
                                  academicYear: '',
                                });
                              }
                            }
                          }}
                          list={[
                            ...subjects.map(subject => {
                              return {
                                id: subject.id,
                                name: (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {subject.name}
                                    <div className='selected-subject-study'>
                                      {subject.study.name}
                                    </div>
                                  </div>
                                ),
                                key: subject.name,
                              };
                            }),
                          ]}
                          headerTitle={subjectDropdownTitle}
                          defaultHeaderOption={t(
                            'dropdowns.subjectsDropdown.defaultOption'
                          )}
                        />
                      }
                    />
                  )}
                  {selectedSubject && (
                    <>
                      <input
                        type='number'
                        name='company-slots'
                        min={1}
                        className='company-slots'
                        placeholder={t('inputs.companyNumberOfFreeSlots')}
                        value={
                          companySlotsConfiguration.slots
                            ? companySlotsConfiguration.slots
                            : ''
                        }
                        onChange={e => {
                          if (e.target.value < 1) {
                            setCompanySlotsConfiguration({
                              ...companySlotsConfiguration,
                              slots: 1,
                            });
                          } else {
                            setCompanySlotsConfiguration({
                              ...companySlotsConfiguration,
                              slots: e.target.value,
                            });
                          }
                        }}
                      />
                      {addCompanyError && addCompanyError.slots && (
                        <div className='error'>
                          {addCompanyError.slots.notNull}
                        </div>
                      )}
                      <input
                        name='company-slots-academic-year'
                        className='company-slots-academic-year'
                        placeholder={t('inputs.companyAcademicYearPlaceholder')}
                        disabled={companySlotsConfiguration.alreadyExists}
                        value={companySlotsConfiguration.academicYear}
                        onChange={e =>
                          setCompanySlotsConfiguration({
                            ...companySlotsConfiguration,
                            academicYear: e.target.value,
                          })
                        }
                      />
                      {addCompanyError && addCompanyError.academicYear && (
                        <>
                          <div className='error'>
                            {addCompanyError.academicYear.academicYear}
                          </div>
                          <div className='error'>
                            {addCompanyError.academicYear.notEmpty}
                          </div>
                        </>
                      )}
                      {props.companyToDisplay && props.companyToDisplay.id && (
                        <button
                          className='add-slot-button'
                          onClick={async () => {
                            if (companySlotsConfiguration.alreadyExists) {
                              await executeRequestsWithLoading(
                                [
                                  dispatch(
                                    updateCompanySlotConfiguration(
                                      props.companyToDisplay.id,
                                      companySlotsConfiguration.slotId,
                                      companySlotsConfiguration.slots
                                    )
                                  ),
                                ],
                                setLoading,
                                mountedRef
                              );
                            } else {
                              await executeRequestsWithLoading(
                                [
                                  dispatch(
                                    createCompanySlotConfiguration(
                                      props.companyToDisplay.id,
                                      selectedSubject,
                                      companySlotsConfiguration.slots,
                                      companySlotsConfiguration.academicYear
                                    )
                                  ),
                                ],
                                setLoading,
                                mountedRef
                              );
                            }
                          }}
                        >
                          {t(
                            'inputs.companyInternshipSlots.setCompanyIntenshipsLimit'
                          )}
                        </button>
                      )}
                    </>
                  )}
                  {companySlotsList && companySlotsList.length > 0 && (
                    <>
                      <div className='slots-display-title'>
                        {t(
                          'inputs.companyInternshipSlots.companyInternshipLimitSet'
                        )}
                      </div>
                      <div className='company-slots-display'>
                        <div className='name'>
                          {t('inputs.companyInternshipSlots.subjectName')}
                        </div>
                        <div className='slots'>
                          {t('inputs.companyInternshipSlots.numberOfSlots')}
                        </div>
                        <div className='year'>
                          {t('inputs.companyInternshipSlots.year')}
                        </div>
                        <div className='actions'>
                          {t('inputs.companyInternshipSlots.actions.title')}
                        </div>
                      </div>
                    </>
                  )}
                  {companySlotsList &&
                    companySlotsList.length > 0 &&
                    companySlotsList.map(
                      companySlot =>
                        companySlot.subjectId !== selectedSubject && (
                          <div
                            className='company-slots-display'
                            key={companySlot.id}
                          >
                            <div className='company-slots-display__subject-name name'>
                              {companySlot.subjectName}
                            </div>
                            <div className='slots'>
                              {companySlot.remainingSlots}/{companySlot.slots}
                            </div>
                            <div className='year'>
                              {companySlot.academicYear}
                            </div>
                            <div className='actions'>
                              <button
                                className='edit'
                                onClick={() => {
                                  setSelectedSubject(companySlot.subjectId);
                                  setSubjectDropdownTitle(
                                    companySlot.subjectName
                                  );
                                  setCompanySlotsConfiguration({
                                    slots: companySlot.slots,
                                    academicYear: companySlot.academicYear,
                                    alreadyExists: true,
                                    slotId: companySlot.id,
                                  });
                                }}
                              >
                                {t(
                                  'inputs.companyInternshipSlots.actions.edit'
                                )}
                              </button>
                              <button
                                className='delete'
                                onClick={async () => {
                                  await executeRequestsWithLoading(
                                    [
                                      dispatch(
                                        deleteCompanySlotConfiguration(
                                          props.companyToDisplay.id,
                                          companySlot.id
                                        )
                                      ),
                                    ],
                                    setLoading,
                                    mountedRef
                                  );
                                }}
                              >
                                {t(
                                  'inputs.companyInternshipSlots.actions.delete'
                                )}
                              </button>
                            </div>
                          </div>
                        )
                    )}
                </div>
              ))}
          </>
          <div className='footer'>
            {!props.companyToDisplay && props.role === 'student' && (
              <div className='new-company-modal__form__date-input'>
                <DataDisplay
                  dataHeader={t('inputs.datepickerPlannedInternshipStartDate')}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <div className='datepicker-wrapper'>
                      <DatePicker
                        disabledKeyboardNavigation
                        withPortal
                        locale='hr'
                        minDate={new Date().setDate(new Date().getDate() + 1)}
                        calendarContainer={({
                          className,
                          children,
                          showPopperArrow,
                        }) =>
                          DatepickerCustomContainer({
                            className,
                            children,
                            mainHeaderText: t(
                              'dropdowns.internshipStartDate.mainHeaderText'
                            ),
                            smallHeaderText: t(
                              'dropdowns.internshipStartDate.descriptionHeaderText'
                            ),
                            showPopperArrow,
                          })
                        }
                        fixedHeight
                        className='new-company-datepicker'
                        dateFormat='dd.MM.yyyy.'
                        selected={newCompanyState.startDate}
                        value={newCompanyState.startDate}
                        onChange={date =>
                          setNewCompanyState({
                            ...newCompanyState,
                            startDate: date,
                          })
                        }
                      />
                      <div className='non-clickable-area' />
                    </div>
                  }
                />
              </div>
            )}
            {props.companyToDisplay && !props.professorView && (
              <div className='new-company-modal__form__date-input-select-company'>
                <DataDisplay
                  dataHeader={t('inputs.datepickerPlannedInternshipStartDate')}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={4}
                  data={
                    <div className='datepicker-wrapper'>
                      <DatePicker
                        disabledKeyboardNavigation
                        withPortal
                        locale='hr'
                        minDate={new Date().setDate(new Date().getDate() + 1)}
                        calendarContainer={({
                          className,
                          children,
                          showPopperArrow,
                        }) =>
                          DatepickerCustomContainer({
                            className,
                            children,
                            mainHeaderText: t(
                              'modal.internshipStartdatePicker.mainHeader'
                            ),
                            smallHeaderText: t(
                              'modal.internshipStartdatePicker.descriptionHeaderText'
                            ),
                            showPopperArrow,
                          })
                        }
                        className='select-company-datepicker'
                        dateFormat='dd.MM.yyyy.'
                        onChange={date => setStartDate(date)}
                        selected={startDate}
                        value={startDate}
                      />
                      <div className='non-clickable-area' />
                    </div>
                  }
                />
              </div>
            )}
            {addInternshipError && addInternshipError.startDate && (
              <>
                <div className='error'>
                  {addInternshipError.startDate.dateAfter}
                </div>
                <div className='error'>
                  {addInternshipError.startDate.notEmpty}
                </div>
              </>
            )}
            <button
              className='new-company-modal__send-button'
              type='button'
              disabled={
                props.role === 'student' &&
                !props.companyToDisplay &&
                !(
                  (newCompanyState.mentorLastName === '' &&
                    newCompanyState.mentorEmail === '' &&
                    newCompanyState.mentorFirstName === '' &&
                    newCompanyState.mentorPhone === '') ||
                  (newCompanyState.mentorLastName !== '' &&
                    newCompanyState.mentorFirstName !== '' &&
                    newCompanyState.mentorEmail !== '')
                )
              }
              onClick={e => {
                e.preventDefault();
                handleSendRequest(
                  props.companyToDisplay
                    ? props.professorView
                      ? 'edit'
                      : 'public'
                    : props.role !== 'student'
                      ? 'create-new'
                      : 'private'
                );
              }}
            >
              {props.professorView
                ? props.companyToDisplay
                  ? t('buttons.update')
                  : t('buttons.save')
                : !props.companyToDisplay
                  ? t('buttons.sendPrivateInternshipRequest')
                  : t('buttons.sendInternshipRequestToCompany')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

DisplayOrCreateCompanyModal.propTypes = {
  companyToDisplay: PropTypes.shape({
    oib: PropTypes.string,
    name: PropTypes.string,
    startingDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    address: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    city: PropTypes.string,
    id: PropTypes.number,
    url: PropTypes.string,
    contactPersonPhone: PropTypes.string,
    contactPersonEmail: PropTypes.string,
    contactPersonFullName: PropTypes.string,
    active: PropTypes.bool,
    enableInternshipRequests: PropTypes.bool,
    economicBranch: PropTypes.string,
    isPublic: PropTypes.bool,
  }),
  role: PropTypes.string.isRequired,
  setNewCompanyState: PropTypes.func,
  setCompanyToDisplay: PropTypes.func,
  setShowCompanymodal: PropTypes.func,
  currentSubject: PropTypes.shape(),
  professorView: PropTypes.bool,
  studentInternshipCompanySelect: PropTypes.bool,
};

export default DisplayOrCreateCompanyModal;
