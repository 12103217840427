import { PURGE } from 'redux-persist/lib/constants';
import { RESET_NOTIFICATION, NOTIFICATION_ARRIVED } from '../types';

const initialState = {
  title: null,
  message: null,
};

export const backgroundNotification = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_ARRIVED: {
      return {
        title: action.payload.title,
        message: action.payload.message,
      };
    }
    case RESET_NOTIFICATION: {
      return {
        title: null,
        message: null,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
