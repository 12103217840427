import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal/Modal';
import { AddOrUpdateMentorModal } from '../AddOrUpdateMentorModal/AddOrUpdateMentorModal';
import './SelectMentorForInternshipModal.scss';
import MentorsPage from '../../Professor/MentorListPage/MentorsPage';
import { useTranslation } from 'react-i18next';

const SelectMentorForInternshipModal = props => {
  const { t } = useTranslation();
  const [showAddMentorModal, setShowAddMentorModal] = useState(false);

  return (
    <Modal
      className='select-mentor-for-internship-modal'
      closeModal={() => {
        if (props.allRequestsList) {
          props.setShowAlertModal(true);
        } else {
          props.setShowMentorListModal(false);
        }
      }}
    >
      <>
        <div className='modal-container-select-mentor-for-internship'>
          <div className='modal-container-select-mentor-for-internship__header'>
            <div className='header-info-image'></div>
            <div className='modal-container-select-mentor-for-internship__header__header-text'>
              <div className='header-main-text'>
                {t('modal.selectMentorForInternship.mainHeaderText')}
              </div>
              <div className='header-small-text'>
                {props.hasMentor
                  ? t(
                    'modal.selectMentorForInternship.selectedMentorDescriptionHeaderText'
                  )
                  : t(
                    'modal.selectMentorForInternship.defaultDescriptionHeaderText'
                  )}
              </div>
            </div>
            <div
              className='header-close-icon'
              onClick={() => {
                if (props.allRequestsList) {
                  props.setShowAlertModal(true);
                } else {
                  props.setShowMentorListModal(false);
                }
              }}
            ></div>
          </div>
          <div className='modal-container-select-mentor-for-internship__body'>
            <MentorsPage
              selectedInternshipRequest={props.selectedInternshipRequest}
              resetInterval={props.resetInterval}
              setLoading={props.setLoading}
              studentMentorSelect
              hasMentor={props.hasMentor}
              companyId={props.companyId}
              setShowMentorListModal={props.setShowMentorListModal}
              setShowAddMentorModal={setShowAddMentorModal}
              allRequestsList={props.allRequestsList}
            />
          </div>
        </div>
        {showAddMentorModal && (
          <AddOrUpdateMentorModal
            companyId={props.companyId}
            studentMentorSelect
            setShowAddMentorModal={setShowAddMentorModal}
          />
        )}
      </>
    </Modal>
  );
};

SelectMentorForInternshipModal.propTypes = {
  setShowMentorListModal: PropTypes.func,
  companyId: PropTypes.number,
  allRequestsList: PropTypes.bool,
  hasMentor: PropTypes.bool,
  setShowAlertModal: PropTypes.func,
  setLoading: PropTypes.func,
  resetInterval: PropTypes.func,
  selectedInternshipRequest: PropTypes.object,
};

export default SelectMentorForInternshipModal;
