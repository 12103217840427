import classNames from 'classnames';
import Compressor from 'compressorjs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactSignatureCanvas from 'react-signature-canvas';
import {
  fetchMentorInfo,
  fetchProfessorInfo,
  fetchStudentInfo,
  setSignatureToUser,
} from '../../../store/actions';
import { Pen } from '../../../styles/assets/images';
import Modal from '../Modal/Modal';
import { ImageCropp } from './ImageCropp/ImageCropp';
import './Signing.scss';

const getBase64 = (file, cb, setError) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    setError(error);
  };
};

export const Signing = props => {
  const { t } = useTranslation();
  const imageInputRef = useRef(null);
  const croppedImageRef = useRef(null);

  const user = useSelector(state => state.user);
  const signature = useSelector(state =>
    user.role === 'student'
      ? state.student?.signature
      : user.role === 'mentor'
        ? state.mentors?.mentorInfo?.signature
        : user.role === 'professor'
          ? state.professors?.professorDetails?.signature
          : null
  );
  const signatureError = useSelector(state => state.signature.error);
  const signatureStatus = useSelector(state => state.signature.status);

  const [showCroppImage, setShowCroppImage] = React.useState(false);
  const [imageUploadError, setImageUploadError] = React.useState(false);
  const [imageExtensionError, setImageExtensionError] = React.useState(false);
  const [imageSizeError, setImageSizeError] = React.useState(false);
  const [signatureImage, setSignatureImage] = React.useState(null);
  const dispatch = useDispatch();
  const [trimmedDataURL, setTrimmedDataURL] = React.useState(null);
  var sigPad = useRef({});

  const clear = () => {
    sigPad.clear();
    setTrimmedDataURL(null);
  };
  const trim = () => {
    setTrimmedDataURL(sigPad.toDataURL('image/png'));
  };

  useEffect(() => {
    return () => {
      croppedImageRef.current = null;
      if (trimmedDataURL) {
        setTrimmedDataURL(null);
      }
    };
  }, []);

  useEffect(() => {
    if (trimmedDataURL) {
      if (user.role === 'admin') {
        props.sendSignatureData(trimmedDataURL);
        props.setShowSignatureModal(false);
        if (props.setShowMentorListModal) {
          props.setShowMentorListModal(false);
        }
      } else {
        dispatch(setSignatureToUser(trimmedDataURL));
      }
    }
  }, [trimmedDataURL]);

  useEffect(() => {
    if (signatureStatus === 200 || signatureStatus === 201) {
      props.sendSignatureData(trimmedDataURL);
      props.setShowSignatureModal(false);
      if (user.role === 'student') {
        dispatch(fetchStudentInfo());
      }
      if (user.role === 'mentor') {
        dispatch(fetchMentorInfo());
      }
      if (user.role === 'professor') {
        dispatch(fetchProfessorInfo());
      }
      if (props.setShowMentorListModal) {
        props.setShowMentorListModal(false);
      }
      dispatch({
        type: 'SET_SIGNATURE_TO_USER',
        payload: { error: null, status: null },
      });
    }
  }, [signatureStatus]);

  return (
    <Modal closeModal={() => props.setShowSignatureModal(false)}>
      <div className='modal-container-signature'>
        <div className='modal-container-signature__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-signature__header__header-text'>
            <div className='header-main-text'>
              {t('modal.signing.mainHeaderText')}
            </div>
            <div className='header-small-text'>
              {t('modal.signing.descriptionHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowSignatureModal(false)}
          ></div>
        </div>
        <div className='modal-container-signature__body'>
          {!signatureImage ? (
            <div className='signing'>
              <ReactSignatureCanvas
                penColor='black'
                canvasProps={{
                  className: 'sigCanvas',
                }}
                ref={ref => {
                  sigPad = ref;
                }}
              />
              <div className='signing-small-text'>
                {t('modal.signing.enterSignatureHere')}
              </div>
            </div>
          ) : (
            <div className='sigCanvas-container'>
              {showCroppImage ? (
                <ImageCropp
                  imageToCrop={signatureImage}
                  onImageCropped={croppedImage =>
                    (croppedImageRef.current = croppedImage)
                  }
                />
              ) : (
                <img
                  className='sigCanvas-container__image'
                  src={signatureImage}
                />
              )}
            </div>
          )}
          <div className='modal-container-signature__body__actions'>
            {user.role !== 'admin' && signature && (
              <div
                hidden={signatureImage}
                className='existing-signature'
                onClick={() => {
                  setSignatureImage(signature);
                }}
              >
                <img className='existing-signature__icon' src={Pen} />
                <div className='existing-signature__text'>
                  {t('modal.signing.useExistingSignature')}
                </div>
              </div>
            )}
            <div
              className={classNames({
                'action-buttons': true,
                fullWidth: user.role === 'admin' || !signature,
              })}
            >
              <button
                className='clear'
                type='button'
                onClick={() => {
                  setShowCroppImage(false);
                  if (signatureImage) {
                    setSignatureImage(null);
                  } else {
                    clear();
                  }
                }}
              >
                {signatureImage
                  ? t('modal.signing.no')
                  : t('modal.signing.removeSignature')}
              </button>
              <button
                type='button'
                className='trim'
                onClick={() => {
                  if (signatureImage) {
                    setTrimmedDataURL(
                      showCroppImage
                        ? croppedImageRef.current
                          ? croppedImageRef.current
                          : signatureImage
                        : signatureImage
                    );
                  } else {
                    if (!sigPad.isEmpty()) {
                      trim();
                    }
                  }
                }}
              >
                {signatureImage
                  ? t('modal.signing.yes')
                  : t('modal.signing.confirm')}
              </button>
            </div>
            {signatureError && <div className='error'>{signatureError}</div>}
          </div>
          <div className='signature-upload'>
            <input
              type='file'
              hidden
              id='signature-image'
              className='signature-image-input'
              name='signature-image-scan'
              accept='image/png, image/jpg, image/jpeg'
              ref={imageInputRef}
              onClick={() => {
                imageInputRef.current.value = '';
                setImageExtensionError(false);
                setImageUploadError(false);
                setImageSizeError(false);
                setSignatureImage(null);
              }}
              onChange={e => {
                if (e.target.files[0]) {
                  if (
                    e.target.files[0].type !== 'image/png' &&
                    e.target.files[0].type !== 'image/jpg' &&
                    e.target.files[0].type !== 'image/jpeg'
                  ) {
                    setImageExtensionError(true);
                    return;
                  }
                  new Compressor(e.target.files[0], {
                    quality: 0.5,
                    success(result) {
                      if ((result.size / (1024 * 1024)).toFixed(2) < 1) {
                        getBase64(
                          result,
                          result => {
                            setSignatureImage(result);
                          },
                          setImageUploadError
                        );
                        setShowCroppImage(true);
                      } else {
                        setImageSizeError(true);
                      }
                    },
                    error() {
                      setImageUploadError(true);
                    },
                  });
                }
              }}
            />
            <label
              htmlFor='signature-image'
              className='signature-transfer-image'
            >
              <div className='signature-upload__text'>
                {t('modal.signing.uploadExistingSignature')}
              </div>
            </label>
          </div>
          {imageUploadError && (
            <div className='error'>
              {t('errors.imageUploadErrorEncountered')}
            </div>
          )}
          {imageSizeError && (
            <div className='error'>{t('errors.imageSizeTooBig')}</div>
          )}
          {imageExtensionError && (
            <div className='error'>{t('errors.wrongImageType')}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

Signing.propTypes = {
  setShowSignatureModal: PropTypes.func.isRequired,
  sendSignatureData: PropTypes.func.isRequired,
  setShowMentorListModal: PropTypes.func,
};
