import hr from 'date-fns/locale/hr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  approvePrivateInternshipRequest,
  approvePublicInternshipRequest,
} from '../../../../store/actions';
import DataDisplay from '../../../SharedComponents/DataDisplay/DataDisplay';
import DatepickerCustomContainer from '../../../SharedComponents/DatepickerCustomContainer/DatepickerCustomContainer';
import Modal from '../../../SharedComponents/Modal/Modal';
import './InternshipRequestInfoModal.scss';

registerLocale('hr', hr);

export const InternshipRequestInfoModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const internshipRequestsError = useSelector(
    state => state.internshipRequests.error
  );
  const internshipRequestsStatus = useSelector(
    state => state.internshipRequests.status
  );

  const [startDate, setStartDate] = useState(new Date(props.request.startDate));
  const [shortDescription, setShortDescription] = useState(
    props.request.shortDescription
  );
  const [description, setDescription] = useState(props.request.description);
  const [companyOrganizationalUnit, setcompanyOrganizationalUnit] =
    useState('');

  const handleSend = () => {
    if (props.request.isPrivate) {
      dispatch(
        approvePrivateInternshipRequest(
          props.request.id,
          companyOrganizationalUnit,
          moment(startDate).format('YYYY-MM-DD'),
          true
        )
      );
    } else {
      dispatch(
        approvePublicInternshipRequest(
          props.request.id,
          description,
          shortDescription,
          companyOrganizationalUnit,
          moment(startDate).format('YYYY-MM-DD'),
          true
        )
      );
    }
  };

  useEffect(() => {
    if (internshipRequestsStatus === 201 || internshipRequestsStatus === 200) {
      props.setShowInternshipRequestInfoModal(false);
      props.setShowSelectMentorForInternshipModal(true);
    }
  }, [internshipRequestsStatus]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_ERROR_INTERNSHIP_REQUESTS_LIST',
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
      setStartDate(null);
      setShortDescription(null);
      setDescription(null);
      setcompanyOrganizationalUnit(null);
    };
  }, []);

  return (
    <Modal
      className='internship-request-modal'
      closeModal={() => props.setShowInternshipRequestInfoModal(false)}
    >
      <div className='modal-container-internship-request'>
        <div className='modal-container-internship-request__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-internship-request__header__header-text'>
            <div className='header-main-text'>
              {t('modal.internshipRequestInfoModal.mainHeaderText')}
            </div>
            <div className='header-small-text'>
              {t('modal.internshipRequestInfoModal.descriptionHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowInternshipRequestInfoModal(false)}
          ></div>
        </div>
        <div className='modal-container-internship-request__body'>
          <DataDisplay
            dataHeader={t('inputs.datepickerInternshipStartDate')}
            dataClass='start-date'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <div className='datepicker-wrapper'>
                <DatePicker
                  disabledKeyboardNavigation
                  withPortal
                  locale='hr'
                  fixedHeight
                  calendarContainer={({
                    className,
                    children,
                    showPopperArrow,
                  }) =>
                    DatepickerCustomContainer({
                      className,
                      children,
                      mainHeaderText: t(
                        'dropdowns.internshipStartDate.mainHeaderText'
                      ),
                      smallHeaderText: t(
                        'dropdowns.internshipStartDate.descriptionHeaderText'
                      ),
                      showPopperArrow,
                    })
                  }
                  className='select-internship-start-date-datepicker'
                  dateFormat='dd.MM.yyyy.'
                  onChange={date => {
                    setStartDate(date);
                  }}
                  selected={startDate}
                  value={startDate}
                />
                <div className='non-clickable-area' />
              </div>
            }
          />
          {internshipRequestsError && internshipRequestsError['startDate'] && (
            <div className='error'>
              {internshipRequestsError['startDate'].dateAfter}
            </div>
          )}
          <DataDisplay
            dataHeader={t('inputs.internshipDescription')}
            dataClass='description'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <textarea
                disabled={props.request.isPrivate}
                placeholder={t('inputs.internshipDescriptionPlaceholder')}
                name='description'
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            }
          />
          <DataDisplay
            dataHeader={t('inputs.internshipShortDescription')}
            dataClass='short-description'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <input
                disabled={props.request.isPrivate}
                placeholder={t('inputs.internshipShortDescriptionPlaceholder')}
                name='short-description'
                value={shortDescription}
                onChange={e => setShortDescription(e.target.value)}
              />
            }
          />
          <DataDisplay
            dataHeader={t('inputs.internshipOrganizationalUnit')}
            dataClass='organizational-unit'
            displayInColumn
            headerBolded
            dataSeparatorTopSpacing={10}
            data={
              <input
                placeholder={t(
                  'inputs.internshipOrganizationalUnitPlaceholder'
                )}
                name='organizational-unit'
                value={companyOrganizationalUnit}
                onChange={e => setcompanyOrganizationalUnit(e.target.value)}
              />
            }
          />
          <div className='button-container'>
            <button
              className='modal-container-internship-request__change-password-modal__send-button'
              id='send-btn'
              type='button'
              disabled={
                description === '' ||
                shortDescription === '' ||
                companyOrganizationalUnit === ''
              }
              onClick={e => {
                e.preventDefault();
                handleSend();
              }}
            >
              {t('buttons.approveInternshipRequest')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InternshipRequestInfoModal.propTypes = {
  setShowInternshipRequestInfoModal: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  fetchInternshipRequestsByPage: PropTypes.func.isRequired,
  setShowSelectMentorForInternshipModal: PropTypes.func,
};
