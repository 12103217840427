import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { executeRequestsWithLoading } from '../../../store/actions';
import './Search.scss';

const Search = props => {
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <div className='search'>
      <form
        className='search__form'
        onSubmit={async e => {
          e.preventDefault();
          if (props.setSearchQuery) {
            if (searchTerm === '') {
              props.setSearchQuery(null);
              await executeRequestsWithLoading(
                [dispatch(props.fetchData())],
                props.setLoading,
                mountedRef,
                true
              );
            } else {
              props.setSearchQuery(searchTerm);
              await executeRequestsWithLoading(
                [dispatch(props.fetchDataByName(searchTerm))],
                props.setLoading,
                mountedRef,
                true
              );
            }
          }
        }}
      >
        <input
          type='text'
          disabled={props.disabled}
          placeholder={t('inputs.search')}
          className='search-input'
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </form>
      <small className='search-by-text'>{props.searchingByInfo}</small>
    </div>
  );
};

Search.propTypes = {
  fetchDataByName: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func,
  searchingByInfo: PropTypes.string,
  disabled: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default Search;
