import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import settings from '../../settings';
import { loginError, loginUser } from '../../store/actions';
import { BannerA5 } from '../../styles/assets/images';
import DataDisplay from '../SharedComponents/DataDisplay/DataDisplay';
import MailToButton from '../SharedComponents/MailToButton/MailToButton';
import './Login.scss';

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const browserhistory = useHistory();
  const errorMessage = useSelector(state => state.errorMessage.message);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [displayPassword, setDisplayPassword] = useState(false);

  const buttonRef = useRef(null);
  const mounted = useRef(0);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const msg = urlParams.get('login.message');

    if (msg) {
      dispatch(loginError(msg));
      window.location.replace(`${settings.REACT_APP_SAML_URL}/saml/slo`);
    }
    mounted.current += 1;
  });

  useEffect(() => {
    if (browserhistory && browserhistory.location) {
      if (
        browserhistory.location &&
        browserhistory.location.hash === '#login'
      ) {
        setShowForm(true);
      } else if (mounted.current > 1 && browserhistory.location.hash === '') {
        setShowForm(false);
      }
    }
  }, [browserhistory?.location?.hash]);

  const onStudentLoginClick = () => {
    window.location.assign(
      `${settings.REACT_APP_SAML_URL}/saml/sso?next=${settings.REACT_APP_AUTH_URL}/api/v1/student/saml`
    );
  };

  const onLoginClick = () => {
    if (browserhistory) {
      browserhistory.push('#login', null);
    }
  };

  const handleOnLogin = () => {
    dispatch(loginUser(email, password));
  };

  return (
    <div className='login'>
      <div className='main-site' hidden={showForm}>
        <div className='banner'>
          <img src={BannerA5} alt='Banner' />
          <div className='banner__text'>{t('login.financedBy')}</div>
          <div className='banner__text'>
            <span>{t('login.forMoreInformationPleaseVisit')}</span>
            <a href='https://www.vevu.hr/step-up/'>www.vevu.hr/step-up/</a>
          </div>
        </div>
        <div className='site-header'>
          <div className='site-logo' />
          <div className='site-title'>{t('login.siteTitle')}</div>
          <div className='site-text-under-title'>{t('login.siteSubtitle')}</div>
        </div>
        <div className='login__button-container'>
          <button
            className='login__button-container__student-btn'
            type='button'
            onClick={() => onStudentLoginClick()}
          >
            <div className='login__button-container__student-btn__text'>
              {t('login.studentLogin')}
            </div>
          </button>
          <button
            className='login__button-container__others-btn'
            type='button'
            onClick={() => onLoginClick()}
          >
            <div className='login__button-container__others-btn__text'>
              {t('login.regularLogin')}
            </div>
          </button>
        </div>
        <div className='download-from'>
          <a
            className='google'
            href='https://play.google.com/store/apps/details?id=com.codecons.vevu.praksa_mobile'
          >
            <img
              className='google-badge'
              alt='Dostupno na usluzi Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/hr_badge_web_generic.png'
            />
          </a>
          <a
            href='https://apps.apple.com/hr/app/vevu-praksa/id1583537423?itsct=apps_box_badge&amp;itscg=30200'
            className='apple'
          >
            <img
              src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1637625600&h=3666cecb36c26624a6a2ca155f8ad4af'
              alt='Preuzmite na App Store'
              className='apple-badge'
            />
          </a>
        </div>
        <MailToButton
          label={t('buttons.userSupport')}
          mailto='mailto:vlatko.goljevacki@codecons.com'
          color='white'
          bold
        />
      </div>
      <form
        className={classNames({
          'normal-login-form': showForm || showForm === false,
          isClosed: showForm === false,
        })}
        onSubmit={e => {
          e.preventDefault();
          handleOnLogin();
        }}
        onClick={e => {
          if (
            e.target.className !== 'content-container__email' &&
            e.target.className !== 'content-container__password' &&
            buttonRef.current
          ) {
            buttonRef.current.focus();
          }
        }}
      >
        {showForm && (
          <div className='normal-login-form__form'>
            <div className='content-container'>
              <div className='header'>
                <div
                  type='btn'
                  className='header__back'
                  onClick={() => {
                    browserhistory.goBack();
                    dispatch(loginError(null));
                  }}
                />
                <div className='sign-in-image' />
                <div className='form-title'>
                  <div className='form-title__main'>
                    {t('login.regularLogin')}
                  </div>
                  <div className='form-title__small-text'>
                    {t('login.regularLoginSubTitle')}
                  </div>
                </div>
              </div>
              <div className='body'>
                <DataDisplay
                  removeTopSeparator
                  dataHeader={t('inputs.email')}
                  headerBolded
                  headerFontSize={13}
                  displayInColumn
                  dataSeparatorTopSpacing={6}
                  data={
                    <input
                      className='content-container__email'
                      type='email'
                      aria-autocomplete='inline'
                      onChange={e => {
                        e.preventDefault();
                        setEmail(e.target.value);
                      }}
                      value={email ? email : ''}
                      placeholder={t('inputs.email')}
                      name='email'
                    />
                  }
                />
                <DataDisplay
                  TopSpacing={40}
                  dataHeader={t('inputs.password')}
                  headerBolded
                  headerFontSize={13}
                  displayInColumn
                  dataSeparatorTopSpacing={6}
                  data={
                    <div className='password-input'>
                      <input
                        className='content-container__password'
                        type={displayPassword ? 'text' : 'password'}
                        value={password ? password : ''}
                        onChange={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setPassword(e.target.value);
                        }}
                        placeholder={t('inputs.password')}
                        name='password'
                      />
                      <div
                        onClick={() => setDisplayPassword(!displayPassword)}
                        className={classNames({
                          'password-icon': true,
                          'hide-password': !displayPassword,
                          'show-password': displayPassword,
                        })}
                      />
                    </div>
                  }
                />
                <button
                  ref={buttonRef}
                  className='content-container__btn'
                  autoFocus
                  type='submit'
                >
                  {t('buttons.login')}
                </button>
                {errorMessage && (
                  <div className='login__message'>{errorMessage}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
