import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import './Pagination.scss';
import { useTranslation } from 'react-i18next';
const Pagination = props => {
  const { t } = useTranslation();
  return (
    <div className='pagination'>
      {props.pages !== 0 && props.pages !== null && (
        <>
          <div className='pagination__number-of-entries'>
            <label className='pagination__number-of-entries__label'>
              {t('buttons.pagination.itemsPerPage')}
            </label>
            <select
              className='pagination__number-of-entries__number'
              value={props.dataPerPage}
              onChange={e => {
                props.setPageAndPerPage(props.page, Number(e.target.value));
                props.fetchDataByPage();
              }}
            >
              <option value={100}>100</option>
              <option value={50}>50</option>
              <option value={20}>20</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className='pagination__pages'>
            <ReactPaginate
              activeLinkClassName='pagination__pages__active-page__number noselect'
              activeClassName='pagination__pages__active-page'
              pageLinkClassName='pagination__pages__page noselect'
              nextLabel='►'
              nextClassName='next noselect'
              previousClassName='previous noselect'
              previousLabel='◄'
              breakClassName='break noselect'
              pageCount={props.pages}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              initialPage={props.page - 1}
              forcePage={props.page - 1}
              disableInitialCallback
              onPageChange={e => {
                props.setPageAndPerPage(e.selected + 1, props.dataPerPage);
                props.fetchDataByPage();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

Pagination.propTypes = {
  dataPerPage: PropTypes.number,
  page: PropTypes.number,
  pages: PropTypes.number,
  fetchDataByPage: PropTypes.func,
  setPageAndPerPage: PropTypes.func,
};

export default Pagination;
