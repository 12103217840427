import { PURGE } from 'redux-persist/lib/constants';
import {
  SET_ERROR_INTERNSHIP_REQUESTS_LIST,
  INTERNSHIP_REQUESTS_LIST_FETCHED,
  SET_PAGE_PER_PAGE_INTERNSHIP_REQUESTS_LIST,
} from '../types';

const initialState = {
  internshipRequestsList: null,
  error: null,
  status: null,
  message: null,
  totalPages: null,
  page: 1,
  perPage: 10,
};

export const internshipRequests = (state = initialState, action) => {
  switch (action.type) {
    case INTERNSHIP_REQUESTS_LIST_FETCHED: {
      return {
        ...state,
        internshipRequestsList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
        totalPages: action.payload.totalPages,
      };
    }
    case SET_PAGE_PER_PAGE_INTERNSHIP_REQUESTS_LIST: {
      return {
        ...state,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    }
    case SET_ERROR_INTERNSHIP_REQUESTS_LIST: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
