import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from '../Modal/Modal';
import './ReasonViewModal.scss';

export const ReasonViewModal = props => {
  const checkType = type => {
    switch (type) {
      case 'PROFESSOR':
        return 'Profesor';
      case 'MENTOR':
        return 'Mentor';
      default:
        break;
    }
  };
  return (
    <Modal closeModal={() => props.setShowReasonViewModal(false)}>
      <div className='reason-view-modal'>
        <div className='reason-view-modal__header'>
          <div className='header-info-image'></div>
          <div className='reason-view-modal__header__header-text'>
            <div className='header-main-text'>Pregled razloga odbijanja</div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => {
              props.setShowReasonViewModal(false);
            }}
          ></div>
        </div>
        <div className='reason-view-modal__body'>
          <div className='rejection-date'>
            {moment(props.rejectionDate).format('DD.MM.YYYY.')}
          </div>
          <div>
            {props.rejecterType === 'ADMIN'
              ? 'Admin je odbio dnevnik prakse.'
              : `${checkType(props.rejecterType)} je odbio dnevnik prakse.`}
          </div>
          <div className='rejection-reason'>
            <b>Razlog:</b> {props.reason}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReasonViewModal.propTypes = {
  setShowReasonViewModal: PropTypes.func.isRequired,
  reason: PropTypes.string,
  rejectionDate: PropTypes.number,
  rejecterType: PropTypes.string,
  rejecterName: PropTypes.string,
};
