import { PURGE } from 'redux-persist/lib/constants';

const initialState = {};

export const filteringOptions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTERING_OPTIONS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};
