import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchStudiesProfessor } from '.';
import settings from '../../settings';
import { SET_ERROR_STUDIES } from '../types';

export const createStudy = (study, setLoading) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${settings.REACT_APP_API_URL}/api/v1/studies/create`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          ...study,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_STUDIES,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_STUDIES,
        payload: {
          error: null,
          status: null,
        },
      });
      if (setLoading) {
        setLoading(true);
      }
      await dispatch(fetchStudiesProfessor(setLoading));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDIES,
          payload: {
            error:
              error.response && error.response.data && error.response.data.data,
            status: error.response && error.response.status,
          },
        })
      );
    }
  };
};

export const updateStudy = (study, setLoading) => {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${settings.REACT_APP_API_URL}/api/v1/studies/${study.id}/update`,
        headers: {
          Authorization: `Bearer ${Cookies.get('Accesstoken')}`,
        },
        data: {
          name: study.name,
          codes: study.codes,
          active: study.active,
          department: study.department,
        },
      });
      if (response.status !== 200 && response.status !== 201) {
        throw new Error();
      }
      dispatch({
        type: SET_ERROR_STUDIES,
        payload: {
          error: null,
          status: response.status,
        },
      });
      dispatch({
        type: SET_ERROR_STUDIES,
        payload: {
          error: null,
          status: null,
        },
      });
      if (setLoading) {
        setLoading(true);
      }
      await dispatch(fetchStudiesProfessor(setLoading));
    } catch (error) {
      return (
        error.response &&
        dispatch({
          type: SET_ERROR_STUDIES,
          payload: {
            error:
              error.response && error.response.data && error.response.data.data,
            status: error.response && error.response.status,
          },
        })
      );
    }
  };
};
