import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Rating from 'react-rating';
import { useDispatch, useSelector } from 'react-redux';
import { getInternshipRating, rateInternship } from '../../../store/actions';
import DataDisplay from '../DataDisplay/DataDisplay';
import Modal from '../Modal/Modal';
import './RatingModal.scss';

const RatingModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const internshipError = useSelector(state => state.internship.error);
  const internship = useSelector(state => state.internship);

  const [ratings, setRatings] = useState(null);

  useEffect(() => {
    dispatch(getInternshipRating());
  }, []);

  useEffect(() => {
    if (internship.status === 201 || internship.status === 200) {
      props.setShowRatingModal(false);
    }
  }, [internship.status]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_ERROR_INTERNSHIP',
        payload: {
          error: null,
          status: null,
          message: null,
        },
      });
    };
  }, []);

  const ratingsArray = [
    {
      canEdit: props.userRole === 'admin' || props.userRole === 'professor',
      canSee: props.userRole === 'admin' || props.userRole === 'professor',
      name: 'Ocjene profesora',
      ratings: [
        {
          name: 'Mentor',
          rating: internship.ratings?.professorMentorScore,
          error: 'mentorScore',
        },
        {
          name: 'Student',
          rating: internship.ratings?.professorStudentScore,
          error: 'studentScore',
        },
      ],
    },
    {
      canEdit: props.userRole === 'mentor',
      canSee: props.userRole === 'admin' || props.userRole === 'mentor',
      name: 'Ocjene mentora',
      ratings: [
        {
          name: 'Profesor',
          rating: internship.ratings?.mentorProfessorScore,
          error: 'professorScore',
        },
        {
          name: 'Student',
          rating: internship.ratings?.mentorStudentScore,
          error: 'studentScore',
        },
      ],
    },
    {
      canEdit: props.userRole === 'student',
      canSee: props.userRole === 'admin' || props.userRole === 'student',
      name: 'Ocjene studenta',
      ratings: [
        {
          name: 'Mentor',
          rating: internship.ratings?.studentMentorScore,
          error: 'mentorScore',
        },
        {
          name: 'Profesor',
          rating: internship.ratings?.studentProfessorScore,
          error: 'professorScore',
        },
      ],
    },
  ];

  return (
    <Modal
      className='rating-modal'
      closeModal={() => props.setShowRatingModal(false)}
    >
      <div className='modal-container-rating-modal'>
        <div className='modal-container-rating-modal__header'>
          <div className='header-info-image'></div>
          <div className='modal-container-rating-modal__header__header-text'>
            <div className='header-main-text'>{t('modal.ratingModal.mainHeaderText')}</div>
            <div className='header-small-text'>
              {t('modal.ratingModal.descriptionHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => props.setShowRatingModal(false)}
          ></div>
        </div>
        <div className='modal-container-rating-modal__body'>
          {ratingsArray.map((item, index) => {
            return (
              item.canSee && (
                <DataDisplay
                  key={index}
                  headerTextColor={'#21ba45'}
                  dataHeader={item.name}
                  headerBolded
                  displayInColumn
                  dataSeparatorTopSpacing={10}
                  data={item.ratings.map((rating, ratingIndex) => {
                    return item.canEdit ? (
                      <DataDisplay
                        key={`${index}-${ratingIndex}`}
                        dataHeader={rating.name}
                        headerBolded
                        removeTopSeparator
                        data={
                          <>
                            <Rating
                              emptySymbol={
                                <img
                                  src='assets/images/star-empty.png'
                                  className='icon'
                                />
                              }
                              fullSymbol={
                                <img
                                  src='assets/images/star-full.png'
                                  className='icon'
                                />
                              }
                              initialRating={
                                ratings?.[rating.error]
                                  ? ratings?.[rating.error]
                                  : rating.rating
                              }
                              readonly={!!rating.rating}
                              onClick={number => {
                                setRatings({
                                  ...ratings,
                                  [rating.error]: number,
                                });
                              }}
                            />
                            {internshipError &&
                              internshipError[rating.error] && (
                              <div className='error'>
                                {
                                  internshipError[rating.error]
                                    .notEmptyForAuthTypes
                                }
                              </div>
                            )}
                          </>
                        }
                      />
                    ) : (
                      <DataDisplay
                        key={`${index}-${ratingIndex}`}
                        dataHeader={rating.name}
                        headerBolded
                        removeTopSeparator
                        data={
                          <Rating
                            initialRating={rating.rating ? rating.rating : 0}
                            readonly
                            emptySymbol={
                              <img
                                src='assets/images/star-empty.png'
                                className='icon'
                              />
                            }
                            fullSymbol={
                              <img
                                src='assets/images/star-full.png'
                                className='icon'
                              />
                            }
                          />
                        }
                      />
                    );
                  })}
                />
              )
            );
          })}
          <div className='button-container'>
            <button
              className='modal-container-rating-modal__rating-modal__send-button'
              id='send-btn'
              type='button'
              disabled={!ratings}
              onClick={e => {
                e.preventDefault();
                if (ratings) {
                  dispatch(rateInternship(ratings));
                }
              }}
            >
              {t('buttons.saveRating')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RatingModal.propTypes = {
  setShowRatingModal: PropTypes.func,
  userRole: PropTypes.string,
};

export default RatingModal;
