/* istanbul ignore file */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { audit } from './auditReducer';
import { backgroundNotification } from './backgroundNotification';
import { comments } from './commentsReducer';
import { companies } from './companyReducer';
import { createdAccount } from './createdAccountReducer';
import { errorMessage } from './errorReducer';
import { internshipsList } from './internshipListReducer';
import { internship, retryGeneration } from './internshipReducer';
import { internshipRequests } from './internshipRequestsListReducer';
import { loader } from './loadingReducer';
import { mentors } from './mentorReducer';
import { professors } from './professorReducer';
import { scriptsAllStudents, scriptsSingleStudent, scriptsExistingStudents } from './scriptsReducer';
import { signature } from './signatureReducer';
import { studentList } from './studentListReducer';
import { student } from './studentReducer';
import { studies } from './studiesReducer';
import { subjects } from './subjectsReducer';
import { user } from './userReducer';
import { filteringOptions } from './filteringReducer';
const internshipImagesPersistConfig = {
  key: 'internship',
  storage: storage,
  blacklist: ['currentLogImages'],
  timeout: null,
};

export const reducers = combineReducers({
  user,
  student,
  internship: persistReducer(internshipImagesPersistConfig, internship),
  companies,
  professors,
  comments,
  errorMessage,
  subjects,
  studentList,
  mentors,
  internshipsList,
  createdAccount,
  audit,
  signature,
  loader,
  internshipRequests,
  scriptsAllStudents,
  scriptsSingleStudent,
  scriptsExistingStudents,
  backgroundNotification,
  studies,
  retryGeneration,
  filteringOptions,
});
