import { PURGE } from 'redux-persist/lib/constants';
import {
  REMOVE_DATA_ALL,
  REMOVE_DATA_ALL_EXISTING,
  REMOVE_DATA_SINGLE,
  SET_ERROR_SYNC,
  SET_ERROR_SYNC_ALL,
  SET_ERROR_SYNC_ALL_EXISTING,
  SET_STATUS_CHECK_ALL,
  SET_STATUS_CHECK_ALL_EXISTING,
  SET_STATUS_CHECK_SINGLE,
  SET_SYNC_ALL_DATA,
  SET_SYNC_ALL_EXISTING_DATA,
  SET_SYNC_SINGLE_DATA,
} from '../types';

const initialState = {
  data: null,
  error: null,
  status: null,
  message: null,
};

const initialStateExisting = {
  data: null,
  error: null,
  status: null,
  message: null,
};

const initialStateSingleStudent = {
  data: null,
  error: null,
  status: null,
  message: null,
};

export const scriptsAllStudents = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYNC_ALL_DATA: {
      return {
        data: action.payload.data,
        error: null,
        status: action.payload.status,
        message: null,
      };
    }
    case SET_STATUS_CHECK_ALL: {
      return {
        ...state,
        message: action.payload.data,
        error: null,
        status: action.payload.status,
      };
    }
    case SET_ERROR_SYNC_ALL: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case REMOVE_DATA_ALL: {
      return {
        ...state,
        data: null,
        status: null,
      };
    }
    case PURGE: {
      return initialState;
    }
    default:
      return state;
  }
};

export const scriptsExistingStudents = (state = initialStateExisting, action) => {
  switch (action.type) {
    case SET_SYNC_ALL_EXISTING_DATA: {
      return {
        data: action.payload.data,
        error: null,
        status: action.payload.status,
        message: null,
      };
    }
    case SET_STATUS_CHECK_ALL_EXISTING: {
      return {
        ...state,
        message: action.payload.data,
        error: null,
        status: action.payload.status,
      };
    }
    case SET_ERROR_SYNC_ALL_EXISTING: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case REMOVE_DATA_ALL_EXISTING: {
      return {
        ...state,
        data: null,
        status: null,
      };
    }
    case PURGE: {
      return initialStateExisting;
    }
    default:
      return state;
  }
};

export const scriptsSingleStudent = (
  state = initialStateSingleStudent,
  action
) => {
  switch (action.type) {
    case SET_SYNC_SINGLE_DATA: {
      return {
        data: action.payload.data,
        error: null,
        status: action.payload.status,
        message: null,
      };
    }
    case SET_STATUS_CHECK_SINGLE: {
      return {
        ...state,
        message: action.payload.data,
        error: null,
        status: action.payload.status,
      };
    }
    case SET_ERROR_SYNC: {
      return {
        ...state,
        error: action.payload.error,
        status: action.payload.status,
        message: action.payload.message,
      };
    }
    case REMOVE_DATA_SINGLE: {
      return {
        ...state,
        data: null,
        status: null,
      };
    }
    case PURGE: {
      return initialStateSingleStudent;
    }
    default:
      return state;
  }
};
