import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadPassedStudents,
  executeRequestsWithLoading,
} from '../../../store/actions';
import Modal from '../../SharedComponents/Modal/Modal';
import DataDisplay from '../DataDisplay/DataDisplay';
import './DownloadPassedStudentsModal.scss';

const DownloadPassedStudentsModal = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const studentList = useSelector(state => state.studentList);

  const { setShowDownloadPassedStudentsModal } = props;
  const [year, setYear] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (studentList.status === 201 || studentList.status === 200) {
      setShowDownloadPassedStudentsModal(false);
    }
  }, [studentList]);

  return (
    <Modal closeModal={() => setShowDownloadPassedStudentsModal(false)}>
      <div className='download-passed-students-modal'>
        <div className='download-passed-students-modal__header'>
          <div className='header-info-image'></div>
          <div className='download-passed-students-modal__header__header-text'>
            <div className='header-main-text'>
              {t('modal.downloadPassedStudents.mainHeaderText')}
            </div>
            <div className='header-small-text'>
              {t('modal.downloadPassedStudents.descriptionHeaderText')}
            </div>
          </div>
          <div
            className='header-close-icon'
            onClick={() => setShowDownloadPassedStudentsModal(false)}
          ></div>
        </div>
        <div className='download-passed-students-modal__body'>
          {loading && <div className='loader-small' />}
          <div hidden={loading}>
            <DataDisplay
              dataHeader={t('inputs.year')}
              headerBolded
              displayInColumn
              dataSeparatorTopSpacing={4}
              data={
                <>
                  <input
                    type='number'
                    placeholder={t('inputs.yearPlaceholder')}
                    min={2005}
                    max={moment().get('year')}
                    onChange={e => setYear(e.target.value)}
                  />
                  {studentList && studentList.error && (
                    <div className='error'>{studentList.error}</div>
                  )}
                </>
              }
            />
            <button
              id='send-btn'
              disabled={!year}
              onClick={async () => {
                if (year) {
                  await executeRequestsWithLoading(
                    [dispatch(downloadPassedStudents(year))],
                    setLoading,
                    mountedRef
                  );
                }
              }}
            >
              {t('buttons.send')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

DownloadPassedStudentsModal.propTypes = {
  setShowDownloadPassedStudentsModal: PropTypes.func.isRequired,
};

export default DownloadPassedStudentsModal;
